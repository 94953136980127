import {useUserStore} from '@/store/user'
import {useCartStore} from '@/store/cart'

export const useAddressHelper = () => {

  const cartStore = useCartStore()
  const userStore = useUserStore()

  const getDeliveryAddressUuid = function() {
    if (userStore.loggedIn) {
      return cartStore.deliveryAddressUuid
    }
    return null
  }

  const getDeliveryAddress = function() {
    if (userStore.loggedIn) {
      return cartStore.deliveryAddress
    }
    return null
  }

  return {
    getDeliveryAddressUuid,
    getDeliveryAddress
  }
}
