import sha256 from 'crypto-js/sha256'
import Base64 from 'crypto-js/enc-base64'

export const getTimestampHash = async function(url, localEnv) {
  const sourceKey = useRuntimeConfig().public.SOURCE_KEY || (localEnv && localEnv.SOURCE_KEY)
  // eslint-disable-next-line eqeqeq
  if (sourceKey == null) {
    return null
  }
  const timestamp = new Date(Date.now())
  // Take current minutes divided by 5 rounded down, multiplied by 5 gives us the last 5 minute interval.
  // Add 5 minutes to get the next 5 minute interval.
  const newMinutes = ((Math.floor(timestamp.getMinutes() / 5) * 5) + 5)
  // Add an hour if we're going to the next hour, ergo > 60 minutes
  if (newMinutes >= 60) {
    timestamp.setHours(timestamp.getHours() + 1)
  }
  // Get cleaned up minute value by using modulo
  timestamp.setMinutes(newMinutes % 60)
  timestamp.setSeconds(0)
  timestamp.setMilliseconds(0)
  const timestampString = timestamp.toISOString()

  return {hash: await wrappersha256(url + sourceKey + timestampString), timestamp: timestampString}
}

async function wrappersha256(message) {
  const arrayBuffer = sha256(message)
  return Base64.stringify(arrayBuffer)
}
