import {useUserStore} from '@/store/user'
import {USER_RIGHTS_MAPPING} from '~/assets/js/constants'

export const userRightsMixin = {
  data() {
    return {
      USER_RIGHTS_MAPPING
    }
  },
  methods: {
    hasRight(right) {
      return useUserStore().rights && useUserStore().rights?.some((item) => item === right)
    }
  }
}

function hasRight(right) {
  const userStore = useUserStore()
  return userStore.rights && userStore.rights?.some((item) => item === right)
}

export default defineNuxtPlugin(nuxtApp => {
  if (!nuxtApp.vueApp.__user_rights_mixin__) {
    nuxtApp.vueApp.__user_rights_mixin__ = true
    nuxtApp.vueApp.mixin(userRightsMixin)
    nuxtApp.provide('hasRight', hasRight)
  }
})
