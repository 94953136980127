//seems to be working fine
let uuid = 0

export default defineNuxtPlugin(nuxtApp => {
  if (!nuxtApp.vueApp.__uuid_mixin__) {
    nuxtApp.vueApp.__uuid_mixin__ = true
    nuxtApp.vueApp.mixin({
      beforeCreate() {
        this.uuid = uuid.toString()
        uuid += 1
      }
    })
  }
})
