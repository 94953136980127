import axios from '@/services/axios_auth'
import Query from '~/assets/js/query'

export const useCartOptionsService = () => {
  const baseurl = useRuntimeConfig().public.BASIC_API_URL || 'https://apim-enext-stg.azure-api.net'

  const cart_options_axios = axios.create({
    baseURL: useRuntimeConfig().public.PURCHASE_API || (baseurl + '/purchase-api/1.0.0')
  })

  async function getAlternativePlants(plant) {
    if (typeof plant === 'string' && plant !== '') {
      const params = new Query().where('plant="' + plant + '"').toOdataParams()
      const res = await cart_options_axios.get('/alternativePlants', {params}).catch(() => {return null})
      return res && res.data ? res.data.value : []
    } else {
      const res = await cart_options_axios.get('/alternativePlants').catch(() => {return null})
      return res && res.data ? res.data.value : []
    }
  }

  async function getCertificates() {
    const res = await cart_options_axios.get('/certificates')
      .catch(() => [])
    return res && res.data ? res.data.value : []
  }

  return {
    getAlternativePlants,
    getCertificates
  }
}
