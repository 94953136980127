export default defineNuxtPlugin(() => {
  window.addEventListener('CookiebotOnDialogDisplay', function() {
    var cookieDialogBody = document.getElementById('CybotCookiebotDialogBodyContentText').querySelector('a')
    if (cookieDialogBody) {
      cookieDialogBody.addEventListener('click', function () {
        document.getElementById('CybotCookiebotDialogBodyButtonDecline').click()
      })
    }
  })
  window.addEventListener('CookiebotOnLoad', function() {
    var cookieDeclarationLink = document.querySelector('.CookieDeclaration > p a')
    if (cookieDeclarationLink) {
      var linkTextElement = document.createElement('span')
      linkTextElement.innerHTML = cookieDeclarationLink.innerHTML
      cookieDeclarationLink.parentNode.replaceChild(linkTextElement, cookieDeclarationLink)
    }
  })
})
