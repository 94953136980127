import baseAxios from 'axios'

import {getTimestampHash} from '../services/helper/url_timestamp_helper.js'
import {FST_REQUEST_AUTH_HEADER} from '../assets/js/constants'
import {initResponseInterceptor} from './interceptors'

const create = function(...axiosConfig) {
  let axiosApiInstance = baseAxios.create(...axiosConfig)
  if (process.server) {
    initResponseInterceptor(axiosApiInstance, getContext)
    axiosApiInstance.interceptors.request.use(
      async config => {
        const result = await getTimestampHash(baseAxios.getUri(config))
        if (result && result.hash && result.timestamp) {
          if (config?.headers) {
            config.headers[FST_REQUEST_AUTH_HEADER.HASH] = result.hash
            config.headers[FST_REQUEST_AUTH_HEADER.TIMESTAMP] = result.timestamp
          }
        }
        return config
      },
      error => {
        Promise.reject(error)
      })
    return axiosApiInstance
  }

  initResponseInterceptor(axiosApiInstance, getContext)
  axiosApiInstance.interceptors.request.use(
    async config => {
      // Add timestamp hash header
      const result = await getTimestampHash(baseAxios.getUri(config))
      if (result && result.hash && result.timestamp) {
        if (config?.headers) {
          config.headers[FST_REQUEST_AUTH_HEADER.HASH] = result.hash
          config.headers[FST_REQUEST_AUTH_HEADER.TIMESTAMP] = result.timestamp
        }
      }
      return config
    },
    error => {
      Promise.reject(error)
    })

  return axiosApiInstance
}

const setContext = function(ctxInstance) {
  ctx = ctxInstance
}

const getContext = function() {
  return ctx
}

export default {
  ...baseAxios,
  create,
  setContext,
  getContext
}
