import {useUserStore} from '@/store/user'

export default defineNuxtPlugin({
  name: 'socialintents',
  setup(nuxtApp) {
    const scriptPath = useRuntimeConfig()?.public?.SOCIALINTENTS_SCRIPT

    // global callback to tell us the script is ready
    window.onSIApiReady = () => {
      nuxtApp.vueApp.__siapi__ready = true
      nuxtApp.runWithContext(async () => {
        const us = useUserStore()
        if (us.name && us.email) {
          // eslint-disable-next-line no-undef
          SI_API?.setChatInfo(us.name, us.email, '', '', '')
        }
      })
    }

    // load script when user is logged in and has the config set up
    nuxtApp.provide('loadSocialIntents', () => {
      // eslint-disable-next-line eqeqeq
      if (scriptPath != null && document?.head != null) {
        const script = document.createElement('script')
        script.src = scriptPath
        script.async = true
        document.head.appendChild(script)
      }
    })

    // dynamically set the chat info
    nuxtApp.provide('setNameEmailSocialIntents', (name, email) => {
      // eslint-disable-next-line no-undef
      SI_API?.setChatInfo(name, email, '', '', '')
    })

    nuxtApp.provide('hideTabSocialIntents', () => {
      // eslint-disable-next-line no-undef
      SI_API?.hideTab()
    })

    nuxtApp.provide('showTabSocialIntents', () => {
      // eslint-disable-next-line no-undef
      SI_API?.showTab()
    })
  }
})
