import {REGEX_MAPPING} from '../../../assets/js/constants'

export const getMappedProduct = function(product) {
  if (product) {
    let productObjectMerged = {}
    if (product.salesAreas) {
      productObjectMerged = {...productObjectMerged, ...product.salesAreas[0]}
    }
    if (product.plants) {
      productObjectMerged = {...productObjectMerged, ...product.plants[0]}
    }
    productObjectMerged = {...productObjectMerged, ...product}
    return productObjectMerged
  }
  return null
}
//for new experimental search
export const getMappedProductNew = function(product) {
  if (product) {
    let productObjectMerged = {}
    if (product.salesAreas) {
      productObjectMerged = {...productObjectMerged, ...product.salesAreas[0]}
    }
    if (product.plants) {
      productObjectMerged = {...productObjectMerged, ...product.plants[0]}
    }
    const productVariant = product.variants?.length > 0 ? product.variants.find(v => v.sku = product.sku.toString()) : null
    //add mapping for locales.[locale]
    productObjectMerged = {...productObjectMerged, ...product,
      designSketch: product.designSketchUri,
      materialPrimary: product.material1Name,
      materialShoreHardness: product.material1ShoreHardness,
      materialCompound: product.material1Compound,
      brandName: product.brand,
      variant: product.variants?.length > 0 ? {
        amount: productVariant.packagingQuantity,
        sku: null,
        subType: productVariant.type,
        type: productVariant.typeCategory,
        unit: productVariant.packagingQuantityUnitOfMeasure
      } : null,
      otherVariants: product.variants?.length > 0 ? product.variants.filter(v => v.sku !== product.sku.toString()).map(v => ({
        amount: v.packagingQuantity,
        sku: v.sku,
        subType: v.type,
        type: v.typeCategory,
        unit: v.packagingQuantityUnitOfMeasure
      })) : null,
      locales: {
        en: product.locales_EN_SalesText,
        de: product.locales_DE_SalesText,
        fr: product.locales_FR_SalesText,
        es: product.locales_ES_SalesText,
        it: product.locales_IT_SalesText
      },
      legacysku: Array.isArray(product.legacysku)
        ? product.legacysku.length > 0 ? product.legacysku[0] : null
        : product.legacysku ?? null,
      materials: product.materialNames?.length > 0 ? product.materialNames.map(m => ({key: m})) : null,
      assets: product.assets?.length > 0 ? product.assets.map(m => ({
        assetType: m.type,
        languageShortCode: m.languageCode,
        lookupKey: m.uri
      })) : null,
      weightUnitOfMeasure: product.netWeightUnitOfMeasure
    }
    return productObjectMerged
  }
  return null
}

export const getMappedProducts = function(products) {
  let mergedProducts = []
  if (products?.products) {
    products.products.forEach(product => {
      mergedProducts.push(getMappedProduct(product))
    })
    return mergedProducts
  }
  //for new experimental search
  if (products.searchResults) {
    products.searchResults.forEach(product => {
      mergedProducts.push(getMappedProductNew(product))
    })
    return mergedProducts
  }
  return null
}

export const getMappedProductAttributes = function(attr, product, experimentalMode = false) {
  const result = []
  if (attr && Array.isArray(attr) && product) {
    for (let i = 0; i < attr.length; i++) {
      const currentAttr = attr[i]

      // only show attributes that have an index path
      if (currentAttr?.Attribute?.IndexPath) {
        const indexPath = experimentalMode ? mapProductAttributeNames(currentAttr.Attribute.IndexPath) : currentAttr.Attribute.IndexPath
        const indexPathSplit = indexPath.split('/')
        const isMaterialsWithIndex = REGEX_MAPPING.MaterialWithIndex.test(indexPath)
        const isAttributes = REGEX_MAPPING.Attributes.test(indexPath)
        const isAttributesNew = REGEX_MAPPING.AttributesNew.test(indexPath)
        if (isMaterialsWithIndex) {
          const materials = product[indexPathSplit[0]]
          if (materials && Array.isArray(materials) && materials.length > 0) {
            const indexObject = materials[Number.parseInt(indexPathSplit[1])]
            if (indexObject) {
              const value = indexObject[indexPathSplit[2]]
              if (value) {
                const objectToSave = currentAttr
                objectToSave.value = value
                objectToSave.valueDec = null
                result.push(objectToSave)
              }
            }
          }
        } else if (isAttributes && indexPathSplit.length === 3 && product['characteristics']) {
          const productAttr = product['characteristics'].find(pa => pa.key === indexPathSplit[2])
          if (productAttr) {
            result.push({...currentAttr, ...productAttr})
          }
        } else if (isAttributesNew && indexPathSplit.length === 2 && product['characteristics']) {
          const productAttr = product['characteristics'].find(pa => pa.name === indexPathSplit[1])
          if (productAttr) {
            result.push({...currentAttr, ...productAttr})
          }
        } else if (indexPathSplit.length === 1) {
          const getCleanedIndexPath = indexPathSplit[0].replace(REGEX_MAPPING.ToLowerCase, (match, p1, p2) => p1.toLowerCase() + p2)
          if (product[getCleanedIndexPath]) {
            const objectToSave = currentAttr
            const value = product[getCleanedIndexPath]
            if (typeof value === 'number') {
              objectToSave.value = null
              objectToSave.valueDec = value
            } else {
              objectToSave.value = value
              objectToSave.valueDec = null
            }
            result.push(objectToSave)
          }
        }
      }
    }
  }
  return result
}

export const getMappedProductCategoryImages = function (product, category, hasAssets) {
  if (product && category) {
    product.designSketch = category.DesignSketch ? category.DesignSketch : category.DesignSketchAssetURI
    product.designInstallationSketch = category.InstallationSketch ? category.InstallationSketch : category.InstallationSketchAssetURI
    if (!hasAssets && category.GalleryImages) {
      product.galleryImages = category.GalleryImages
    }
  }
  return product
}

export const mapProductAttributeNames = function (name) {
  let newName = ''
  switch (name) {
  case 'MaterialPrimary':
    newName = 'material1Name'
    break
  case 'materialprimary':
    newName = 'material1name'
    break
  case 'BrandName':
    newName = 'brand'
    break
  case 'brandname':
    newName = 'brand'
    break
  case 'ShoreHardnessPrimary':
    newName = 'material1ShoreHardness'
    break
  case 'shorehardnessprimary':
    newName = 'material1shorehardness'
    break
  case 'PolymerPrimary':
    newName = 'material1Compound'
    break
  case 'polymerprimary':
    newName = 'material1compound'
    break
  default:
    newName = name
    break
  }
  if (name?.includes('Characteristics/key/')) {
    newName = name.replace('Characteristics/key/', 'characteristics/')
  }
  if (name?.includes('CharacteristicsValue/')) {
    newName = name.replace('CharacteristicsValue/', 'characteristicsValues/')
  }
  return newName ? newName.charAt(0).toLowerCase() + newName.slice(1) : newName
}
