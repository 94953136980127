import {useUserStore} from '@/store/user'
//provide loggedIn without include store in every component
export default defineNuxtPlugin((nuxtApp) => {
  const userStore = useUserStore()
  const loggedIn = computed(() => userStore.loggedIn)
  const name = computed(() => userStore.name)

  let auth = reactive({
    loggedIn: loggedIn,
    user: {
      name: name
    }
  })

  nuxtApp.provide('auth', auth)
})
