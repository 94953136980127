import {useUserStore} from '@/store/user'
import jwt_decode from 'jwt-decode'

export default defineNuxtRouteMiddleware(async (to) => {
  if (!process.server) {
    const app = useNuxtApp()
    const userStore = useUserStore()
    const accessToken = userStore.accessToken
    if (accessToken) {
      const decodedToken = jwt_decode(accessToken)
      if (decodedToken
        && (decodedToken.loginWizardDone === 'false' || decodedToken.redirectTandC === 'true')
        && Object.prototype.hasOwnProperty.call(decodedToken, 'isUnknownUser')
        && Object.prototype.hasOwnProperty.call(decodedToken, 'redirectTandC')) {
        if (to.path !== app.$globalization.localePath('/setup')) {
          return navigateTo(app.$globalization.localePath('/setup'))
        } else {
          // if user is already on setup or is logging out, let him be
          return
        }
      }
    }

    if (to.path === app.$globalization.localePath('/setup')) {
      return navigateTo('/')
    }
    return
  }
})
