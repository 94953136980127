import jwt_decode from 'jwt-decode'
import {useUserStore} from '@/store/user'

export default defineNuxtPlugin(nuxtApp => {
  const appInsights = nuxtApp.$appInsights
  const userStore = useUserStore()
  if (appInsights) {
    appInsights.addTelemetryInitializer((envelope) => {
      try {
        envelope.data['FstSource'] = 'frontend'
        if (nuxtApp.$auth.loggedIn) {
          const userUuid = userStore.userUuid
          const companyUuid = userStore.companyUuid
          envelope.data['UserUuid'] = userUuid
          envelope.data['CustomerUuid'] = companyUuid
          const accessToken = userStore.accessToken
          if (accessToken) {
            const decodedToken = jwt_decode(accessToken)
            if (decodedToken && decodedToken.companyNo) {
              envelope.data['CompanyNo'] = decodedToken.companyNo
            }
          }
        }
      }
      catch {
        // ignore errors gracefully
      }
    })
  }
})
