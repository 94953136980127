import jwt_decode from 'jwt-decode'
import * as Sentry from '@sentry/browser'
import {useUserStore} from '@/store/user'

export const B2C_ERRORCODES = {
  USER_DOES_NOT_EXIST: 'AADB2C99002',
  FORGOT_PASSWORD: 'AADB2C90118',
  USER_CANCELLATION: 'AADB2C90091',
  SERVICE_UNAVAILABLE: 'AADB2C90035',
  PROCESS_ERROR: 'AADB2C90037',
  UNHANDLED_EXCEPTION: 'AADB2C90048'
}

export function getMatchedComponents (route, matches = false, prop = 'components') {
  return Array.prototype.concat.apply([], route.matched.map((m, index) => {
    return Object.keys(m[prop]).map((key) => {
      matches && matches.push(index)
      return m[prop][key]
    })
  }))
}

export function routeOption (route, key, value) {
  return route.matched.some((m) => {
    if (process.client) {
      return Object.values(m.components).some((component) => component.options && component.options[key] === value)
    } else {
      return Object.values(m.components).some((component) => Object.values(component._Ctor).some((ctor) => ctor.options && ctor.options[key] === value))
    }
  })
}

export function isTokenExpired(token) {
  if (token) {
    try {
      const now = Date.now()
      let {exp} = jwt_decode(token)
      if (exp) {
        const timeSlackMillis = 500
        exp = exp * 1000 - timeSlackMillis

        if (now < exp) {
          return false
        }
      }
    } catch (err) {
      try {
        Sentry.captureMessage('Token expired check failed', {
          level: 'warning'
        })
      }
      catch (e) {
        // do nothing
      }
    }
  }
  return true
}

export async function checkAndRefreshB2CTokens(ctx) {
  let {
    tokenExpired,
    refreshTokenExpired,
    isRefreshable
  } = ctx.$auth.check(true)

  // for testing only, but doen't harm anyone
  if (window && window.forceRefreshTokens) {
    tokenExpired = true
    window.forceRefreshTokens = false
  }

  if (refreshTokenExpired) {
    resetAllTokens(ctx)
  } else if (tokenExpired) {
    if (isRefreshable) {
      try {
        await ctx.$auth.refreshTokens()
      } catch (error) {
        resetAllTokens(ctx)
      }
    } else {
      resetAllTokens(ctx)
    }
  }
}

export function getB2CError(description) {
  if (!description) {
    return null
  }
  let match = /AADB2C(\d{5})+/.exec(description)
  if (match) {
    return match[0]
  }

  return null
}

function resetAllTokens(ctx) {
  ctx.$auth.reset()
  const userStore = useUserStore(ctx.$pinia)
  if (userStore) {
    userStore.setAccessToken('')
  }
}
