import {defineStore} from 'pinia'
import {useCustomerService} from '@/composables/customer_service.js'
import {useUserStore} from '@/store/user.js'

export const useCustomerSettingsStore = defineStore('CustomerSettingsModule', {
  state: () => ({
    settings: [],
    settingsValues: [],
    settingsAge: 0,
    settingsValuesAge: 0,
    settingsCustomer: 'none',
    useOwnArticleNo: false

  }),
  getters: {
    currentCustomerSettings() {
      return this.settings
    },
    currentCustomerSettingsValues() {
      return this.settingsValues
    }
  },
  actions: {
    async fetchCustomerSettings(force = false) {
      const {getSettings} = useCustomerService()
      const CUSTOMER_SETTINGS_MAX_AGE = useRuntimeConfig().public.CUSTOMER_SETTINGS_MAX_AGE || 3600000
      const currentTime = new Date().getTime()
      const userStore = useUserStore()
      const currentCustomerUuid = userStore.loadedCustomerUuid
      if (force || (currentCustomerUuid !== 'default' &&
      (currentCustomerUuid !== this.settingsCustomer.value ||
        currentTime > this.settingsAge.value + CUSTOMER_SETTINGS_MAX_AGE))) {
        const res = await getSettings(currentCustomerUuid)
        if (res) {
          this.settings = res
          this.settingsAge = currentTime
          this.settingsCustomer = currentCustomerUuid
        }
      }
    },
    async fetchCustomerSettingsValues() {
      const {getSettingValues} = useCustomerService()
      const CUSTOMER_SETTINGS_MAX_AGE = useRuntimeConfig().public.CUSTOMER_SETTINGS_MAX_AGE || 3600000
      const currentTime = new Date().getTime()
      const userStore = useUserStore()
      const currentCustomerUuid = userStore.loadedCustomerUuid
      if (currentCustomerUuid !== 'default' &&
        (currentCustomerUuid !== this.settingsCustomer ||
          currentTime > this.settingsValuesAge + CUSTOMER_SETTINGS_MAX_AGE)) {
        const res = await getSettingValues(currentCustomerUuid)
        if (res) {
          this.settingsValues = res
          this.settingsValuesAge = currentTime
          this.settingsCustomer = currentCustomerUuid
        }
      }
    },
    async updateSettings(updatedSettings) {
      let storeSettings = this.settings.filter(s => updatedSettings.some(us => us.settingUuid === s.customerSettingUuid)).map(setting => {
        let newSettingValue = updatedSettings.find(us => us.settingUuid === setting.customerSettingUuid).value
        return {customerSettingValue: newSettingValue, customerSettingUuid: setting.customerSettingUuid, customerSettingType: setting.customerSettingType}
      })
      for (let {customerSettingUuid, customerSettingValue} of storeSettings) {
        let setting = this.settings.find(s => s.customerSettingUuid === customerSettingUuid)
        if (setting) {
          setting.customerSettingValue = customerSettingValue
        }
      }
    }
  }
})
