import axios from '@/services/axios_auth'
//import {stripTimeString} from '~/assets/js/utils'
import {useAddressHelper} from '@/composables/address_helper'

export const useCartService = () => {
  const baseurl = useRuntimeConfig().public.BASIC_API_URL || 'https://apim-enext-stg.azure-api.net'
  const {getDeliveryAddressUuid} = useAddressHelper()

  const cart_axios_old = axios.create({
    baseURL: useRuntimeConfig().public.PURCHASE_API || (baseurl + '/purchase-api/1.0.0')
  })

  const cart_axios = axios.create({
    baseURL: useRuntimeConfig().public.PURCHASE_API_110 || (baseurl + '/purchase-api/1.1.0')
  })

  async function getCartInfo() {
    const res = await cart_axios_old.get('/carts')
      .catch(() => {return {data: []}})
    return res ? res.data : []
  }

  async function getAllItems(region, locale) {
    const deliveryAddressUuid = getDeliveryAddressUuid()
    let where = ''
    let route = '/carts/items'
    if (deliveryAddressUuid !== '') {
      where = 'apply=filter(deliveryAddressUuid eq \'' + deliveryAddressUuid + '\')'
      route = `${route}?${where}`
    }
    const headers = {fstRegion: region, contentLanguage: locale}
    const res = await cart_axios.get(route, {headers})
      .catch(() => {return {data: {items: [], products: []}}})
    return res && res.data ? {items: res.data.items.map(postProcessItem), products: res.data.products} : []
  }

  async function getCartCount() {
    let route = '/carts/items?$count=true&$top=0'

    const res = await cart_axios.get(route)
      .catch(() => {return {data: []}})
    return res ? res.data.count : 0
  }

  async function addItems(items, region, locale, useOwnArticleNo) {
    if (Array.isArray(items)) {
      items = items.map(function(i) {
        return prepareItem(i)
      })
      const deliveryAddressUuid = getDeliveryAddressUuid()
      let where = ''
      let route = '/carts/items'
      if (deliveryAddressUuid !== '') {
        where = 'apply=filter(deliveryAddressUuid eq \'' + deliveryAddressUuid + '\') and useOwnArticleNumber eq ' + useOwnArticleNo
        route = `${route}?${where}`
      } else {
        where = 'apply=useOwnArticleNumber eq ' + useOwnArticleNo
        route = `${route}?${where}`
      }
      const headers = {fstRegion: region, contentLanguage: locale}
      const res = await cart_axios.post(route, items, {headers})
        .catch((res) => {
          const data = res?.response?.data
          if (data?.errorCode === 'Cart_Max_Items_Exceeded') {
            return data
          }
          return null
        })
      if (res?.errorCode === 'Cart_Max_Items_Exceeded') {
        return res
      }
      return res ? {...res.data, items: res.data.items.map(postProcessItem), products: res.data.products} : null
    }
    return null
  }

  async function updateItem(item, region, locale) {
    if (typeof item.cartItemUuid === 'string' && item.cartItemUuid !== '' && typeof item === 'object') {
      item = prepareItem(item)
      const deliveryAddressUuid = getDeliveryAddressUuid()
      let where = ''
      let route = '/carts/items(\'' + item.cartItemUuid + '\')'
      if (deliveryAddressUuid !== '') {
        where = 'apply=filter(deliveryAddressUuid eq \'' + deliveryAddressUuid + '\')'
        route = `${route}?${where}`
      }
      const headers = {fstRegion: region, contentLanguage: locale}
      const res = await cart_axios.put(route, item, {headers})
        .catch(() => {return null})
      return res ? {...res.data, items: res.data.items.map(postProcessItem)} : null
    }
    return null
  }

  function prepareItem(item) {
    // quick fix: add customerPricePer100 and expectedPricePer100 as nullable decimal
    if (typeof(item.customerPricePer100) === 'string') {
      item.customerPricePer100 = item.customerPricePer100.replace(/,/, '.')
    }
    if (typeof(item.expectedPricePer100) === 'string') {
      item.expectedPricePer100 = item.expectedPricePer100.replace(/,/, '.')
    }
    const customerPrice = parseFloat(item.customerPricePer100)
    const expectedPrice = parseFloat(item.expectedPricePer100)
    return {...item, customerPricePer100: isNaN(customerPrice) ? null : customerPrice, expectedPricePer100: isNaN(expectedPrice) ? null : expectedPrice, availabilityDate: item.availabilityDate}
  }

  function postProcessItem(item) {
    if (item && item.availabilityDate) {
      return {...item, availabilityDate: item.availabilityDate}
    }
    return item
  }

  async function deleteItems(cartItemUuids) {
    if (cartItemUuids && Array.isArray(cartItemUuids)) {
      const res = await cart_axios_old.delete('/carts/items', {data: cartItemUuids}).catch(() => {return false})
      return !!res
    }
    return false
  }

  return {
    getCartInfo,
    getAllItems,
    getCartCount,
    addItems,
    updateItem,
    deleteItems
  }
}
