import {defineStore} from 'pinia'

export const useBreadcrumbStore = defineStore('breadcrumb', {
  state: () => ({paths: [] as object[], hide: false}),
  actions: {
    setPaths(paths: object[]) {
      this.paths = paths
    }
  }
})
