import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(() => {
  const router = useRouter()
  const app = useNuxtApp()
  const vueApp = app.vueApp
  const config = useRuntimeConfig()

  Sentry.init({
    app: vueApp,
    dsn: config.public.sentryDsn,
    environment: config.public.environment,
    release: process.env.SENTRY_RELEASE || 'DEV',
    sendClientReports: false,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: config.public.sentryTracesSampleRate
  })

  app.provide('sentry', Sentry)
})
