import axios from '@/services/axios_auth'
import {getReponseType, getRequestType, typeGuard} from '@/types/helper.d'

const presetResponseType = getReponseType(
  '/1.0.0/hydraulic-selector/presets',
  'get'
)
const metadataResponseType = getReponseType(
  '/1.0.0/hydraulic-selector/metadata',
  'get'
)
const hydrasResponseType = getReponseType('/1.0.0/hydraulic-selector', 'post')
const hydrasRequestType = getRequestType('/1.0.0/hydraulic-selector', 'post')

type Presets = typeof presetResponseType;
type Metadata = typeof metadataResponseType;
type Hydras = typeof hydrasResponseType;
type HydrasRequestBody = typeof hydrasRequestType;

export const useHydraulicSelectorService = () => {
  const runtimeConfig = useRuntimeConfig()
  const baseurl = runtimeConfig.public.BASIC_API_URL  || 'https://apim-enext-stg.azure-api.net'

  const hydras_axios = axios.create({
    baseURL: runtimeConfig.public.HYDRAS_API || (baseurl + '/hydraulic-selector-api/1.0.0/hydraulic-selector')
  })

  const getPresets = async (): Promise<Presets[]> => {
    const presets = await hydras_axios
      .get('/presets')
      .then((res) => {
        return res ? res.data : []
      })
      .catch(() => {
        return []
      })

    return presets
  }

  const getMetadata = async (): Promise<Metadata> => {
    const metadata = await hydras_axios
      .get('/metadata')
      .then((res) => {
        return res ? res.data : {}
      })
      .catch(() => {
        return {}
      })

    return metadata
  }

  const postHydraulicSelectorValues = async (
    requestBody: HydrasRequestBody
  ): Promise<Hydras | null> => {
    if (typeGuard(requestBody)) {
      const postResponse = await hydras_axios.post('', requestBody)
        .then((res) => {
          return res ? res.data : null
        })
        .catch(() => {
          return null
        })
      return postResponse
    }
    return null
  }

  return {
    getPresets,
    getMetadata,
    postHydraulicSelectorValues
  }
}
