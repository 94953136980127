import axios from '@/services/axios_auth'
import baseAxios from '@/services/axios_base'

export const useCustomerService = () => {
  const runtimeConfig = useRuntimeConfig()

  const baseurl = runtimeConfig.public.BASIC_API_URL || 'https://apim-enext-stg.azure-api.net'

  const customer_axios = axios.create({
    baseURL: runtimeConfig.public.CUSTOMERS_API || (baseurl + '/customers-api/1.0.0')
  })

  const customer_base_axios = baseAxios.create({
    baseURL: runtimeConfig.public.CUSTOMERS_API || (baseurl + '/customers-api/1.0.0')
  })

  const customer_new_axios = axios.create({
    baseURL: runtimeConfig.public.CUSTOMERS_API || (baseurl + '/customers-api/1.1.0')
  })

  const getCustomers = async () => {
    const res = await customer_axios.get('/customers')
      .catch(() => {return []})
    return res ? res.data : []
  }

  const getBasicCustomer = async (customerUuid, customToken) => {
    if (typeof customerUuid === 'string' && customerUuid !== '' && customerUuid !== 'default') {
      if (customToken) {
        const res = await customer_base_axios.get(`/customers?$filter=customerUuid eq ${customerUuid}`, {headers: {'Authorization': `Bearer ${customToken}`}})
          .catch(() => {return null})
        return res ? res.data : null
      } else {
        const res = await customer_axios.get(`/customers?$filter=customerUuid eq ${customerUuid}`)
          .catch(() => {return null})
        return res ? res.data : null
      }
    }
    return null
  }

  const getCustomer = async (customerUuid, customToken) => {
    if (typeof customerUuid === 'string' && customerUuid !== '' && customerUuid !== 'default') {
      if (customToken) {
        const res = await customer_base_axios.get(`/customers('${customerUuid}')`, {headers: {'Authorization': `Bearer ${customToken}`}})
          .catch(() => {return null})
        return res ? res.data : null
      } else {
        const res = await customer_axios.get(`/customers('${customerUuid}')`)
          .catch(() => {return null})
        return res ? res.data : null
      }
    }
    return null
  }

  const getAddresses = async (customerUuid) => {
    if (typeof customerUuid === 'string' && customerUuid !== '' && customerUuid !== 'default') {
      const res = await customer_axios.get(`/customers('${customerUuid}')/addresses`)
        .catch(() => {return []})
      return res && res?.data ? res.data : []
    }
    return []
  }

  const getDeliveryAddresses = async (customerUuid) => {
    if (typeof customerUuid === 'string' && customerUuid !== '' && customerUuid !== 'default') {
      const res = await customer_axios.get(`/customers('${customerUuid}')/addresses?$filter=addressType eq 'Delivery'`)
        .catch(() => {return []})
      return res ? res.data : []
    }
    return []
  }

  const postAddress = (customerUuid, requestBody) => {
    if (typeof customerUuid === 'string' && typeof requestBody === 'object' && customerUuid !== '') {
      return customer_axios.post(`/customers('${customerUuid}')/addresses`, requestBody)
    }
    return null
  }

  const getAddress = async (customerUuid, addressUuid) => {
    if (typeof customerUuid === 'string' && typeof addressUuid === 'string' &&
        addressUuid !== '' &&  customerUuid !== '' && customerUuid !== 'default') {
      const res = await customer_axios.get(`/customers('${customerUuid}')/addresses('${addressUuid}')`)
        .catch(() => {return null})
      return res ? res.data : null
    }
    return null
  }

  const putAddress = (customerUuid, addressUuid, requestBody) => {
    if (typeof customerUuid === 'string' && typeof addressUuid === 'string' &&
        typeof requestBody === 'object' && addressUuid !== '' && customerUuid !== '') {
      return customer_axios.put(`/customers('${customerUuid}')/addresses('${addressUuid}')`, requestBody)
    }
    return null
  }

  const deleteAddress = (customerUuid, addressUuid) => {
    if (typeof customerUuid === 'string' && typeof addressUuid === 'string' &&
        addressUuid !== '' && customerUuid !== '') {
      return customer_axios.delete(`/customers('${customerUuid}')/addresses('${addressUuid}')`)
    }
    return null
  }

  const getSettings = async (customerUuid) => {
    if (typeof customerUuid === 'string' && customerUuid !== '' && customerUuid !== 'default') {
      const res = await customer_axios.get(`/customers('${customerUuid}')/settings`)
        .catch(() => {return false})
      return res ? res.data : []
    }
    return []
  }

  const getSettingValues = async (customerUuid) => {
    if (typeof customerUuid === 'string' && customerUuid !== '' && customerUuid !== 'default') {
      const res = await customer_axios.get(`/customers('${customerUuid}')/settings/values`)
        .catch(() => {return []})
      return res ? res.data : []
    }
    return []
  }

  const putSettings = async (customerUuid, settings) => {
    if (!settings) {
      return false
    }

    const requestBody = settings.filter(s => s.value !== undefined)
      .map(s => ({settingUuid: s.settingUuid, customerSettingValue: s.value?.toString() ?? ''}))

    if (typeof customerUuid === 'string' && customerUuid !== '' && requestBody.length > 0) {
      const response = await customer_axios.put(`/customers('${customerUuid}')/settings`, requestBody).catch(() => {
        return false
      })
      return response && response.status >= 200 && response.status <= 299
    }
    return false
  }

  const getFilteredCustomerList = async (filter, take) => {
    const params = {
      '$count': 'true'
    }

    params['$orderBy'] = 'sortOrder'

    const filterToUse = typeof(filter) === 'string' ? filter : filter && filter.value
    if (filterToUse) {
      params['$filter'] = `contains(tolower(customerLabel),'${filterToUse.replace('\'', '').toLowerCase()}')`
    }

    if (take && !isNaN(parseInt(take, 10))) {
      params['$top'] = parseInt(take, 10)
    }

    const res = await customer_axios.get('/customers', {params})
      // eslint-disable-next-line no-console
      .catch((e) => { console.error(e); return undefined})

    return res ? {count: res.data['@odata.count'], value: res.data.value} : {count: 0, value: []}
  }

  const getSupplier = async (supplierNumber) => {
    if (!supplierNumber) {
      return null
    }
    const res = await customer_axios.get(`/suppliers('${supplierNumber}')`).catch(() => {return null})
    return res ? res.data : null
  }

  const getAllowedRegions = async (country) => {
    if (country && country !== '') {
      const params = {
        country: country
      }
      const res = await customer_axios.get('/regions', {params: params})
      return res && res.data ? res.data : []
    }
    return []
  }

  const getSalesPartnerData = async (customerUuid) => {
    if (!customerUuid) {
      return null
    }
    const res = await customer_new_axios.get(`/customers('${customerUuid}')/salesPartner`).catch(() => {return null})
    return res ? res.data : null
  }

  return {
    getCustomers,
    getBasicCustomer,
    getCustomer,
    getAddresses,
    getDeliveryAddresses,
    postAddress,
    getAddress,
    putAddress,
    deleteAddress,
    getSettings,
    getSettingValues,
    putSettings,
    getFilteredCustomerList,
    getSupplier,
    getAllowedRegions,
    getSalesPartnerData
  }
}
