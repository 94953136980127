import {defineStore} from 'pinia'

export const useAuthStore = defineStore('AuthModule', {
  persist: {
    storage: persistedState.localStorage,
    paths: ['userObject']
  },
  state: () => ({
    userObject: null,
    b2cError: null,
    b2cErrorCode: null
  }),
  getters: {
    isTokenExpired() {
      const now = new Date().getTime() / 1000
      const result = this.userObject?.expires_at ? now >= this.userObject?.expires_at : true
      return result
    },
    accessToken() {
      return this.userObject?.access_token || null
    }
  },
  actions: {
    setUserObject(userObject) {
      this.userObject = userObject
    },
    setB2cError(b2cError) {
      this.b2cError = b2cError
    },
    setB2cErrorCode(b2cErrorCode) {
      this.b2cErrorCode = b2cErrorCode
    }
  }
})
