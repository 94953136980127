import {useUserStore} from '@/store/user'
import {getMatchedComponents} from '~/assets/js/authentication'

const hasRight = (listOfRights, right) => listOfRights && listOfRights.some((item) => item === right)

export default defineNuxtRouteMiddleware(async (to) => {
  if (!process.client) { return }
  const app = useNuxtApp()
  const userManager = app.$userManager
  const userStore = useUserStore()

  const user = await userManager.getUser()
  const userAuthenticated = user && !user.expired

  const matchedComponents = getMatchedComponents(to)

  // each right for each component must pass
  for (let i = 0; i < matchedComponents.length; i++) {
    let compLocal = matchedComponents[i]
    if (typeof compLocal === 'function') {
      compLocal = await compLocal()
    }
    const authNeeded = 'auth' in compLocal ? compLocal.auth : false

    if (authNeeded && !userAuthenticated) {
      return navigateTo('/auth/login')
    }
    // get childs of component
    const componentsOfComponent = compLocal.components || {}

    let rightsNeeded = []
    if ('rights' in compLocal) {
      rightsNeeded.push(compLocal.rights)
    }
    const rightsOfChildComponents = Object.values(componentsOfComponent).filter(c => 'rights' in c).map((c) => c.rights).flat()
    if (rightsOfChildComponents) {
      rightsNeeded.push(rightsOfChildComponents)
    }

    rightsNeeded = [...new Set(rightsNeeded.flat())]

    if (rightsNeeded && Array.isArray(rightsNeeded) && rightsNeeded.length > 0) {
      // if not authenticated redirect to login
      if (!userAuthenticated) {
        return navigateTo('/auth/login')
      }

      if (!userStore.rightsLoaded) {
        await new Promise((resolve) => {
          const interval = setInterval(() => {
            if (userStore.rightsLoaded) {
              clearInterval(interval)
              resolve()
            }
          }, 100)
        })
      }

      rightsNeeded.forEach((rightNeeded) => {
        if (!hasRight(userStore.rights, rightNeeded)) {
          throw createError({statusCode: 403, statusMessage: 'Permission denied'})
        }
      })
    }
  }
})
