import axios from '@/services/axios_auth'
import {RoleScope} from '~/assets/js/constants'
import {getUserQueryParams} from '~/assets/js/management'
import Query from '~/assets/js/query'

export const useUserService = () => {
  const baseurl = useRuntimeConfig().public.BASIC_API_URL || 'https://apim-enext-stg.azure-api.net'

  const user_axios = axios.create({
    baseURL: useRuntimeConfig().public.USERS_API || (baseurl + '/users-api/1.0.0')
  })

  const user_customer_axios = axios.create({
    baseURL: useRuntimeConfig().public.USERS_API_101 || (baseurl + '/users-api/1.0.1')
  })

  const user_global_axios = axios.create({
    baseURL: useRuntimeConfig().public.USERS_API_110 || (baseurl + '/users-api/1.1.0')
  })

  async function getUsersCustomers(searchQuery, options) {
    const apiUrl = '/users/customers'
    const params = getUserQueryParams(searchQuery, options)
    const res = await user_customer_axios.get(apiUrl, {params}).catch(() => {return null})
    return res ? res.data : null
  }

  async function getUser(userUuid) {
    if (typeof userUuid === 'string' && userUuid !== '') {
      const apiUrl = '/users(\'' + userUuid + '\')'
      const res = await user_axios.get(apiUrl).catch(() => {return null})
      return res && res.data ? res.data : null
    }
    return null
  }

  async function getUserCustomer(userUuid) {
    if (typeof userUuid === 'string' && userUuid !== '') {
      const apiUrl = '/users/customers'
      const params = new Query().where('`azureOid eq ' + userUuid + '`').toOdataParams()
      const res = await user_customer_axios.get(apiUrl, {params}).catch(() => {return null})
      return res && res.data && res.data.value && res.data.value.length > 0 ? res.data.value[0] : null
    }
    return null
  }

  async function updateUser(userUuid, data) {
    if (typeof userUuid === 'string' && userUuid !== '' && data) {
      const apiUrl = '/users(\'' + userUuid + '\')'
      const body = {
        'forename': data.forename,
        'name': data.name,
        'email': data.email,
        'isActive': data.isActive,
        'timeZoneUuid': data.timeZoneUuid,
        'budgetLimit': data.budgetLimit
      }
      const response = await user_axios.put(apiUrl, body).catch(() => {return false})
      return response && response.status >= 200 && response.status <= 299
    }
    return false
  }

  async function getAvailableRoles() {
    const res = await user_axios.get('/roles(\'' + RoleScope + '\')').catch(() => {return null})
    return res ? res.data : null
  }

  const getTimeZones = async function() {
    const apiUrl = '/timezones'
    const res = await user_axios.get(apiUrl).catch(() => {return null})
    return res ? res.data : null
  }

  async function deleteUserCustomer(customerUuid, userUuid) {
    if (typeof customerUuid === 'string' && customerUuid !== '' && typeof userUuid === 'string' && userUuid !== '') {
      const apiUrl = '/users(\'' + userUuid + '\')/customers(\'' + customerUuid + '\')'
      return user_customer_axios.delete(apiUrl).catch(() => {return null})
    }
    return null
  }

  async function updateUserCustomer(customerUuid, data) {
    if (typeof customerUuid === 'string' && customerUuid !== '') {
      const apiUrl = '/users(\'' + data.azureOid + '\')/customers(\'' + customerUuid + '\')'
      const body = {
        'forename': data.forename,
        'name': data.name,
        'email': data.email,
        'isActive': data.isActive,
        'customerRoles': data.customerRoles,
        'timeZoneUuid': data.timeZone.timeZoneUuid,
        'budgetLimit': data.budgetLimitFloat
      }
      return user_customer_axios.put(apiUrl, body).catch(() => {return null})
    }
    return null
  }

  const addNewUserCustomer = async function(customerUuid, data) {
    if (typeof customerUuid === 'string' && customerUuid !== '' && Array.isArray(data) && data.length > 0) {
      const apiUrl = '/users/customers(\'' + customerUuid + '\')'
      const body = []
      data.forEach(d => {
        body.push({
          'forename': d.firstname ?? null,
          'name': d.lastname ?? null,
          'email': d.email ?? null,
          'budgetLimit': d.budgetLimitFloat ?? null,
          'customerRoles': d.roles ?? null,
          'isActive': true,
          'communicationLanguage': d.language?.value?.toUpperCase() ?? 'EN'
        })
      })

      const response = await user_customer_axios.post(apiUrl, body).catch(() => {return null})
      return response && response.status >= 200 && response.status <= 299
    }
    return null
  }

  async function getUserSettings(userUuid) {
    if (typeof userUuid === 'string' && userUuid !== '') {
      const apiUrl = '/users(\'' + userUuid + '\')/settings'
      const res = await user_axios.get(apiUrl).catch(() => {return null})
      return res ? res.data : null
    }
    return null
  }

  async function getUserSettingsValues(userUuid) {
    if (typeof userUuid === 'string' && userUuid !== '') {
      const apiUrl = `/users('${userUuid}')/settings/values`
      const res = await user_axios.get(apiUrl).catch(() => {return null})
      return res ? res.data : null
    }
    return null
  }

  const patchUserSettings = async function(userUuid, settings) {
    if (typeof userUuid === 'string' && userUuid !== '' && settings) {
      const userSettingObjs = settings.map(c => ({
        userSettingUuid: c.settingUuid,
        userSettingValue: c.value,
        userSettingType: c.settingType
      }))
      const apiUrl = '/users(\'' + userUuid + '\')/settings'
      const response = await user_axios.patch(apiUrl, userSettingObjs).catch(() => {return false})
      return response && response.status >= 200 && response.status <= 299
    }
    return false
  }

  async function getCheckUser(mail) {
    if (typeof mail === 'string' && mail !== '') {
      const apiUrl = '/users/mails(\'' + mail + '\')'
      const res = await user_global_axios.get(apiUrl).catch((res) => {
        if (res && (res.response?.status >= 400 || res.response?.status <= 500)) {
          return res.response
        }
        return res?.response
      })
      return res
    }
    return null
  }

  async function internalRegistration(body) {
    const requestBody = {
      'email': body.email
    }
    const res = await user_axios.post('/registrations/internals', requestBody).catch((res) => {
      if (res && (res.response?.status >= 400 || res.response?.status <= 500)) {
        return res.response
      }
      return res?.response
    })
    return res
  }

  return {
    getUsersCustomers,
    getUser,
    getUserCustomer,
    updateUser,
    getAvailableRoles,
    getTimeZones,
    deleteUserCustomer,
    updateUserCustomer,
    addNewUserCustomer,
    getUserSettings,
    getUserSettingsValues,
    patchUserSettings,
    getCheckUser,
    internalRegistration
  }
}
