// eslint-disable-next-line no-unused-vars
import indexedDB from 'fake-indexeddb'
import {MAX_PRICE_AGE} from '../../assets/js/constants'
let db = null
let indexedDbNotUsable = null
const DB_VERSION = 2

const initDb = async function () {
  if (!window || !window.indexedDB || indexedDbNotUsable) {
    return
  }

  return new Promise((resolve, reject) => {
    let request = window.indexedDB.open('cartdb', DB_VERSION)

    request.onerror = e => {
      // eslint-disable-next-line no-console
      console.warn('Error opening db', e)
      reject()
    }

    request.onsuccess = e => {
      resolve(e.target.result)
    }

    request.onupgradeneeded = e => {
      indexedDbNotUsable = true
      db = e.target.result
      if (e.oldVersion >= 1) {
        db.deleteObjectStore('prices')
      }
      db.createObjectStore('prices', {
        keyPath: ['sku', 'qty', 'adressUuid']
      })
    }
  })
    .then(r => {
      indexedDbNotUsable = false
      return r
    }).catch(function () {
      indexedDbNotUsable = true
      return null
    })
}

export const getPrices = async function (ignoreCache = false) {
  if (!db) {
    try {
      db = await initDb()
    } catch {
      // no index db. gracefully ignore.
    }
  }
  if (!db || ignoreCache || indexedDbNotUsable) {
    return []
  }

  return new Promise((resolve, reject) => {
    let trans = db.transaction(['prices'], 'readonly')
    const store = trans.objectStore('prices')

    let request = store.openCursor()
    let prices = []

    request.onsuccess = e => {
      let cursor = e.target.result
      if (cursor) {
        if (cursor.value.timestamp && cursor.value.timestamp > new Date().getTime() - MAX_PRICE_AGE) {
          prices.push({sku: cursor.key[0], qty: cursor.key[1], adressUuid: cursor.key[2], price: cursor.value.price})
        }
        cursor.continue()
      }
      else {
        resolve(prices)
      }

    }
    request.onerror = e => {
      // eslint-disable-next-line no-console
      console.warn('Error get prices', e)
      reject()
    }
  })
}

export const addPrice = async function (price, sku, qty, adressUuid) {
  if (!db) {
    try {
      db = await initDb()
    } catch {
      // no index db. gracefully ignore.
    }
  }
  if (!db || indexedDbNotUsable) {
    return null
  }

  return new Promise((resolve, reject) => {
    let trans = db.transaction(['prices'], 'readwrite')
    let store = trans.objectStore('prices')
    let req = store.openCursor([sku, qty, adressUuid])
    let res = null

    req.onsuccess = function (e) {
      const cursor = e.target.result
      if (cursor) {
        res = cursor.update({
          sku,
          qty,
          adressUuid,
          price,
          timestamp: new Date().getTime()
        })
      } else { // key not exist
        res = store.add({
          sku,
          qty,
          adressUuid,
          price,
          timestamp: new Date().getTime()
        })
      }
      res.onsuccess = () => {
        resolve()
      }
      res.onerror = err => {
        // eslint-disable-next-line no-console
        console.warn('Error add price', err)
        reject()
      }
    }

  })
}

export const getPrice = async function (sku, qty, adressUuid) {
  if (!db) {
    try {
      db = await initDb()
    } catch {
      // no index db. gracefully ignore.
    }
  }
  if (!db || indexedDbNotUsable) {
    return null
  }

  return new Promise((resolve, reject) => {
    let trans = db.transaction(['prices'], 'readonly')
    const store = trans.objectStore('prices')
    // eslint-disable-next-line eqeqeq
    if (sku == null || qty == null || adressUuid == null) {
      return null
    }
    let res = store.get([sku, qty, adressUuid])

    res.onsuccess = () => {
      let result = res.result
      if (result && ((result.timestamp && result.timestamp + MAX_PRICE_AGE < new Date().getTime()) || !result.timestamp)) {
        result = null
      }
      resolve(result || null)
    }
    res.onerror = e => {
      // eslint-disable-next-line no-console
      console.warn('Error get price', e)
      reject()
    }
  })
}

export const wipeData = async function () {
  if (!db) {
    try {
      db = await initDb()
    } catch {
      // no index db. gracefully ignore.
    }
  }
  if (!db || indexedDbNotUsable) {
    return null
  }

  return new Promise((resolve, reject) => {
    let trans = db.transaction(['prices'], 'readwrite')
    let objectStore = trans.objectStore('prices')
    const objectStoreRequest = objectStore.clear()

    objectStoreRequest.onsuccess = () => {
      resolve(true)
    }

    objectStoreRequest.onerror = () => {
      reject()
    }
  })
}

export const isDbUsable = async function () {
  if (indexedDbNotUsable === true || indexedDbNotUsable === false) {
    return !indexedDbNotUsable
  }
  try {
    db = await initDb()
  } catch {
    return false
  }
  return !indexedDbNotUsable
}
