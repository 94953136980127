import Query from '../../../assets/js/query'

export const DEFAULT_VALUES = {
  SKIP: 0,
  TAKE: 25
}

export function getUserQueryParams(searchQuery, options) {
  const query = new Query()
  if (searchQuery !== '') {
    query.where('forename~=\'' + searchQuery + '\' | name~=\'' + searchQuery + '\' | email~=\'' + searchQuery + '\'')
  }
  addPaginationAndOrderToQuery(query, options)

  return query.includeCount().toOdataParams()
}

export function addPaginationAndOrderToQuery(query, options) {
  if (options) {
    query.top(options.pagination.take).skip(options.pagination.skip)
    if (options.sort.length > 0) {
      const sortField = options.sort[0].field
      options.sort[0].dir === 'asc' ? query.orderBy(sortField) : query.orderByDesc(sortField)
    }
  }
}
