import type {RouteLocationNormalized} from 'vue-router'
import {UserManager, User} from 'oidc-client-ts'
import {useUserStore} from '@/store/user'
import {fetchUser, passwordReset, userImpersonation} from '~/assets/js/authentication/azureb2cNew'
import {useAuthStore} from '@/store/auth'
import {B2C_ERRORCODES, getB2CError} from 'assets/js/authentication'
import {parseQuery} from 'assets/js/utils'
import * as Sentry from '@sentry/browser'
import {useGtm} from '@gtm-support/vue-gtm'
import {callWithNuxt} from 'nuxt/app'
import jwt_decode from 'jwt-decode'
import {getRegionForSalesArea} from '~/assets/js/utils/region_service'

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (!process.client) {
    return
  }
  const app = useNuxtApp()
  const userManager = app.$userManager
  const impersonationManager = app.$impersonationManager
  const authStore = useAuthStore()
  const userStore = useUserStore()

  // check if user is logged in but the stored token doesn't match it
  const currentUser = await userManager.getUser()
  if (currentUser && (!authStore.userObject || authStore.userObject.access_token !== currentUser.access_token)) {
    authStore.setUserObject(currentUser)
  }

  // remove orphans
  if (!currentUser && authStore.userObject) {
    authStore.setUserObject(null)
  }

  if (!currentUser && userStore.accessToken) {
    userStore.accessToken = ''
  }

  if (typeof(to.query?.debug) !== 'undefined') {
    // eslint-disable-next-line no-console
    console.log('auth middleware diagnostics\n------------BEGIN-------------\n', currentUser, JSON.stringify(currentUser), '\n------------------------------\n', JSON.stringify(authStore.userObject), '\n------------------------------\n', userStore.accessToken, '\n--------------END-------------\n')
  }

  if (to.path === '/auth/logout') {
    return handleLogout(from, userManager)
  }

  if (
    !authStore.isTokenExpired
        && authStore?.userObject
        && userStore?.userUuid === ''
        && !to.fullPath.startsWith('/auth#state')
  ) {
    const storedUser = new User({
      id_token: authStore.userObject.id_token,
      access_token: authStore.userObject.access_token,
      refresh_token: authStore.userObject.refresh_token,
      token_type: authStore.userObject.token_type,
      scope: authStore.userObject.scope,
      profile: authStore.userObject.profile,
      session_state: authStore.userObject.session_state,
      expires_at: authStore.userObject.expires_at
    })

    userManager.storeUser(storedUser)
    if (!to.redirectedFrom?.fullPath?.startsWith('/auth#state=')) {
      await fetchUser(userManager)
    }
  }

  const hash = parseQuery(to.hash.substr(1))
  const parsedQuery = Object.assign({}, to.query, hash)
  if (parsedQuery.error) {
    const errorCode = getB2CError(parsedQuery.error_description)
    if (errorCode) {
      if (errorCode === B2C_ERRORCODES.USER_DOES_NOT_EXIST) {
        return navigateTo(getLocalePath('/internal-registration'))
      }
      else if (errorCode === B2C_ERRORCODES.FORGOT_PASSWORD) {
        return passwordReset()
      }
      else if (errorCode === B2C_ERRORCODES.USER_CANCELLATION) {
        return navigateTo(getLocalePath('/'))
      }
      else
      {
        try {
          Sentry.captureMessage(`Azure B2C error ${errorCode}. Error description: ${parsedQuery.error_description}`, {level: 'warning'})
        } catch (_) {
          // do nothing
        }
        authStore.setUserObject(null)
        userStore.accessToken = ''
        authStore.setB2cError(true)
        authStore.setB2cErrorCode(errorCode)
        return navigateTo(getLocalePath('/b2c-error'))
      }
    }
  }
  if (parsedQuery?.targetEmail) {
    return userImpersonation(impersonationManager, {params: {targetEmail: parsedQuery.targetEmail}})
  }

  let params: any = {}
  const user = await userManager.getUser()
  // eslint-disable-next-line eqeqeq
  const isLoggedIn = user != null && !user.expired
  if (!isLoggedIn) {
    let region = app.$globalization.__currentRegion
    let urlRegion = app.$globalization.getRegionFromPath(to.path)
    if (!to.path.startsWith('/auth') && region?.toLowerCase() !== urlRegion?.toLowerCase() && to.path !== getLocalePath(to.path)) {
      app.$globalization.setRegion(urlRegion)
      return navigateTo(getLocalePath(to.path))
    }
    if (!region || region.toLowerCase() === 'global') {
      region = 'eu'
    }

    region = region.toLowerCase()
    if (app.$i18n.locale.value) {
      params = {
        params: {
          lang: app.$i18n.locale.value,
          region
        }
      }
    }

    if (to.query.DOItoken) {
      params.params = {
        ...params.params,
        DOItoken: to.query.DOItoken
      }
    }

    if (to.query.resetpassword) {
      useGtm().trackEvent({
        event: 'password_reset',
        category: 'login',
        action: 'password_reset'
      })
      return passwordReset(params)
    }
  }

  if (to.path === '/auth' || to.path === '/auth/silent') {
    return handleAuthRedirect(to, userManager, impersonationManager)
  }

  if (to.path === '/auth/login' && !isLoggedIn) {
    useGtm().trackEvent({
      event: 'login_initiated',
      category: 'login',
      action: 'login_initiated'
    })
    return handleLogin(from, userManager, params.params)
  }
  else if (to.path === '/auth/login' && isLoggedIn) {
    return navigateTo(getLocalePath('/'))
  }
  const accessToken = userStore.accessToken
  const decodedToken = accessToken ? jwt_decode(accessToken) as any : null
  const tokenRegion = decodedToken?.salesAreaName ? getRegionForSalesArea(decodedToken?.salesAreaName.replaceAll('_', '-')) : null
  if (userStore.newCustomer === null && tokenRegion && !to.path.startsWith('/' + tokenRegion.toLowerCase())) {
    return navigateTo(app.$globalization.localePath(to.path, app.$i18n.locale.value, tokenRegion))
  }
})

async function handleLogin(from: RouteLocationNormalized, userManager: UserManager, extraQueryParams?: object) {
  let redirectUri = from.path as string | undefined
  if (redirectUri && redirectUri.startsWith('/auth')) {
    redirectUri = undefined
  }
  const app = useNuxtApp()
  const state = {
    signInRedirect: redirectUri ? redirectUri : null,
    locale: app.$i18n.locale.value,
    region: app.$globalization.getRegion()
  }
  await userManager.signinRedirect({state, extraQueryParams: {
    lang: app.$i18n.locale.value,
    region: app.$globalization.getRegion(),
    ...extraQueryParams
  }})
  return abortNavigation()
}

async function handleLogout(from: RouteLocationNormalized, userManager: UserManager) {
  let redirectUri = from.path as string | undefined
  if (redirectUri && (redirectUri.startsWith('/auth') || redirectUri.endsWith('/cart') || redirectUri.includes('/cart/'))) {
    redirectUri = ''
  }
  const app = useNuxtApp()
  const authStore = useAuthStore()
  const userStore = useUserStore()
  const state = {
    signInRedirect: redirectUri ? redirectUri : null,
    locale: app.$i18n.locale.value,
    region: app.$globalization.getRegion()
  }

  authStore.setUserObject(null)
  userStore.accessToken = ''
  await userManager.signoutRedirect({state, extraQueryParams: {
    post_logout_redirect_uri: app.$config.public.BASE_URL + redirectUri
  }})

  return abortNavigation()
}

async function handleAuthRedirect(to: RouteLocationNormalized, userManager: UserManager, impersonationManager: UserManager) {
  try {
    const user = await userManager.signinRedirectCallback()
    const authStore = useAuthStore()
    // storing b2c token
    await userManager.storeUser(user)
    authStore.setUserObject(user)
    // ger correct signInRedirect from state
    const redirectUri = getRedirectUri(user)
    if (redirectUri) {
      const app = useNuxtApp()
      return callWithNuxt(app, () => navigateTo(redirectUri))
    }
  } catch {
    try {
      //try impersonated case
      const user = await impersonationManager.signinRedirectCallback()
      const authStore = useAuthStore()
      // storing b2c token
      await userManager.storeUser(user)
      authStore.setUserObject(user)
      await fetchUser(userManager, 'login', user.access_token)
      // ger correct signInRedirect from state
      const redirectUri = getRedirectUri(user)
      if (redirectUri) {
        return navigateTo(redirectUri)
      }
    } catch {
      return navigateTo('/')
    }
  }
}

function getRedirectUri(user: User) {
  let redirectUri = user.state && (user.state as any).signInRedirect
  const region = user.state && (user.state as any).region
  const locale = user.state && (user.state as any).locale
  if (!redirectUri) {
    redirectUri = useNuxtApp().$globalization.localePath('index', locale, region)
  }
  return redirectUri
}

function getLocalePath(page: string) {
  const app = useNuxtApp()
  const locale = app.$i18n.locale.value || 'en'
  const region = app.$globalization.getRegion(false) || 'eu'
  return app.$globalization.localePath(page, locale, region)
}
