<template>
  <NuxtLayout>
    <div class="error__content">
      <div v-if="error.statusCode === 404">
        <h1
          v-tv:servicepages="'page-not-found-header'"
        />
        <p v-tv:servicepages="'page-not-found-text'" />
      </div>
      <div v-if="error.statusCode === 403">
        <h1
          v-tv:servicepages="'insufficient-privileges-header'"
        />
        <p v-tv:servicepages="'insufficient-privileges-text'" />
      </div>
      <div v-else>
        <h1
          v-tv:servicepages="'error-occurred-header'"
        />
        <p
          class="text"
          v-tv:servicepages="'error-occurred-text'"
        />
        <p
          class="subline"
          v-tv:servicepages="'error-occurred-subtext'"
        />
      </div>
      <NuxtLink
        class="k-button k-button-md k-button-solid k-button-solid-primary"
        :to="localePath('/')"
        v-tv:servicepages="'back-to-home-text'"
      />
    </div>
  </NuxtLayout>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    if (this.error.statusCode === 404) {
      this.$gtm.trackEvent({
        event: 'page_not_found',
        category: 'exception',
        action: 'page_not_found',
        errorUrl: window.location.href,
        referrer: document.referrer
      })
    }

    this.$nextTick().then(() => {
      document.body.classList.remove('popup--open')
      document.body.style.paddingRight = ''
    })
  }
}
</script>

<style lang="scss">
.error__content {
  margin: 2rem 2rem 14rem;

  @include breakpoint-down($md) {
    margin: 2rem 1rem;
  }
}
</style>
