import mitt from 'mitt'

const emitter = mitt()

export default defineNuxtPlugin(() => {
  return {
    provide: {
      'eventbus': {
        emit: emitter.emit,
        on: emitter.on,
        off: emitter.off,
        all: emitter.all
      }
    }
  }
})
