<template>
  <div
    v-show="loading"
    class="loading-page"
  >
    <span
      class="k-icon k-i-loading loading-indicator"
    />
  </div>
</template>

<script>
export default defineNuxtComponent({
  data: () => ({
    loading: false
  }),
  mounted() {
    this.$eventbus.on('loading', (loading) => {
      this.loading = loading
    })
  },
  unmounted() {
    this.$eventbus.off('loading')
  }
})
</script>

<style lang="scss" scoped>
.loading-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255 255 255 / 80%);
  text-align: center;
  padding: 0;
  font-size: 1.875rem;
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  z-index: 5;
}
</style>
