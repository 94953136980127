import {UserManager, WebStorageStateStore} from 'oidc-client-ts'
import {CookieStorage} from 'cookie-storage'
import {useAuthStore} from '@/store/auth'
import {fetchUser} from '~/assets/js/authentication/azureb2cNew'
import {reloadNuxtApp} from 'nuxt/app'

export default defineNuxtPlugin({
  enforce: 'pre',
  async setup(nuxtApp) {
    const host = nuxtApp.$config.public.BASE_URL
    const cookieStorage = new CookieStorage({
      expires: new Date(Date.now() + 3600 * 1000),
      path: '/'
    })
    const userManager = new UserManager({
      authority: nuxtApp.$config.public.auth.endpoints.signIn,
      redirect_uri: host + nuxtApp.$config.public.auth.redirect.auth,
      client_id: nuxtApp.$config.public.auth.clientId,
      scope: nuxtApp.$config.public.auth.scope,
      response_type: nuxtApp.$config.public.auth.responseType,
      response_mode: nuxtApp.$config.public.auth.responseMode as any,
      userStore: new WebStorageStateStore({
        prefix: 'ENuxtB2cUser.',
        store: window.localStorage
      }),
      stateStore: new WebStorageStateStore({
        prefix: 'ENuxtB2cState.',
        store: window.localStorage
      }),
      includeRedirectUriInRefreshTokenRequest: true
    })

    userManager.events.addUserLoaded(async (user) => {
      nuxtApp.runWithContext(async () => {
        const authStore = useAuthStore()
        authStore.setUserObject(user)
        await fetchUser(userManager)
        cookieStorage.setItem('ENuxtB2cUser.sub', user.profile.sub)
      })
    })

    userManager.events.addUserUnloaded(async () => {
      cookieStorage.removeItem('ENuxtB2cUser.sub')
    })

    const impersonationManager = new UserManager({
      authority: nuxtApp.$config.public.auth.endpoints.userImpersonation,
      redirect_uri: host + nuxtApp.$config.public.auth.redirect.auth,
      client_id: nuxtApp.$config.public.auth.impersonationClientId,
      scope: nuxtApp.$config.public.auth.scope,
      response_type: nuxtApp.$config.public.auth.responseType,
      response_mode: nuxtApp.$config.public.auth.responseMode as any,
      userStore: new WebStorageStateStore({
        prefix: 'ENuxtB2cImpersoUser.',
        store: window.localStorage
      }),
      stateStore: new WebStorageStateStore({
        prefix: 'ENuxtB2cImpersoState.',
        store: window.localStorage
      })
    })
    nuxtApp.provide('userManager', userManager)
    nuxtApp.provide('impersonationManager', impersonationManager)
  },
  hooks: {
    'app:beforeMount': async () => {
      const nuxtApp = useNuxtApp()
      const existingUser = await nuxtApp.$userManager.getUser()
      const cookieStorage = new CookieStorage({
        expires: new Date(Date.now() + 3600 * 1000),
        path: '/'
      })
      if (existingUser && existingUser.expired) {
        await nuxtApp.$userManager.removeUser()
        cookieStorage.removeItem('ENuxtB2cUser.sub')
      } else if (existingUser) {
        if (nuxtApp.payload?.serverRendered) {
          cookieStorage.setItem('ENuxtB2cUser.sub', existingUser.profile.sub)
          // eslint-disable-next-line no-console
          console.log('ssr mismatch, reloading')
          reloadNuxtApp()
        } else {
          await fetchUser(nuxtApp.$userManager)
        }
      }
    }
  }
})
