import {useCartOptionsService} from '@/composables/cart_options_service'
import {PLANT, USER_RIGHTS_MAPPING} from '~/assets/js/constants'
import {arrayOfObjectsGroupBy} from '~/assets/js/utils'
import {defineStore} from 'pinia'
import {useUserStore} from './user'

const hasRight = (listOfRights, right) => listOfRights && listOfRights.some((item) => item === right)

export const usePlantStore = defineStore('PlantsModule', {
  state: () => ({
    allPlants: [],
    currentlyProcessing: false
  }),
  actions: {
    async fetchAllPlants(forced) {
      const userStore = useUserStore()
      const {getAlternativePlants} = useCartOptionsService()
      const rightsList = userStore.rights
      if (!this.currentlyProcessing && (forced || this.allPlants.length === 0) && hasRight(rightsList, USER_RIGHTS_MAPPING.PRODUCT_VIEW_AVAILABILITY)) {
        this.currentlyProcessing = true
        let alternativePlants = null
        alternativePlants = await getAlternativePlants()
        if (alternativePlants) {
          const plantsGrouped = arrayOfObjectsGroupBy(alternativePlants, 'plant')
          let defaultPlants = []
          for (const [key] of Object.entries(plantsGrouped)) {
            defaultPlants.push({...PLANT.DEFAULT_PLANT_OBJECT, plant: key})
          }
          this.allPlants = defaultPlants.concat(alternativePlants)
        }
        this.currentlyProcessing = false
      }
    }
  }
})
