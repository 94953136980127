import {isProxy, toRaw} from 'vue'

export default defineNuxtPlugin({
  setup(nuxtApp) {
    const deproxifier = (obj) => {
      if (isProxy(obj)) {
        return toRaw(obj)
      }
      return obj
    }

    nuxtApp.provide('deproxy', deproxifier)
  }
})
