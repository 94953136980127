import {useUserStore} from '@/store/user'
import {REGION_KEYS} from '../assets/js/constants'
import {parseCookies, setCookie} from '../assets/js/utils'

let regionGetterObject = {
  getRegion() {
    return this.getRegionFromURL()
  },
  getRegionFromURL() {
    return REGION_KEYS.EU
  },
  getRegionFromCookie() {
    return REGION_KEYS.EU
  }
}

const getCookies = function () {
  if ((typeof (window) !== 'undefined') && window.document && window.document.cookie) {
    return parseCookies(window.document.cookie)
  }

  return {}
}

export default defineNuxtPlugin(nuxtApp => {
  const router = useRouter()
  const originalLocalePath = useLocalePath()
  const regions = nuxtApp.$config.public.regions
  let globalization = reactive({
    name: 'globalization',
    __currentRegion: '',
    localePath(path, locale, region) {
      if (!path) {
        return router.resolve('')
      }
      const localeToUse = locale || nuxtApp.$i18n.locale.value
      const regionToUse = (region && region.toLowerCase()) || (globalization.getRegion(false) && globalization.getRegion(false).toLowerCase())
      let resolvedPath = resolvePath(path, router, regionToUse, localeToUse)
      // eslint-disable-next-line eqeqeq
      if (resolvedPath != null) {
        return resolvedPath
      } else {
        // eslint-disable-next-line no-console
        console.warn('[globalization] couldn\'t resolve path ', path)
      }

      const regionLocalePath = (globalization.getRegion(false) && globalization.getRegion(false).toLowerCase()) + '/' + (locale || nuxtApp.$i18n.locale)
      return (typeof path !== 'object' && path !== '/' + regionLocalePath) ? originalLocalePath(path, regionLocalePath) : originalLocalePath(path)
    },
    async switchRegion(region, becauseOfCustomerChange = false) {
      if (doesRegionExists(region, regions)) {
        this.setRegion(region)
        if (!becauseOfCustomerChange && region !== REGION_KEYS.NA) {
          useUserStore().setShowInchValues(false)
        }
        this.__currentRegion = region
        let path = this.getRegionPath(region).replace('?fromglobal=1', '')
        await navigateTo(path)
      }
    },
    getRegion(linkGlobalRegion = true) {
      let urlRegion = this.getRegionFromURL()
      let cookieRegion = this.getRegionFromCookie()
      let region = !urlRegion ? cookieRegion : urlRegion
      return linkGlobalRegion && region === REGION_KEYS.GLOBAL ? REGION_KEYS.EU : region ?? REGION_KEYS.EU
    },
    setRegion(region) {
      if ((typeof (window) !== 'undefined')) {
        setCookie('region', region, 365)
        this.__currentRegion = region
      }
    },
    getRegionPath(region) {
      let route = router.currentRoute.value
      return this.localePath(route, nuxtApp.$i18n.locale.value, region)
    },
    getRegionFromURL() {
      const regionExtractor = /^\/(?<region>[^/]{2,})\/.*$/g
      const route = router.currentRoute.value
      let match = regionExtractor.exec(route && route.fullPath)
      let region = match && match.groups.region || undefined
      let validRegion = typeof region === 'string' ? regions.find(r => r.RegionShortName.toLowerCase() === region) : null
      return region && validRegion ? validRegion.RegionShortName : null
    },
    getRegionFromPath(path = '') {
      const regionExtractor = /^\/(?<region>[^/]{2,})\/.*$/g
      let match = regionExtractor.exec(path)
      let region = match && match.groups.region || undefined
      let validRegion = typeof region === 'string' ? regions.find(r => r.RegionShortName.toLowerCase() === region) : null
      return region && validRegion ? validRegion.RegionShortName : null
    },
    getRegionFromCookie() {
      if ((typeof (window) !== 'undefined')) {
        let cookies = getCookies()
        return cookies['region'] || null
      }
      return null
    }

  })
  regionGetterObject.getRegion = globalization.getRegion
  regionGetterObject.getRegionFromURL = globalization.getRegionFromURL
  regionGetterObject.getRegionFromCookie = globalization.getRegionFromCookie
  nuxtApp.provide('globalization', globalization)
  nuxtApp.vueApp.mixin({
    methods: {
      localePath: globalization.localePath
    }
  })

  nuxtApp.vueApp.localePath = globalization.localePath
  const currentRegion = globalization.getRegion(false)
  if (currentRegion !== null && (currentRegion !== globalization.getRegionFromCookie() || currentRegion !== globalization.__currentRegion)) {
    globalization.setRegion(currentRegion) // Client-side
  }
  else if (currentRegion === null) {
    globalization.setRegion(REGION_KEYS.EU) // Client-side
  }
})

export const regionGetter = regionGetterObject

function resolvePath(path, router, regionToUse, localeToUse, params) {
  let resolvedPath = null
  const routeNameRegex = /^(.+):([a-z]+)(___[a-z]{2})?$/i
  // search by "name" of route?
  if (typeof (path) === 'string' && path.indexOf('/') !== 0) {
    let pathToUse = path
    if (routeNameRegex.test(path)) {
      pathToUse = routeNameRegex.exec(path)[1]
    }
    resolvedPath = getRouteByName(router, pathToUse, regionToUse, localeToUse, params)
  } else if (typeof (path) === 'object' && path.name) {
    let pathToUse = path.name
    if (routeNameRegex.test(pathToUse)) {
      pathToUse = routeNameRegex.exec(pathToUse)[1]
    }
    resolvedPath = getRouteByName(router, pathToUse, regionToUse, localeToUse, {...path.params, ...params}, path.query)
  } else if (typeof (path) === 'string' && path.indexOf('/') === 0) {
    resolvedPath = getRouteByPath(router, path, regionToUse, localeToUse, params)
  } else if (typeof (path) === 'object' && path.path) {
    resolvedPath = getRouteByPath(router, path.path, regionToUse, localeToUse, {...path.params, ...params}, path.query)
  }
  return resolvedPath
}

function doesRegionExists(region, regions) {
  const foundRegion = typeof region === 'string' ? regions.find(r => r.RegionShortName.toLowerCase() === region.toLowerCase()) : false
  return foundRegion ? true : false
}

function getRouteByName(router, name, region, locale, params, query) {
  const fullName = `${name}:${region}___${locale}`
  let resolvedRoute // TODO remove try/catch when all routes are working 
  try {
    resolvedRoute = router.resolve({name: fullName, params, query})
  } catch (_)
  {
    // do nothing
  }
  return (resolvedRoute && resolvedRoute.fullPath) || resolvedRoute
}

function getRouteByPath(router, path, region, locale, params, query) {
  const routes = router.getRoutes()
  const foundGlobalRoute = router.resolve(path)
  if (foundGlobalRoute  && foundGlobalRoute.name) {
    const globalName = foundGlobalRoute.name
    const nameWithoutRegionLang = globalName.split(':')[0]
    return getRouteByName(router, nameWithoutRegionLang, region, locale, {...foundGlobalRoute.params, ...params}, query)
  } else {
    const foundRoute = routes.find(r => r.path === path)
    if (foundRoute) {
      return getRouteByName(router, foundRoute.name, region, locale, {...foundRoute.params, ...params}, query)
    }
  }
}
