import {components} from '@/types/hydras.d'

export enum HydraulicSelectorState {
  startpage = 'startpage',
  newconstruction = 'newconstruction',
  replacement = 'replacement',
}
export type Checkboxes = {
  [key: string]: boolean;
};
export interface inputs {
  BasicInputs: {
    rodDiameter?: number | null;
    outerDiameter?: number | null;
    applicationArea?: string | null;
    applicationAreaUnknown?: boolean | null;
    sealsNeeds?: string | null;
  };
}
type RequestBody = components['schemas']['ApiPostProductRequest'];

export interface HydraulicSelectorModule {
  selectorState: Ref<HydraulicSelectorState>;
  currentData: Ref<RequestBody>;
  presets: Ref<components['schemas']['ApiApplicationPreset'][]>;
  metadata: Ref<components['schemas']['ApiHydraulicMetaData']>;
  presetsAndMetadataInitialized: ComputedRef<boolean>;
  dataIncompleteForStepTwo: ComputedRef<boolean>
  replacementStep: Ref<number>
  wiperProfile: Ref<string>
  rodSealProfile: Ref<string>
  rodGuideProfile: Ref<string>
  bufferSealProfile: Ref<string>
  pistonSealProfile: Ref<string>
  pistonGuideProfile: Ref<string>
  wiperDefault: Ref<string>
  pistonSealDefaultB: Ref<boolean>
  isErrorResponse: Ref<boolean>
  // eslint-disable-next-line no-undef
  getPostRequestObject: ComputedRef<ApiPostProductRequest>
  setSelectorState: (state: HydraulicSelectorState) => void;
  setCurrentData: (data: RequestBody) => void;
  setCurrentDataMultiple: (
    properties: { key: keyof RequestBody; value: any }[]
  ) => void;
  setPresets: (data: components['schemas']['ApiApplicationPreset'][]) => void;
  setMetadata: (data: components['schemas']['ApiHydraulicMetaData']) => void;
  loadDefaultData: () => void;
  basicInput: Ref<inputs['BasicInputs']>;
  checkboxes: Ref<Checkboxes>;
  resetState: () => void;
}
