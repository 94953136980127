import { default as index3qCxpmpDpbMeta } from "/home/vsts/work/1/s/src/pages/accumulator-selector/calculator/index.vue?macro=true";
import { default as indexId7wyGuUaNMeta } from "/home/vsts/work/1/s/src/pages/accumulator-selector/guided-finder/index.vue?macro=true";
import { default as indexqZxO0a0oxUMeta } from "/home/vsts/work/1/s/src/pages/accumulator-selector/index.vue?macro=true";
import { default as appSettingsiNrIJDOsDjMeta } from "/home/vsts/work/1/s/src/pages/appSettings.vue?macro=true";
import { default as index9ERZjXBiNiMeta } from "/home/vsts/work/1/s/src/pages/auth/index.vue?macro=true";
import { default as logintkSW4BW5ORMeta } from "/home/vsts/work/1/s/src/pages/auth/login.vue?macro=true";
import { default as logoutlWtSOQAZxDMeta } from "/home/vsts/work/1/s/src/pages/auth/logout.vue?macro=true";
import { default as b2c_45errorUPUUOKvVJ9Meta } from "/home/vsts/work/1/s/src/pages/b2c-error.vue?macro=true";
import { default as checkoutgN6Ffmf2h8Meta } from "/home/vsts/work/1/s/src/pages/cart/checkout.vue?macro=true";
import { default as indexDqHxHSe66yMeta } from "/home/vsts/work/1/s/src/pages/cart/index.vue?macro=true";
import { default as order_45thankyouFpKEcElYZnMeta } from "/home/vsts/work/1/s/src/pages/cart/order-thankyou.vue?macro=true";
import { default as indextHDl6znZdFMeta } from "/home/vsts/work/1/s/src/pages/categories/[...categorypath]/index.vue?macro=true";
import { default as _91product_938R7b3dSvdOMeta } from "/home/vsts/work/1/s/src/pages/categories/[design]/products/[product].vue?macro=true";
import { default as indexzDQCbiAojAMeta } from "/home/vsts/work/1/s/src/pages/categories/[design]/products/index.vue?macro=true";
import { default as indexWurEanfaYRMeta } from "/home/vsts/work/1/s/src/pages/categories/index.vue?macro=true";
import { default as indexyZc4rktduJMeta } from "/home/vsts/work/1/s/src/pages/chemical-resistance-guide/index.vue?macro=true";
import { default as cross_45reference7A0eYBD4J5Meta } from "/home/vsts/work/1/s/src/pages/cross-reference.vue?macro=true";
import { default as distributor_45searchj1w3O4pEhxMeta } from "/home/vsts/work/1/s/src/pages/distributor-search.vue?macro=true";
import { default as faq9K29EW5IX0Meta } from "/home/vsts/work/1/s/src/pages/faq.vue?macro=true";
import { default as _91guideDetail_9371jiqr2eaNMeta } from "/home/vsts/work/1/s/src/pages/guides/[guideCategories]/[guideDetail].vue?macro=true";
import { default as indexZxRO3EAAxgMeta } from "/home/vsts/work/1/s/src/pages/guides/[guideCategories]/index.vue?macro=true";
import { default as indexbrBsmH2y9KMeta } from "/home/vsts/work/1/s/src/pages/guides/index.vue?macro=true";
import { default as indexakUrKMxmV5Meta } from "/home/vsts/work/1/s/src/pages/hydraulic-selector/index.vue?macro=true";
import { default as indexAIAEjEelX6Meta } from "/home/vsts/work/1/s/src/pages/hydraulic-selector/new-construction/index.vue?macro=true";
import { default as index9gJaQTLVLfMeta } from "/home/vsts/work/1/s/src/pages/hydraulic-selector/replacement/index.vue?macro=true";
import { default as indexsWhPMsLzMKMeta } from "/home/vsts/work/1/s/src/pages/index.vue?macro=true";
import { default as internal_45registrationMcxzzQlNz6Meta } from "/home/vsts/work/1/s/src/pages/internal-registration.vue?macro=true";
import { default as _91management_93J0zfUadgIzMeta } from "/home/vsts/work/1/s/src/pages/management/[management].vue?macro=true";
import { default as indexRfVItVttNeMeta } from "/home/vsts/work/1/s/src/pages/management/index.vue?macro=true";
import { default as _91newsDetail_93qxwtIbxfV1Meta } from "/home/vsts/work/1/s/src/pages/news/[newsDetail].vue?macro=true";
import { default as indexYY0TzDVdGsMeta } from "/home/vsts/work/1/s/src/pages/news/index.vue?macro=true";
import { default as index6AY1IaCh4mMeta } from "/home/vsts/work/1/s/src/pages/orderHistory/index.vue?macro=true";
import { default as _91product_93qdgql8cVUCMeta } from "/home/vsts/work/1/s/src/pages/products/[product].vue?macro=true";
import { default as profile1vvtTRV7QgMeta } from "/home/vsts/work/1/s/src/pages/profile.vue?macro=true";
import { default as register_45thankyouYADaN8wnyQMeta } from "/home/vsts/work/1/s/src/pages/register-thankyou.vue?macro=true";
import { default as registerduRznR7f4eMeta } from "/home/vsts/work/1/s/src/pages/register.vue?macro=true";
import { default as indexSm87cCTbKDMeta } from "/home/vsts/work/1/s/src/pages/searchResults/index.vue?macro=true";
import { default as setupByOG7HKR9NMeta } from "/home/vsts/work/1/s/src/pages/setup.vue?macro=true";
import { default as verification_45required2WqqgFfW6jMeta } from "/home/vsts/work/1/s/src/pages/verification-required.vue?macro=true";
import { default as indexCxBRArySCGMeta } from "/home/vsts/work/1/s/src/pages/xpress/index.vue?macro=true";
export default [
  {
    name: index3qCxpmpDpbMeta?.name ?? "accumulator-selector-calculator",
    path: index3qCxpmpDpbMeta?.path ?? "/accumulator-selector/calculator",
    meta: index3qCxpmpDpbMeta || {},
    alias: index3qCxpmpDpbMeta?.alias || [],
    redirect: index3qCxpmpDpbMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index3qCxpmpDpbMeta?.name ?? "accumulator-selector-calculator___en",
    path: index3qCxpmpDpbMeta?.path ?? "/en/accumulator-selector/calculator",
    meta: index3qCxpmpDpbMeta || {},
    alias: index3qCxpmpDpbMeta?.alias || [],
    redirect: index3qCxpmpDpbMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index3qCxpmpDpbMeta?.name ?? "accumulator-selector-calculator___de",
    path: index3qCxpmpDpbMeta?.path ?? "/de/accumulator-selector/calculator",
    meta: index3qCxpmpDpbMeta || {},
    alias: index3qCxpmpDpbMeta?.alias || [],
    redirect: index3qCxpmpDpbMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index3qCxpmpDpbMeta?.name ?? "accumulator-selector-calculator___fr",
    path: index3qCxpmpDpbMeta?.path ?? "/fr/accumulator-selector/calculator",
    meta: index3qCxpmpDpbMeta || {},
    alias: index3qCxpmpDpbMeta?.alias || [],
    redirect: index3qCxpmpDpbMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index3qCxpmpDpbMeta?.name ?? "accumulator-selector-calculator___it",
    path: index3qCxpmpDpbMeta?.path ?? "/it/accumulator-selector/calculator",
    meta: index3qCxpmpDpbMeta || {},
    alias: index3qCxpmpDpbMeta?.alias || [],
    redirect: index3qCxpmpDpbMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index3qCxpmpDpbMeta?.name ?? "accumulator-selector-calculator___es",
    path: index3qCxpmpDpbMeta?.path ?? "/es/accumulator-selector/calculator",
    meta: index3qCxpmpDpbMeta || {},
    alias: index3qCxpmpDpbMeta?.alias || [],
    redirect: index3qCxpmpDpbMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexId7wyGuUaNMeta?.name ?? "accumulator-selector-guided-finder",
    path: indexId7wyGuUaNMeta?.path ?? "/accumulator-selector/guided-finder",
    meta: indexId7wyGuUaNMeta || {},
    alias: indexId7wyGuUaNMeta?.alias || [],
    redirect: indexId7wyGuUaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/guided-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexId7wyGuUaNMeta?.name ?? "accumulator-selector-guided-finder___en",
    path: indexId7wyGuUaNMeta?.path ?? "/en/accumulator-selector/guided-finder",
    meta: indexId7wyGuUaNMeta || {},
    alias: indexId7wyGuUaNMeta?.alias || [],
    redirect: indexId7wyGuUaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/guided-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexId7wyGuUaNMeta?.name ?? "accumulator-selector-guided-finder___de",
    path: indexId7wyGuUaNMeta?.path ?? "/de/accumulator-selector/guided-finder",
    meta: indexId7wyGuUaNMeta || {},
    alias: indexId7wyGuUaNMeta?.alias || [],
    redirect: indexId7wyGuUaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/guided-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexId7wyGuUaNMeta?.name ?? "accumulator-selector-guided-finder___fr",
    path: indexId7wyGuUaNMeta?.path ?? "/fr/accumulator-selector/guided-finder",
    meta: indexId7wyGuUaNMeta || {},
    alias: indexId7wyGuUaNMeta?.alias || [],
    redirect: indexId7wyGuUaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/guided-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexId7wyGuUaNMeta?.name ?? "accumulator-selector-guided-finder___it",
    path: indexId7wyGuUaNMeta?.path ?? "/it/accumulator-selector/guided-finder",
    meta: indexId7wyGuUaNMeta || {},
    alias: indexId7wyGuUaNMeta?.alias || [],
    redirect: indexId7wyGuUaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/guided-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexId7wyGuUaNMeta?.name ?? "accumulator-selector-guided-finder___es",
    path: indexId7wyGuUaNMeta?.path ?? "/es/accumulator-selector/guided-finder",
    meta: indexId7wyGuUaNMeta || {},
    alias: indexId7wyGuUaNMeta?.alias || [],
    redirect: indexId7wyGuUaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/guided-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexqZxO0a0oxUMeta?.name ?? "accumulator-selector",
    path: indexqZxO0a0oxUMeta?.path ?? "/accumulator-selector",
    meta: indexqZxO0a0oxUMeta || {},
    alias: indexqZxO0a0oxUMeta?.alias || [],
    redirect: indexqZxO0a0oxUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexqZxO0a0oxUMeta?.name ?? "accumulator-selector___en",
    path: indexqZxO0a0oxUMeta?.path ?? "/en/accumulator-selector",
    meta: indexqZxO0a0oxUMeta || {},
    alias: indexqZxO0a0oxUMeta?.alias || [],
    redirect: indexqZxO0a0oxUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexqZxO0a0oxUMeta?.name ?? "accumulator-selector___de",
    path: indexqZxO0a0oxUMeta?.path ?? "/de/accumulator-selector",
    meta: indexqZxO0a0oxUMeta || {},
    alias: indexqZxO0a0oxUMeta?.alias || [],
    redirect: indexqZxO0a0oxUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexqZxO0a0oxUMeta?.name ?? "accumulator-selector___fr",
    path: indexqZxO0a0oxUMeta?.path ?? "/fr/accumulator-selector",
    meta: indexqZxO0a0oxUMeta || {},
    alias: indexqZxO0a0oxUMeta?.alias || [],
    redirect: indexqZxO0a0oxUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexqZxO0a0oxUMeta?.name ?? "accumulator-selector___it",
    path: indexqZxO0a0oxUMeta?.path ?? "/it/accumulator-selector",
    meta: indexqZxO0a0oxUMeta || {},
    alias: indexqZxO0a0oxUMeta?.alias || [],
    redirect: indexqZxO0a0oxUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexqZxO0a0oxUMeta?.name ?? "accumulator-selector___es",
    path: indexqZxO0a0oxUMeta?.path ?? "/es/accumulator-selector",
    meta: indexqZxO0a0oxUMeta || {},
    alias: indexqZxO0a0oxUMeta?.alias || [],
    redirect: indexqZxO0a0oxUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/index.vue").then(m => m.default || m)
  },
  {
    name: appSettingsiNrIJDOsDjMeta?.name ?? "appSettings",
    path: appSettingsiNrIJDOsDjMeta?.path ?? "/appSettings",
    meta: appSettingsiNrIJDOsDjMeta || {},
    alias: appSettingsiNrIJDOsDjMeta?.alias || [],
    redirect: appSettingsiNrIJDOsDjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/appSettings.vue").then(m => m.default || m)
  },
  {
    name: appSettingsiNrIJDOsDjMeta?.name ?? "appSettings___en",
    path: appSettingsiNrIJDOsDjMeta?.path ?? "/en/app-settings",
    meta: appSettingsiNrIJDOsDjMeta || {},
    alias: appSettingsiNrIJDOsDjMeta?.alias || [],
    redirect: appSettingsiNrIJDOsDjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/appSettings.vue").then(m => m.default || m)
  },
  {
    name: appSettingsiNrIJDOsDjMeta?.name ?? "appSettings___de",
    path: appSettingsiNrIJDOsDjMeta?.path ?? "/de/app-einstellungen",
    meta: appSettingsiNrIJDOsDjMeta || {},
    alias: appSettingsiNrIJDOsDjMeta?.alias || [],
    redirect: appSettingsiNrIJDOsDjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/appSettings.vue").then(m => m.default || m)
  },
  {
    name: appSettingsiNrIJDOsDjMeta?.name ?? "appSettings___fr",
    path: appSettingsiNrIJDOsDjMeta?.path ?? "/fr/parametres-l-application",
    meta: appSettingsiNrIJDOsDjMeta || {},
    alias: appSettingsiNrIJDOsDjMeta?.alias || [],
    redirect: appSettingsiNrIJDOsDjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/appSettings.vue").then(m => m.default || m)
  },
  {
    name: appSettingsiNrIJDOsDjMeta?.name ?? "appSettings___it",
    path: appSettingsiNrIJDOsDjMeta?.path ?? "/it/impostazioni-dell-app",
    meta: appSettingsiNrIJDOsDjMeta || {},
    alias: appSettingsiNrIJDOsDjMeta?.alias || [],
    redirect: appSettingsiNrIJDOsDjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/appSettings.vue").then(m => m.default || m)
  },
  {
    name: appSettingsiNrIJDOsDjMeta?.name ?? "appSettings___es",
    path: appSettingsiNrIJDOsDjMeta?.path ?? "/es/configuraci%C3%B3n",
    meta: appSettingsiNrIJDOsDjMeta || {},
    alias: appSettingsiNrIJDOsDjMeta?.alias || [],
    redirect: appSettingsiNrIJDOsDjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/appSettings.vue").then(m => m.default || m)
  },
  {
    name: index9ERZjXBiNiMeta?.name ?? "auth",
    path: index9ERZjXBiNiMeta?.path ?? "/auth",
    meta: index9ERZjXBiNiMeta || {},
    alias: index9ERZjXBiNiMeta?.alias || [],
    redirect: index9ERZjXBiNiMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: logintkSW4BW5ORMeta?.name ?? "auth-login",
    path: logintkSW4BW5ORMeta?.path ?? "/auth/login",
    meta: logintkSW4BW5ORMeta || {},
    alias: logintkSW4BW5ORMeta?.alias || [],
    redirect: logintkSW4BW5ORMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: logoutlWtSOQAZxDMeta?.name ?? "auth-logout",
    path: logoutlWtSOQAZxDMeta?.path ?? "/auth/logout",
    meta: logoutlWtSOQAZxDMeta || {},
    alias: logoutlWtSOQAZxDMeta?.alias || [],
    redirect: logoutlWtSOQAZxDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: b2c_45errorUPUUOKvVJ9Meta?.name ?? "b2c-error",
    path: b2c_45errorUPUUOKvVJ9Meta?.path ?? "/b2c-error",
    meta: b2c_45errorUPUUOKvVJ9Meta || {},
    alias: b2c_45errorUPUUOKvVJ9Meta?.alias || [],
    redirect: b2c_45errorUPUUOKvVJ9Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/b2c-error.vue").then(m => m.default || m)
  },
  {
    name: b2c_45errorUPUUOKvVJ9Meta?.name ?? "b2c-error___en",
    path: b2c_45errorUPUUOKvVJ9Meta?.path ?? "/en/b2c-error",
    meta: b2c_45errorUPUUOKvVJ9Meta || {},
    alias: b2c_45errorUPUUOKvVJ9Meta?.alias || [],
    redirect: b2c_45errorUPUUOKvVJ9Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/b2c-error.vue").then(m => m.default || m)
  },
  {
    name: b2c_45errorUPUUOKvVJ9Meta?.name ?? "b2c-error___de",
    path: b2c_45errorUPUUOKvVJ9Meta?.path ?? "/de/b2c-error",
    meta: b2c_45errorUPUUOKvVJ9Meta || {},
    alias: b2c_45errorUPUUOKvVJ9Meta?.alias || [],
    redirect: b2c_45errorUPUUOKvVJ9Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/b2c-error.vue").then(m => m.default || m)
  },
  {
    name: b2c_45errorUPUUOKvVJ9Meta?.name ?? "b2c-error___fr",
    path: b2c_45errorUPUUOKvVJ9Meta?.path ?? "/fr/b2c-error",
    meta: b2c_45errorUPUUOKvVJ9Meta || {},
    alias: b2c_45errorUPUUOKvVJ9Meta?.alias || [],
    redirect: b2c_45errorUPUUOKvVJ9Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/b2c-error.vue").then(m => m.default || m)
  },
  {
    name: b2c_45errorUPUUOKvVJ9Meta?.name ?? "b2c-error___it",
    path: b2c_45errorUPUUOKvVJ9Meta?.path ?? "/it/b2c-error",
    meta: b2c_45errorUPUUOKvVJ9Meta || {},
    alias: b2c_45errorUPUUOKvVJ9Meta?.alias || [],
    redirect: b2c_45errorUPUUOKvVJ9Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/b2c-error.vue").then(m => m.default || m)
  },
  {
    name: b2c_45errorUPUUOKvVJ9Meta?.name ?? "b2c-error___es",
    path: b2c_45errorUPUUOKvVJ9Meta?.path ?? "/es/b2c-error",
    meta: b2c_45errorUPUUOKvVJ9Meta || {},
    alias: b2c_45errorUPUUOKvVJ9Meta?.alias || [],
    redirect: b2c_45errorUPUUOKvVJ9Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/b2c-error.vue").then(m => m.default || m)
  },
  {
    name: checkoutgN6Ffmf2h8Meta?.name ?? "cart-checkout",
    path: checkoutgN6Ffmf2h8Meta?.path ?? "/cart/checkout",
    meta: checkoutgN6Ffmf2h8Meta || {},
    alias: checkoutgN6Ffmf2h8Meta?.alias || [],
    redirect: checkoutgN6Ffmf2h8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutgN6Ffmf2h8Meta?.name ?? "cart-checkout___en",
    path: checkoutgN6Ffmf2h8Meta?.path ?? "/en/cart/checkout",
    meta: checkoutgN6Ffmf2h8Meta || {},
    alias: checkoutgN6Ffmf2h8Meta?.alias || [],
    redirect: checkoutgN6Ffmf2h8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutgN6Ffmf2h8Meta?.name ?? "cart-checkout___de",
    path: checkoutgN6Ffmf2h8Meta?.path ?? "/de/warenkorb/checkout",
    meta: checkoutgN6Ffmf2h8Meta || {},
    alias: checkoutgN6Ffmf2h8Meta?.alias || [],
    redirect: checkoutgN6Ffmf2h8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutgN6Ffmf2h8Meta?.name ?? "cart-checkout___fr",
    path: checkoutgN6Ffmf2h8Meta?.path ?? "/fr/panier/checkout",
    meta: checkoutgN6Ffmf2h8Meta || {},
    alias: checkoutgN6Ffmf2h8Meta?.alias || [],
    redirect: checkoutgN6Ffmf2h8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutgN6Ffmf2h8Meta?.name ?? "cart-checkout___it",
    path: checkoutgN6Ffmf2h8Meta?.path ?? "/it/carrello/checkout",
    meta: checkoutgN6Ffmf2h8Meta || {},
    alias: checkoutgN6Ffmf2h8Meta?.alias || [],
    redirect: checkoutgN6Ffmf2h8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutgN6Ffmf2h8Meta?.name ?? "cart-checkout___es",
    path: checkoutgN6Ffmf2h8Meta?.path ?? "/es/carrito/checkout",
    meta: checkoutgN6Ffmf2h8Meta || {},
    alias: checkoutgN6Ffmf2h8Meta?.alias || [],
    redirect: checkoutgN6Ffmf2h8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: indexDqHxHSe66yMeta?.name ?? "cart",
    path: indexDqHxHSe66yMeta?.path ?? "/cart",
    meta: indexDqHxHSe66yMeta || {},
    alias: indexDqHxHSe66yMeta?.alias || [],
    redirect: indexDqHxHSe66yMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqHxHSe66yMeta?.name ?? "cart___en",
    path: indexDqHxHSe66yMeta?.path ?? "/en/cart",
    meta: indexDqHxHSe66yMeta || {},
    alias: indexDqHxHSe66yMeta?.alias || [],
    redirect: indexDqHxHSe66yMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqHxHSe66yMeta?.name ?? "cart___de",
    path: indexDqHxHSe66yMeta?.path ?? "/de/warenkorb",
    meta: indexDqHxHSe66yMeta || {},
    alias: indexDqHxHSe66yMeta?.alias || [],
    redirect: indexDqHxHSe66yMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqHxHSe66yMeta?.name ?? "cart___fr",
    path: indexDqHxHSe66yMeta?.path ?? "/fr/panier",
    meta: indexDqHxHSe66yMeta || {},
    alias: indexDqHxHSe66yMeta?.alias || [],
    redirect: indexDqHxHSe66yMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqHxHSe66yMeta?.name ?? "cart___it",
    path: indexDqHxHSe66yMeta?.path ?? "/it/carrello",
    meta: indexDqHxHSe66yMeta || {},
    alias: indexDqHxHSe66yMeta?.alias || [],
    redirect: indexDqHxHSe66yMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqHxHSe66yMeta?.name ?? "cart___es",
    path: indexDqHxHSe66yMeta?.path ?? "/es/carrito",
    meta: indexDqHxHSe66yMeta || {},
    alias: indexDqHxHSe66yMeta?.alias || [],
    redirect: indexDqHxHSe66yMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: order_45thankyouFpKEcElYZnMeta?.name ?? "cart-order-thankyou",
    path: order_45thankyouFpKEcElYZnMeta?.path ?? "/cart/order-thankyou",
    meta: order_45thankyouFpKEcElYZnMeta || {},
    alias: order_45thankyouFpKEcElYZnMeta?.alias || [],
    redirect: order_45thankyouFpKEcElYZnMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/order-thankyou.vue").then(m => m.default || m)
  },
  {
    name: order_45thankyouFpKEcElYZnMeta?.name ?? "cart-order-thankyou___en",
    path: order_45thankyouFpKEcElYZnMeta?.path ?? "/en/cart/order-thankyou",
    meta: order_45thankyouFpKEcElYZnMeta || {},
    alias: order_45thankyouFpKEcElYZnMeta?.alias || [],
    redirect: order_45thankyouFpKEcElYZnMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/order-thankyou.vue").then(m => m.default || m)
  },
  {
    name: order_45thankyouFpKEcElYZnMeta?.name ?? "cart-order-thankyou___de",
    path: order_45thankyouFpKEcElYZnMeta?.path ?? "/de/warenkorb/order-thankyou",
    meta: order_45thankyouFpKEcElYZnMeta || {},
    alias: order_45thankyouFpKEcElYZnMeta?.alias || [],
    redirect: order_45thankyouFpKEcElYZnMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/order-thankyou.vue").then(m => m.default || m)
  },
  {
    name: order_45thankyouFpKEcElYZnMeta?.name ?? "cart-order-thankyou___fr",
    path: order_45thankyouFpKEcElYZnMeta?.path ?? "/fr/panier/order-thankyou",
    meta: order_45thankyouFpKEcElYZnMeta || {},
    alias: order_45thankyouFpKEcElYZnMeta?.alias || [],
    redirect: order_45thankyouFpKEcElYZnMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/order-thankyou.vue").then(m => m.default || m)
  },
  {
    name: order_45thankyouFpKEcElYZnMeta?.name ?? "cart-order-thankyou___it",
    path: order_45thankyouFpKEcElYZnMeta?.path ?? "/it/carrello/order-thankyou",
    meta: order_45thankyouFpKEcElYZnMeta || {},
    alias: order_45thankyouFpKEcElYZnMeta?.alias || [],
    redirect: order_45thankyouFpKEcElYZnMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/order-thankyou.vue").then(m => m.default || m)
  },
  {
    name: order_45thankyouFpKEcElYZnMeta?.name ?? "cart-order-thankyou___es",
    path: order_45thankyouFpKEcElYZnMeta?.path ?? "/es/carrito/order-thankyou",
    meta: order_45thankyouFpKEcElYZnMeta || {},
    alias: order_45thankyouFpKEcElYZnMeta?.alias || [],
    redirect: order_45thankyouFpKEcElYZnMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/order-thankyou.vue").then(m => m.default || m)
  },
  {
    name: indextHDl6znZdFMeta?.name ?? "categories-categorypath",
    path: indextHDl6znZdFMeta?.path ?? "/categories/:categorypath(.*)*",
    meta: indextHDl6znZdFMeta || {},
    alias: indextHDl6znZdFMeta?.alias || [],
    redirect: indextHDl6znZdFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[...categorypath]/index.vue").then(m => m.default || m)
  },
  {
    name: indextHDl6znZdFMeta?.name ?? "categories-categorypath___en",
    path: indextHDl6znZdFMeta?.path ?? "/en/categories/:categorypath(.*)*",
    meta: indextHDl6znZdFMeta || {},
    alias: indextHDl6znZdFMeta?.alias || [],
    redirect: indextHDl6znZdFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[...categorypath]/index.vue").then(m => m.default || m)
  },
  {
    name: indextHDl6znZdFMeta?.name ?? "categories-categorypath___de",
    path: indextHDl6znZdFMeta?.path ?? "/de/kategorien/:categorypath(.*)*",
    meta: indextHDl6znZdFMeta || {},
    alias: indextHDl6znZdFMeta?.alias || [],
    redirect: indextHDl6znZdFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[...categorypath]/index.vue").then(m => m.default || m)
  },
  {
    name: indextHDl6znZdFMeta?.name ?? "categories-categorypath___fr",
    path: indextHDl6znZdFMeta?.path ?? "/fr/cat%C3%A9gories/:categorypath(.*)*",
    meta: indextHDl6znZdFMeta || {},
    alias: indextHDl6znZdFMeta?.alias || [],
    redirect: indextHDl6znZdFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[...categorypath]/index.vue").then(m => m.default || m)
  },
  {
    name: indextHDl6znZdFMeta?.name ?? "categories-categorypath___it",
    path: indextHDl6znZdFMeta?.path ?? "/it/categorie/:categorypath(.*)*",
    meta: indextHDl6znZdFMeta || {},
    alias: indextHDl6znZdFMeta?.alias || [],
    redirect: indextHDl6znZdFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[...categorypath]/index.vue").then(m => m.default || m)
  },
  {
    name: indextHDl6znZdFMeta?.name ?? "categories-categorypath___es",
    path: indextHDl6znZdFMeta?.path ?? "/es/categor%C3%ADas/:categorypath(.*)*",
    meta: indextHDl6znZdFMeta || {},
    alias: indextHDl6znZdFMeta?.alias || [],
    redirect: indextHDl6znZdFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[...categorypath]/index.vue").then(m => m.default || m)
  },
  {
    name: _91product_938R7b3dSvdOMeta?.name ?? "categories-design-products-product",
    path: _91product_938R7b3dSvdOMeta?.path ?? "/categories/:design()/products/:product()",
    meta: _91product_938R7b3dSvdOMeta || {},
    alias: _91product_938R7b3dSvdOMeta?.alias || [],
    redirect: _91product_938R7b3dSvdOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_938R7b3dSvdOMeta?.name ?? "categories-design-products-product___en",
    path: _91product_938R7b3dSvdOMeta?.path ?? "/en/categories/:design/products/:product",
    meta: _91product_938R7b3dSvdOMeta || {},
    alias: _91product_938R7b3dSvdOMeta?.alias || [],
    redirect: _91product_938R7b3dSvdOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_938R7b3dSvdOMeta?.name ?? "categories-design-products-product___de",
    path: _91product_938R7b3dSvdOMeta?.path ?? "/de/kategorien/:design/produkte/:product",
    meta: _91product_938R7b3dSvdOMeta || {},
    alias: _91product_938R7b3dSvdOMeta?.alias || [],
    redirect: _91product_938R7b3dSvdOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_938R7b3dSvdOMeta?.name ?? "categories-design-products-product___fr",
    path: _91product_938R7b3dSvdOMeta?.path ?? "/fr/cat%C3%A9gories/:design/produits/:product",
    meta: _91product_938R7b3dSvdOMeta || {},
    alias: _91product_938R7b3dSvdOMeta?.alias || [],
    redirect: _91product_938R7b3dSvdOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_938R7b3dSvdOMeta?.name ?? "categories-design-products-product___it",
    path: _91product_938R7b3dSvdOMeta?.path ?? "/it/categorie/:design/prodotto/:product",
    meta: _91product_938R7b3dSvdOMeta || {},
    alias: _91product_938R7b3dSvdOMeta?.alias || [],
    redirect: _91product_938R7b3dSvdOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_938R7b3dSvdOMeta?.name ?? "categories-design-products-product___es",
    path: _91product_938R7b3dSvdOMeta?.path ?? "/es/categor%C3%ADas/:design/productos/:product",
    meta: _91product_938R7b3dSvdOMeta || {},
    alias: _91product_938R7b3dSvdOMeta?.alias || [],
    redirect: _91product_938R7b3dSvdOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/[product].vue").then(m => m.default || m)
  },
  {
    name: indexzDQCbiAojAMeta?.name ?? "categories-design-products",
    path: indexzDQCbiAojAMeta?.path ?? "/categories/:design()/products",
    meta: indexzDQCbiAojAMeta || {},
    alias: indexzDQCbiAojAMeta?.alias || [],
    redirect: indexzDQCbiAojAMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexzDQCbiAojAMeta?.name ?? "categories-design-products___en",
    path: indexzDQCbiAojAMeta?.path ?? "/en/categories/:design()/products",
    meta: indexzDQCbiAojAMeta || {},
    alias: indexzDQCbiAojAMeta?.alias || [],
    redirect: indexzDQCbiAojAMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexzDQCbiAojAMeta?.name ?? "categories-design-products___de",
    path: indexzDQCbiAojAMeta?.path ?? "/de/categories/:design()/products",
    meta: indexzDQCbiAojAMeta || {},
    alias: indexzDQCbiAojAMeta?.alias || [],
    redirect: indexzDQCbiAojAMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexzDQCbiAojAMeta?.name ?? "categories-design-products___fr",
    path: indexzDQCbiAojAMeta?.path ?? "/fr/categories/:design()/products",
    meta: indexzDQCbiAojAMeta || {},
    alias: indexzDQCbiAojAMeta?.alias || [],
    redirect: indexzDQCbiAojAMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexzDQCbiAojAMeta?.name ?? "categories-design-products___it",
    path: indexzDQCbiAojAMeta?.path ?? "/it/categories/:design()/products",
    meta: indexzDQCbiAojAMeta || {},
    alias: indexzDQCbiAojAMeta?.alias || [],
    redirect: indexzDQCbiAojAMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexzDQCbiAojAMeta?.name ?? "categories-design-products___es",
    path: indexzDQCbiAojAMeta?.path ?? "/es/categories/:design()/products",
    meta: indexzDQCbiAojAMeta || {},
    alias: indexzDQCbiAojAMeta?.alias || [],
    redirect: indexzDQCbiAojAMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexWurEanfaYRMeta?.name ?? "categories",
    path: indexWurEanfaYRMeta?.path ?? "/categories",
    meta: indexWurEanfaYRMeta || {},
    alias: indexWurEanfaYRMeta?.alias || [],
    redirect: indexWurEanfaYRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexWurEanfaYRMeta?.name ?? "categories___en",
    path: indexWurEanfaYRMeta?.path ?? "/en/categories",
    meta: indexWurEanfaYRMeta || {},
    alias: indexWurEanfaYRMeta?.alias || [],
    redirect: indexWurEanfaYRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexWurEanfaYRMeta?.name ?? "categories___de",
    path: indexWurEanfaYRMeta?.path ?? "/de/kategorien",
    meta: indexWurEanfaYRMeta || {},
    alias: indexWurEanfaYRMeta?.alias || [],
    redirect: indexWurEanfaYRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexWurEanfaYRMeta?.name ?? "categories___fr",
    path: indexWurEanfaYRMeta?.path ?? "/fr/cat%C3%A9gories",
    meta: indexWurEanfaYRMeta || {},
    alias: indexWurEanfaYRMeta?.alias || [],
    redirect: indexWurEanfaYRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexWurEanfaYRMeta?.name ?? "categories___it",
    path: indexWurEanfaYRMeta?.path ?? "/it/categorie",
    meta: indexWurEanfaYRMeta || {},
    alias: indexWurEanfaYRMeta?.alias || [],
    redirect: indexWurEanfaYRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexWurEanfaYRMeta?.name ?? "categories___es",
    path: indexWurEanfaYRMeta?.path ?? "/es/categor%C3%ADas",
    meta: indexWurEanfaYRMeta || {},
    alias: indexWurEanfaYRMeta?.alias || [],
    redirect: indexWurEanfaYRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZc4rktduJMeta?.name ?? "chemical-resistance-guide",
    path: indexyZc4rktduJMeta?.path ?? "/chemical-resistance-guide",
    meta: indexyZc4rktduJMeta || {},
    alias: indexyZc4rktduJMeta?.alias || [],
    redirect: indexyZc4rktduJMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/chemical-resistance-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZc4rktduJMeta?.name ?? "chemical-resistance-guide___en",
    path: indexyZc4rktduJMeta?.path ?? "/en/chemical-resistance-guide",
    meta: indexyZc4rktduJMeta || {},
    alias: indexyZc4rktduJMeta?.alias || [],
    redirect: indexyZc4rktduJMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/chemical-resistance-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZc4rktduJMeta?.name ?? "chemical-resistance-guide___de",
    path: indexyZc4rktduJMeta?.path ?? "/de/chemical-resistance-guide",
    meta: indexyZc4rktduJMeta || {},
    alias: indexyZc4rktduJMeta?.alias || [],
    redirect: indexyZc4rktduJMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/chemical-resistance-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZc4rktduJMeta?.name ?? "chemical-resistance-guide___fr",
    path: indexyZc4rktduJMeta?.path ?? "/fr/chemical-resistance-guide",
    meta: indexyZc4rktduJMeta || {},
    alias: indexyZc4rktduJMeta?.alias || [],
    redirect: indexyZc4rktduJMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/chemical-resistance-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZc4rktduJMeta?.name ?? "chemical-resistance-guide___it",
    path: indexyZc4rktduJMeta?.path ?? "/it/chemical-resistance-guide",
    meta: indexyZc4rktduJMeta || {},
    alias: indexyZc4rktduJMeta?.alias || [],
    redirect: indexyZc4rktduJMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/chemical-resistance-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZc4rktduJMeta?.name ?? "chemical-resistance-guide___es",
    path: indexyZc4rktduJMeta?.path ?? "/es/chemical-resistance-guide",
    meta: indexyZc4rktduJMeta || {},
    alias: indexyZc4rktduJMeta?.alias || [],
    redirect: indexyZc4rktduJMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/chemical-resistance-guide/index.vue").then(m => m.default || m)
  },
  {
    name: cross_45reference7A0eYBD4J5Meta?.name ?? "cross-reference",
    path: cross_45reference7A0eYBD4J5Meta?.path ?? "/cross-reference",
    meta: cross_45reference7A0eYBD4J5Meta || {},
    alias: cross_45reference7A0eYBD4J5Meta?.alias || [],
    redirect: cross_45reference7A0eYBD4J5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cross-reference.vue").then(m => m.default || m)
  },
  {
    name: cross_45reference7A0eYBD4J5Meta?.name ?? "cross-reference___en",
    path: cross_45reference7A0eYBD4J5Meta?.path ?? "/en/cross-reference",
    meta: cross_45reference7A0eYBD4J5Meta || {},
    alias: cross_45reference7A0eYBD4J5Meta?.alias || [],
    redirect: cross_45reference7A0eYBD4J5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cross-reference.vue").then(m => m.default || m)
  },
  {
    name: cross_45reference7A0eYBD4J5Meta?.name ?? "cross-reference___de",
    path: cross_45reference7A0eYBD4J5Meta?.path ?? "/de/cross-reference",
    meta: cross_45reference7A0eYBD4J5Meta || {},
    alias: cross_45reference7A0eYBD4J5Meta?.alias || [],
    redirect: cross_45reference7A0eYBD4J5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cross-reference.vue").then(m => m.default || m)
  },
  {
    name: cross_45reference7A0eYBD4J5Meta?.name ?? "cross-reference___fr",
    path: cross_45reference7A0eYBD4J5Meta?.path ?? "/fr/reference-croisee",
    meta: cross_45reference7A0eYBD4J5Meta || {},
    alias: cross_45reference7A0eYBD4J5Meta?.alias || [],
    redirect: cross_45reference7A0eYBD4J5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cross-reference.vue").then(m => m.default || m)
  },
  {
    name: cross_45reference7A0eYBD4J5Meta?.name ?? "cross-reference___it",
    path: cross_45reference7A0eYBD4J5Meta?.path ?? "/it/rimando",
    meta: cross_45reference7A0eYBD4J5Meta || {},
    alias: cross_45reference7A0eYBD4J5Meta?.alias || [],
    redirect: cross_45reference7A0eYBD4J5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cross-reference.vue").then(m => m.default || m)
  },
  {
    name: cross_45reference7A0eYBD4J5Meta?.name ?? "cross-reference___es",
    path: cross_45reference7A0eYBD4J5Meta?.path ?? "/es/referencia-cruzada",
    meta: cross_45reference7A0eYBD4J5Meta || {},
    alias: cross_45reference7A0eYBD4J5Meta?.alias || [],
    redirect: cross_45reference7A0eYBD4J5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cross-reference.vue").then(m => m.default || m)
  },
  {
    name: distributor_45searchj1w3O4pEhxMeta?.name ?? "distributor-search",
    path: distributor_45searchj1w3O4pEhxMeta?.path ?? "/distributor-search",
    meta: distributor_45searchj1w3O4pEhxMeta || {},
    alias: distributor_45searchj1w3O4pEhxMeta?.alias || [],
    redirect: distributor_45searchj1w3O4pEhxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/distributor-search.vue").then(m => m.default || m)
  },
  {
    name: distributor_45searchj1w3O4pEhxMeta?.name ?? "distributor-search___en",
    path: distributor_45searchj1w3O4pEhxMeta?.path ?? "/en/distributor-search/:distributor%3F",
    meta: distributor_45searchj1w3O4pEhxMeta || {},
    alias: distributor_45searchj1w3O4pEhxMeta?.alias || [],
    redirect: distributor_45searchj1w3O4pEhxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/distributor-search.vue").then(m => m.default || m)
  },
  {
    name: distributor_45searchj1w3O4pEhxMeta?.name ?? "distributor-search___de",
    path: distributor_45searchj1w3O4pEhxMeta?.path ?? "/de/haendlersuche/:distributor%3F",
    meta: distributor_45searchj1w3O4pEhxMeta || {},
    alias: distributor_45searchj1w3O4pEhxMeta?.alias || [],
    redirect: distributor_45searchj1w3O4pEhxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/distributor-search.vue").then(m => m.default || m)
  },
  {
    name: distributor_45searchj1w3O4pEhxMeta?.name ?? "distributor-search___fr",
    path: distributor_45searchj1w3O4pEhxMeta?.path ?? "/fr/recherche-distributeurs/:distributor%3F",
    meta: distributor_45searchj1w3O4pEhxMeta || {},
    alias: distributor_45searchj1w3O4pEhxMeta?.alias || [],
    redirect: distributor_45searchj1w3O4pEhxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/distributor-search.vue").then(m => m.default || m)
  },
  {
    name: distributor_45searchj1w3O4pEhxMeta?.name ?? "distributor-search___it",
    path: distributor_45searchj1w3O4pEhxMeta?.path ?? "/it/cerca-distributore/:distributor%3F",
    meta: distributor_45searchj1w3O4pEhxMeta || {},
    alias: distributor_45searchj1w3O4pEhxMeta?.alias || [],
    redirect: distributor_45searchj1w3O4pEhxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/distributor-search.vue").then(m => m.default || m)
  },
  {
    name: distributor_45searchj1w3O4pEhxMeta?.name ?? "distributor-search___es",
    path: distributor_45searchj1w3O4pEhxMeta?.path ?? "/es/b%C3%BAsqueda-distribuidores/:distributor%3F",
    meta: distributor_45searchj1w3O4pEhxMeta || {},
    alias: distributor_45searchj1w3O4pEhxMeta?.alias || [],
    redirect: distributor_45searchj1w3O4pEhxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/distributor-search.vue").then(m => m.default || m)
  },
  {
    name: faq9K29EW5IX0Meta?.name ?? "faq",
    path: faq9K29EW5IX0Meta?.path ?? "/faq",
    meta: faq9K29EW5IX0Meta || {},
    alias: faq9K29EW5IX0Meta?.alias || [],
    redirect: faq9K29EW5IX0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faq9K29EW5IX0Meta?.name ?? "faq___en",
    path: faq9K29EW5IX0Meta?.path ?? "/en/faq",
    meta: faq9K29EW5IX0Meta || {},
    alias: faq9K29EW5IX0Meta?.alias || [],
    redirect: faq9K29EW5IX0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faq9K29EW5IX0Meta?.name ?? "faq___de",
    path: faq9K29EW5IX0Meta?.path ?? "/de/faq",
    meta: faq9K29EW5IX0Meta || {},
    alias: faq9K29EW5IX0Meta?.alias || [],
    redirect: faq9K29EW5IX0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faq9K29EW5IX0Meta?.name ?? "faq___fr",
    path: faq9K29EW5IX0Meta?.path ?? "/fr/faq",
    meta: faq9K29EW5IX0Meta || {},
    alias: faq9K29EW5IX0Meta?.alias || [],
    redirect: faq9K29EW5IX0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faq9K29EW5IX0Meta?.name ?? "faq___it",
    path: faq9K29EW5IX0Meta?.path ?? "/it/faq",
    meta: faq9K29EW5IX0Meta || {},
    alias: faq9K29EW5IX0Meta?.alias || [],
    redirect: faq9K29EW5IX0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faq9K29EW5IX0Meta?.name ?? "faq___es",
    path: faq9K29EW5IX0Meta?.path ?? "/es/PP.%20FF.",
    meta: faq9K29EW5IX0Meta || {},
    alias: faq9K29EW5IX0Meta?.alias || [],
    redirect: faq9K29EW5IX0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: _91guideDetail_9371jiqr2eaNMeta?.name ?? "guides-guideCategories-guideDetail",
    path: _91guideDetail_9371jiqr2eaNMeta?.path ?? "/guides/:guideCategories()/:guideDetail()",
    meta: _91guideDetail_9371jiqr2eaNMeta || {},
    alias: _91guideDetail_9371jiqr2eaNMeta?.alias || [],
    redirect: _91guideDetail_9371jiqr2eaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/[guideDetail].vue").then(m => m.default || m)
  },
  {
    name: _91guideDetail_9371jiqr2eaNMeta?.name ?? "guides-guideCategories-guideDetail___en",
    path: _91guideDetail_9371jiqr2eaNMeta?.path ?? "/en/guides/:guideCategories/:guideDetail",
    meta: _91guideDetail_9371jiqr2eaNMeta || {},
    alias: _91guideDetail_9371jiqr2eaNMeta?.alias || [],
    redirect: _91guideDetail_9371jiqr2eaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/[guideDetail].vue").then(m => m.default || m)
  },
  {
    name: _91guideDetail_9371jiqr2eaNMeta?.name ?? "guides-guideCategories-guideDetail___de",
    path: _91guideDetail_9371jiqr2eaNMeta?.path ?? "/de/anleitungen/:guideCategories/:guideDetail",
    meta: _91guideDetail_9371jiqr2eaNMeta || {},
    alias: _91guideDetail_9371jiqr2eaNMeta?.alias || [],
    redirect: _91guideDetail_9371jiqr2eaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/[guideDetail].vue").then(m => m.default || m)
  },
  {
    name: _91guideDetail_9371jiqr2eaNMeta?.name ?? "guides-guideCategories-guideDetail___fr",
    path: _91guideDetail_9371jiqr2eaNMeta?.path ?? "/fr/instructions/:guideCategories/:guideDetail",
    meta: _91guideDetail_9371jiqr2eaNMeta || {},
    alias: _91guideDetail_9371jiqr2eaNMeta?.alias || [],
    redirect: _91guideDetail_9371jiqr2eaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/[guideDetail].vue").then(m => m.default || m)
  },
  {
    name: _91guideDetail_9371jiqr2eaNMeta?.name ?? "guides-guideCategories-guideDetail___it",
    path: _91guideDetail_9371jiqr2eaNMeta?.path ?? "/it/istruzioni/:guideCategories/:guideDetail",
    meta: _91guideDetail_9371jiqr2eaNMeta || {},
    alias: _91guideDetail_9371jiqr2eaNMeta?.alias || [],
    redirect: _91guideDetail_9371jiqr2eaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/[guideDetail].vue").then(m => m.default || m)
  },
  {
    name: _91guideDetail_9371jiqr2eaNMeta?.name ?? "guides-guideCategories-guideDetail___es",
    path: _91guideDetail_9371jiqr2eaNMeta?.path ?? "/es/gu%C3%ADas/:guideCategories/:guideDetail",
    meta: _91guideDetail_9371jiqr2eaNMeta || {},
    alias: _91guideDetail_9371jiqr2eaNMeta?.alias || [],
    redirect: _91guideDetail_9371jiqr2eaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/[guideDetail].vue").then(m => m.default || m)
  },
  {
    name: indexZxRO3EAAxgMeta?.name ?? "guides-guideCategories",
    path: indexZxRO3EAAxgMeta?.path ?? "/guides/:guideCategories()",
    meta: indexZxRO3EAAxgMeta || {},
    alias: indexZxRO3EAAxgMeta?.alias || [],
    redirect: indexZxRO3EAAxgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZxRO3EAAxgMeta?.name ?? "guides-guideCategories___en",
    path: indexZxRO3EAAxgMeta?.path ?? "/en/guides/:guideCategories",
    meta: indexZxRO3EAAxgMeta || {},
    alias: indexZxRO3EAAxgMeta?.alias || [],
    redirect: indexZxRO3EAAxgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZxRO3EAAxgMeta?.name ?? "guides-guideCategories___de",
    path: indexZxRO3EAAxgMeta?.path ?? "/de/anleitungen/:guideCategories",
    meta: indexZxRO3EAAxgMeta || {},
    alias: indexZxRO3EAAxgMeta?.alias || [],
    redirect: indexZxRO3EAAxgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZxRO3EAAxgMeta?.name ?? "guides-guideCategories___fr",
    path: indexZxRO3EAAxgMeta?.path ?? "/fr/instructions/:guideCategories",
    meta: indexZxRO3EAAxgMeta || {},
    alias: indexZxRO3EAAxgMeta?.alias || [],
    redirect: indexZxRO3EAAxgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZxRO3EAAxgMeta?.name ?? "guides-guideCategories___it",
    path: indexZxRO3EAAxgMeta?.path ?? "/it/istruzioni/:guideCategories",
    meta: indexZxRO3EAAxgMeta || {},
    alias: indexZxRO3EAAxgMeta?.alias || [],
    redirect: indexZxRO3EAAxgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZxRO3EAAxgMeta?.name ?? "guides-guideCategories___es",
    path: indexZxRO3EAAxgMeta?.path ?? "/es/gu%C3%ADas/:guideCategories",
    meta: indexZxRO3EAAxgMeta || {},
    alias: indexZxRO3EAAxgMeta?.alias || [],
    redirect: indexZxRO3EAAxgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrBsmH2y9KMeta?.name ?? "guides",
    path: indexbrBsmH2y9KMeta?.path ?? "/guides",
    meta: indexbrBsmH2y9KMeta || {},
    alias: indexbrBsmH2y9KMeta?.alias || [],
    redirect: indexbrBsmH2y9KMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrBsmH2y9KMeta?.name ?? "guides___en",
    path: indexbrBsmH2y9KMeta?.path ?? "/en/guides",
    meta: indexbrBsmH2y9KMeta || {},
    alias: indexbrBsmH2y9KMeta?.alias || [],
    redirect: indexbrBsmH2y9KMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrBsmH2y9KMeta?.name ?? "guides___de",
    path: indexbrBsmH2y9KMeta?.path ?? "/de/anleitungen",
    meta: indexbrBsmH2y9KMeta || {},
    alias: indexbrBsmH2y9KMeta?.alias || [],
    redirect: indexbrBsmH2y9KMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrBsmH2y9KMeta?.name ?? "guides___fr",
    path: indexbrBsmH2y9KMeta?.path ?? "/fr/instructions",
    meta: indexbrBsmH2y9KMeta || {},
    alias: indexbrBsmH2y9KMeta?.alias || [],
    redirect: indexbrBsmH2y9KMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrBsmH2y9KMeta?.name ?? "guides___it",
    path: indexbrBsmH2y9KMeta?.path ?? "/it/istruzioni",
    meta: indexbrBsmH2y9KMeta || {},
    alias: indexbrBsmH2y9KMeta?.alias || [],
    redirect: indexbrBsmH2y9KMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrBsmH2y9KMeta?.name ?? "guides___es",
    path: indexbrBsmH2y9KMeta?.path ?? "/es/gu%C3%ADas",
    meta: indexbrBsmH2y9KMeta || {},
    alias: indexbrBsmH2y9KMeta?.alias || [],
    redirect: indexbrBsmH2y9KMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexakUrKMxmV5Meta?.name ?? "hydraulic-selector",
    path: indexakUrKMxmV5Meta?.path ?? "/hydraulic-selector",
    meta: indexakUrKMxmV5Meta || {},
    alias: indexakUrKMxmV5Meta?.alias || [],
    redirect: indexakUrKMxmV5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexakUrKMxmV5Meta?.name ?? "hydraulic-selector___en",
    path: indexakUrKMxmV5Meta?.path ?? "/en/hydraulic-selector",
    meta: indexakUrKMxmV5Meta || {},
    alias: indexakUrKMxmV5Meta?.alias || [],
    redirect: indexakUrKMxmV5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexakUrKMxmV5Meta?.name ?? "hydraulic-selector___de",
    path: indexakUrKMxmV5Meta?.path ?? "/de/hydraulic-selector",
    meta: indexakUrKMxmV5Meta || {},
    alias: indexakUrKMxmV5Meta?.alias || [],
    redirect: indexakUrKMxmV5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexakUrKMxmV5Meta?.name ?? "hydraulic-selector___fr",
    path: indexakUrKMxmV5Meta?.path ?? "/fr/hydraulic-selector",
    meta: indexakUrKMxmV5Meta || {},
    alias: indexakUrKMxmV5Meta?.alias || [],
    redirect: indexakUrKMxmV5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexakUrKMxmV5Meta?.name ?? "hydraulic-selector___it",
    path: indexakUrKMxmV5Meta?.path ?? "/it/hydraulic-selector",
    meta: indexakUrKMxmV5Meta || {},
    alias: indexakUrKMxmV5Meta?.alias || [],
    redirect: indexakUrKMxmV5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexakUrKMxmV5Meta?.name ?? "hydraulic-selector___es",
    path: indexakUrKMxmV5Meta?.path ?? "/es/hydraulic-selector",
    meta: indexakUrKMxmV5Meta || {},
    alias: indexakUrKMxmV5Meta?.alias || [],
    redirect: indexakUrKMxmV5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIAEjEelX6Meta?.name ?? "hydraulic-selector-new-construction",
    path: indexAIAEjEelX6Meta?.path ?? "/hydraulic-selector/new-construction",
    meta: indexAIAEjEelX6Meta || {},
    alias: indexAIAEjEelX6Meta?.alias || [],
    redirect: indexAIAEjEelX6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/new-construction/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIAEjEelX6Meta?.name ?? "hydraulic-selector-new-construction___en",
    path: indexAIAEjEelX6Meta?.path ?? "/en/hydraulic-selector/new-construction",
    meta: indexAIAEjEelX6Meta || {},
    alias: indexAIAEjEelX6Meta?.alias || [],
    redirect: indexAIAEjEelX6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/new-construction/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIAEjEelX6Meta?.name ?? "hydraulic-selector-new-construction___de",
    path: indexAIAEjEelX6Meta?.path ?? "/de/hydraulic-selector/new-construction",
    meta: indexAIAEjEelX6Meta || {},
    alias: indexAIAEjEelX6Meta?.alias || [],
    redirect: indexAIAEjEelX6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/new-construction/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIAEjEelX6Meta?.name ?? "hydraulic-selector-new-construction___fr",
    path: indexAIAEjEelX6Meta?.path ?? "/fr/hydraulic-selector/new-construction",
    meta: indexAIAEjEelX6Meta || {},
    alias: indexAIAEjEelX6Meta?.alias || [],
    redirect: indexAIAEjEelX6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/new-construction/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIAEjEelX6Meta?.name ?? "hydraulic-selector-new-construction___it",
    path: indexAIAEjEelX6Meta?.path ?? "/it/hydraulic-selector/new-construction",
    meta: indexAIAEjEelX6Meta || {},
    alias: indexAIAEjEelX6Meta?.alias || [],
    redirect: indexAIAEjEelX6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/new-construction/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIAEjEelX6Meta?.name ?? "hydraulic-selector-new-construction___es",
    path: indexAIAEjEelX6Meta?.path ?? "/es/hydraulic-selector/new-construction",
    meta: indexAIAEjEelX6Meta || {},
    alias: indexAIAEjEelX6Meta?.alias || [],
    redirect: indexAIAEjEelX6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/new-construction/index.vue").then(m => m.default || m)
  },
  {
    name: index9gJaQTLVLfMeta?.name ?? "hydraulic-selector-replacement",
    path: index9gJaQTLVLfMeta?.path ?? "/hydraulic-selector/replacement",
    meta: index9gJaQTLVLfMeta || {},
    alias: index9gJaQTLVLfMeta?.alias || [],
    redirect: index9gJaQTLVLfMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/replacement/index.vue").then(m => m.default || m)
  },
  {
    name: index9gJaQTLVLfMeta?.name ?? "hydraulic-selector-replacement___en",
    path: index9gJaQTLVLfMeta?.path ?? "/en/hydraulic-selector/replacement",
    meta: index9gJaQTLVLfMeta || {},
    alias: index9gJaQTLVLfMeta?.alias || [],
    redirect: index9gJaQTLVLfMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/replacement/index.vue").then(m => m.default || m)
  },
  {
    name: index9gJaQTLVLfMeta?.name ?? "hydraulic-selector-replacement___de",
    path: index9gJaQTLVLfMeta?.path ?? "/de/hydraulic-selector/replacement",
    meta: index9gJaQTLVLfMeta || {},
    alias: index9gJaQTLVLfMeta?.alias || [],
    redirect: index9gJaQTLVLfMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/replacement/index.vue").then(m => m.default || m)
  },
  {
    name: index9gJaQTLVLfMeta?.name ?? "hydraulic-selector-replacement___fr",
    path: index9gJaQTLVLfMeta?.path ?? "/fr/hydraulic-selector/replacement",
    meta: index9gJaQTLVLfMeta || {},
    alias: index9gJaQTLVLfMeta?.alias || [],
    redirect: index9gJaQTLVLfMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/replacement/index.vue").then(m => m.default || m)
  },
  {
    name: index9gJaQTLVLfMeta?.name ?? "hydraulic-selector-replacement___it",
    path: index9gJaQTLVLfMeta?.path ?? "/it/hydraulic-selector/replacement",
    meta: index9gJaQTLVLfMeta || {},
    alias: index9gJaQTLVLfMeta?.alias || [],
    redirect: index9gJaQTLVLfMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/replacement/index.vue").then(m => m.default || m)
  },
  {
    name: index9gJaQTLVLfMeta?.name ?? "hydraulic-selector-replacement___es",
    path: index9gJaQTLVLfMeta?.path ?? "/es/hydraulic-selector/replacement",
    meta: index9gJaQTLVLfMeta || {},
    alias: index9gJaQTLVLfMeta?.alias || [],
    redirect: index9gJaQTLVLfMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/replacement/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWhPMsLzMKMeta?.name ?? "index",
    path: indexsWhPMsLzMKMeta?.path ?? "/",
    meta: indexsWhPMsLzMKMeta || {},
    alias: indexsWhPMsLzMKMeta?.alias || [],
    redirect: indexsWhPMsLzMKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWhPMsLzMKMeta?.name ?? "index___en",
    path: indexsWhPMsLzMKMeta?.path ?? "/en",
    meta: indexsWhPMsLzMKMeta || {},
    alias: indexsWhPMsLzMKMeta?.alias || [],
    redirect: indexsWhPMsLzMKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWhPMsLzMKMeta?.name ?? "index___de",
    path: indexsWhPMsLzMKMeta?.path ?? "/de",
    meta: indexsWhPMsLzMKMeta || {},
    alias: indexsWhPMsLzMKMeta?.alias || [],
    redirect: indexsWhPMsLzMKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWhPMsLzMKMeta?.name ?? "index___fr",
    path: indexsWhPMsLzMKMeta?.path ?? "/fr",
    meta: indexsWhPMsLzMKMeta || {},
    alias: indexsWhPMsLzMKMeta?.alias || [],
    redirect: indexsWhPMsLzMKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWhPMsLzMKMeta?.name ?? "index___it",
    path: indexsWhPMsLzMKMeta?.path ?? "/it",
    meta: indexsWhPMsLzMKMeta || {},
    alias: indexsWhPMsLzMKMeta?.alias || [],
    redirect: indexsWhPMsLzMKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWhPMsLzMKMeta?.name ?? "index___es",
    path: indexsWhPMsLzMKMeta?.path ?? "/es",
    meta: indexsWhPMsLzMKMeta || {},
    alias: indexsWhPMsLzMKMeta?.alias || [],
    redirect: indexsWhPMsLzMKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: internal_45registrationMcxzzQlNz6Meta?.name ?? "internal-registration",
    path: internal_45registrationMcxzzQlNz6Meta?.path ?? "/internal-registration",
    meta: internal_45registrationMcxzzQlNz6Meta || {},
    alias: internal_45registrationMcxzzQlNz6Meta?.alias || [],
    redirect: internal_45registrationMcxzzQlNz6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/internal-registration.vue").then(m => m.default || m)
  },
  {
    name: internal_45registrationMcxzzQlNz6Meta?.name ?? "internal-registration___en",
    path: internal_45registrationMcxzzQlNz6Meta?.path ?? "/en/internal-registration",
    meta: internal_45registrationMcxzzQlNz6Meta || {},
    alias: internal_45registrationMcxzzQlNz6Meta?.alias || [],
    redirect: internal_45registrationMcxzzQlNz6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/internal-registration.vue").then(m => m.default || m)
  },
  {
    name: internal_45registrationMcxzzQlNz6Meta?.name ?? "internal-registration___de",
    path: internal_45registrationMcxzzQlNz6Meta?.path ?? "/de/internal-registration",
    meta: internal_45registrationMcxzzQlNz6Meta || {},
    alias: internal_45registrationMcxzzQlNz6Meta?.alias || [],
    redirect: internal_45registrationMcxzzQlNz6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/internal-registration.vue").then(m => m.default || m)
  },
  {
    name: internal_45registrationMcxzzQlNz6Meta?.name ?? "internal-registration___fr",
    path: internal_45registrationMcxzzQlNz6Meta?.path ?? "/fr/internal-registration",
    meta: internal_45registrationMcxzzQlNz6Meta || {},
    alias: internal_45registrationMcxzzQlNz6Meta?.alias || [],
    redirect: internal_45registrationMcxzzQlNz6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/internal-registration.vue").then(m => m.default || m)
  },
  {
    name: internal_45registrationMcxzzQlNz6Meta?.name ?? "internal-registration___it",
    path: internal_45registrationMcxzzQlNz6Meta?.path ?? "/it/internal-registration",
    meta: internal_45registrationMcxzzQlNz6Meta || {},
    alias: internal_45registrationMcxzzQlNz6Meta?.alias || [],
    redirect: internal_45registrationMcxzzQlNz6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/internal-registration.vue").then(m => m.default || m)
  },
  {
    name: internal_45registrationMcxzzQlNz6Meta?.name ?? "internal-registration___es",
    path: internal_45registrationMcxzzQlNz6Meta?.path ?? "/es/internal-registration",
    meta: internal_45registrationMcxzzQlNz6Meta || {},
    alias: internal_45registrationMcxzzQlNz6Meta?.alias || [],
    redirect: internal_45registrationMcxzzQlNz6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/internal-registration.vue").then(m => m.default || m)
  },
  {
    name: _91management_93J0zfUadgIzMeta?.name ?? "management-management",
    path: _91management_93J0zfUadgIzMeta?.path ?? "/management/:management()",
    meta: _91management_93J0zfUadgIzMeta || {},
    alias: _91management_93J0zfUadgIzMeta?.alias || [],
    redirect: _91management_93J0zfUadgIzMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/[management].vue").then(m => m.default || m)
  },
  {
    name: _91management_93J0zfUadgIzMeta?.name ?? "management-management___en",
    path: _91management_93J0zfUadgIzMeta?.path ?? "/en/management/:management",
    meta: _91management_93J0zfUadgIzMeta || {},
    alias: _91management_93J0zfUadgIzMeta?.alias || [],
    redirect: _91management_93J0zfUadgIzMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/[management].vue").then(m => m.default || m)
  },
  {
    name: _91management_93J0zfUadgIzMeta?.name ?? "management-management___de",
    path: _91management_93J0zfUadgIzMeta?.path ?? "/de/verwaltung/:management",
    meta: _91management_93J0zfUadgIzMeta || {},
    alias: _91management_93J0zfUadgIzMeta?.alias || [],
    redirect: _91management_93J0zfUadgIzMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/[management].vue").then(m => m.default || m)
  },
  {
    name: _91management_93J0zfUadgIzMeta?.name ?? "management-management___fr",
    path: _91management_93J0zfUadgIzMeta?.path ?? "/fr/administration/:management",
    meta: _91management_93J0zfUadgIzMeta || {},
    alias: _91management_93J0zfUadgIzMeta?.alias || [],
    redirect: _91management_93J0zfUadgIzMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/[management].vue").then(m => m.default || m)
  },
  {
    name: _91management_93J0zfUadgIzMeta?.name ?? "management-management___it",
    path: _91management_93J0zfUadgIzMeta?.path ?? "/it/gestione/:management",
    meta: _91management_93J0zfUadgIzMeta || {},
    alias: _91management_93J0zfUadgIzMeta?.alias || [],
    redirect: _91management_93J0zfUadgIzMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/[management].vue").then(m => m.default || m)
  },
  {
    name: _91management_93J0zfUadgIzMeta?.name ?? "management-management___es",
    path: _91management_93J0zfUadgIzMeta?.path ?? "/es/administraci%C3%B3n/:management",
    meta: _91management_93J0zfUadgIzMeta || {},
    alias: _91management_93J0zfUadgIzMeta?.alias || [],
    redirect: _91management_93J0zfUadgIzMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/[management].vue").then(m => m.default || m)
  },
  {
    name: indexRfVItVttNeMeta?.name ?? "management",
    path: indexRfVItVttNeMeta?.path ?? "/management",
    meta: indexRfVItVttNeMeta || {},
    alias: indexRfVItVttNeMeta?.alias || [],
    redirect: indexRfVItVttNeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: indexRfVItVttNeMeta?.name ?? "management___en",
    path: indexRfVItVttNeMeta?.path ?? "/en/management",
    meta: indexRfVItVttNeMeta || {},
    alias: indexRfVItVttNeMeta?.alias || [],
    redirect: indexRfVItVttNeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: indexRfVItVttNeMeta?.name ?? "management___de",
    path: indexRfVItVttNeMeta?.path ?? "/de/verwaltung",
    meta: indexRfVItVttNeMeta || {},
    alias: indexRfVItVttNeMeta?.alias || [],
    redirect: indexRfVItVttNeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: indexRfVItVttNeMeta?.name ?? "management___fr",
    path: indexRfVItVttNeMeta?.path ?? "/fr/administration",
    meta: indexRfVItVttNeMeta || {},
    alias: indexRfVItVttNeMeta?.alias || [],
    redirect: indexRfVItVttNeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: indexRfVItVttNeMeta?.name ?? "management___it",
    path: indexRfVItVttNeMeta?.path ?? "/it/gestione",
    meta: indexRfVItVttNeMeta || {},
    alias: indexRfVItVttNeMeta?.alias || [],
    redirect: indexRfVItVttNeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: indexRfVItVttNeMeta?.name ?? "management___es",
    path: indexRfVItVttNeMeta?.path ?? "/es/administraci%C3%B3n",
    meta: indexRfVItVttNeMeta || {},
    alias: indexRfVItVttNeMeta?.alias || [],
    redirect: indexRfVItVttNeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: _91newsDetail_93qxwtIbxfV1Meta?.name ?? "news-newsDetail",
    path: _91newsDetail_93qxwtIbxfV1Meta?.path ?? "/news/:newsDetail()",
    meta: _91newsDetail_93qxwtIbxfV1Meta || {},
    alias: _91newsDetail_93qxwtIbxfV1Meta?.alias || [],
    redirect: _91newsDetail_93qxwtIbxfV1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/[newsDetail].vue").then(m => m.default || m)
  },
  {
    name: _91newsDetail_93qxwtIbxfV1Meta?.name ?? "news-newsDetail___en",
    path: _91newsDetail_93qxwtIbxfV1Meta?.path ?? "/en/news/:newsDetail",
    meta: _91newsDetail_93qxwtIbxfV1Meta || {},
    alias: _91newsDetail_93qxwtIbxfV1Meta?.alias || [],
    redirect: _91newsDetail_93qxwtIbxfV1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/[newsDetail].vue").then(m => m.default || m)
  },
  {
    name: _91newsDetail_93qxwtIbxfV1Meta?.name ?? "news-newsDetail___de",
    path: _91newsDetail_93qxwtIbxfV1Meta?.path ?? "/de/nachrichten/:newsDetail",
    meta: _91newsDetail_93qxwtIbxfV1Meta || {},
    alias: _91newsDetail_93qxwtIbxfV1Meta?.alias || [],
    redirect: _91newsDetail_93qxwtIbxfV1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/[newsDetail].vue").then(m => m.default || m)
  },
  {
    name: _91newsDetail_93qxwtIbxfV1Meta?.name ?? "news-newsDetail___fr",
    path: _91newsDetail_93qxwtIbxfV1Meta?.path ?? "/fr/actualit%C3%A9s/:newsDetail",
    meta: _91newsDetail_93qxwtIbxfV1Meta || {},
    alias: _91newsDetail_93qxwtIbxfV1Meta?.alias || [],
    redirect: _91newsDetail_93qxwtIbxfV1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/[newsDetail].vue").then(m => m.default || m)
  },
  {
    name: _91newsDetail_93qxwtIbxfV1Meta?.name ?? "news-newsDetail___it",
    path: _91newsDetail_93qxwtIbxfV1Meta?.path ?? "/it/novit%C3%A0/:newsDetail",
    meta: _91newsDetail_93qxwtIbxfV1Meta || {},
    alias: _91newsDetail_93qxwtIbxfV1Meta?.alias || [],
    redirect: _91newsDetail_93qxwtIbxfV1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/[newsDetail].vue").then(m => m.default || m)
  },
  {
    name: _91newsDetail_93qxwtIbxfV1Meta?.name ?? "news-newsDetail___es",
    path: _91newsDetail_93qxwtIbxfV1Meta?.path ?? "/es/novedades/:newsDetail",
    meta: _91newsDetail_93qxwtIbxfV1Meta || {},
    alias: _91newsDetail_93qxwtIbxfV1Meta?.alias || [],
    redirect: _91newsDetail_93qxwtIbxfV1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/[newsDetail].vue").then(m => m.default || m)
  },
  {
    name: indexYY0TzDVdGsMeta?.name ?? "news",
    path: indexYY0TzDVdGsMeta?.path ?? "/news",
    meta: indexYY0TzDVdGsMeta || {},
    alias: indexYY0TzDVdGsMeta?.alias || [],
    redirect: indexYY0TzDVdGsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYY0TzDVdGsMeta?.name ?? "news___en",
    path: indexYY0TzDVdGsMeta?.path ?? "/en/news",
    meta: indexYY0TzDVdGsMeta || {},
    alias: indexYY0TzDVdGsMeta?.alias || [],
    redirect: indexYY0TzDVdGsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYY0TzDVdGsMeta?.name ?? "news___de",
    path: indexYY0TzDVdGsMeta?.path ?? "/de/nachrichten",
    meta: indexYY0TzDVdGsMeta || {},
    alias: indexYY0TzDVdGsMeta?.alias || [],
    redirect: indexYY0TzDVdGsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYY0TzDVdGsMeta?.name ?? "news___fr",
    path: indexYY0TzDVdGsMeta?.path ?? "/fr/actualit%C3%A9s",
    meta: indexYY0TzDVdGsMeta || {},
    alias: indexYY0TzDVdGsMeta?.alias || [],
    redirect: indexYY0TzDVdGsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYY0TzDVdGsMeta?.name ?? "news___it",
    path: indexYY0TzDVdGsMeta?.path ?? "/it/novit%C3%A0",
    meta: indexYY0TzDVdGsMeta || {},
    alias: indexYY0TzDVdGsMeta?.alias || [],
    redirect: indexYY0TzDVdGsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYY0TzDVdGsMeta?.name ?? "news___es",
    path: indexYY0TzDVdGsMeta?.path ?? "/es/novedades",
    meta: indexYY0TzDVdGsMeta || {},
    alias: indexYY0TzDVdGsMeta?.alias || [],
    redirect: indexYY0TzDVdGsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: index6AY1IaCh4mMeta?.name ?? "orderHistory",
    path: index6AY1IaCh4mMeta?.path ?? "/orderHistory",
    meta: index6AY1IaCh4mMeta || {},
    alias: index6AY1IaCh4mMeta?.alias || [],
    redirect: index6AY1IaCh4mMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/orderHistory/index.vue").then(m => m.default || m)
  },
  {
    name: index6AY1IaCh4mMeta?.name ?? "orderHistory___en",
    path: index6AY1IaCh4mMeta?.path ?? "/en/order-history",
    meta: index6AY1IaCh4mMeta || {},
    alias: index6AY1IaCh4mMeta?.alias || [],
    redirect: index6AY1IaCh4mMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/orderHistory/index.vue").then(m => m.default || m)
  },
  {
    name: index6AY1IaCh4mMeta?.name ?? "orderHistory___de",
    path: index6AY1IaCh4mMeta?.path ?? "/de/bestellhistorie",
    meta: index6AY1IaCh4mMeta || {},
    alias: index6AY1IaCh4mMeta?.alias || [],
    redirect: index6AY1IaCh4mMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/orderHistory/index.vue").then(m => m.default || m)
  },
  {
    name: index6AY1IaCh4mMeta?.name ?? "orderHistory___fr",
    path: index6AY1IaCh4mMeta?.path ?? "/fr/historique-commande",
    meta: index6AY1IaCh4mMeta || {},
    alias: index6AY1IaCh4mMeta?.alias || [],
    redirect: index6AY1IaCh4mMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/orderHistory/index.vue").then(m => m.default || m)
  },
  {
    name: index6AY1IaCh4mMeta?.name ?? "orderHistory___it",
    path: index6AY1IaCh4mMeta?.path ?? "/it/cronologia-ordini",
    meta: index6AY1IaCh4mMeta || {},
    alias: index6AY1IaCh4mMeta?.alias || [],
    redirect: index6AY1IaCh4mMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/orderHistory/index.vue").then(m => m.default || m)
  },
  {
    name: index6AY1IaCh4mMeta?.name ?? "orderHistory___es",
    path: index6AY1IaCh4mMeta?.path ?? "/es/historial-pedidos",
    meta: index6AY1IaCh4mMeta || {},
    alias: index6AY1IaCh4mMeta?.alias || [],
    redirect: index6AY1IaCh4mMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/orderHistory/index.vue").then(m => m.default || m)
  },
  {
    name: _91product_93qdgql8cVUCMeta?.name ?? "products-product",
    path: _91product_93qdgql8cVUCMeta?.path ?? "/products/:product()",
    meta: _91product_93qdgql8cVUCMeta || {},
    alias: _91product_93qdgql8cVUCMeta?.alias || [],
    redirect: _91product_93qdgql8cVUCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93qdgql8cVUCMeta?.name ?? "products-product___en",
    path: _91product_93qdgql8cVUCMeta?.path ?? "/en/products/:product()",
    meta: _91product_93qdgql8cVUCMeta || {},
    alias: _91product_93qdgql8cVUCMeta?.alias || [],
    redirect: _91product_93qdgql8cVUCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93qdgql8cVUCMeta?.name ?? "products-product___de",
    path: _91product_93qdgql8cVUCMeta?.path ?? "/de/products/:product()",
    meta: _91product_93qdgql8cVUCMeta || {},
    alias: _91product_93qdgql8cVUCMeta?.alias || [],
    redirect: _91product_93qdgql8cVUCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93qdgql8cVUCMeta?.name ?? "products-product___fr",
    path: _91product_93qdgql8cVUCMeta?.path ?? "/fr/products/:product()",
    meta: _91product_93qdgql8cVUCMeta || {},
    alias: _91product_93qdgql8cVUCMeta?.alias || [],
    redirect: _91product_93qdgql8cVUCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93qdgql8cVUCMeta?.name ?? "products-product___it",
    path: _91product_93qdgql8cVUCMeta?.path ?? "/it/products/:product()",
    meta: _91product_93qdgql8cVUCMeta || {},
    alias: _91product_93qdgql8cVUCMeta?.alias || [],
    redirect: _91product_93qdgql8cVUCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93qdgql8cVUCMeta?.name ?? "products-product___es",
    path: _91product_93qdgql8cVUCMeta?.path ?? "/es/products/:product()",
    meta: _91product_93qdgql8cVUCMeta || {},
    alias: _91product_93qdgql8cVUCMeta?.alias || [],
    redirect: _91product_93qdgql8cVUCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: profile1vvtTRV7QgMeta?.name ?? "profile",
    path: profile1vvtTRV7QgMeta?.path ?? "/profile",
    meta: profile1vvtTRV7QgMeta || {},
    alias: profile1vvtTRV7QgMeta?.alias || [],
    redirect: profile1vvtTRV7QgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profile1vvtTRV7QgMeta?.name ?? "profile___en",
    path: profile1vvtTRV7QgMeta?.path ?? "/en/profile",
    meta: profile1vvtTRV7QgMeta || {},
    alias: profile1vvtTRV7QgMeta?.alias || [],
    redirect: profile1vvtTRV7QgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profile1vvtTRV7QgMeta?.name ?? "profile___de",
    path: profile1vvtTRV7QgMeta?.path ?? "/de/profil",
    meta: profile1vvtTRV7QgMeta || {},
    alias: profile1vvtTRV7QgMeta?.alias || [],
    redirect: profile1vvtTRV7QgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profile1vvtTRV7QgMeta?.name ?? "profile___fr",
    path: profile1vvtTRV7QgMeta?.path ?? "/fr/profil",
    meta: profile1vvtTRV7QgMeta || {},
    alias: profile1vvtTRV7QgMeta?.alias || [],
    redirect: profile1vvtTRV7QgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profile1vvtTRV7QgMeta?.name ?? "profile___it",
    path: profile1vvtTRV7QgMeta?.path ?? "/it/profilo",
    meta: profile1vvtTRV7QgMeta || {},
    alias: profile1vvtTRV7QgMeta?.alias || [],
    redirect: profile1vvtTRV7QgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profile1vvtTRV7QgMeta?.name ?? "profile___es",
    path: profile1vvtTRV7QgMeta?.path ?? "/es/perfil",
    meta: profile1vvtTRV7QgMeta || {},
    alias: profile1vvtTRV7QgMeta?.alias || [],
    redirect: profile1vvtTRV7QgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: register_45thankyouYADaN8wnyQMeta?.name ?? "register-thankyou",
    path: register_45thankyouYADaN8wnyQMeta?.path ?? "/register-thankyou",
    meta: register_45thankyouYADaN8wnyQMeta || {},
    alias: register_45thankyouYADaN8wnyQMeta?.alias || [],
    redirect: register_45thankyouYADaN8wnyQMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register-thankyou.vue").then(m => m.default || m)
  },
  {
    name: register_45thankyouYADaN8wnyQMeta?.name ?? "register-thankyou___en",
    path: register_45thankyouYADaN8wnyQMeta?.path ?? "/en/register-thankyou",
    meta: register_45thankyouYADaN8wnyQMeta || {},
    alias: register_45thankyouYADaN8wnyQMeta?.alias || [],
    redirect: register_45thankyouYADaN8wnyQMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register-thankyou.vue").then(m => m.default || m)
  },
  {
    name: register_45thankyouYADaN8wnyQMeta?.name ?? "register-thankyou___de",
    path: register_45thankyouYADaN8wnyQMeta?.path ?? "/de/registrierung-danke",
    meta: register_45thankyouYADaN8wnyQMeta || {},
    alias: register_45thankyouYADaN8wnyQMeta?.alias || [],
    redirect: register_45thankyouYADaN8wnyQMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register-thankyou.vue").then(m => m.default || m)
  },
  {
    name: register_45thankyouYADaN8wnyQMeta?.name ?? "register-thankyou___fr",
    path: register_45thankyouYADaN8wnyQMeta?.path ?? "/fr/enregistrement-merci",
    meta: register_45thankyouYADaN8wnyQMeta || {},
    alias: register_45thankyouYADaN8wnyQMeta?.alias || [],
    redirect: register_45thankyouYADaN8wnyQMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register-thankyou.vue").then(m => m.default || m)
  },
  {
    name: register_45thankyouYADaN8wnyQMeta?.name ?? "register-thankyou___it",
    path: register_45thankyouYADaN8wnyQMeta?.path ?? "/it/registrazione-grazie",
    meta: register_45thankyouYADaN8wnyQMeta || {},
    alias: register_45thankyouYADaN8wnyQMeta?.alias || [],
    redirect: register_45thankyouYADaN8wnyQMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register-thankyou.vue").then(m => m.default || m)
  },
  {
    name: register_45thankyouYADaN8wnyQMeta?.name ?? "register-thankyou___es",
    path: register_45thankyouYADaN8wnyQMeta?.path ?? "/es/registrarse-gracias",
    meta: register_45thankyouYADaN8wnyQMeta || {},
    alias: register_45thankyouYADaN8wnyQMeta?.alias || [],
    redirect: register_45thankyouYADaN8wnyQMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register-thankyou.vue").then(m => m.default || m)
  },
  {
    name: registerduRznR7f4eMeta?.name ?? "register",
    path: registerduRznR7f4eMeta?.path ?? "/register",
    meta: registerduRznR7f4eMeta || {},
    alias: registerduRznR7f4eMeta?.alias || [],
    redirect: registerduRznR7f4eMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerduRznR7f4eMeta?.name ?? "register___en",
    path: registerduRznR7f4eMeta?.path ?? "/en/register",
    meta: registerduRznR7f4eMeta || {},
    alias: registerduRznR7f4eMeta?.alias || [],
    redirect: registerduRznR7f4eMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerduRznR7f4eMeta?.name ?? "register___de",
    path: registerduRznR7f4eMeta?.path ?? "/de/registrierung",
    meta: registerduRznR7f4eMeta || {},
    alias: registerduRznR7f4eMeta?.alias || [],
    redirect: registerduRznR7f4eMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerduRznR7f4eMeta?.name ?? "register___fr",
    path: registerduRznR7f4eMeta?.path ?? "/fr/enregistrement",
    meta: registerduRznR7f4eMeta || {},
    alias: registerduRznR7f4eMeta?.alias || [],
    redirect: registerduRznR7f4eMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerduRznR7f4eMeta?.name ?? "register___it",
    path: registerduRznR7f4eMeta?.path ?? "/it/registrazione",
    meta: registerduRznR7f4eMeta || {},
    alias: registerduRznR7f4eMeta?.alias || [],
    redirect: registerduRznR7f4eMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerduRznR7f4eMeta?.name ?? "register___es",
    path: registerduRznR7f4eMeta?.path ?? "/es/registrarse",
    meta: registerduRznR7f4eMeta || {},
    alias: registerduRznR7f4eMeta?.alias || [],
    redirect: registerduRznR7f4eMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: indexSm87cCTbKDMeta?.name ?? "searchResults",
    path: indexSm87cCTbKDMeta?.path ?? "/searchResults",
    meta: indexSm87cCTbKDMeta || {},
    alias: indexSm87cCTbKDMeta?.alias || [],
    redirect: indexSm87cCTbKDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/searchResults/index.vue").then(m => m.default || m)
  },
  {
    name: indexSm87cCTbKDMeta?.name ?? "searchResults___en",
    path: indexSm87cCTbKDMeta?.path ?? "/en/search-results",
    meta: indexSm87cCTbKDMeta || {},
    alias: indexSm87cCTbKDMeta?.alias || [],
    redirect: indexSm87cCTbKDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/searchResults/index.vue").then(m => m.default || m)
  },
  {
    name: indexSm87cCTbKDMeta?.name ?? "searchResults___de",
    path: indexSm87cCTbKDMeta?.path ?? "/de/suchergebnisse",
    meta: indexSm87cCTbKDMeta || {},
    alias: indexSm87cCTbKDMeta?.alias || [],
    redirect: indexSm87cCTbKDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/searchResults/index.vue").then(m => m.default || m)
  },
  {
    name: indexSm87cCTbKDMeta?.name ?? "searchResults___fr",
    path: indexSm87cCTbKDMeta?.path ?? "/fr/resultats-recherche",
    meta: indexSm87cCTbKDMeta || {},
    alias: indexSm87cCTbKDMeta?.alias || [],
    redirect: indexSm87cCTbKDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/searchResults/index.vue").then(m => m.default || m)
  },
  {
    name: indexSm87cCTbKDMeta?.name ?? "searchResults___it",
    path: indexSm87cCTbKDMeta?.path ?? "/it/risultati-ricerca",
    meta: indexSm87cCTbKDMeta || {},
    alias: indexSm87cCTbKDMeta?.alias || [],
    redirect: indexSm87cCTbKDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/searchResults/index.vue").then(m => m.default || m)
  },
  {
    name: indexSm87cCTbKDMeta?.name ?? "searchResults___es",
    path: indexSm87cCTbKDMeta?.path ?? "/es/resultados-b%C3%BAsqueda",
    meta: indexSm87cCTbKDMeta || {},
    alias: indexSm87cCTbKDMeta?.alias || [],
    redirect: indexSm87cCTbKDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/searchResults/index.vue").then(m => m.default || m)
  },
  {
    name: setupByOG7HKR9NMeta?.name ?? "setup",
    path: setupByOG7HKR9NMeta?.path ?? "/setup",
    meta: setupByOG7HKR9NMeta || {},
    alias: setupByOG7HKR9NMeta?.alias || [],
    redirect: setupByOG7HKR9NMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: setupByOG7HKR9NMeta?.name ?? "setup___en",
    path: setupByOG7HKR9NMeta?.path ?? "/en/setup",
    meta: setupByOG7HKR9NMeta || {},
    alias: setupByOG7HKR9NMeta?.alias || [],
    redirect: setupByOG7HKR9NMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: setupByOG7HKR9NMeta?.name ?? "setup___de",
    path: setupByOG7HKR9NMeta?.path ?? "/de/einrichtung",
    meta: setupByOG7HKR9NMeta || {},
    alias: setupByOG7HKR9NMeta?.alias || [],
    redirect: setupByOG7HKR9NMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: setupByOG7HKR9NMeta?.name ?? "setup___fr",
    path: setupByOG7HKR9NMeta?.path ?? "/fr/configuration",
    meta: setupByOG7HKR9NMeta || {},
    alias: setupByOG7HKR9NMeta?.alias || [],
    redirect: setupByOG7HKR9NMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: setupByOG7HKR9NMeta?.name ?? "setup___it",
    path: setupByOG7HKR9NMeta?.path ?? "/it/impostazione",
    meta: setupByOG7HKR9NMeta || {},
    alias: setupByOG7HKR9NMeta?.alias || [],
    redirect: setupByOG7HKR9NMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: setupByOG7HKR9NMeta?.name ?? "setup___es",
    path: setupByOG7HKR9NMeta?.path ?? "/es/instalaci%C3%B3n",
    meta: setupByOG7HKR9NMeta || {},
    alias: setupByOG7HKR9NMeta?.alias || [],
    redirect: setupByOG7HKR9NMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: verification_45required2WqqgFfW6jMeta?.name ?? "verification-required",
    path: verification_45required2WqqgFfW6jMeta?.path ?? "/verification-required",
    meta: verification_45required2WqqgFfW6jMeta || {},
    alias: verification_45required2WqqgFfW6jMeta?.alias || [],
    redirect: verification_45required2WqqgFfW6jMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/verification-required.vue").then(m => m.default || m)
  },
  {
    name: verification_45required2WqqgFfW6jMeta?.name ?? "verification-required___en",
    path: verification_45required2WqqgFfW6jMeta?.path ?? "/en/verification-required",
    meta: verification_45required2WqqgFfW6jMeta || {},
    alias: verification_45required2WqqgFfW6jMeta?.alias || [],
    redirect: verification_45required2WqqgFfW6jMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/verification-required.vue").then(m => m.default || m)
  },
  {
    name: verification_45required2WqqgFfW6jMeta?.name ?? "verification-required___de",
    path: verification_45required2WqqgFfW6jMeta?.path ?? "/de/verification-required",
    meta: verification_45required2WqqgFfW6jMeta || {},
    alias: verification_45required2WqqgFfW6jMeta?.alias || [],
    redirect: verification_45required2WqqgFfW6jMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/verification-required.vue").then(m => m.default || m)
  },
  {
    name: verification_45required2WqqgFfW6jMeta?.name ?? "verification-required___fr",
    path: verification_45required2WqqgFfW6jMeta?.path ?? "/fr/verification-required",
    meta: verification_45required2WqqgFfW6jMeta || {},
    alias: verification_45required2WqqgFfW6jMeta?.alias || [],
    redirect: verification_45required2WqqgFfW6jMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/verification-required.vue").then(m => m.default || m)
  },
  {
    name: verification_45required2WqqgFfW6jMeta?.name ?? "verification-required___it",
    path: verification_45required2WqqgFfW6jMeta?.path ?? "/it/verification-required",
    meta: verification_45required2WqqgFfW6jMeta || {},
    alias: verification_45required2WqqgFfW6jMeta?.alias || [],
    redirect: verification_45required2WqqgFfW6jMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/verification-required.vue").then(m => m.default || m)
  },
  {
    name: verification_45required2WqqgFfW6jMeta?.name ?? "verification-required___es",
    path: verification_45required2WqqgFfW6jMeta?.path ?? "/es/verification-required",
    meta: verification_45required2WqqgFfW6jMeta || {},
    alias: verification_45required2WqqgFfW6jMeta?.alias || [],
    redirect: verification_45required2WqqgFfW6jMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/verification-required.vue").then(m => m.default || m)
  },
  {
    name: indexCxBRArySCGMeta?.name ?? "xpress",
    path: indexCxBRArySCGMeta?.path ?? "/xpress",
    meta: indexCxBRArySCGMeta || {},
    alias: indexCxBRArySCGMeta?.alias || [],
    redirect: indexCxBRArySCGMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/xpress/index.vue").then(m => m.default || m)
  },
  {
    name: indexCxBRArySCGMeta?.name ?? "xpress___en",
    path: indexCxBRArySCGMeta?.path ?? "/en/xpress",
    meta: indexCxBRArySCGMeta || {},
    alias: indexCxBRArySCGMeta?.alias || [],
    redirect: indexCxBRArySCGMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/xpress/index.vue").then(m => m.default || m)
  },
  {
    name: indexCxBRArySCGMeta?.name ?? "xpress___de",
    path: indexCxBRArySCGMeta?.path ?? "/de/xpress",
    meta: indexCxBRArySCGMeta || {},
    alias: indexCxBRArySCGMeta?.alias || [],
    redirect: indexCxBRArySCGMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/xpress/index.vue").then(m => m.default || m)
  },
  {
    name: indexCxBRArySCGMeta?.name ?? "xpress___fr",
    path: indexCxBRArySCGMeta?.path ?? "/fr/xpress",
    meta: indexCxBRArySCGMeta || {},
    alias: indexCxBRArySCGMeta?.alias || [],
    redirect: indexCxBRArySCGMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/xpress/index.vue").then(m => m.default || m)
  },
  {
    name: indexCxBRArySCGMeta?.name ?? "xpress___it",
    path: indexCxBRArySCGMeta?.path ?? "/it/xpress",
    meta: indexCxBRArySCGMeta || {},
    alias: indexCxBRArySCGMeta?.alias || [],
    redirect: indexCxBRArySCGMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/xpress/index.vue").then(m => m.default || m)
  },
  {
    name: indexCxBRArySCGMeta?.name ?? "xpress___es",
    path: indexCxBRArySCGMeta?.path ?? "/es/xpress",
    meta: indexCxBRArySCGMeta || {},
    alias: indexCxBRArySCGMeta?.alias || [],
    redirect: indexCxBRArySCGMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/xpress/index.vue").then(m => m.default || m)
  },
  {
    name: index3qCxpmpDpbMeta?.name ?? "accumulator-selector-calculator",
    path: index3qCxpmpDpbMeta?.path ?? "/accumulator-selector/calculator",
    extended: true,
    meta: index3qCxpmpDpbMeta || {},
    alias: index3qCxpmpDpbMeta?.alias || [],
    redirect: index3qCxpmpDpbMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index3qCxpmpDpbMeta?.name ?? "accumulator-selector-calculator:na___en",
    path: index3qCxpmpDpbMeta?.path ?? "/na/en/accumulator-selector/calculator",
    extended: true,
    meta: index3qCxpmpDpbMeta || {},
    alias: index3qCxpmpDpbMeta?.alias || [],
    redirect: index3qCxpmpDpbMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index3qCxpmpDpbMeta?.name ?? "accumulator-selector-calculator:global___en",
    path: index3qCxpmpDpbMeta?.path ?? "/global/en/accumulator-selector/calculator",
    extended: true,
    meta: index3qCxpmpDpbMeta || {},
    alias: index3qCxpmpDpbMeta?.alias || [],
    redirect: index3qCxpmpDpbMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index3qCxpmpDpbMeta?.name ?? "accumulator-selector-calculator:eu___en",
    path: index3qCxpmpDpbMeta?.path ?? "/eu/en/accumulator-selector/calculator",
    extended: true,
    meta: index3qCxpmpDpbMeta || {},
    alias: index3qCxpmpDpbMeta?.alias || [],
    redirect: index3qCxpmpDpbMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index3qCxpmpDpbMeta?.name ?? "accumulator-selector-calculator:na___de",
    path: index3qCxpmpDpbMeta?.path ?? "/na/de/accumulator-selector/calculator",
    extended: true,
    meta: index3qCxpmpDpbMeta || {},
    alias: index3qCxpmpDpbMeta?.alias || [],
    redirect: index3qCxpmpDpbMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index3qCxpmpDpbMeta?.name ?? "accumulator-selector-calculator:global___de",
    path: index3qCxpmpDpbMeta?.path ?? "/global/de/accumulator-selector/calculator",
    extended: true,
    meta: index3qCxpmpDpbMeta || {},
    alias: index3qCxpmpDpbMeta?.alias || [],
    redirect: index3qCxpmpDpbMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index3qCxpmpDpbMeta?.name ?? "accumulator-selector-calculator:eu___de",
    path: index3qCxpmpDpbMeta?.path ?? "/eu/de/accumulator-selector/calculator",
    extended: true,
    meta: index3qCxpmpDpbMeta || {},
    alias: index3qCxpmpDpbMeta?.alias || [],
    redirect: index3qCxpmpDpbMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index3qCxpmpDpbMeta?.name ?? "accumulator-selector-calculator:na___fr",
    path: index3qCxpmpDpbMeta?.path ?? "/na/fr/accumulator-selector/calculator",
    extended: true,
    meta: index3qCxpmpDpbMeta || {},
    alias: index3qCxpmpDpbMeta?.alias || [],
    redirect: index3qCxpmpDpbMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index3qCxpmpDpbMeta?.name ?? "accumulator-selector-calculator:global___fr",
    path: index3qCxpmpDpbMeta?.path ?? "/global/fr/accumulator-selector/calculator",
    extended: true,
    meta: index3qCxpmpDpbMeta || {},
    alias: index3qCxpmpDpbMeta?.alias || [],
    redirect: index3qCxpmpDpbMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index3qCxpmpDpbMeta?.name ?? "accumulator-selector-calculator:eu___fr",
    path: index3qCxpmpDpbMeta?.path ?? "/eu/fr/accumulator-selector/calculator",
    extended: true,
    meta: index3qCxpmpDpbMeta || {},
    alias: index3qCxpmpDpbMeta?.alias || [],
    redirect: index3qCxpmpDpbMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index3qCxpmpDpbMeta?.name ?? "accumulator-selector-calculator:na___it",
    path: index3qCxpmpDpbMeta?.path ?? "/na/it/accumulator-selector/calculator",
    extended: true,
    meta: index3qCxpmpDpbMeta || {},
    alias: index3qCxpmpDpbMeta?.alias || [],
    redirect: index3qCxpmpDpbMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index3qCxpmpDpbMeta?.name ?? "accumulator-selector-calculator:global___it",
    path: index3qCxpmpDpbMeta?.path ?? "/global/it/accumulator-selector/calculator",
    extended: true,
    meta: index3qCxpmpDpbMeta || {},
    alias: index3qCxpmpDpbMeta?.alias || [],
    redirect: index3qCxpmpDpbMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index3qCxpmpDpbMeta?.name ?? "accumulator-selector-calculator:eu___it",
    path: index3qCxpmpDpbMeta?.path ?? "/eu/it/accumulator-selector/calculator",
    extended: true,
    meta: index3qCxpmpDpbMeta || {},
    alias: index3qCxpmpDpbMeta?.alias || [],
    redirect: index3qCxpmpDpbMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index3qCxpmpDpbMeta?.name ?? "accumulator-selector-calculator:na___es",
    path: index3qCxpmpDpbMeta?.path ?? "/na/es/accumulator-selector/calculator",
    extended: true,
    meta: index3qCxpmpDpbMeta || {},
    alias: index3qCxpmpDpbMeta?.alias || [],
    redirect: index3qCxpmpDpbMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index3qCxpmpDpbMeta?.name ?? "accumulator-selector-calculator:global___es",
    path: index3qCxpmpDpbMeta?.path ?? "/global/es/accumulator-selector/calculator",
    extended: true,
    meta: index3qCxpmpDpbMeta || {},
    alias: index3qCxpmpDpbMeta?.alias || [],
    redirect: index3qCxpmpDpbMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index3qCxpmpDpbMeta?.name ?? "accumulator-selector-calculator:eu___es",
    path: index3qCxpmpDpbMeta?.path ?? "/eu/es/accumulator-selector/calculator",
    extended: true,
    meta: index3qCxpmpDpbMeta || {},
    alias: index3qCxpmpDpbMeta?.alias || [],
    redirect: index3qCxpmpDpbMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexId7wyGuUaNMeta?.name ?? "accumulator-selector-guided-finder",
    path: indexId7wyGuUaNMeta?.path ?? "/accumulator-selector/guided-finder",
    extended: true,
    meta: indexId7wyGuUaNMeta || {},
    alias: indexId7wyGuUaNMeta?.alias || [],
    redirect: indexId7wyGuUaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/guided-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexId7wyGuUaNMeta?.name ?? "accumulator-selector-guided-finder:na___en",
    path: indexId7wyGuUaNMeta?.path ?? "/na/en/accumulator-selector/guided-finder",
    extended: true,
    meta: indexId7wyGuUaNMeta || {},
    alias: indexId7wyGuUaNMeta?.alias || [],
    redirect: indexId7wyGuUaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/guided-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexId7wyGuUaNMeta?.name ?? "accumulator-selector-guided-finder:global___en",
    path: indexId7wyGuUaNMeta?.path ?? "/global/en/accumulator-selector/guided-finder",
    extended: true,
    meta: indexId7wyGuUaNMeta || {},
    alias: indexId7wyGuUaNMeta?.alias || [],
    redirect: indexId7wyGuUaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/guided-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexId7wyGuUaNMeta?.name ?? "accumulator-selector-guided-finder:eu___en",
    path: indexId7wyGuUaNMeta?.path ?? "/eu/en/accumulator-selector/guided-finder",
    extended: true,
    meta: indexId7wyGuUaNMeta || {},
    alias: indexId7wyGuUaNMeta?.alias || [],
    redirect: indexId7wyGuUaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/guided-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexId7wyGuUaNMeta?.name ?? "accumulator-selector-guided-finder:na___de",
    path: indexId7wyGuUaNMeta?.path ?? "/na/de/accumulator-selector/guided-finder",
    extended: true,
    meta: indexId7wyGuUaNMeta || {},
    alias: indexId7wyGuUaNMeta?.alias || [],
    redirect: indexId7wyGuUaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/guided-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexId7wyGuUaNMeta?.name ?? "accumulator-selector-guided-finder:global___de",
    path: indexId7wyGuUaNMeta?.path ?? "/global/de/accumulator-selector/guided-finder",
    extended: true,
    meta: indexId7wyGuUaNMeta || {},
    alias: indexId7wyGuUaNMeta?.alias || [],
    redirect: indexId7wyGuUaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/guided-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexId7wyGuUaNMeta?.name ?? "accumulator-selector-guided-finder:eu___de",
    path: indexId7wyGuUaNMeta?.path ?? "/eu/de/accumulator-selector/guided-finder",
    extended: true,
    meta: indexId7wyGuUaNMeta || {},
    alias: indexId7wyGuUaNMeta?.alias || [],
    redirect: indexId7wyGuUaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/guided-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexId7wyGuUaNMeta?.name ?? "accumulator-selector-guided-finder:na___fr",
    path: indexId7wyGuUaNMeta?.path ?? "/na/fr/accumulator-selector/guided-finder",
    extended: true,
    meta: indexId7wyGuUaNMeta || {},
    alias: indexId7wyGuUaNMeta?.alias || [],
    redirect: indexId7wyGuUaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/guided-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexId7wyGuUaNMeta?.name ?? "accumulator-selector-guided-finder:global___fr",
    path: indexId7wyGuUaNMeta?.path ?? "/global/fr/accumulator-selector/guided-finder",
    extended: true,
    meta: indexId7wyGuUaNMeta || {},
    alias: indexId7wyGuUaNMeta?.alias || [],
    redirect: indexId7wyGuUaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/guided-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexId7wyGuUaNMeta?.name ?? "accumulator-selector-guided-finder:eu___fr",
    path: indexId7wyGuUaNMeta?.path ?? "/eu/fr/accumulator-selector/guided-finder",
    extended: true,
    meta: indexId7wyGuUaNMeta || {},
    alias: indexId7wyGuUaNMeta?.alias || [],
    redirect: indexId7wyGuUaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/guided-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexId7wyGuUaNMeta?.name ?? "accumulator-selector-guided-finder:na___it",
    path: indexId7wyGuUaNMeta?.path ?? "/na/it/accumulator-selector/guided-finder",
    extended: true,
    meta: indexId7wyGuUaNMeta || {},
    alias: indexId7wyGuUaNMeta?.alias || [],
    redirect: indexId7wyGuUaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/guided-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexId7wyGuUaNMeta?.name ?? "accumulator-selector-guided-finder:global___it",
    path: indexId7wyGuUaNMeta?.path ?? "/global/it/accumulator-selector/guided-finder",
    extended: true,
    meta: indexId7wyGuUaNMeta || {},
    alias: indexId7wyGuUaNMeta?.alias || [],
    redirect: indexId7wyGuUaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/guided-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexId7wyGuUaNMeta?.name ?? "accumulator-selector-guided-finder:eu___it",
    path: indexId7wyGuUaNMeta?.path ?? "/eu/it/accumulator-selector/guided-finder",
    extended: true,
    meta: indexId7wyGuUaNMeta || {},
    alias: indexId7wyGuUaNMeta?.alias || [],
    redirect: indexId7wyGuUaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/guided-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexId7wyGuUaNMeta?.name ?? "accumulator-selector-guided-finder:na___es",
    path: indexId7wyGuUaNMeta?.path ?? "/na/es/accumulator-selector/guided-finder",
    extended: true,
    meta: indexId7wyGuUaNMeta || {},
    alias: indexId7wyGuUaNMeta?.alias || [],
    redirect: indexId7wyGuUaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/guided-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexId7wyGuUaNMeta?.name ?? "accumulator-selector-guided-finder:global___es",
    path: indexId7wyGuUaNMeta?.path ?? "/global/es/accumulator-selector/guided-finder",
    extended: true,
    meta: indexId7wyGuUaNMeta || {},
    alias: indexId7wyGuUaNMeta?.alias || [],
    redirect: indexId7wyGuUaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/guided-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexId7wyGuUaNMeta?.name ?? "accumulator-selector-guided-finder:eu___es",
    path: indexId7wyGuUaNMeta?.path ?? "/eu/es/accumulator-selector/guided-finder",
    extended: true,
    meta: indexId7wyGuUaNMeta || {},
    alias: indexId7wyGuUaNMeta?.alias || [],
    redirect: indexId7wyGuUaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/guided-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexqZxO0a0oxUMeta?.name ?? "accumulator-selector",
    path: indexqZxO0a0oxUMeta?.path ?? "/accumulator-selector",
    extended: true,
    meta: indexqZxO0a0oxUMeta || {},
    alias: indexqZxO0a0oxUMeta?.alias || [],
    redirect: indexqZxO0a0oxUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexqZxO0a0oxUMeta?.name ?? "accumulator-selector:na___en",
    path: indexqZxO0a0oxUMeta?.path ?? "/na/en/accumulator-selector",
    extended: true,
    meta: indexqZxO0a0oxUMeta || {},
    alias: indexqZxO0a0oxUMeta?.alias || [],
    redirect: indexqZxO0a0oxUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexqZxO0a0oxUMeta?.name ?? "accumulator-selector:global___en",
    path: indexqZxO0a0oxUMeta?.path ?? "/global/en/accumulator-selector",
    extended: true,
    meta: indexqZxO0a0oxUMeta || {},
    alias: indexqZxO0a0oxUMeta?.alias || [],
    redirect: indexqZxO0a0oxUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexqZxO0a0oxUMeta?.name ?? "accumulator-selector:eu___en",
    path: indexqZxO0a0oxUMeta?.path ?? "/eu/en/accumulator-selector",
    extended: true,
    meta: indexqZxO0a0oxUMeta || {},
    alias: indexqZxO0a0oxUMeta?.alias || [],
    redirect: indexqZxO0a0oxUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexqZxO0a0oxUMeta?.name ?? "accumulator-selector:na___de",
    path: indexqZxO0a0oxUMeta?.path ?? "/na/de/accumulator-selector",
    extended: true,
    meta: indexqZxO0a0oxUMeta || {},
    alias: indexqZxO0a0oxUMeta?.alias || [],
    redirect: indexqZxO0a0oxUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexqZxO0a0oxUMeta?.name ?? "accumulator-selector:global___de",
    path: indexqZxO0a0oxUMeta?.path ?? "/global/de/accumulator-selector",
    extended: true,
    meta: indexqZxO0a0oxUMeta || {},
    alias: indexqZxO0a0oxUMeta?.alias || [],
    redirect: indexqZxO0a0oxUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexqZxO0a0oxUMeta?.name ?? "accumulator-selector:eu___de",
    path: indexqZxO0a0oxUMeta?.path ?? "/eu/de/accumulator-selector",
    extended: true,
    meta: indexqZxO0a0oxUMeta || {},
    alias: indexqZxO0a0oxUMeta?.alias || [],
    redirect: indexqZxO0a0oxUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexqZxO0a0oxUMeta?.name ?? "accumulator-selector:na___fr",
    path: indexqZxO0a0oxUMeta?.path ?? "/na/fr/accumulator-selector",
    extended: true,
    meta: indexqZxO0a0oxUMeta || {},
    alias: indexqZxO0a0oxUMeta?.alias || [],
    redirect: indexqZxO0a0oxUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexqZxO0a0oxUMeta?.name ?? "accumulator-selector:global___fr",
    path: indexqZxO0a0oxUMeta?.path ?? "/global/fr/accumulator-selector",
    extended: true,
    meta: indexqZxO0a0oxUMeta || {},
    alias: indexqZxO0a0oxUMeta?.alias || [],
    redirect: indexqZxO0a0oxUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexqZxO0a0oxUMeta?.name ?? "accumulator-selector:eu___fr",
    path: indexqZxO0a0oxUMeta?.path ?? "/eu/fr/accumulator-selector",
    extended: true,
    meta: indexqZxO0a0oxUMeta || {},
    alias: indexqZxO0a0oxUMeta?.alias || [],
    redirect: indexqZxO0a0oxUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexqZxO0a0oxUMeta?.name ?? "accumulator-selector:na___it",
    path: indexqZxO0a0oxUMeta?.path ?? "/na/it/accumulator-selector",
    extended: true,
    meta: indexqZxO0a0oxUMeta || {},
    alias: indexqZxO0a0oxUMeta?.alias || [],
    redirect: indexqZxO0a0oxUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexqZxO0a0oxUMeta?.name ?? "accumulator-selector:global___it",
    path: indexqZxO0a0oxUMeta?.path ?? "/global/it/accumulator-selector",
    extended: true,
    meta: indexqZxO0a0oxUMeta || {},
    alias: indexqZxO0a0oxUMeta?.alias || [],
    redirect: indexqZxO0a0oxUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexqZxO0a0oxUMeta?.name ?? "accumulator-selector:eu___it",
    path: indexqZxO0a0oxUMeta?.path ?? "/eu/it/accumulator-selector",
    extended: true,
    meta: indexqZxO0a0oxUMeta || {},
    alias: indexqZxO0a0oxUMeta?.alias || [],
    redirect: indexqZxO0a0oxUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexqZxO0a0oxUMeta?.name ?? "accumulator-selector:na___es",
    path: indexqZxO0a0oxUMeta?.path ?? "/na/es/accumulator-selector",
    extended: true,
    meta: indexqZxO0a0oxUMeta || {},
    alias: indexqZxO0a0oxUMeta?.alias || [],
    redirect: indexqZxO0a0oxUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexqZxO0a0oxUMeta?.name ?? "accumulator-selector:global___es",
    path: indexqZxO0a0oxUMeta?.path ?? "/global/es/accumulator-selector",
    extended: true,
    meta: indexqZxO0a0oxUMeta || {},
    alias: indexqZxO0a0oxUMeta?.alias || [],
    redirect: indexqZxO0a0oxUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexqZxO0a0oxUMeta?.name ?? "accumulator-selector:eu___es",
    path: indexqZxO0a0oxUMeta?.path ?? "/eu/es/accumulator-selector",
    extended: true,
    meta: indexqZxO0a0oxUMeta || {},
    alias: indexqZxO0a0oxUMeta?.alias || [],
    redirect: indexqZxO0a0oxUMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/accumulator-selector/index.vue").then(m => m.default || m)
  },
  {
    name: appSettingsiNrIJDOsDjMeta?.name ?? "appSettings",
    path: appSettingsiNrIJDOsDjMeta?.path ?? "/appSettings",
    extended: true,
    meta: appSettingsiNrIJDOsDjMeta || {},
    alias: appSettingsiNrIJDOsDjMeta?.alias || [],
    redirect: appSettingsiNrIJDOsDjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/appSettings.vue").then(m => m.default || m)
  },
  {
    name: appSettingsiNrIJDOsDjMeta?.name ?? "appSettings:na___en",
    path: appSettingsiNrIJDOsDjMeta?.path ?? "/na/en/app-settings",
    extended: true,
    meta: appSettingsiNrIJDOsDjMeta || {},
    alias: appSettingsiNrIJDOsDjMeta?.alias || [],
    redirect: appSettingsiNrIJDOsDjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/appSettings.vue").then(m => m.default || m)
  },
  {
    name: appSettingsiNrIJDOsDjMeta?.name ?? "appSettings:global___en",
    path: appSettingsiNrIJDOsDjMeta?.path ?? "/global/en/app-settings",
    extended: true,
    meta: appSettingsiNrIJDOsDjMeta || {},
    alias: appSettingsiNrIJDOsDjMeta?.alias || [],
    redirect: appSettingsiNrIJDOsDjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/appSettings.vue").then(m => m.default || m)
  },
  {
    name: appSettingsiNrIJDOsDjMeta?.name ?? "appSettings:eu___en",
    path: appSettingsiNrIJDOsDjMeta?.path ?? "/eu/en/app-settings",
    extended: true,
    meta: appSettingsiNrIJDOsDjMeta || {},
    alias: appSettingsiNrIJDOsDjMeta?.alias || [],
    redirect: appSettingsiNrIJDOsDjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/appSettings.vue").then(m => m.default || m)
  },
  {
    name: appSettingsiNrIJDOsDjMeta?.name ?? "appSettings:na___de",
    path: appSettingsiNrIJDOsDjMeta?.path ?? "/na/de/app-einstellungen",
    extended: true,
    meta: appSettingsiNrIJDOsDjMeta || {},
    alias: appSettingsiNrIJDOsDjMeta?.alias || [],
    redirect: appSettingsiNrIJDOsDjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/appSettings.vue").then(m => m.default || m)
  },
  {
    name: appSettingsiNrIJDOsDjMeta?.name ?? "appSettings:global___de",
    path: appSettingsiNrIJDOsDjMeta?.path ?? "/global/de/app-einstellungen",
    extended: true,
    meta: appSettingsiNrIJDOsDjMeta || {},
    alias: appSettingsiNrIJDOsDjMeta?.alias || [],
    redirect: appSettingsiNrIJDOsDjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/appSettings.vue").then(m => m.default || m)
  },
  {
    name: appSettingsiNrIJDOsDjMeta?.name ?? "appSettings:eu___de",
    path: appSettingsiNrIJDOsDjMeta?.path ?? "/eu/de/app-einstellungen",
    extended: true,
    meta: appSettingsiNrIJDOsDjMeta || {},
    alias: appSettingsiNrIJDOsDjMeta?.alias || [],
    redirect: appSettingsiNrIJDOsDjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/appSettings.vue").then(m => m.default || m)
  },
  {
    name: appSettingsiNrIJDOsDjMeta?.name ?? "appSettings:na___fr",
    path: appSettingsiNrIJDOsDjMeta?.path ?? "/na/fr/parametres-l-application",
    extended: true,
    meta: appSettingsiNrIJDOsDjMeta || {},
    alias: appSettingsiNrIJDOsDjMeta?.alias || [],
    redirect: appSettingsiNrIJDOsDjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/appSettings.vue").then(m => m.default || m)
  },
  {
    name: appSettingsiNrIJDOsDjMeta?.name ?? "appSettings:global___fr",
    path: appSettingsiNrIJDOsDjMeta?.path ?? "/global/fr/parametres-l-application",
    extended: true,
    meta: appSettingsiNrIJDOsDjMeta || {},
    alias: appSettingsiNrIJDOsDjMeta?.alias || [],
    redirect: appSettingsiNrIJDOsDjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/appSettings.vue").then(m => m.default || m)
  },
  {
    name: appSettingsiNrIJDOsDjMeta?.name ?? "appSettings:eu___fr",
    path: appSettingsiNrIJDOsDjMeta?.path ?? "/eu/fr/parametres-l-application",
    extended: true,
    meta: appSettingsiNrIJDOsDjMeta || {},
    alias: appSettingsiNrIJDOsDjMeta?.alias || [],
    redirect: appSettingsiNrIJDOsDjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/appSettings.vue").then(m => m.default || m)
  },
  {
    name: appSettingsiNrIJDOsDjMeta?.name ?? "appSettings:na___it",
    path: appSettingsiNrIJDOsDjMeta?.path ?? "/na/it/impostazioni-dell-app",
    extended: true,
    meta: appSettingsiNrIJDOsDjMeta || {},
    alias: appSettingsiNrIJDOsDjMeta?.alias || [],
    redirect: appSettingsiNrIJDOsDjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/appSettings.vue").then(m => m.default || m)
  },
  {
    name: appSettingsiNrIJDOsDjMeta?.name ?? "appSettings:global___it",
    path: appSettingsiNrIJDOsDjMeta?.path ?? "/global/it/impostazioni-dell-app",
    extended: true,
    meta: appSettingsiNrIJDOsDjMeta || {},
    alias: appSettingsiNrIJDOsDjMeta?.alias || [],
    redirect: appSettingsiNrIJDOsDjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/appSettings.vue").then(m => m.default || m)
  },
  {
    name: appSettingsiNrIJDOsDjMeta?.name ?? "appSettings:eu___it",
    path: appSettingsiNrIJDOsDjMeta?.path ?? "/eu/it/impostazioni-dell-app",
    extended: true,
    meta: appSettingsiNrIJDOsDjMeta || {},
    alias: appSettingsiNrIJDOsDjMeta?.alias || [],
    redirect: appSettingsiNrIJDOsDjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/appSettings.vue").then(m => m.default || m)
  },
  {
    name: appSettingsiNrIJDOsDjMeta?.name ?? "appSettings:na___es",
    path: appSettingsiNrIJDOsDjMeta?.path ?? "/na/es/configuraci%C3%B3n",
    extended: true,
    meta: appSettingsiNrIJDOsDjMeta || {},
    alias: appSettingsiNrIJDOsDjMeta?.alias || [],
    redirect: appSettingsiNrIJDOsDjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/appSettings.vue").then(m => m.default || m)
  },
  {
    name: appSettingsiNrIJDOsDjMeta?.name ?? "appSettings:global___es",
    path: appSettingsiNrIJDOsDjMeta?.path ?? "/global/es/configuraci%C3%B3n",
    extended: true,
    meta: appSettingsiNrIJDOsDjMeta || {},
    alias: appSettingsiNrIJDOsDjMeta?.alias || [],
    redirect: appSettingsiNrIJDOsDjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/appSettings.vue").then(m => m.default || m)
  },
  {
    name: appSettingsiNrIJDOsDjMeta?.name ?? "appSettings:eu___es",
    path: appSettingsiNrIJDOsDjMeta?.path ?? "/eu/es/configuraci%C3%B3n",
    extended: true,
    meta: appSettingsiNrIJDOsDjMeta || {},
    alias: appSettingsiNrIJDOsDjMeta?.alias || [],
    redirect: appSettingsiNrIJDOsDjMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/appSettings.vue").then(m => m.default || m)
  },
  {
    name: index9ERZjXBiNiMeta?.name ?? "auth",
    path: index9ERZjXBiNiMeta?.path ?? "/auth",
    extended: true,
    meta: index9ERZjXBiNiMeta || {},
    alias: index9ERZjXBiNiMeta?.alias || [],
    redirect: index9ERZjXBiNiMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: logintkSW4BW5ORMeta?.name ?? "auth-login",
    path: logintkSW4BW5ORMeta?.path ?? "/auth/login",
    extended: true,
    meta: logintkSW4BW5ORMeta || {},
    alias: logintkSW4BW5ORMeta?.alias || [],
    redirect: logintkSW4BW5ORMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: logoutlWtSOQAZxDMeta?.name ?? "auth-logout",
    path: logoutlWtSOQAZxDMeta?.path ?? "/auth/logout",
    extended: true,
    meta: logoutlWtSOQAZxDMeta || {},
    alias: logoutlWtSOQAZxDMeta?.alias || [],
    redirect: logoutlWtSOQAZxDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: b2c_45errorUPUUOKvVJ9Meta?.name ?? "b2c-error",
    path: b2c_45errorUPUUOKvVJ9Meta?.path ?? "/b2c-error",
    extended: true,
    meta: b2c_45errorUPUUOKvVJ9Meta || {},
    alias: b2c_45errorUPUUOKvVJ9Meta?.alias || [],
    redirect: b2c_45errorUPUUOKvVJ9Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/b2c-error.vue").then(m => m.default || m)
  },
  {
    name: b2c_45errorUPUUOKvVJ9Meta?.name ?? "b2c-error:na___en",
    path: b2c_45errorUPUUOKvVJ9Meta?.path ?? "/na/en/b2c-error",
    extended: true,
    meta: b2c_45errorUPUUOKvVJ9Meta || {},
    alias: b2c_45errorUPUUOKvVJ9Meta?.alias || [],
    redirect: b2c_45errorUPUUOKvVJ9Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/b2c-error.vue").then(m => m.default || m)
  },
  {
    name: b2c_45errorUPUUOKvVJ9Meta?.name ?? "b2c-error:global___en",
    path: b2c_45errorUPUUOKvVJ9Meta?.path ?? "/global/en/b2c-error",
    extended: true,
    meta: b2c_45errorUPUUOKvVJ9Meta || {},
    alias: b2c_45errorUPUUOKvVJ9Meta?.alias || [],
    redirect: b2c_45errorUPUUOKvVJ9Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/b2c-error.vue").then(m => m.default || m)
  },
  {
    name: b2c_45errorUPUUOKvVJ9Meta?.name ?? "b2c-error:eu___en",
    path: b2c_45errorUPUUOKvVJ9Meta?.path ?? "/eu/en/b2c-error",
    extended: true,
    meta: b2c_45errorUPUUOKvVJ9Meta || {},
    alias: b2c_45errorUPUUOKvVJ9Meta?.alias || [],
    redirect: b2c_45errorUPUUOKvVJ9Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/b2c-error.vue").then(m => m.default || m)
  },
  {
    name: b2c_45errorUPUUOKvVJ9Meta?.name ?? "b2c-error:na___de",
    path: b2c_45errorUPUUOKvVJ9Meta?.path ?? "/na/de/b2c-error",
    extended: true,
    meta: b2c_45errorUPUUOKvVJ9Meta || {},
    alias: b2c_45errorUPUUOKvVJ9Meta?.alias || [],
    redirect: b2c_45errorUPUUOKvVJ9Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/b2c-error.vue").then(m => m.default || m)
  },
  {
    name: b2c_45errorUPUUOKvVJ9Meta?.name ?? "b2c-error:global___de",
    path: b2c_45errorUPUUOKvVJ9Meta?.path ?? "/global/de/b2c-error",
    extended: true,
    meta: b2c_45errorUPUUOKvVJ9Meta || {},
    alias: b2c_45errorUPUUOKvVJ9Meta?.alias || [],
    redirect: b2c_45errorUPUUOKvVJ9Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/b2c-error.vue").then(m => m.default || m)
  },
  {
    name: b2c_45errorUPUUOKvVJ9Meta?.name ?? "b2c-error:eu___de",
    path: b2c_45errorUPUUOKvVJ9Meta?.path ?? "/eu/de/b2c-error",
    extended: true,
    meta: b2c_45errorUPUUOKvVJ9Meta || {},
    alias: b2c_45errorUPUUOKvVJ9Meta?.alias || [],
    redirect: b2c_45errorUPUUOKvVJ9Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/b2c-error.vue").then(m => m.default || m)
  },
  {
    name: b2c_45errorUPUUOKvVJ9Meta?.name ?? "b2c-error:na___fr",
    path: b2c_45errorUPUUOKvVJ9Meta?.path ?? "/na/fr/b2c-error",
    extended: true,
    meta: b2c_45errorUPUUOKvVJ9Meta || {},
    alias: b2c_45errorUPUUOKvVJ9Meta?.alias || [],
    redirect: b2c_45errorUPUUOKvVJ9Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/b2c-error.vue").then(m => m.default || m)
  },
  {
    name: b2c_45errorUPUUOKvVJ9Meta?.name ?? "b2c-error:global___fr",
    path: b2c_45errorUPUUOKvVJ9Meta?.path ?? "/global/fr/b2c-error",
    extended: true,
    meta: b2c_45errorUPUUOKvVJ9Meta || {},
    alias: b2c_45errorUPUUOKvVJ9Meta?.alias || [],
    redirect: b2c_45errorUPUUOKvVJ9Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/b2c-error.vue").then(m => m.default || m)
  },
  {
    name: b2c_45errorUPUUOKvVJ9Meta?.name ?? "b2c-error:eu___fr",
    path: b2c_45errorUPUUOKvVJ9Meta?.path ?? "/eu/fr/b2c-error",
    extended: true,
    meta: b2c_45errorUPUUOKvVJ9Meta || {},
    alias: b2c_45errorUPUUOKvVJ9Meta?.alias || [],
    redirect: b2c_45errorUPUUOKvVJ9Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/b2c-error.vue").then(m => m.default || m)
  },
  {
    name: b2c_45errorUPUUOKvVJ9Meta?.name ?? "b2c-error:na___it",
    path: b2c_45errorUPUUOKvVJ9Meta?.path ?? "/na/it/b2c-error",
    extended: true,
    meta: b2c_45errorUPUUOKvVJ9Meta || {},
    alias: b2c_45errorUPUUOKvVJ9Meta?.alias || [],
    redirect: b2c_45errorUPUUOKvVJ9Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/b2c-error.vue").then(m => m.default || m)
  },
  {
    name: b2c_45errorUPUUOKvVJ9Meta?.name ?? "b2c-error:global___it",
    path: b2c_45errorUPUUOKvVJ9Meta?.path ?? "/global/it/b2c-error",
    extended: true,
    meta: b2c_45errorUPUUOKvVJ9Meta || {},
    alias: b2c_45errorUPUUOKvVJ9Meta?.alias || [],
    redirect: b2c_45errorUPUUOKvVJ9Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/b2c-error.vue").then(m => m.default || m)
  },
  {
    name: b2c_45errorUPUUOKvVJ9Meta?.name ?? "b2c-error:eu___it",
    path: b2c_45errorUPUUOKvVJ9Meta?.path ?? "/eu/it/b2c-error",
    extended: true,
    meta: b2c_45errorUPUUOKvVJ9Meta || {},
    alias: b2c_45errorUPUUOKvVJ9Meta?.alias || [],
    redirect: b2c_45errorUPUUOKvVJ9Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/b2c-error.vue").then(m => m.default || m)
  },
  {
    name: b2c_45errorUPUUOKvVJ9Meta?.name ?? "b2c-error:na___es",
    path: b2c_45errorUPUUOKvVJ9Meta?.path ?? "/na/es/b2c-error",
    extended: true,
    meta: b2c_45errorUPUUOKvVJ9Meta || {},
    alias: b2c_45errorUPUUOKvVJ9Meta?.alias || [],
    redirect: b2c_45errorUPUUOKvVJ9Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/b2c-error.vue").then(m => m.default || m)
  },
  {
    name: b2c_45errorUPUUOKvVJ9Meta?.name ?? "b2c-error:global___es",
    path: b2c_45errorUPUUOKvVJ9Meta?.path ?? "/global/es/b2c-error",
    extended: true,
    meta: b2c_45errorUPUUOKvVJ9Meta || {},
    alias: b2c_45errorUPUUOKvVJ9Meta?.alias || [],
    redirect: b2c_45errorUPUUOKvVJ9Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/b2c-error.vue").then(m => m.default || m)
  },
  {
    name: b2c_45errorUPUUOKvVJ9Meta?.name ?? "b2c-error:eu___es",
    path: b2c_45errorUPUUOKvVJ9Meta?.path ?? "/eu/es/b2c-error",
    extended: true,
    meta: b2c_45errorUPUUOKvVJ9Meta || {},
    alias: b2c_45errorUPUUOKvVJ9Meta?.alias || [],
    redirect: b2c_45errorUPUUOKvVJ9Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/b2c-error.vue").then(m => m.default || m)
  },
  {
    name: checkoutgN6Ffmf2h8Meta?.name ?? "cart-checkout",
    path: checkoutgN6Ffmf2h8Meta?.path ?? "/cart/checkout",
    extended: true,
    meta: checkoutgN6Ffmf2h8Meta || {},
    alias: checkoutgN6Ffmf2h8Meta?.alias || [],
    redirect: checkoutgN6Ffmf2h8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutgN6Ffmf2h8Meta?.name ?? "cart-checkout:na___en",
    path: checkoutgN6Ffmf2h8Meta?.path ?? "/na/en/cart/checkout",
    extended: true,
    meta: checkoutgN6Ffmf2h8Meta || {},
    alias: checkoutgN6Ffmf2h8Meta?.alias || [],
    redirect: checkoutgN6Ffmf2h8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutgN6Ffmf2h8Meta?.name ?? "cart-checkout:global___en",
    path: checkoutgN6Ffmf2h8Meta?.path ?? "/global/en/cart/checkout",
    extended: true,
    meta: checkoutgN6Ffmf2h8Meta || {},
    alias: checkoutgN6Ffmf2h8Meta?.alias || [],
    redirect: checkoutgN6Ffmf2h8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutgN6Ffmf2h8Meta?.name ?? "cart-checkout:eu___en",
    path: checkoutgN6Ffmf2h8Meta?.path ?? "/eu/en/cart/checkout",
    extended: true,
    meta: checkoutgN6Ffmf2h8Meta || {},
    alias: checkoutgN6Ffmf2h8Meta?.alias || [],
    redirect: checkoutgN6Ffmf2h8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutgN6Ffmf2h8Meta?.name ?? "cart-checkout:na___de",
    path: checkoutgN6Ffmf2h8Meta?.path ?? "/na/de/warenkorb/checkout",
    extended: true,
    meta: checkoutgN6Ffmf2h8Meta || {},
    alias: checkoutgN6Ffmf2h8Meta?.alias || [],
    redirect: checkoutgN6Ffmf2h8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutgN6Ffmf2h8Meta?.name ?? "cart-checkout:global___de",
    path: checkoutgN6Ffmf2h8Meta?.path ?? "/global/de/warenkorb/checkout",
    extended: true,
    meta: checkoutgN6Ffmf2h8Meta || {},
    alias: checkoutgN6Ffmf2h8Meta?.alias || [],
    redirect: checkoutgN6Ffmf2h8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutgN6Ffmf2h8Meta?.name ?? "cart-checkout:eu___de",
    path: checkoutgN6Ffmf2h8Meta?.path ?? "/eu/de/warenkorb/checkout",
    extended: true,
    meta: checkoutgN6Ffmf2h8Meta || {},
    alias: checkoutgN6Ffmf2h8Meta?.alias || [],
    redirect: checkoutgN6Ffmf2h8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutgN6Ffmf2h8Meta?.name ?? "cart-checkout:na___fr",
    path: checkoutgN6Ffmf2h8Meta?.path ?? "/na/fr/panier/checkout",
    extended: true,
    meta: checkoutgN6Ffmf2h8Meta || {},
    alias: checkoutgN6Ffmf2h8Meta?.alias || [],
    redirect: checkoutgN6Ffmf2h8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutgN6Ffmf2h8Meta?.name ?? "cart-checkout:global___fr",
    path: checkoutgN6Ffmf2h8Meta?.path ?? "/global/fr/panier/checkout",
    extended: true,
    meta: checkoutgN6Ffmf2h8Meta || {},
    alias: checkoutgN6Ffmf2h8Meta?.alias || [],
    redirect: checkoutgN6Ffmf2h8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutgN6Ffmf2h8Meta?.name ?? "cart-checkout:eu___fr",
    path: checkoutgN6Ffmf2h8Meta?.path ?? "/eu/fr/panier/checkout",
    extended: true,
    meta: checkoutgN6Ffmf2h8Meta || {},
    alias: checkoutgN6Ffmf2h8Meta?.alias || [],
    redirect: checkoutgN6Ffmf2h8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutgN6Ffmf2h8Meta?.name ?? "cart-checkout:na___it",
    path: checkoutgN6Ffmf2h8Meta?.path ?? "/na/it/carrello/checkout",
    extended: true,
    meta: checkoutgN6Ffmf2h8Meta || {},
    alias: checkoutgN6Ffmf2h8Meta?.alias || [],
    redirect: checkoutgN6Ffmf2h8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutgN6Ffmf2h8Meta?.name ?? "cart-checkout:global___it",
    path: checkoutgN6Ffmf2h8Meta?.path ?? "/global/it/carrello/checkout",
    extended: true,
    meta: checkoutgN6Ffmf2h8Meta || {},
    alias: checkoutgN6Ffmf2h8Meta?.alias || [],
    redirect: checkoutgN6Ffmf2h8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutgN6Ffmf2h8Meta?.name ?? "cart-checkout:eu___it",
    path: checkoutgN6Ffmf2h8Meta?.path ?? "/eu/it/carrello/checkout",
    extended: true,
    meta: checkoutgN6Ffmf2h8Meta || {},
    alias: checkoutgN6Ffmf2h8Meta?.alias || [],
    redirect: checkoutgN6Ffmf2h8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutgN6Ffmf2h8Meta?.name ?? "cart-checkout:na___es",
    path: checkoutgN6Ffmf2h8Meta?.path ?? "/na/es/carrito/checkout",
    extended: true,
    meta: checkoutgN6Ffmf2h8Meta || {},
    alias: checkoutgN6Ffmf2h8Meta?.alias || [],
    redirect: checkoutgN6Ffmf2h8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutgN6Ffmf2h8Meta?.name ?? "cart-checkout:global___es",
    path: checkoutgN6Ffmf2h8Meta?.path ?? "/global/es/carrito/checkout",
    extended: true,
    meta: checkoutgN6Ffmf2h8Meta || {},
    alias: checkoutgN6Ffmf2h8Meta?.alias || [],
    redirect: checkoutgN6Ffmf2h8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutgN6Ffmf2h8Meta?.name ?? "cart-checkout:eu___es",
    path: checkoutgN6Ffmf2h8Meta?.path ?? "/eu/es/carrito/checkout",
    extended: true,
    meta: checkoutgN6Ffmf2h8Meta || {},
    alias: checkoutgN6Ffmf2h8Meta?.alias || [],
    redirect: checkoutgN6Ffmf2h8Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/checkout.vue").then(m => m.default || m)
  },
  {
    name: indexDqHxHSe66yMeta?.name ?? "cart",
    path: indexDqHxHSe66yMeta?.path ?? "/cart",
    extended: true,
    meta: indexDqHxHSe66yMeta || {},
    alias: indexDqHxHSe66yMeta?.alias || [],
    redirect: indexDqHxHSe66yMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqHxHSe66yMeta?.name ?? "cart:na___en",
    path: indexDqHxHSe66yMeta?.path ?? "/na/en/cart",
    extended: true,
    meta: indexDqHxHSe66yMeta || {},
    alias: indexDqHxHSe66yMeta?.alias || [],
    redirect: indexDqHxHSe66yMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqHxHSe66yMeta?.name ?? "cart:global___en",
    path: indexDqHxHSe66yMeta?.path ?? "/global/en/cart",
    extended: true,
    meta: indexDqHxHSe66yMeta || {},
    alias: indexDqHxHSe66yMeta?.alias || [],
    redirect: indexDqHxHSe66yMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqHxHSe66yMeta?.name ?? "cart:eu___en",
    path: indexDqHxHSe66yMeta?.path ?? "/eu/en/cart",
    extended: true,
    meta: indexDqHxHSe66yMeta || {},
    alias: indexDqHxHSe66yMeta?.alias || [],
    redirect: indexDqHxHSe66yMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqHxHSe66yMeta?.name ?? "cart:na___de",
    path: indexDqHxHSe66yMeta?.path ?? "/na/de/warenkorb",
    extended: true,
    meta: indexDqHxHSe66yMeta || {},
    alias: indexDqHxHSe66yMeta?.alias || [],
    redirect: indexDqHxHSe66yMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqHxHSe66yMeta?.name ?? "cart:global___de",
    path: indexDqHxHSe66yMeta?.path ?? "/global/de/warenkorb",
    extended: true,
    meta: indexDqHxHSe66yMeta || {},
    alias: indexDqHxHSe66yMeta?.alias || [],
    redirect: indexDqHxHSe66yMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqHxHSe66yMeta?.name ?? "cart:eu___de",
    path: indexDqHxHSe66yMeta?.path ?? "/eu/de/warenkorb",
    extended: true,
    meta: indexDqHxHSe66yMeta || {},
    alias: indexDqHxHSe66yMeta?.alias || [],
    redirect: indexDqHxHSe66yMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqHxHSe66yMeta?.name ?? "cart:na___fr",
    path: indexDqHxHSe66yMeta?.path ?? "/na/fr/panier",
    extended: true,
    meta: indexDqHxHSe66yMeta || {},
    alias: indexDqHxHSe66yMeta?.alias || [],
    redirect: indexDqHxHSe66yMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqHxHSe66yMeta?.name ?? "cart:global___fr",
    path: indexDqHxHSe66yMeta?.path ?? "/global/fr/panier",
    extended: true,
    meta: indexDqHxHSe66yMeta || {},
    alias: indexDqHxHSe66yMeta?.alias || [],
    redirect: indexDqHxHSe66yMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqHxHSe66yMeta?.name ?? "cart:eu___fr",
    path: indexDqHxHSe66yMeta?.path ?? "/eu/fr/panier",
    extended: true,
    meta: indexDqHxHSe66yMeta || {},
    alias: indexDqHxHSe66yMeta?.alias || [],
    redirect: indexDqHxHSe66yMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqHxHSe66yMeta?.name ?? "cart:na___it",
    path: indexDqHxHSe66yMeta?.path ?? "/na/it/carrello",
    extended: true,
    meta: indexDqHxHSe66yMeta || {},
    alias: indexDqHxHSe66yMeta?.alias || [],
    redirect: indexDqHxHSe66yMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqHxHSe66yMeta?.name ?? "cart:global___it",
    path: indexDqHxHSe66yMeta?.path ?? "/global/it/carrello",
    extended: true,
    meta: indexDqHxHSe66yMeta || {},
    alias: indexDqHxHSe66yMeta?.alias || [],
    redirect: indexDqHxHSe66yMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqHxHSe66yMeta?.name ?? "cart:eu___it",
    path: indexDqHxHSe66yMeta?.path ?? "/eu/it/carrello",
    extended: true,
    meta: indexDqHxHSe66yMeta || {},
    alias: indexDqHxHSe66yMeta?.alias || [],
    redirect: indexDqHxHSe66yMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqHxHSe66yMeta?.name ?? "cart:na___es",
    path: indexDqHxHSe66yMeta?.path ?? "/na/es/carrito",
    extended: true,
    meta: indexDqHxHSe66yMeta || {},
    alias: indexDqHxHSe66yMeta?.alias || [],
    redirect: indexDqHxHSe66yMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqHxHSe66yMeta?.name ?? "cart:global___es",
    path: indexDqHxHSe66yMeta?.path ?? "/global/es/carrito",
    extended: true,
    meta: indexDqHxHSe66yMeta || {},
    alias: indexDqHxHSe66yMeta?.alias || [],
    redirect: indexDqHxHSe66yMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqHxHSe66yMeta?.name ?? "cart:eu___es",
    path: indexDqHxHSe66yMeta?.path ?? "/eu/es/carrito",
    extended: true,
    meta: indexDqHxHSe66yMeta || {},
    alias: indexDqHxHSe66yMeta?.alias || [],
    redirect: indexDqHxHSe66yMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: order_45thankyouFpKEcElYZnMeta?.name ?? "cart-order-thankyou",
    path: order_45thankyouFpKEcElYZnMeta?.path ?? "/cart/order-thankyou",
    extended: true,
    meta: order_45thankyouFpKEcElYZnMeta || {},
    alias: order_45thankyouFpKEcElYZnMeta?.alias || [],
    redirect: order_45thankyouFpKEcElYZnMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/order-thankyou.vue").then(m => m.default || m)
  },
  {
    name: order_45thankyouFpKEcElYZnMeta?.name ?? "cart-order-thankyou:na___en",
    path: order_45thankyouFpKEcElYZnMeta?.path ?? "/na/en/cart/order-thankyou",
    extended: true,
    meta: order_45thankyouFpKEcElYZnMeta || {},
    alias: order_45thankyouFpKEcElYZnMeta?.alias || [],
    redirect: order_45thankyouFpKEcElYZnMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/order-thankyou.vue").then(m => m.default || m)
  },
  {
    name: order_45thankyouFpKEcElYZnMeta?.name ?? "cart-order-thankyou:global___en",
    path: order_45thankyouFpKEcElYZnMeta?.path ?? "/global/en/cart/order-thankyou",
    extended: true,
    meta: order_45thankyouFpKEcElYZnMeta || {},
    alias: order_45thankyouFpKEcElYZnMeta?.alias || [],
    redirect: order_45thankyouFpKEcElYZnMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/order-thankyou.vue").then(m => m.default || m)
  },
  {
    name: order_45thankyouFpKEcElYZnMeta?.name ?? "cart-order-thankyou:eu___en",
    path: order_45thankyouFpKEcElYZnMeta?.path ?? "/eu/en/cart/order-thankyou",
    extended: true,
    meta: order_45thankyouFpKEcElYZnMeta || {},
    alias: order_45thankyouFpKEcElYZnMeta?.alias || [],
    redirect: order_45thankyouFpKEcElYZnMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/order-thankyou.vue").then(m => m.default || m)
  },
  {
    name: order_45thankyouFpKEcElYZnMeta?.name ?? "cart-order-thankyou:na___de",
    path: order_45thankyouFpKEcElYZnMeta?.path ?? "/na/de/warenkorb/order-thankyou",
    extended: true,
    meta: order_45thankyouFpKEcElYZnMeta || {},
    alias: order_45thankyouFpKEcElYZnMeta?.alias || [],
    redirect: order_45thankyouFpKEcElYZnMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/order-thankyou.vue").then(m => m.default || m)
  },
  {
    name: order_45thankyouFpKEcElYZnMeta?.name ?? "cart-order-thankyou:global___de",
    path: order_45thankyouFpKEcElYZnMeta?.path ?? "/global/de/warenkorb/order-thankyou",
    extended: true,
    meta: order_45thankyouFpKEcElYZnMeta || {},
    alias: order_45thankyouFpKEcElYZnMeta?.alias || [],
    redirect: order_45thankyouFpKEcElYZnMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/order-thankyou.vue").then(m => m.default || m)
  },
  {
    name: order_45thankyouFpKEcElYZnMeta?.name ?? "cart-order-thankyou:eu___de",
    path: order_45thankyouFpKEcElYZnMeta?.path ?? "/eu/de/warenkorb/order-thankyou",
    extended: true,
    meta: order_45thankyouFpKEcElYZnMeta || {},
    alias: order_45thankyouFpKEcElYZnMeta?.alias || [],
    redirect: order_45thankyouFpKEcElYZnMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/order-thankyou.vue").then(m => m.default || m)
  },
  {
    name: order_45thankyouFpKEcElYZnMeta?.name ?? "cart-order-thankyou:na___fr",
    path: order_45thankyouFpKEcElYZnMeta?.path ?? "/na/fr/panier/order-thankyou",
    extended: true,
    meta: order_45thankyouFpKEcElYZnMeta || {},
    alias: order_45thankyouFpKEcElYZnMeta?.alias || [],
    redirect: order_45thankyouFpKEcElYZnMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/order-thankyou.vue").then(m => m.default || m)
  },
  {
    name: order_45thankyouFpKEcElYZnMeta?.name ?? "cart-order-thankyou:global___fr",
    path: order_45thankyouFpKEcElYZnMeta?.path ?? "/global/fr/panier/order-thankyou",
    extended: true,
    meta: order_45thankyouFpKEcElYZnMeta || {},
    alias: order_45thankyouFpKEcElYZnMeta?.alias || [],
    redirect: order_45thankyouFpKEcElYZnMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/order-thankyou.vue").then(m => m.default || m)
  },
  {
    name: order_45thankyouFpKEcElYZnMeta?.name ?? "cart-order-thankyou:eu___fr",
    path: order_45thankyouFpKEcElYZnMeta?.path ?? "/eu/fr/panier/order-thankyou",
    extended: true,
    meta: order_45thankyouFpKEcElYZnMeta || {},
    alias: order_45thankyouFpKEcElYZnMeta?.alias || [],
    redirect: order_45thankyouFpKEcElYZnMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/order-thankyou.vue").then(m => m.default || m)
  },
  {
    name: order_45thankyouFpKEcElYZnMeta?.name ?? "cart-order-thankyou:na___it",
    path: order_45thankyouFpKEcElYZnMeta?.path ?? "/na/it/carrello/order-thankyou",
    extended: true,
    meta: order_45thankyouFpKEcElYZnMeta || {},
    alias: order_45thankyouFpKEcElYZnMeta?.alias || [],
    redirect: order_45thankyouFpKEcElYZnMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/order-thankyou.vue").then(m => m.default || m)
  },
  {
    name: order_45thankyouFpKEcElYZnMeta?.name ?? "cart-order-thankyou:global___it",
    path: order_45thankyouFpKEcElYZnMeta?.path ?? "/global/it/carrello/order-thankyou",
    extended: true,
    meta: order_45thankyouFpKEcElYZnMeta || {},
    alias: order_45thankyouFpKEcElYZnMeta?.alias || [],
    redirect: order_45thankyouFpKEcElYZnMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/order-thankyou.vue").then(m => m.default || m)
  },
  {
    name: order_45thankyouFpKEcElYZnMeta?.name ?? "cart-order-thankyou:eu___it",
    path: order_45thankyouFpKEcElYZnMeta?.path ?? "/eu/it/carrello/order-thankyou",
    extended: true,
    meta: order_45thankyouFpKEcElYZnMeta || {},
    alias: order_45thankyouFpKEcElYZnMeta?.alias || [],
    redirect: order_45thankyouFpKEcElYZnMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/order-thankyou.vue").then(m => m.default || m)
  },
  {
    name: order_45thankyouFpKEcElYZnMeta?.name ?? "cart-order-thankyou:na___es",
    path: order_45thankyouFpKEcElYZnMeta?.path ?? "/na/es/carrito/order-thankyou",
    extended: true,
    meta: order_45thankyouFpKEcElYZnMeta || {},
    alias: order_45thankyouFpKEcElYZnMeta?.alias || [],
    redirect: order_45thankyouFpKEcElYZnMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/order-thankyou.vue").then(m => m.default || m)
  },
  {
    name: order_45thankyouFpKEcElYZnMeta?.name ?? "cart-order-thankyou:global___es",
    path: order_45thankyouFpKEcElYZnMeta?.path ?? "/global/es/carrito/order-thankyou",
    extended: true,
    meta: order_45thankyouFpKEcElYZnMeta || {},
    alias: order_45thankyouFpKEcElYZnMeta?.alias || [],
    redirect: order_45thankyouFpKEcElYZnMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/order-thankyou.vue").then(m => m.default || m)
  },
  {
    name: order_45thankyouFpKEcElYZnMeta?.name ?? "cart-order-thankyou:eu___es",
    path: order_45thankyouFpKEcElYZnMeta?.path ?? "/eu/es/carrito/order-thankyou",
    extended: true,
    meta: order_45thankyouFpKEcElYZnMeta || {},
    alias: order_45thankyouFpKEcElYZnMeta?.alias || [],
    redirect: order_45thankyouFpKEcElYZnMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cart/order-thankyou.vue").then(m => m.default || m)
  },
  {
    name: indextHDl6znZdFMeta?.name ?? "categories-categorypath",
    path: indextHDl6znZdFMeta?.path ?? "/categories/:categorypath(.*)*",
    extended: true,
    meta: indextHDl6znZdFMeta || {},
    alias: indextHDl6znZdFMeta?.alias || [],
    redirect: indextHDl6znZdFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[...categorypath]/index.vue").then(m => m.default || m)
  },
  {
    name: indextHDl6znZdFMeta?.name ?? "categories-categorypath:na___en",
    path: indextHDl6znZdFMeta?.path ?? "/na/en/categories/:categorypath(.*)*",
    extended: true,
    meta: indextHDl6znZdFMeta || {},
    alias: indextHDl6znZdFMeta?.alias || [],
    redirect: indextHDl6znZdFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[...categorypath]/index.vue").then(m => m.default || m)
  },
  {
    name: indextHDl6znZdFMeta?.name ?? "categories-categorypath:global___en",
    path: indextHDl6znZdFMeta?.path ?? "/global/en/categories/:categorypath(.*)*",
    extended: true,
    meta: indextHDl6znZdFMeta || {},
    alias: indextHDl6znZdFMeta?.alias || [],
    redirect: indextHDl6znZdFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[...categorypath]/index.vue").then(m => m.default || m)
  },
  {
    name: indextHDl6znZdFMeta?.name ?? "categories-categorypath:eu___en",
    path: indextHDl6znZdFMeta?.path ?? "/eu/en/categories/:categorypath(.*)*",
    extended: true,
    meta: indextHDl6znZdFMeta || {},
    alias: indextHDl6znZdFMeta?.alias || [],
    redirect: indextHDl6znZdFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[...categorypath]/index.vue").then(m => m.default || m)
  },
  {
    name: indextHDl6znZdFMeta?.name ?? "categories-categorypath:na___de",
    path: indextHDl6znZdFMeta?.path ?? "/na/de/kategorien/:categorypath(.*)*",
    extended: true,
    meta: indextHDl6znZdFMeta || {},
    alias: indextHDl6znZdFMeta?.alias || [],
    redirect: indextHDl6znZdFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[...categorypath]/index.vue").then(m => m.default || m)
  },
  {
    name: indextHDl6znZdFMeta?.name ?? "categories-categorypath:global___de",
    path: indextHDl6znZdFMeta?.path ?? "/global/de/kategorien/:categorypath(.*)*",
    extended: true,
    meta: indextHDl6znZdFMeta || {},
    alias: indextHDl6znZdFMeta?.alias || [],
    redirect: indextHDl6znZdFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[...categorypath]/index.vue").then(m => m.default || m)
  },
  {
    name: indextHDl6znZdFMeta?.name ?? "categories-categorypath:eu___de",
    path: indextHDl6znZdFMeta?.path ?? "/eu/de/kategorien/:categorypath(.*)*",
    extended: true,
    meta: indextHDl6znZdFMeta || {},
    alias: indextHDl6znZdFMeta?.alias || [],
    redirect: indextHDl6znZdFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[...categorypath]/index.vue").then(m => m.default || m)
  },
  {
    name: indextHDl6znZdFMeta?.name ?? "categories-categorypath:na___fr",
    path: indextHDl6znZdFMeta?.path ?? "/na/fr/cat%C3%A9gories/:categorypath(.*)*",
    extended: true,
    meta: indextHDl6znZdFMeta || {},
    alias: indextHDl6znZdFMeta?.alias || [],
    redirect: indextHDl6znZdFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[...categorypath]/index.vue").then(m => m.default || m)
  },
  {
    name: indextHDl6znZdFMeta?.name ?? "categories-categorypath:global___fr",
    path: indextHDl6znZdFMeta?.path ?? "/global/fr/cat%C3%A9gories/:categorypath(.*)*",
    extended: true,
    meta: indextHDl6znZdFMeta || {},
    alias: indextHDl6znZdFMeta?.alias || [],
    redirect: indextHDl6znZdFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[...categorypath]/index.vue").then(m => m.default || m)
  },
  {
    name: indextHDl6znZdFMeta?.name ?? "categories-categorypath:eu___fr",
    path: indextHDl6znZdFMeta?.path ?? "/eu/fr/cat%C3%A9gories/:categorypath(.*)*",
    extended: true,
    meta: indextHDl6znZdFMeta || {},
    alias: indextHDl6znZdFMeta?.alias || [],
    redirect: indextHDl6znZdFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[...categorypath]/index.vue").then(m => m.default || m)
  },
  {
    name: indextHDl6znZdFMeta?.name ?? "categories-categorypath:na___it",
    path: indextHDl6znZdFMeta?.path ?? "/na/it/categorie/:categorypath(.*)*",
    extended: true,
    meta: indextHDl6znZdFMeta || {},
    alias: indextHDl6znZdFMeta?.alias || [],
    redirect: indextHDl6znZdFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[...categorypath]/index.vue").then(m => m.default || m)
  },
  {
    name: indextHDl6znZdFMeta?.name ?? "categories-categorypath:global___it",
    path: indextHDl6znZdFMeta?.path ?? "/global/it/categorie/:categorypath(.*)*",
    extended: true,
    meta: indextHDl6znZdFMeta || {},
    alias: indextHDl6znZdFMeta?.alias || [],
    redirect: indextHDl6znZdFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[...categorypath]/index.vue").then(m => m.default || m)
  },
  {
    name: indextHDl6znZdFMeta?.name ?? "categories-categorypath:eu___it",
    path: indextHDl6znZdFMeta?.path ?? "/eu/it/categorie/:categorypath(.*)*",
    extended: true,
    meta: indextHDl6znZdFMeta || {},
    alias: indextHDl6znZdFMeta?.alias || [],
    redirect: indextHDl6znZdFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[...categorypath]/index.vue").then(m => m.default || m)
  },
  {
    name: indextHDl6znZdFMeta?.name ?? "categories-categorypath:na___es",
    path: indextHDl6znZdFMeta?.path ?? "/na/es/categor%C3%ADas/:categorypath(.*)*",
    extended: true,
    meta: indextHDl6znZdFMeta || {},
    alias: indextHDl6znZdFMeta?.alias || [],
    redirect: indextHDl6znZdFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[...categorypath]/index.vue").then(m => m.default || m)
  },
  {
    name: indextHDl6znZdFMeta?.name ?? "categories-categorypath:global___es",
    path: indextHDl6znZdFMeta?.path ?? "/global/es/categor%C3%ADas/:categorypath(.*)*",
    extended: true,
    meta: indextHDl6znZdFMeta || {},
    alias: indextHDl6znZdFMeta?.alias || [],
    redirect: indextHDl6znZdFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[...categorypath]/index.vue").then(m => m.default || m)
  },
  {
    name: indextHDl6znZdFMeta?.name ?? "categories-categorypath:eu___es",
    path: indextHDl6znZdFMeta?.path ?? "/eu/es/categor%C3%ADas/:categorypath(.*)*",
    extended: true,
    meta: indextHDl6znZdFMeta || {},
    alias: indextHDl6znZdFMeta?.alias || [],
    redirect: indextHDl6znZdFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[...categorypath]/index.vue").then(m => m.default || m)
  },
  {
    name: _91product_938R7b3dSvdOMeta?.name ?? "categories-design-products-product",
    path: _91product_938R7b3dSvdOMeta?.path ?? "/categories/:design()/products/:product()",
    extended: true,
    meta: _91product_938R7b3dSvdOMeta || {},
    alias: _91product_938R7b3dSvdOMeta?.alias || [],
    redirect: _91product_938R7b3dSvdOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_938R7b3dSvdOMeta?.name ?? "categories-design-products-product:na___en",
    path: _91product_938R7b3dSvdOMeta?.path ?? "/na/en/categories/:design/products/:product",
    extended: true,
    meta: _91product_938R7b3dSvdOMeta || {},
    alias: _91product_938R7b3dSvdOMeta?.alias || [],
    redirect: _91product_938R7b3dSvdOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_938R7b3dSvdOMeta?.name ?? "categories-design-products-product:global___en",
    path: _91product_938R7b3dSvdOMeta?.path ?? "/global/en/categories/:design/products/:product",
    extended: true,
    meta: _91product_938R7b3dSvdOMeta || {},
    alias: _91product_938R7b3dSvdOMeta?.alias || [],
    redirect: _91product_938R7b3dSvdOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_938R7b3dSvdOMeta?.name ?? "categories-design-products-product:eu___en",
    path: _91product_938R7b3dSvdOMeta?.path ?? "/eu/en/categories/:design/products/:product",
    extended: true,
    meta: _91product_938R7b3dSvdOMeta || {},
    alias: _91product_938R7b3dSvdOMeta?.alias || [],
    redirect: _91product_938R7b3dSvdOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_938R7b3dSvdOMeta?.name ?? "categories-design-products-product:na___de",
    path: _91product_938R7b3dSvdOMeta?.path ?? "/na/de/kategorien/:design/produkte/:product",
    extended: true,
    meta: _91product_938R7b3dSvdOMeta || {},
    alias: _91product_938R7b3dSvdOMeta?.alias || [],
    redirect: _91product_938R7b3dSvdOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_938R7b3dSvdOMeta?.name ?? "categories-design-products-product:global___de",
    path: _91product_938R7b3dSvdOMeta?.path ?? "/global/de/kategorien/:design/produkte/:product",
    extended: true,
    meta: _91product_938R7b3dSvdOMeta || {},
    alias: _91product_938R7b3dSvdOMeta?.alias || [],
    redirect: _91product_938R7b3dSvdOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_938R7b3dSvdOMeta?.name ?? "categories-design-products-product:eu___de",
    path: _91product_938R7b3dSvdOMeta?.path ?? "/eu/de/kategorien/:design/produkte/:product",
    extended: true,
    meta: _91product_938R7b3dSvdOMeta || {},
    alias: _91product_938R7b3dSvdOMeta?.alias || [],
    redirect: _91product_938R7b3dSvdOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_938R7b3dSvdOMeta?.name ?? "categories-design-products-product:na___fr",
    path: _91product_938R7b3dSvdOMeta?.path ?? "/na/fr/cat%C3%A9gories/:design/produits/:product",
    extended: true,
    meta: _91product_938R7b3dSvdOMeta || {},
    alias: _91product_938R7b3dSvdOMeta?.alias || [],
    redirect: _91product_938R7b3dSvdOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_938R7b3dSvdOMeta?.name ?? "categories-design-products-product:global___fr",
    path: _91product_938R7b3dSvdOMeta?.path ?? "/global/fr/cat%C3%A9gories/:design/produits/:product",
    extended: true,
    meta: _91product_938R7b3dSvdOMeta || {},
    alias: _91product_938R7b3dSvdOMeta?.alias || [],
    redirect: _91product_938R7b3dSvdOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_938R7b3dSvdOMeta?.name ?? "categories-design-products-product:eu___fr",
    path: _91product_938R7b3dSvdOMeta?.path ?? "/eu/fr/cat%C3%A9gories/:design/produits/:product",
    extended: true,
    meta: _91product_938R7b3dSvdOMeta || {},
    alias: _91product_938R7b3dSvdOMeta?.alias || [],
    redirect: _91product_938R7b3dSvdOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_938R7b3dSvdOMeta?.name ?? "categories-design-products-product:na___it",
    path: _91product_938R7b3dSvdOMeta?.path ?? "/na/it/categorie/:design/prodotto/:product",
    extended: true,
    meta: _91product_938R7b3dSvdOMeta || {},
    alias: _91product_938R7b3dSvdOMeta?.alias || [],
    redirect: _91product_938R7b3dSvdOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_938R7b3dSvdOMeta?.name ?? "categories-design-products-product:global___it",
    path: _91product_938R7b3dSvdOMeta?.path ?? "/global/it/categorie/:design/prodotto/:product",
    extended: true,
    meta: _91product_938R7b3dSvdOMeta || {},
    alias: _91product_938R7b3dSvdOMeta?.alias || [],
    redirect: _91product_938R7b3dSvdOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_938R7b3dSvdOMeta?.name ?? "categories-design-products-product:eu___it",
    path: _91product_938R7b3dSvdOMeta?.path ?? "/eu/it/categorie/:design/prodotto/:product",
    extended: true,
    meta: _91product_938R7b3dSvdOMeta || {},
    alias: _91product_938R7b3dSvdOMeta?.alias || [],
    redirect: _91product_938R7b3dSvdOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_938R7b3dSvdOMeta?.name ?? "categories-design-products-product:na___es",
    path: _91product_938R7b3dSvdOMeta?.path ?? "/na/es/categor%C3%ADas/:design/productos/:product",
    extended: true,
    meta: _91product_938R7b3dSvdOMeta || {},
    alias: _91product_938R7b3dSvdOMeta?.alias || [],
    redirect: _91product_938R7b3dSvdOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_938R7b3dSvdOMeta?.name ?? "categories-design-products-product:global___es",
    path: _91product_938R7b3dSvdOMeta?.path ?? "/global/es/categor%C3%ADas/:design/productos/:product",
    extended: true,
    meta: _91product_938R7b3dSvdOMeta || {},
    alias: _91product_938R7b3dSvdOMeta?.alias || [],
    redirect: _91product_938R7b3dSvdOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_938R7b3dSvdOMeta?.name ?? "categories-design-products-product:eu___es",
    path: _91product_938R7b3dSvdOMeta?.path ?? "/eu/es/categor%C3%ADas/:design/productos/:product",
    extended: true,
    meta: _91product_938R7b3dSvdOMeta || {},
    alias: _91product_938R7b3dSvdOMeta?.alias || [],
    redirect: _91product_938R7b3dSvdOMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/[product].vue").then(m => m.default || m)
  },
  {
    name: indexzDQCbiAojAMeta?.name ?? "categories-design-products",
    path: indexzDQCbiAojAMeta?.path ?? "/categories/:design()/products",
    extended: true,
    meta: indexzDQCbiAojAMeta || {},
    alias: indexzDQCbiAojAMeta?.alias || [],
    redirect: indexzDQCbiAojAMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexzDQCbiAojAMeta?.name ?? "categories-design-products:na___en",
    path: indexzDQCbiAojAMeta?.path ?? "/na/en/categories/:design()/products",
    extended: true,
    meta: indexzDQCbiAojAMeta || {},
    alias: indexzDQCbiAojAMeta?.alias || [],
    redirect: indexzDQCbiAojAMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexzDQCbiAojAMeta?.name ?? "categories-design-products:global___en",
    path: indexzDQCbiAojAMeta?.path ?? "/global/en/categories/:design()/products",
    extended: true,
    meta: indexzDQCbiAojAMeta || {},
    alias: indexzDQCbiAojAMeta?.alias || [],
    redirect: indexzDQCbiAojAMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexzDQCbiAojAMeta?.name ?? "categories-design-products:eu___en",
    path: indexzDQCbiAojAMeta?.path ?? "/eu/en/categories/:design()/products",
    extended: true,
    meta: indexzDQCbiAojAMeta || {},
    alias: indexzDQCbiAojAMeta?.alias || [],
    redirect: indexzDQCbiAojAMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexzDQCbiAojAMeta?.name ?? "categories-design-products:na___de",
    path: indexzDQCbiAojAMeta?.path ?? "/na/de/categories/:design()/products",
    extended: true,
    meta: indexzDQCbiAojAMeta || {},
    alias: indexzDQCbiAojAMeta?.alias || [],
    redirect: indexzDQCbiAojAMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexzDQCbiAojAMeta?.name ?? "categories-design-products:global___de",
    path: indexzDQCbiAojAMeta?.path ?? "/global/de/categories/:design()/products",
    extended: true,
    meta: indexzDQCbiAojAMeta || {},
    alias: indexzDQCbiAojAMeta?.alias || [],
    redirect: indexzDQCbiAojAMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexzDQCbiAojAMeta?.name ?? "categories-design-products:eu___de",
    path: indexzDQCbiAojAMeta?.path ?? "/eu/de/categories/:design()/products",
    extended: true,
    meta: indexzDQCbiAojAMeta || {},
    alias: indexzDQCbiAojAMeta?.alias || [],
    redirect: indexzDQCbiAojAMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexzDQCbiAojAMeta?.name ?? "categories-design-products:na___fr",
    path: indexzDQCbiAojAMeta?.path ?? "/na/fr/categories/:design()/products",
    extended: true,
    meta: indexzDQCbiAojAMeta || {},
    alias: indexzDQCbiAojAMeta?.alias || [],
    redirect: indexzDQCbiAojAMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexzDQCbiAojAMeta?.name ?? "categories-design-products:global___fr",
    path: indexzDQCbiAojAMeta?.path ?? "/global/fr/categories/:design()/products",
    extended: true,
    meta: indexzDQCbiAojAMeta || {},
    alias: indexzDQCbiAojAMeta?.alias || [],
    redirect: indexzDQCbiAojAMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexzDQCbiAojAMeta?.name ?? "categories-design-products:eu___fr",
    path: indexzDQCbiAojAMeta?.path ?? "/eu/fr/categories/:design()/products",
    extended: true,
    meta: indexzDQCbiAojAMeta || {},
    alias: indexzDQCbiAojAMeta?.alias || [],
    redirect: indexzDQCbiAojAMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexzDQCbiAojAMeta?.name ?? "categories-design-products:na___it",
    path: indexzDQCbiAojAMeta?.path ?? "/na/it/categories/:design()/products",
    extended: true,
    meta: indexzDQCbiAojAMeta || {},
    alias: indexzDQCbiAojAMeta?.alias || [],
    redirect: indexzDQCbiAojAMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexzDQCbiAojAMeta?.name ?? "categories-design-products:global___it",
    path: indexzDQCbiAojAMeta?.path ?? "/global/it/categories/:design()/products",
    extended: true,
    meta: indexzDQCbiAojAMeta || {},
    alias: indexzDQCbiAojAMeta?.alias || [],
    redirect: indexzDQCbiAojAMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexzDQCbiAojAMeta?.name ?? "categories-design-products:eu___it",
    path: indexzDQCbiAojAMeta?.path ?? "/eu/it/categories/:design()/products",
    extended: true,
    meta: indexzDQCbiAojAMeta || {},
    alias: indexzDQCbiAojAMeta?.alias || [],
    redirect: indexzDQCbiAojAMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexzDQCbiAojAMeta?.name ?? "categories-design-products:na___es",
    path: indexzDQCbiAojAMeta?.path ?? "/na/es/categories/:design()/products",
    extended: true,
    meta: indexzDQCbiAojAMeta || {},
    alias: indexzDQCbiAojAMeta?.alias || [],
    redirect: indexzDQCbiAojAMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexzDQCbiAojAMeta?.name ?? "categories-design-products:global___es",
    path: indexzDQCbiAojAMeta?.path ?? "/global/es/categories/:design()/products",
    extended: true,
    meta: indexzDQCbiAojAMeta || {},
    alias: indexzDQCbiAojAMeta?.alias || [],
    redirect: indexzDQCbiAojAMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexzDQCbiAojAMeta?.name ?? "categories-design-products:eu___es",
    path: indexzDQCbiAojAMeta?.path ?? "/eu/es/categories/:design()/products",
    extended: true,
    meta: indexzDQCbiAojAMeta || {},
    alias: indexzDQCbiAojAMeta?.alias || [],
    redirect: indexzDQCbiAojAMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/[design]/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexWurEanfaYRMeta?.name ?? "categories",
    path: indexWurEanfaYRMeta?.path ?? "/categories",
    extended: true,
    meta: indexWurEanfaYRMeta || {},
    alias: indexWurEanfaYRMeta?.alias || [],
    redirect: indexWurEanfaYRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexWurEanfaYRMeta?.name ?? "categories:na___en",
    path: indexWurEanfaYRMeta?.path ?? "/na/en/categories",
    extended: true,
    meta: indexWurEanfaYRMeta || {},
    alias: indexWurEanfaYRMeta?.alias || [],
    redirect: indexWurEanfaYRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexWurEanfaYRMeta?.name ?? "categories:global___en",
    path: indexWurEanfaYRMeta?.path ?? "/global/en/categories",
    extended: true,
    meta: indexWurEanfaYRMeta || {},
    alias: indexWurEanfaYRMeta?.alias || [],
    redirect: indexWurEanfaYRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexWurEanfaYRMeta?.name ?? "categories:eu___en",
    path: indexWurEanfaYRMeta?.path ?? "/eu/en/categories",
    extended: true,
    meta: indexWurEanfaYRMeta || {},
    alias: indexWurEanfaYRMeta?.alias || [],
    redirect: indexWurEanfaYRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexWurEanfaYRMeta?.name ?? "categories:na___de",
    path: indexWurEanfaYRMeta?.path ?? "/na/de/kategorien",
    extended: true,
    meta: indexWurEanfaYRMeta || {},
    alias: indexWurEanfaYRMeta?.alias || [],
    redirect: indexWurEanfaYRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexWurEanfaYRMeta?.name ?? "categories:global___de",
    path: indexWurEanfaYRMeta?.path ?? "/global/de/kategorien",
    extended: true,
    meta: indexWurEanfaYRMeta || {},
    alias: indexWurEanfaYRMeta?.alias || [],
    redirect: indexWurEanfaYRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexWurEanfaYRMeta?.name ?? "categories:eu___de",
    path: indexWurEanfaYRMeta?.path ?? "/eu/de/kategorien",
    extended: true,
    meta: indexWurEanfaYRMeta || {},
    alias: indexWurEanfaYRMeta?.alias || [],
    redirect: indexWurEanfaYRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexWurEanfaYRMeta?.name ?? "categories:na___fr",
    path: indexWurEanfaYRMeta?.path ?? "/na/fr/cat%C3%A9gories",
    extended: true,
    meta: indexWurEanfaYRMeta || {},
    alias: indexWurEanfaYRMeta?.alias || [],
    redirect: indexWurEanfaYRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexWurEanfaYRMeta?.name ?? "categories:global___fr",
    path: indexWurEanfaYRMeta?.path ?? "/global/fr/cat%C3%A9gories",
    extended: true,
    meta: indexWurEanfaYRMeta || {},
    alias: indexWurEanfaYRMeta?.alias || [],
    redirect: indexWurEanfaYRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexWurEanfaYRMeta?.name ?? "categories:eu___fr",
    path: indexWurEanfaYRMeta?.path ?? "/eu/fr/cat%C3%A9gories",
    extended: true,
    meta: indexWurEanfaYRMeta || {},
    alias: indexWurEanfaYRMeta?.alias || [],
    redirect: indexWurEanfaYRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexWurEanfaYRMeta?.name ?? "categories:na___it",
    path: indexWurEanfaYRMeta?.path ?? "/na/it/categorie",
    extended: true,
    meta: indexWurEanfaYRMeta || {},
    alias: indexWurEanfaYRMeta?.alias || [],
    redirect: indexWurEanfaYRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexWurEanfaYRMeta?.name ?? "categories:global___it",
    path: indexWurEanfaYRMeta?.path ?? "/global/it/categorie",
    extended: true,
    meta: indexWurEanfaYRMeta || {},
    alias: indexWurEanfaYRMeta?.alias || [],
    redirect: indexWurEanfaYRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexWurEanfaYRMeta?.name ?? "categories:eu___it",
    path: indexWurEanfaYRMeta?.path ?? "/eu/it/categorie",
    extended: true,
    meta: indexWurEanfaYRMeta || {},
    alias: indexWurEanfaYRMeta?.alias || [],
    redirect: indexWurEanfaYRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexWurEanfaYRMeta?.name ?? "categories:na___es",
    path: indexWurEanfaYRMeta?.path ?? "/na/es/categor%C3%ADas",
    extended: true,
    meta: indexWurEanfaYRMeta || {},
    alias: indexWurEanfaYRMeta?.alias || [],
    redirect: indexWurEanfaYRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexWurEanfaYRMeta?.name ?? "categories:global___es",
    path: indexWurEanfaYRMeta?.path ?? "/global/es/categor%C3%ADas",
    extended: true,
    meta: indexWurEanfaYRMeta || {},
    alias: indexWurEanfaYRMeta?.alias || [],
    redirect: indexWurEanfaYRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexWurEanfaYRMeta?.name ?? "categories:eu___es",
    path: indexWurEanfaYRMeta?.path ?? "/eu/es/categor%C3%ADas",
    extended: true,
    meta: indexWurEanfaYRMeta || {},
    alias: indexWurEanfaYRMeta?.alias || [],
    redirect: indexWurEanfaYRMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZc4rktduJMeta?.name ?? "chemical-resistance-guide",
    path: indexyZc4rktduJMeta?.path ?? "/chemical-resistance-guide",
    extended: true,
    meta: indexyZc4rktduJMeta || {},
    alias: indexyZc4rktduJMeta?.alias || [],
    redirect: indexyZc4rktduJMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/chemical-resistance-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZc4rktduJMeta?.name ?? "chemical-resistance-guide:na___en",
    path: indexyZc4rktduJMeta?.path ?? "/na/en/chemical-resistance-guide",
    extended: true,
    meta: indexyZc4rktduJMeta || {},
    alias: indexyZc4rktduJMeta?.alias || [],
    redirect: indexyZc4rktduJMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/chemical-resistance-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZc4rktduJMeta?.name ?? "chemical-resistance-guide:global___en",
    path: indexyZc4rktduJMeta?.path ?? "/global/en/chemical-resistance-guide",
    extended: true,
    meta: indexyZc4rktduJMeta || {},
    alias: indexyZc4rktduJMeta?.alias || [],
    redirect: indexyZc4rktduJMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/chemical-resistance-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZc4rktduJMeta?.name ?? "chemical-resistance-guide:eu___en",
    path: indexyZc4rktduJMeta?.path ?? "/eu/en/chemical-resistance-guide",
    extended: true,
    meta: indexyZc4rktduJMeta || {},
    alias: indexyZc4rktduJMeta?.alias || [],
    redirect: indexyZc4rktduJMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/chemical-resistance-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZc4rktduJMeta?.name ?? "chemical-resistance-guide:na___de",
    path: indexyZc4rktduJMeta?.path ?? "/na/de/chemical-resistance-guide",
    extended: true,
    meta: indexyZc4rktduJMeta || {},
    alias: indexyZc4rktduJMeta?.alias || [],
    redirect: indexyZc4rktduJMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/chemical-resistance-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZc4rktduJMeta?.name ?? "chemical-resistance-guide:global___de",
    path: indexyZc4rktduJMeta?.path ?? "/global/de/chemical-resistance-guide",
    extended: true,
    meta: indexyZc4rktduJMeta || {},
    alias: indexyZc4rktduJMeta?.alias || [],
    redirect: indexyZc4rktduJMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/chemical-resistance-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZc4rktduJMeta?.name ?? "chemical-resistance-guide:eu___de",
    path: indexyZc4rktduJMeta?.path ?? "/eu/de/chemical-resistance-guide",
    extended: true,
    meta: indexyZc4rktduJMeta || {},
    alias: indexyZc4rktduJMeta?.alias || [],
    redirect: indexyZc4rktduJMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/chemical-resistance-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZc4rktduJMeta?.name ?? "chemical-resistance-guide:na___fr",
    path: indexyZc4rktduJMeta?.path ?? "/na/fr/chemical-resistance-guide",
    extended: true,
    meta: indexyZc4rktduJMeta || {},
    alias: indexyZc4rktduJMeta?.alias || [],
    redirect: indexyZc4rktduJMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/chemical-resistance-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZc4rktduJMeta?.name ?? "chemical-resistance-guide:global___fr",
    path: indexyZc4rktduJMeta?.path ?? "/global/fr/chemical-resistance-guide",
    extended: true,
    meta: indexyZc4rktduJMeta || {},
    alias: indexyZc4rktduJMeta?.alias || [],
    redirect: indexyZc4rktduJMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/chemical-resistance-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZc4rktduJMeta?.name ?? "chemical-resistance-guide:eu___fr",
    path: indexyZc4rktduJMeta?.path ?? "/eu/fr/chemical-resistance-guide",
    extended: true,
    meta: indexyZc4rktduJMeta || {},
    alias: indexyZc4rktduJMeta?.alias || [],
    redirect: indexyZc4rktduJMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/chemical-resistance-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZc4rktduJMeta?.name ?? "chemical-resistance-guide:na___it",
    path: indexyZc4rktduJMeta?.path ?? "/na/it/chemical-resistance-guide",
    extended: true,
    meta: indexyZc4rktduJMeta || {},
    alias: indexyZc4rktduJMeta?.alias || [],
    redirect: indexyZc4rktduJMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/chemical-resistance-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZc4rktduJMeta?.name ?? "chemical-resistance-guide:global___it",
    path: indexyZc4rktduJMeta?.path ?? "/global/it/chemical-resistance-guide",
    extended: true,
    meta: indexyZc4rktduJMeta || {},
    alias: indexyZc4rktduJMeta?.alias || [],
    redirect: indexyZc4rktduJMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/chemical-resistance-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZc4rktduJMeta?.name ?? "chemical-resistance-guide:eu___it",
    path: indexyZc4rktduJMeta?.path ?? "/eu/it/chemical-resistance-guide",
    extended: true,
    meta: indexyZc4rktduJMeta || {},
    alias: indexyZc4rktduJMeta?.alias || [],
    redirect: indexyZc4rktduJMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/chemical-resistance-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZc4rktduJMeta?.name ?? "chemical-resistance-guide:na___es",
    path: indexyZc4rktduJMeta?.path ?? "/na/es/chemical-resistance-guide",
    extended: true,
    meta: indexyZc4rktduJMeta || {},
    alias: indexyZc4rktduJMeta?.alias || [],
    redirect: indexyZc4rktduJMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/chemical-resistance-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZc4rktduJMeta?.name ?? "chemical-resistance-guide:global___es",
    path: indexyZc4rktduJMeta?.path ?? "/global/es/chemical-resistance-guide",
    extended: true,
    meta: indexyZc4rktduJMeta || {},
    alias: indexyZc4rktduJMeta?.alias || [],
    redirect: indexyZc4rktduJMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/chemical-resistance-guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZc4rktduJMeta?.name ?? "chemical-resistance-guide:eu___es",
    path: indexyZc4rktduJMeta?.path ?? "/eu/es/chemical-resistance-guide",
    extended: true,
    meta: indexyZc4rktduJMeta || {},
    alias: indexyZc4rktduJMeta?.alias || [],
    redirect: indexyZc4rktduJMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/chemical-resistance-guide/index.vue").then(m => m.default || m)
  },
  {
    name: cross_45reference7A0eYBD4J5Meta?.name ?? "cross-reference",
    path: cross_45reference7A0eYBD4J5Meta?.path ?? "/cross-reference",
    extended: true,
    meta: cross_45reference7A0eYBD4J5Meta || {},
    alias: cross_45reference7A0eYBD4J5Meta?.alias || [],
    redirect: cross_45reference7A0eYBD4J5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cross-reference.vue").then(m => m.default || m)
  },
  {
    name: cross_45reference7A0eYBD4J5Meta?.name ?? "cross-reference:na___en",
    path: cross_45reference7A0eYBD4J5Meta?.path ?? "/na/en/cross-reference",
    extended: true,
    meta: cross_45reference7A0eYBD4J5Meta || {},
    alias: cross_45reference7A0eYBD4J5Meta?.alias || [],
    redirect: cross_45reference7A0eYBD4J5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cross-reference.vue").then(m => m.default || m)
  },
  {
    name: cross_45reference7A0eYBD4J5Meta?.name ?? "cross-reference:global___en",
    path: cross_45reference7A0eYBD4J5Meta?.path ?? "/global/en/cross-reference",
    extended: true,
    meta: cross_45reference7A0eYBD4J5Meta || {},
    alias: cross_45reference7A0eYBD4J5Meta?.alias || [],
    redirect: cross_45reference7A0eYBD4J5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cross-reference.vue").then(m => m.default || m)
  },
  {
    name: cross_45reference7A0eYBD4J5Meta?.name ?? "cross-reference:eu___en",
    path: cross_45reference7A0eYBD4J5Meta?.path ?? "/eu/en/cross-reference",
    extended: true,
    meta: cross_45reference7A0eYBD4J5Meta || {},
    alias: cross_45reference7A0eYBD4J5Meta?.alias || [],
    redirect: cross_45reference7A0eYBD4J5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cross-reference.vue").then(m => m.default || m)
  },
  {
    name: cross_45reference7A0eYBD4J5Meta?.name ?? "cross-reference:na___de",
    path: cross_45reference7A0eYBD4J5Meta?.path ?? "/na/de/cross-reference",
    extended: true,
    meta: cross_45reference7A0eYBD4J5Meta || {},
    alias: cross_45reference7A0eYBD4J5Meta?.alias || [],
    redirect: cross_45reference7A0eYBD4J5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cross-reference.vue").then(m => m.default || m)
  },
  {
    name: cross_45reference7A0eYBD4J5Meta?.name ?? "cross-reference:global___de",
    path: cross_45reference7A0eYBD4J5Meta?.path ?? "/global/de/cross-reference",
    extended: true,
    meta: cross_45reference7A0eYBD4J5Meta || {},
    alias: cross_45reference7A0eYBD4J5Meta?.alias || [],
    redirect: cross_45reference7A0eYBD4J5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cross-reference.vue").then(m => m.default || m)
  },
  {
    name: cross_45reference7A0eYBD4J5Meta?.name ?? "cross-reference:eu___de",
    path: cross_45reference7A0eYBD4J5Meta?.path ?? "/eu/de/cross-reference",
    extended: true,
    meta: cross_45reference7A0eYBD4J5Meta || {},
    alias: cross_45reference7A0eYBD4J5Meta?.alias || [],
    redirect: cross_45reference7A0eYBD4J5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cross-reference.vue").then(m => m.default || m)
  },
  {
    name: cross_45reference7A0eYBD4J5Meta?.name ?? "cross-reference:na___fr",
    path: cross_45reference7A0eYBD4J5Meta?.path ?? "/na/fr/reference-croisee",
    extended: true,
    meta: cross_45reference7A0eYBD4J5Meta || {},
    alias: cross_45reference7A0eYBD4J5Meta?.alias || [],
    redirect: cross_45reference7A0eYBD4J5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cross-reference.vue").then(m => m.default || m)
  },
  {
    name: cross_45reference7A0eYBD4J5Meta?.name ?? "cross-reference:global___fr",
    path: cross_45reference7A0eYBD4J5Meta?.path ?? "/global/fr/reference-croisee",
    extended: true,
    meta: cross_45reference7A0eYBD4J5Meta || {},
    alias: cross_45reference7A0eYBD4J5Meta?.alias || [],
    redirect: cross_45reference7A0eYBD4J5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cross-reference.vue").then(m => m.default || m)
  },
  {
    name: cross_45reference7A0eYBD4J5Meta?.name ?? "cross-reference:eu___fr",
    path: cross_45reference7A0eYBD4J5Meta?.path ?? "/eu/fr/reference-croisee",
    extended: true,
    meta: cross_45reference7A0eYBD4J5Meta || {},
    alias: cross_45reference7A0eYBD4J5Meta?.alias || [],
    redirect: cross_45reference7A0eYBD4J5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cross-reference.vue").then(m => m.default || m)
  },
  {
    name: cross_45reference7A0eYBD4J5Meta?.name ?? "cross-reference:na___it",
    path: cross_45reference7A0eYBD4J5Meta?.path ?? "/na/it/rimando",
    extended: true,
    meta: cross_45reference7A0eYBD4J5Meta || {},
    alias: cross_45reference7A0eYBD4J5Meta?.alias || [],
    redirect: cross_45reference7A0eYBD4J5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cross-reference.vue").then(m => m.default || m)
  },
  {
    name: cross_45reference7A0eYBD4J5Meta?.name ?? "cross-reference:global___it",
    path: cross_45reference7A0eYBD4J5Meta?.path ?? "/global/it/rimando",
    extended: true,
    meta: cross_45reference7A0eYBD4J5Meta || {},
    alias: cross_45reference7A0eYBD4J5Meta?.alias || [],
    redirect: cross_45reference7A0eYBD4J5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cross-reference.vue").then(m => m.default || m)
  },
  {
    name: cross_45reference7A0eYBD4J5Meta?.name ?? "cross-reference:eu___it",
    path: cross_45reference7A0eYBD4J5Meta?.path ?? "/eu/it/rimando",
    extended: true,
    meta: cross_45reference7A0eYBD4J5Meta || {},
    alias: cross_45reference7A0eYBD4J5Meta?.alias || [],
    redirect: cross_45reference7A0eYBD4J5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cross-reference.vue").then(m => m.default || m)
  },
  {
    name: cross_45reference7A0eYBD4J5Meta?.name ?? "cross-reference:na___es",
    path: cross_45reference7A0eYBD4J5Meta?.path ?? "/na/es/referencia-cruzada",
    extended: true,
    meta: cross_45reference7A0eYBD4J5Meta || {},
    alias: cross_45reference7A0eYBD4J5Meta?.alias || [],
    redirect: cross_45reference7A0eYBD4J5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cross-reference.vue").then(m => m.default || m)
  },
  {
    name: cross_45reference7A0eYBD4J5Meta?.name ?? "cross-reference:global___es",
    path: cross_45reference7A0eYBD4J5Meta?.path ?? "/global/es/referencia-cruzada",
    extended: true,
    meta: cross_45reference7A0eYBD4J5Meta || {},
    alias: cross_45reference7A0eYBD4J5Meta?.alias || [],
    redirect: cross_45reference7A0eYBD4J5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cross-reference.vue").then(m => m.default || m)
  },
  {
    name: cross_45reference7A0eYBD4J5Meta?.name ?? "cross-reference:eu___es",
    path: cross_45reference7A0eYBD4J5Meta?.path ?? "/eu/es/referencia-cruzada",
    extended: true,
    meta: cross_45reference7A0eYBD4J5Meta || {},
    alias: cross_45reference7A0eYBD4J5Meta?.alias || [],
    redirect: cross_45reference7A0eYBD4J5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/cross-reference.vue").then(m => m.default || m)
  },
  {
    name: distributor_45searchj1w3O4pEhxMeta?.name ?? "distributor-search",
    path: distributor_45searchj1w3O4pEhxMeta?.path ?? "/distributor-search",
    extended: true,
    meta: distributor_45searchj1w3O4pEhxMeta || {},
    alias: distributor_45searchj1w3O4pEhxMeta?.alias || [],
    redirect: distributor_45searchj1w3O4pEhxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/distributor-search.vue").then(m => m.default || m)
  },
  {
    name: distributor_45searchj1w3O4pEhxMeta?.name ?? "distributor-search:na___en",
    path: distributor_45searchj1w3O4pEhxMeta?.path ?? "/na/en/distributor-search/:distributor?",
    extended: true,
    meta: distributor_45searchj1w3O4pEhxMeta || {},
    alias: distributor_45searchj1w3O4pEhxMeta?.alias || [],
    redirect: distributor_45searchj1w3O4pEhxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/distributor-search.vue").then(m => m.default || m)
  },
  {
    name: distributor_45searchj1w3O4pEhxMeta?.name ?? "distributor-search:global___en",
    path: distributor_45searchj1w3O4pEhxMeta?.path ?? "/global/en/distributor-search/:distributor?",
    extended: true,
    meta: distributor_45searchj1w3O4pEhxMeta || {},
    alias: distributor_45searchj1w3O4pEhxMeta?.alias || [],
    redirect: distributor_45searchj1w3O4pEhxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/distributor-search.vue").then(m => m.default || m)
  },
  {
    name: distributor_45searchj1w3O4pEhxMeta?.name ?? "distributor-search:eu___en",
    path: distributor_45searchj1w3O4pEhxMeta?.path ?? "/eu/en/distributor-search/:distributor?",
    extended: true,
    meta: distributor_45searchj1w3O4pEhxMeta || {},
    alias: distributor_45searchj1w3O4pEhxMeta?.alias || [],
    redirect: distributor_45searchj1w3O4pEhxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/distributor-search.vue").then(m => m.default || m)
  },
  {
    name: distributor_45searchj1w3O4pEhxMeta?.name ?? "distributor-search:na___de",
    path: distributor_45searchj1w3O4pEhxMeta?.path ?? "/na/de/haendlersuche/:distributor?",
    extended: true,
    meta: distributor_45searchj1w3O4pEhxMeta || {},
    alias: distributor_45searchj1w3O4pEhxMeta?.alias || [],
    redirect: distributor_45searchj1w3O4pEhxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/distributor-search.vue").then(m => m.default || m)
  },
  {
    name: distributor_45searchj1w3O4pEhxMeta?.name ?? "distributor-search:global___de",
    path: distributor_45searchj1w3O4pEhxMeta?.path ?? "/global/de/haendlersuche/:distributor?",
    extended: true,
    meta: distributor_45searchj1w3O4pEhxMeta || {},
    alias: distributor_45searchj1w3O4pEhxMeta?.alias || [],
    redirect: distributor_45searchj1w3O4pEhxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/distributor-search.vue").then(m => m.default || m)
  },
  {
    name: distributor_45searchj1w3O4pEhxMeta?.name ?? "distributor-search:eu___de",
    path: distributor_45searchj1w3O4pEhxMeta?.path ?? "/eu/de/haendlersuche/:distributor?",
    extended: true,
    meta: distributor_45searchj1w3O4pEhxMeta || {},
    alias: distributor_45searchj1w3O4pEhxMeta?.alias || [],
    redirect: distributor_45searchj1w3O4pEhxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/distributor-search.vue").then(m => m.default || m)
  },
  {
    name: distributor_45searchj1w3O4pEhxMeta?.name ?? "distributor-search:na___fr",
    path: distributor_45searchj1w3O4pEhxMeta?.path ?? "/na/fr/recherche-distributeurs/:distributor?",
    extended: true,
    meta: distributor_45searchj1w3O4pEhxMeta || {},
    alias: distributor_45searchj1w3O4pEhxMeta?.alias || [],
    redirect: distributor_45searchj1w3O4pEhxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/distributor-search.vue").then(m => m.default || m)
  },
  {
    name: distributor_45searchj1w3O4pEhxMeta?.name ?? "distributor-search:global___fr",
    path: distributor_45searchj1w3O4pEhxMeta?.path ?? "/global/fr/recherche-distributeurs/:distributor?",
    extended: true,
    meta: distributor_45searchj1w3O4pEhxMeta || {},
    alias: distributor_45searchj1w3O4pEhxMeta?.alias || [],
    redirect: distributor_45searchj1w3O4pEhxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/distributor-search.vue").then(m => m.default || m)
  },
  {
    name: distributor_45searchj1w3O4pEhxMeta?.name ?? "distributor-search:eu___fr",
    path: distributor_45searchj1w3O4pEhxMeta?.path ?? "/eu/fr/recherche-distributeurs/:distributor?",
    extended: true,
    meta: distributor_45searchj1w3O4pEhxMeta || {},
    alias: distributor_45searchj1w3O4pEhxMeta?.alias || [],
    redirect: distributor_45searchj1w3O4pEhxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/distributor-search.vue").then(m => m.default || m)
  },
  {
    name: distributor_45searchj1w3O4pEhxMeta?.name ?? "distributor-search:na___it",
    path: distributor_45searchj1w3O4pEhxMeta?.path ?? "/na/it/cerca-distributore/:distributor?",
    extended: true,
    meta: distributor_45searchj1w3O4pEhxMeta || {},
    alias: distributor_45searchj1w3O4pEhxMeta?.alias || [],
    redirect: distributor_45searchj1w3O4pEhxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/distributor-search.vue").then(m => m.default || m)
  },
  {
    name: distributor_45searchj1w3O4pEhxMeta?.name ?? "distributor-search:global___it",
    path: distributor_45searchj1w3O4pEhxMeta?.path ?? "/global/it/cerca-distributore/:distributor?",
    extended: true,
    meta: distributor_45searchj1w3O4pEhxMeta || {},
    alias: distributor_45searchj1w3O4pEhxMeta?.alias || [],
    redirect: distributor_45searchj1w3O4pEhxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/distributor-search.vue").then(m => m.default || m)
  },
  {
    name: distributor_45searchj1w3O4pEhxMeta?.name ?? "distributor-search:eu___it",
    path: distributor_45searchj1w3O4pEhxMeta?.path ?? "/eu/it/cerca-distributore/:distributor?",
    extended: true,
    meta: distributor_45searchj1w3O4pEhxMeta || {},
    alias: distributor_45searchj1w3O4pEhxMeta?.alias || [],
    redirect: distributor_45searchj1w3O4pEhxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/distributor-search.vue").then(m => m.default || m)
  },
  {
    name: distributor_45searchj1w3O4pEhxMeta?.name ?? "distributor-search:na___es",
    path: distributor_45searchj1w3O4pEhxMeta?.path ?? "/na/es/b%C3%BAsqueda-distribuidores/:distributor?",
    extended: true,
    meta: distributor_45searchj1w3O4pEhxMeta || {},
    alias: distributor_45searchj1w3O4pEhxMeta?.alias || [],
    redirect: distributor_45searchj1w3O4pEhxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/distributor-search.vue").then(m => m.default || m)
  },
  {
    name: distributor_45searchj1w3O4pEhxMeta?.name ?? "distributor-search:global___es",
    path: distributor_45searchj1w3O4pEhxMeta?.path ?? "/global/es/b%C3%BAsqueda-distribuidores/:distributor?",
    extended: true,
    meta: distributor_45searchj1w3O4pEhxMeta || {},
    alias: distributor_45searchj1w3O4pEhxMeta?.alias || [],
    redirect: distributor_45searchj1w3O4pEhxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/distributor-search.vue").then(m => m.default || m)
  },
  {
    name: distributor_45searchj1w3O4pEhxMeta?.name ?? "distributor-search:eu___es",
    path: distributor_45searchj1w3O4pEhxMeta?.path ?? "/eu/es/b%C3%BAsqueda-distribuidores/:distributor?",
    extended: true,
    meta: distributor_45searchj1w3O4pEhxMeta || {},
    alias: distributor_45searchj1w3O4pEhxMeta?.alias || [],
    redirect: distributor_45searchj1w3O4pEhxMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/distributor-search.vue").then(m => m.default || m)
  },
  {
    name: faq9K29EW5IX0Meta?.name ?? "faq",
    path: faq9K29EW5IX0Meta?.path ?? "/faq",
    extended: true,
    meta: faq9K29EW5IX0Meta || {},
    alias: faq9K29EW5IX0Meta?.alias || [],
    redirect: faq9K29EW5IX0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faq9K29EW5IX0Meta?.name ?? "faq:na___en",
    path: faq9K29EW5IX0Meta?.path ?? "/na/en/faq",
    extended: true,
    meta: faq9K29EW5IX0Meta || {},
    alias: faq9K29EW5IX0Meta?.alias || [],
    redirect: faq9K29EW5IX0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faq9K29EW5IX0Meta?.name ?? "faq:global___en",
    path: faq9K29EW5IX0Meta?.path ?? "/global/en/faq",
    extended: true,
    meta: faq9K29EW5IX0Meta || {},
    alias: faq9K29EW5IX0Meta?.alias || [],
    redirect: faq9K29EW5IX0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faq9K29EW5IX0Meta?.name ?? "faq:eu___en",
    path: faq9K29EW5IX0Meta?.path ?? "/eu/en/faq",
    extended: true,
    meta: faq9K29EW5IX0Meta || {},
    alias: faq9K29EW5IX0Meta?.alias || [],
    redirect: faq9K29EW5IX0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faq9K29EW5IX0Meta?.name ?? "faq:na___de",
    path: faq9K29EW5IX0Meta?.path ?? "/na/de/faq",
    extended: true,
    meta: faq9K29EW5IX0Meta || {},
    alias: faq9K29EW5IX0Meta?.alias || [],
    redirect: faq9K29EW5IX0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faq9K29EW5IX0Meta?.name ?? "faq:global___de",
    path: faq9K29EW5IX0Meta?.path ?? "/global/de/faq",
    extended: true,
    meta: faq9K29EW5IX0Meta || {},
    alias: faq9K29EW5IX0Meta?.alias || [],
    redirect: faq9K29EW5IX0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faq9K29EW5IX0Meta?.name ?? "faq:eu___de",
    path: faq9K29EW5IX0Meta?.path ?? "/eu/de/faq",
    extended: true,
    meta: faq9K29EW5IX0Meta || {},
    alias: faq9K29EW5IX0Meta?.alias || [],
    redirect: faq9K29EW5IX0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faq9K29EW5IX0Meta?.name ?? "faq:na___fr",
    path: faq9K29EW5IX0Meta?.path ?? "/na/fr/faq",
    extended: true,
    meta: faq9K29EW5IX0Meta || {},
    alias: faq9K29EW5IX0Meta?.alias || [],
    redirect: faq9K29EW5IX0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faq9K29EW5IX0Meta?.name ?? "faq:global___fr",
    path: faq9K29EW5IX0Meta?.path ?? "/global/fr/faq",
    extended: true,
    meta: faq9K29EW5IX0Meta || {},
    alias: faq9K29EW5IX0Meta?.alias || [],
    redirect: faq9K29EW5IX0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faq9K29EW5IX0Meta?.name ?? "faq:eu___fr",
    path: faq9K29EW5IX0Meta?.path ?? "/eu/fr/faq",
    extended: true,
    meta: faq9K29EW5IX0Meta || {},
    alias: faq9K29EW5IX0Meta?.alias || [],
    redirect: faq9K29EW5IX0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faq9K29EW5IX0Meta?.name ?? "faq:na___it",
    path: faq9K29EW5IX0Meta?.path ?? "/na/it/faq",
    extended: true,
    meta: faq9K29EW5IX0Meta || {},
    alias: faq9K29EW5IX0Meta?.alias || [],
    redirect: faq9K29EW5IX0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faq9K29EW5IX0Meta?.name ?? "faq:global___it",
    path: faq9K29EW5IX0Meta?.path ?? "/global/it/faq",
    extended: true,
    meta: faq9K29EW5IX0Meta || {},
    alias: faq9K29EW5IX0Meta?.alias || [],
    redirect: faq9K29EW5IX0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faq9K29EW5IX0Meta?.name ?? "faq:eu___it",
    path: faq9K29EW5IX0Meta?.path ?? "/eu/it/faq",
    extended: true,
    meta: faq9K29EW5IX0Meta || {},
    alias: faq9K29EW5IX0Meta?.alias || [],
    redirect: faq9K29EW5IX0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faq9K29EW5IX0Meta?.name ?? "faq:na___es",
    path: faq9K29EW5IX0Meta?.path ?? "/na/es/PP.%20FF.",
    extended: true,
    meta: faq9K29EW5IX0Meta || {},
    alias: faq9K29EW5IX0Meta?.alias || [],
    redirect: faq9K29EW5IX0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faq9K29EW5IX0Meta?.name ?? "faq:global___es",
    path: faq9K29EW5IX0Meta?.path ?? "/global/es/PP.%20FF.",
    extended: true,
    meta: faq9K29EW5IX0Meta || {},
    alias: faq9K29EW5IX0Meta?.alias || [],
    redirect: faq9K29EW5IX0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faq9K29EW5IX0Meta?.name ?? "faq:eu___es",
    path: faq9K29EW5IX0Meta?.path ?? "/eu/es/PP.%20FF.",
    extended: true,
    meta: faq9K29EW5IX0Meta || {},
    alias: faq9K29EW5IX0Meta?.alias || [],
    redirect: faq9K29EW5IX0Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: _91guideDetail_9371jiqr2eaNMeta?.name ?? "guides-guideCategories-guideDetail",
    path: _91guideDetail_9371jiqr2eaNMeta?.path ?? "/guides/:guideCategories()/:guideDetail()",
    extended: true,
    meta: _91guideDetail_9371jiqr2eaNMeta || {},
    alias: _91guideDetail_9371jiqr2eaNMeta?.alias || [],
    redirect: _91guideDetail_9371jiqr2eaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/[guideDetail].vue").then(m => m.default || m)
  },
  {
    name: _91guideDetail_9371jiqr2eaNMeta?.name ?? "guides-guideCategories-guideDetail:na___en",
    path: _91guideDetail_9371jiqr2eaNMeta?.path ?? "/na/en/guides/:guideCategories/:guideDetail",
    extended: true,
    meta: _91guideDetail_9371jiqr2eaNMeta || {},
    alias: _91guideDetail_9371jiqr2eaNMeta?.alias || [],
    redirect: _91guideDetail_9371jiqr2eaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/[guideDetail].vue").then(m => m.default || m)
  },
  {
    name: _91guideDetail_9371jiqr2eaNMeta?.name ?? "guides-guideCategories-guideDetail:global___en",
    path: _91guideDetail_9371jiqr2eaNMeta?.path ?? "/global/en/guides/:guideCategories/:guideDetail",
    extended: true,
    meta: _91guideDetail_9371jiqr2eaNMeta || {},
    alias: _91guideDetail_9371jiqr2eaNMeta?.alias || [],
    redirect: _91guideDetail_9371jiqr2eaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/[guideDetail].vue").then(m => m.default || m)
  },
  {
    name: _91guideDetail_9371jiqr2eaNMeta?.name ?? "guides-guideCategories-guideDetail:eu___en",
    path: _91guideDetail_9371jiqr2eaNMeta?.path ?? "/eu/en/guides/:guideCategories/:guideDetail",
    extended: true,
    meta: _91guideDetail_9371jiqr2eaNMeta || {},
    alias: _91guideDetail_9371jiqr2eaNMeta?.alias || [],
    redirect: _91guideDetail_9371jiqr2eaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/[guideDetail].vue").then(m => m.default || m)
  },
  {
    name: _91guideDetail_9371jiqr2eaNMeta?.name ?? "guides-guideCategories-guideDetail:na___de",
    path: _91guideDetail_9371jiqr2eaNMeta?.path ?? "/na/de/anleitungen/:guideCategories/:guideDetail",
    extended: true,
    meta: _91guideDetail_9371jiqr2eaNMeta || {},
    alias: _91guideDetail_9371jiqr2eaNMeta?.alias || [],
    redirect: _91guideDetail_9371jiqr2eaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/[guideDetail].vue").then(m => m.default || m)
  },
  {
    name: _91guideDetail_9371jiqr2eaNMeta?.name ?? "guides-guideCategories-guideDetail:global___de",
    path: _91guideDetail_9371jiqr2eaNMeta?.path ?? "/global/de/anleitungen/:guideCategories/:guideDetail",
    extended: true,
    meta: _91guideDetail_9371jiqr2eaNMeta || {},
    alias: _91guideDetail_9371jiqr2eaNMeta?.alias || [],
    redirect: _91guideDetail_9371jiqr2eaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/[guideDetail].vue").then(m => m.default || m)
  },
  {
    name: _91guideDetail_9371jiqr2eaNMeta?.name ?? "guides-guideCategories-guideDetail:eu___de",
    path: _91guideDetail_9371jiqr2eaNMeta?.path ?? "/eu/de/anleitungen/:guideCategories/:guideDetail",
    extended: true,
    meta: _91guideDetail_9371jiqr2eaNMeta || {},
    alias: _91guideDetail_9371jiqr2eaNMeta?.alias || [],
    redirect: _91guideDetail_9371jiqr2eaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/[guideDetail].vue").then(m => m.default || m)
  },
  {
    name: _91guideDetail_9371jiqr2eaNMeta?.name ?? "guides-guideCategories-guideDetail:na___fr",
    path: _91guideDetail_9371jiqr2eaNMeta?.path ?? "/na/fr/instructions/:guideCategories/:guideDetail",
    extended: true,
    meta: _91guideDetail_9371jiqr2eaNMeta || {},
    alias: _91guideDetail_9371jiqr2eaNMeta?.alias || [],
    redirect: _91guideDetail_9371jiqr2eaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/[guideDetail].vue").then(m => m.default || m)
  },
  {
    name: _91guideDetail_9371jiqr2eaNMeta?.name ?? "guides-guideCategories-guideDetail:global___fr",
    path: _91guideDetail_9371jiqr2eaNMeta?.path ?? "/global/fr/instructions/:guideCategories/:guideDetail",
    extended: true,
    meta: _91guideDetail_9371jiqr2eaNMeta || {},
    alias: _91guideDetail_9371jiqr2eaNMeta?.alias || [],
    redirect: _91guideDetail_9371jiqr2eaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/[guideDetail].vue").then(m => m.default || m)
  },
  {
    name: _91guideDetail_9371jiqr2eaNMeta?.name ?? "guides-guideCategories-guideDetail:eu___fr",
    path: _91guideDetail_9371jiqr2eaNMeta?.path ?? "/eu/fr/instructions/:guideCategories/:guideDetail",
    extended: true,
    meta: _91guideDetail_9371jiqr2eaNMeta || {},
    alias: _91guideDetail_9371jiqr2eaNMeta?.alias || [],
    redirect: _91guideDetail_9371jiqr2eaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/[guideDetail].vue").then(m => m.default || m)
  },
  {
    name: _91guideDetail_9371jiqr2eaNMeta?.name ?? "guides-guideCategories-guideDetail:na___it",
    path: _91guideDetail_9371jiqr2eaNMeta?.path ?? "/na/it/istruzioni/:guideCategories/:guideDetail",
    extended: true,
    meta: _91guideDetail_9371jiqr2eaNMeta || {},
    alias: _91guideDetail_9371jiqr2eaNMeta?.alias || [],
    redirect: _91guideDetail_9371jiqr2eaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/[guideDetail].vue").then(m => m.default || m)
  },
  {
    name: _91guideDetail_9371jiqr2eaNMeta?.name ?? "guides-guideCategories-guideDetail:global___it",
    path: _91guideDetail_9371jiqr2eaNMeta?.path ?? "/global/it/istruzioni/:guideCategories/:guideDetail",
    extended: true,
    meta: _91guideDetail_9371jiqr2eaNMeta || {},
    alias: _91guideDetail_9371jiqr2eaNMeta?.alias || [],
    redirect: _91guideDetail_9371jiqr2eaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/[guideDetail].vue").then(m => m.default || m)
  },
  {
    name: _91guideDetail_9371jiqr2eaNMeta?.name ?? "guides-guideCategories-guideDetail:eu___it",
    path: _91guideDetail_9371jiqr2eaNMeta?.path ?? "/eu/it/istruzioni/:guideCategories/:guideDetail",
    extended: true,
    meta: _91guideDetail_9371jiqr2eaNMeta || {},
    alias: _91guideDetail_9371jiqr2eaNMeta?.alias || [],
    redirect: _91guideDetail_9371jiqr2eaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/[guideDetail].vue").then(m => m.default || m)
  },
  {
    name: _91guideDetail_9371jiqr2eaNMeta?.name ?? "guides-guideCategories-guideDetail:na___es",
    path: _91guideDetail_9371jiqr2eaNMeta?.path ?? "/na/es/gu%C3%ADas/:guideCategories/:guideDetail",
    extended: true,
    meta: _91guideDetail_9371jiqr2eaNMeta || {},
    alias: _91guideDetail_9371jiqr2eaNMeta?.alias || [],
    redirect: _91guideDetail_9371jiqr2eaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/[guideDetail].vue").then(m => m.default || m)
  },
  {
    name: _91guideDetail_9371jiqr2eaNMeta?.name ?? "guides-guideCategories-guideDetail:global___es",
    path: _91guideDetail_9371jiqr2eaNMeta?.path ?? "/global/es/gu%C3%ADas/:guideCategories/:guideDetail",
    extended: true,
    meta: _91guideDetail_9371jiqr2eaNMeta || {},
    alias: _91guideDetail_9371jiqr2eaNMeta?.alias || [],
    redirect: _91guideDetail_9371jiqr2eaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/[guideDetail].vue").then(m => m.default || m)
  },
  {
    name: _91guideDetail_9371jiqr2eaNMeta?.name ?? "guides-guideCategories-guideDetail:eu___es",
    path: _91guideDetail_9371jiqr2eaNMeta?.path ?? "/eu/es/gu%C3%ADas/:guideCategories/:guideDetail",
    extended: true,
    meta: _91guideDetail_9371jiqr2eaNMeta || {},
    alias: _91guideDetail_9371jiqr2eaNMeta?.alias || [],
    redirect: _91guideDetail_9371jiqr2eaNMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/[guideDetail].vue").then(m => m.default || m)
  },
  {
    name: indexZxRO3EAAxgMeta?.name ?? "guides-guideCategories",
    path: indexZxRO3EAAxgMeta?.path ?? "/guides/:guideCategories()",
    extended: true,
    meta: indexZxRO3EAAxgMeta || {},
    alias: indexZxRO3EAAxgMeta?.alias || [],
    redirect: indexZxRO3EAAxgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZxRO3EAAxgMeta?.name ?? "guides-guideCategories:na___en",
    path: indexZxRO3EAAxgMeta?.path ?? "/na/en/guides/:guideCategories",
    extended: true,
    meta: indexZxRO3EAAxgMeta || {},
    alias: indexZxRO3EAAxgMeta?.alias || [],
    redirect: indexZxRO3EAAxgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZxRO3EAAxgMeta?.name ?? "guides-guideCategories:global___en",
    path: indexZxRO3EAAxgMeta?.path ?? "/global/en/guides/:guideCategories",
    extended: true,
    meta: indexZxRO3EAAxgMeta || {},
    alias: indexZxRO3EAAxgMeta?.alias || [],
    redirect: indexZxRO3EAAxgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZxRO3EAAxgMeta?.name ?? "guides-guideCategories:eu___en",
    path: indexZxRO3EAAxgMeta?.path ?? "/eu/en/guides/:guideCategories",
    extended: true,
    meta: indexZxRO3EAAxgMeta || {},
    alias: indexZxRO3EAAxgMeta?.alias || [],
    redirect: indexZxRO3EAAxgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZxRO3EAAxgMeta?.name ?? "guides-guideCategories:na___de",
    path: indexZxRO3EAAxgMeta?.path ?? "/na/de/anleitungen/:guideCategories",
    extended: true,
    meta: indexZxRO3EAAxgMeta || {},
    alias: indexZxRO3EAAxgMeta?.alias || [],
    redirect: indexZxRO3EAAxgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZxRO3EAAxgMeta?.name ?? "guides-guideCategories:global___de",
    path: indexZxRO3EAAxgMeta?.path ?? "/global/de/anleitungen/:guideCategories",
    extended: true,
    meta: indexZxRO3EAAxgMeta || {},
    alias: indexZxRO3EAAxgMeta?.alias || [],
    redirect: indexZxRO3EAAxgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZxRO3EAAxgMeta?.name ?? "guides-guideCategories:eu___de",
    path: indexZxRO3EAAxgMeta?.path ?? "/eu/de/anleitungen/:guideCategories",
    extended: true,
    meta: indexZxRO3EAAxgMeta || {},
    alias: indexZxRO3EAAxgMeta?.alias || [],
    redirect: indexZxRO3EAAxgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZxRO3EAAxgMeta?.name ?? "guides-guideCategories:na___fr",
    path: indexZxRO3EAAxgMeta?.path ?? "/na/fr/instructions/:guideCategories",
    extended: true,
    meta: indexZxRO3EAAxgMeta || {},
    alias: indexZxRO3EAAxgMeta?.alias || [],
    redirect: indexZxRO3EAAxgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZxRO3EAAxgMeta?.name ?? "guides-guideCategories:global___fr",
    path: indexZxRO3EAAxgMeta?.path ?? "/global/fr/instructions/:guideCategories",
    extended: true,
    meta: indexZxRO3EAAxgMeta || {},
    alias: indexZxRO3EAAxgMeta?.alias || [],
    redirect: indexZxRO3EAAxgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZxRO3EAAxgMeta?.name ?? "guides-guideCategories:eu___fr",
    path: indexZxRO3EAAxgMeta?.path ?? "/eu/fr/instructions/:guideCategories",
    extended: true,
    meta: indexZxRO3EAAxgMeta || {},
    alias: indexZxRO3EAAxgMeta?.alias || [],
    redirect: indexZxRO3EAAxgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZxRO3EAAxgMeta?.name ?? "guides-guideCategories:na___it",
    path: indexZxRO3EAAxgMeta?.path ?? "/na/it/istruzioni/:guideCategories",
    extended: true,
    meta: indexZxRO3EAAxgMeta || {},
    alias: indexZxRO3EAAxgMeta?.alias || [],
    redirect: indexZxRO3EAAxgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZxRO3EAAxgMeta?.name ?? "guides-guideCategories:global___it",
    path: indexZxRO3EAAxgMeta?.path ?? "/global/it/istruzioni/:guideCategories",
    extended: true,
    meta: indexZxRO3EAAxgMeta || {},
    alias: indexZxRO3EAAxgMeta?.alias || [],
    redirect: indexZxRO3EAAxgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZxRO3EAAxgMeta?.name ?? "guides-guideCategories:eu___it",
    path: indexZxRO3EAAxgMeta?.path ?? "/eu/it/istruzioni/:guideCategories",
    extended: true,
    meta: indexZxRO3EAAxgMeta || {},
    alias: indexZxRO3EAAxgMeta?.alias || [],
    redirect: indexZxRO3EAAxgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZxRO3EAAxgMeta?.name ?? "guides-guideCategories:na___es",
    path: indexZxRO3EAAxgMeta?.path ?? "/na/es/gu%C3%ADas/:guideCategories",
    extended: true,
    meta: indexZxRO3EAAxgMeta || {},
    alias: indexZxRO3EAAxgMeta?.alias || [],
    redirect: indexZxRO3EAAxgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZxRO3EAAxgMeta?.name ?? "guides-guideCategories:global___es",
    path: indexZxRO3EAAxgMeta?.path ?? "/global/es/gu%C3%ADas/:guideCategories",
    extended: true,
    meta: indexZxRO3EAAxgMeta || {},
    alias: indexZxRO3EAAxgMeta?.alias || [],
    redirect: indexZxRO3EAAxgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZxRO3EAAxgMeta?.name ?? "guides-guideCategories:eu___es",
    path: indexZxRO3EAAxgMeta?.path ?? "/eu/es/gu%C3%ADas/:guideCategories",
    extended: true,
    meta: indexZxRO3EAAxgMeta || {},
    alias: indexZxRO3EAAxgMeta?.alias || [],
    redirect: indexZxRO3EAAxgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/[guideCategories]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrBsmH2y9KMeta?.name ?? "guides",
    path: indexbrBsmH2y9KMeta?.path ?? "/guides",
    extended: true,
    meta: indexbrBsmH2y9KMeta || {},
    alias: indexbrBsmH2y9KMeta?.alias || [],
    redirect: indexbrBsmH2y9KMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrBsmH2y9KMeta?.name ?? "guides:na___en",
    path: indexbrBsmH2y9KMeta?.path ?? "/na/en/guides",
    extended: true,
    meta: indexbrBsmH2y9KMeta || {},
    alias: indexbrBsmH2y9KMeta?.alias || [],
    redirect: indexbrBsmH2y9KMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrBsmH2y9KMeta?.name ?? "guides:global___en",
    path: indexbrBsmH2y9KMeta?.path ?? "/global/en/guides",
    extended: true,
    meta: indexbrBsmH2y9KMeta || {},
    alias: indexbrBsmH2y9KMeta?.alias || [],
    redirect: indexbrBsmH2y9KMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrBsmH2y9KMeta?.name ?? "guides:eu___en",
    path: indexbrBsmH2y9KMeta?.path ?? "/eu/en/guides",
    extended: true,
    meta: indexbrBsmH2y9KMeta || {},
    alias: indexbrBsmH2y9KMeta?.alias || [],
    redirect: indexbrBsmH2y9KMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrBsmH2y9KMeta?.name ?? "guides:na___de",
    path: indexbrBsmH2y9KMeta?.path ?? "/na/de/anleitungen",
    extended: true,
    meta: indexbrBsmH2y9KMeta || {},
    alias: indexbrBsmH2y9KMeta?.alias || [],
    redirect: indexbrBsmH2y9KMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrBsmH2y9KMeta?.name ?? "guides:global___de",
    path: indexbrBsmH2y9KMeta?.path ?? "/global/de/anleitungen",
    extended: true,
    meta: indexbrBsmH2y9KMeta || {},
    alias: indexbrBsmH2y9KMeta?.alias || [],
    redirect: indexbrBsmH2y9KMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrBsmH2y9KMeta?.name ?? "guides:eu___de",
    path: indexbrBsmH2y9KMeta?.path ?? "/eu/de/anleitungen",
    extended: true,
    meta: indexbrBsmH2y9KMeta || {},
    alias: indexbrBsmH2y9KMeta?.alias || [],
    redirect: indexbrBsmH2y9KMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrBsmH2y9KMeta?.name ?? "guides:na___fr",
    path: indexbrBsmH2y9KMeta?.path ?? "/na/fr/instructions",
    extended: true,
    meta: indexbrBsmH2y9KMeta || {},
    alias: indexbrBsmH2y9KMeta?.alias || [],
    redirect: indexbrBsmH2y9KMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrBsmH2y9KMeta?.name ?? "guides:global___fr",
    path: indexbrBsmH2y9KMeta?.path ?? "/global/fr/instructions",
    extended: true,
    meta: indexbrBsmH2y9KMeta || {},
    alias: indexbrBsmH2y9KMeta?.alias || [],
    redirect: indexbrBsmH2y9KMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrBsmH2y9KMeta?.name ?? "guides:eu___fr",
    path: indexbrBsmH2y9KMeta?.path ?? "/eu/fr/instructions",
    extended: true,
    meta: indexbrBsmH2y9KMeta || {},
    alias: indexbrBsmH2y9KMeta?.alias || [],
    redirect: indexbrBsmH2y9KMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrBsmH2y9KMeta?.name ?? "guides:na___it",
    path: indexbrBsmH2y9KMeta?.path ?? "/na/it/istruzioni",
    extended: true,
    meta: indexbrBsmH2y9KMeta || {},
    alias: indexbrBsmH2y9KMeta?.alias || [],
    redirect: indexbrBsmH2y9KMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrBsmH2y9KMeta?.name ?? "guides:global___it",
    path: indexbrBsmH2y9KMeta?.path ?? "/global/it/istruzioni",
    extended: true,
    meta: indexbrBsmH2y9KMeta || {},
    alias: indexbrBsmH2y9KMeta?.alias || [],
    redirect: indexbrBsmH2y9KMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrBsmH2y9KMeta?.name ?? "guides:eu___it",
    path: indexbrBsmH2y9KMeta?.path ?? "/eu/it/istruzioni",
    extended: true,
    meta: indexbrBsmH2y9KMeta || {},
    alias: indexbrBsmH2y9KMeta?.alias || [],
    redirect: indexbrBsmH2y9KMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrBsmH2y9KMeta?.name ?? "guides:na___es",
    path: indexbrBsmH2y9KMeta?.path ?? "/na/es/gu%C3%ADas",
    extended: true,
    meta: indexbrBsmH2y9KMeta || {},
    alias: indexbrBsmH2y9KMeta?.alias || [],
    redirect: indexbrBsmH2y9KMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrBsmH2y9KMeta?.name ?? "guides:global___es",
    path: indexbrBsmH2y9KMeta?.path ?? "/global/es/gu%C3%ADas",
    extended: true,
    meta: indexbrBsmH2y9KMeta || {},
    alias: indexbrBsmH2y9KMeta?.alias || [],
    redirect: indexbrBsmH2y9KMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexbrBsmH2y9KMeta?.name ?? "guides:eu___es",
    path: indexbrBsmH2y9KMeta?.path ?? "/eu/es/gu%C3%ADas",
    extended: true,
    meta: indexbrBsmH2y9KMeta || {},
    alias: indexbrBsmH2y9KMeta?.alias || [],
    redirect: indexbrBsmH2y9KMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexakUrKMxmV5Meta?.name ?? "hydraulic-selector",
    path: indexakUrKMxmV5Meta?.path ?? "/hydraulic-selector",
    extended: true,
    meta: indexakUrKMxmV5Meta || {},
    alias: indexakUrKMxmV5Meta?.alias || [],
    redirect: indexakUrKMxmV5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexakUrKMxmV5Meta?.name ?? "hydraulic-selector:na___en",
    path: indexakUrKMxmV5Meta?.path ?? "/na/en/hydraulic-selector",
    extended: true,
    meta: indexakUrKMxmV5Meta || {},
    alias: indexakUrKMxmV5Meta?.alias || [],
    redirect: indexakUrKMxmV5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexakUrKMxmV5Meta?.name ?? "hydraulic-selector:global___en",
    path: indexakUrKMxmV5Meta?.path ?? "/global/en/hydraulic-selector",
    extended: true,
    meta: indexakUrKMxmV5Meta || {},
    alias: indexakUrKMxmV5Meta?.alias || [],
    redirect: indexakUrKMxmV5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexakUrKMxmV5Meta?.name ?? "hydraulic-selector:eu___en",
    path: indexakUrKMxmV5Meta?.path ?? "/eu/en/hydraulic-selector",
    extended: true,
    meta: indexakUrKMxmV5Meta || {},
    alias: indexakUrKMxmV5Meta?.alias || [],
    redirect: indexakUrKMxmV5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexakUrKMxmV5Meta?.name ?? "hydraulic-selector:na___de",
    path: indexakUrKMxmV5Meta?.path ?? "/na/de/hydraulic-selector",
    extended: true,
    meta: indexakUrKMxmV5Meta || {},
    alias: indexakUrKMxmV5Meta?.alias || [],
    redirect: indexakUrKMxmV5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexakUrKMxmV5Meta?.name ?? "hydraulic-selector:global___de",
    path: indexakUrKMxmV5Meta?.path ?? "/global/de/hydraulic-selector",
    extended: true,
    meta: indexakUrKMxmV5Meta || {},
    alias: indexakUrKMxmV5Meta?.alias || [],
    redirect: indexakUrKMxmV5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexakUrKMxmV5Meta?.name ?? "hydraulic-selector:eu___de",
    path: indexakUrKMxmV5Meta?.path ?? "/eu/de/hydraulic-selector",
    extended: true,
    meta: indexakUrKMxmV5Meta || {},
    alias: indexakUrKMxmV5Meta?.alias || [],
    redirect: indexakUrKMxmV5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexakUrKMxmV5Meta?.name ?? "hydraulic-selector:na___fr",
    path: indexakUrKMxmV5Meta?.path ?? "/na/fr/hydraulic-selector",
    extended: true,
    meta: indexakUrKMxmV5Meta || {},
    alias: indexakUrKMxmV5Meta?.alias || [],
    redirect: indexakUrKMxmV5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexakUrKMxmV5Meta?.name ?? "hydraulic-selector:global___fr",
    path: indexakUrKMxmV5Meta?.path ?? "/global/fr/hydraulic-selector",
    extended: true,
    meta: indexakUrKMxmV5Meta || {},
    alias: indexakUrKMxmV5Meta?.alias || [],
    redirect: indexakUrKMxmV5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexakUrKMxmV5Meta?.name ?? "hydraulic-selector:eu___fr",
    path: indexakUrKMxmV5Meta?.path ?? "/eu/fr/hydraulic-selector",
    extended: true,
    meta: indexakUrKMxmV5Meta || {},
    alias: indexakUrKMxmV5Meta?.alias || [],
    redirect: indexakUrKMxmV5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexakUrKMxmV5Meta?.name ?? "hydraulic-selector:na___it",
    path: indexakUrKMxmV5Meta?.path ?? "/na/it/hydraulic-selector",
    extended: true,
    meta: indexakUrKMxmV5Meta || {},
    alias: indexakUrKMxmV5Meta?.alias || [],
    redirect: indexakUrKMxmV5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexakUrKMxmV5Meta?.name ?? "hydraulic-selector:global___it",
    path: indexakUrKMxmV5Meta?.path ?? "/global/it/hydraulic-selector",
    extended: true,
    meta: indexakUrKMxmV5Meta || {},
    alias: indexakUrKMxmV5Meta?.alias || [],
    redirect: indexakUrKMxmV5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexakUrKMxmV5Meta?.name ?? "hydraulic-selector:eu___it",
    path: indexakUrKMxmV5Meta?.path ?? "/eu/it/hydraulic-selector",
    extended: true,
    meta: indexakUrKMxmV5Meta || {},
    alias: indexakUrKMxmV5Meta?.alias || [],
    redirect: indexakUrKMxmV5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexakUrKMxmV5Meta?.name ?? "hydraulic-selector:na___es",
    path: indexakUrKMxmV5Meta?.path ?? "/na/es/hydraulic-selector",
    extended: true,
    meta: indexakUrKMxmV5Meta || {},
    alias: indexakUrKMxmV5Meta?.alias || [],
    redirect: indexakUrKMxmV5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexakUrKMxmV5Meta?.name ?? "hydraulic-selector:global___es",
    path: indexakUrKMxmV5Meta?.path ?? "/global/es/hydraulic-selector",
    extended: true,
    meta: indexakUrKMxmV5Meta || {},
    alias: indexakUrKMxmV5Meta?.alias || [],
    redirect: indexakUrKMxmV5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexakUrKMxmV5Meta?.name ?? "hydraulic-selector:eu___es",
    path: indexakUrKMxmV5Meta?.path ?? "/eu/es/hydraulic-selector",
    extended: true,
    meta: indexakUrKMxmV5Meta || {},
    alias: indexakUrKMxmV5Meta?.alias || [],
    redirect: indexakUrKMxmV5Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIAEjEelX6Meta?.name ?? "hydraulic-selector-new-construction",
    path: indexAIAEjEelX6Meta?.path ?? "/hydraulic-selector/new-construction",
    extended: true,
    meta: indexAIAEjEelX6Meta || {},
    alias: indexAIAEjEelX6Meta?.alias || [],
    redirect: indexAIAEjEelX6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/new-construction/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIAEjEelX6Meta?.name ?? "hydraulic-selector-new-construction:na___en",
    path: indexAIAEjEelX6Meta?.path ?? "/na/en/hydraulic-selector/new-construction",
    extended: true,
    meta: indexAIAEjEelX6Meta || {},
    alias: indexAIAEjEelX6Meta?.alias || [],
    redirect: indexAIAEjEelX6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/new-construction/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIAEjEelX6Meta?.name ?? "hydraulic-selector-new-construction:global___en",
    path: indexAIAEjEelX6Meta?.path ?? "/global/en/hydraulic-selector/new-construction",
    extended: true,
    meta: indexAIAEjEelX6Meta || {},
    alias: indexAIAEjEelX6Meta?.alias || [],
    redirect: indexAIAEjEelX6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/new-construction/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIAEjEelX6Meta?.name ?? "hydraulic-selector-new-construction:eu___en",
    path: indexAIAEjEelX6Meta?.path ?? "/eu/en/hydraulic-selector/new-construction",
    extended: true,
    meta: indexAIAEjEelX6Meta || {},
    alias: indexAIAEjEelX6Meta?.alias || [],
    redirect: indexAIAEjEelX6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/new-construction/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIAEjEelX6Meta?.name ?? "hydraulic-selector-new-construction:na___de",
    path: indexAIAEjEelX6Meta?.path ?? "/na/de/hydraulic-selector/new-construction",
    extended: true,
    meta: indexAIAEjEelX6Meta || {},
    alias: indexAIAEjEelX6Meta?.alias || [],
    redirect: indexAIAEjEelX6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/new-construction/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIAEjEelX6Meta?.name ?? "hydraulic-selector-new-construction:global___de",
    path: indexAIAEjEelX6Meta?.path ?? "/global/de/hydraulic-selector/new-construction",
    extended: true,
    meta: indexAIAEjEelX6Meta || {},
    alias: indexAIAEjEelX6Meta?.alias || [],
    redirect: indexAIAEjEelX6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/new-construction/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIAEjEelX6Meta?.name ?? "hydraulic-selector-new-construction:eu___de",
    path: indexAIAEjEelX6Meta?.path ?? "/eu/de/hydraulic-selector/new-construction",
    extended: true,
    meta: indexAIAEjEelX6Meta || {},
    alias: indexAIAEjEelX6Meta?.alias || [],
    redirect: indexAIAEjEelX6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/new-construction/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIAEjEelX6Meta?.name ?? "hydraulic-selector-new-construction:na___fr",
    path: indexAIAEjEelX6Meta?.path ?? "/na/fr/hydraulic-selector/new-construction",
    extended: true,
    meta: indexAIAEjEelX6Meta || {},
    alias: indexAIAEjEelX6Meta?.alias || [],
    redirect: indexAIAEjEelX6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/new-construction/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIAEjEelX6Meta?.name ?? "hydraulic-selector-new-construction:global___fr",
    path: indexAIAEjEelX6Meta?.path ?? "/global/fr/hydraulic-selector/new-construction",
    extended: true,
    meta: indexAIAEjEelX6Meta || {},
    alias: indexAIAEjEelX6Meta?.alias || [],
    redirect: indexAIAEjEelX6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/new-construction/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIAEjEelX6Meta?.name ?? "hydraulic-selector-new-construction:eu___fr",
    path: indexAIAEjEelX6Meta?.path ?? "/eu/fr/hydraulic-selector/new-construction",
    extended: true,
    meta: indexAIAEjEelX6Meta || {},
    alias: indexAIAEjEelX6Meta?.alias || [],
    redirect: indexAIAEjEelX6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/new-construction/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIAEjEelX6Meta?.name ?? "hydraulic-selector-new-construction:na___it",
    path: indexAIAEjEelX6Meta?.path ?? "/na/it/hydraulic-selector/new-construction",
    extended: true,
    meta: indexAIAEjEelX6Meta || {},
    alias: indexAIAEjEelX6Meta?.alias || [],
    redirect: indexAIAEjEelX6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/new-construction/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIAEjEelX6Meta?.name ?? "hydraulic-selector-new-construction:global___it",
    path: indexAIAEjEelX6Meta?.path ?? "/global/it/hydraulic-selector/new-construction",
    extended: true,
    meta: indexAIAEjEelX6Meta || {},
    alias: indexAIAEjEelX6Meta?.alias || [],
    redirect: indexAIAEjEelX6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/new-construction/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIAEjEelX6Meta?.name ?? "hydraulic-selector-new-construction:eu___it",
    path: indexAIAEjEelX6Meta?.path ?? "/eu/it/hydraulic-selector/new-construction",
    extended: true,
    meta: indexAIAEjEelX6Meta || {},
    alias: indexAIAEjEelX6Meta?.alias || [],
    redirect: indexAIAEjEelX6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/new-construction/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIAEjEelX6Meta?.name ?? "hydraulic-selector-new-construction:na___es",
    path: indexAIAEjEelX6Meta?.path ?? "/na/es/hydraulic-selector/new-construction",
    extended: true,
    meta: indexAIAEjEelX6Meta || {},
    alias: indexAIAEjEelX6Meta?.alias || [],
    redirect: indexAIAEjEelX6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/new-construction/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIAEjEelX6Meta?.name ?? "hydraulic-selector-new-construction:global___es",
    path: indexAIAEjEelX6Meta?.path ?? "/global/es/hydraulic-selector/new-construction",
    extended: true,
    meta: indexAIAEjEelX6Meta || {},
    alias: indexAIAEjEelX6Meta?.alias || [],
    redirect: indexAIAEjEelX6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/new-construction/index.vue").then(m => m.default || m)
  },
  {
    name: indexAIAEjEelX6Meta?.name ?? "hydraulic-selector-new-construction:eu___es",
    path: indexAIAEjEelX6Meta?.path ?? "/eu/es/hydraulic-selector/new-construction",
    extended: true,
    meta: indexAIAEjEelX6Meta || {},
    alias: indexAIAEjEelX6Meta?.alias || [],
    redirect: indexAIAEjEelX6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/new-construction/index.vue").then(m => m.default || m)
  },
  {
    name: index9gJaQTLVLfMeta?.name ?? "hydraulic-selector-replacement",
    path: index9gJaQTLVLfMeta?.path ?? "/hydraulic-selector/replacement",
    extended: true,
    meta: index9gJaQTLVLfMeta || {},
    alias: index9gJaQTLVLfMeta?.alias || [],
    redirect: index9gJaQTLVLfMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/replacement/index.vue").then(m => m.default || m)
  },
  {
    name: index9gJaQTLVLfMeta?.name ?? "hydraulic-selector-replacement:na___en",
    path: index9gJaQTLVLfMeta?.path ?? "/na/en/hydraulic-selector/replacement",
    extended: true,
    meta: index9gJaQTLVLfMeta || {},
    alias: index9gJaQTLVLfMeta?.alias || [],
    redirect: index9gJaQTLVLfMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/replacement/index.vue").then(m => m.default || m)
  },
  {
    name: index9gJaQTLVLfMeta?.name ?? "hydraulic-selector-replacement:global___en",
    path: index9gJaQTLVLfMeta?.path ?? "/global/en/hydraulic-selector/replacement",
    extended: true,
    meta: index9gJaQTLVLfMeta || {},
    alias: index9gJaQTLVLfMeta?.alias || [],
    redirect: index9gJaQTLVLfMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/replacement/index.vue").then(m => m.default || m)
  },
  {
    name: index9gJaQTLVLfMeta?.name ?? "hydraulic-selector-replacement:eu___en",
    path: index9gJaQTLVLfMeta?.path ?? "/eu/en/hydraulic-selector/replacement",
    extended: true,
    meta: index9gJaQTLVLfMeta || {},
    alias: index9gJaQTLVLfMeta?.alias || [],
    redirect: index9gJaQTLVLfMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/replacement/index.vue").then(m => m.default || m)
  },
  {
    name: index9gJaQTLVLfMeta?.name ?? "hydraulic-selector-replacement:na___de",
    path: index9gJaQTLVLfMeta?.path ?? "/na/de/hydraulic-selector/replacement",
    extended: true,
    meta: index9gJaQTLVLfMeta || {},
    alias: index9gJaQTLVLfMeta?.alias || [],
    redirect: index9gJaQTLVLfMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/replacement/index.vue").then(m => m.default || m)
  },
  {
    name: index9gJaQTLVLfMeta?.name ?? "hydraulic-selector-replacement:global___de",
    path: index9gJaQTLVLfMeta?.path ?? "/global/de/hydraulic-selector/replacement",
    extended: true,
    meta: index9gJaQTLVLfMeta || {},
    alias: index9gJaQTLVLfMeta?.alias || [],
    redirect: index9gJaQTLVLfMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/replacement/index.vue").then(m => m.default || m)
  },
  {
    name: index9gJaQTLVLfMeta?.name ?? "hydraulic-selector-replacement:eu___de",
    path: index9gJaQTLVLfMeta?.path ?? "/eu/de/hydraulic-selector/replacement",
    extended: true,
    meta: index9gJaQTLVLfMeta || {},
    alias: index9gJaQTLVLfMeta?.alias || [],
    redirect: index9gJaQTLVLfMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/replacement/index.vue").then(m => m.default || m)
  },
  {
    name: index9gJaQTLVLfMeta?.name ?? "hydraulic-selector-replacement:na___fr",
    path: index9gJaQTLVLfMeta?.path ?? "/na/fr/hydraulic-selector/replacement",
    extended: true,
    meta: index9gJaQTLVLfMeta || {},
    alias: index9gJaQTLVLfMeta?.alias || [],
    redirect: index9gJaQTLVLfMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/replacement/index.vue").then(m => m.default || m)
  },
  {
    name: index9gJaQTLVLfMeta?.name ?? "hydraulic-selector-replacement:global___fr",
    path: index9gJaQTLVLfMeta?.path ?? "/global/fr/hydraulic-selector/replacement",
    extended: true,
    meta: index9gJaQTLVLfMeta || {},
    alias: index9gJaQTLVLfMeta?.alias || [],
    redirect: index9gJaQTLVLfMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/replacement/index.vue").then(m => m.default || m)
  },
  {
    name: index9gJaQTLVLfMeta?.name ?? "hydraulic-selector-replacement:eu___fr",
    path: index9gJaQTLVLfMeta?.path ?? "/eu/fr/hydraulic-selector/replacement",
    extended: true,
    meta: index9gJaQTLVLfMeta || {},
    alias: index9gJaQTLVLfMeta?.alias || [],
    redirect: index9gJaQTLVLfMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/replacement/index.vue").then(m => m.default || m)
  },
  {
    name: index9gJaQTLVLfMeta?.name ?? "hydraulic-selector-replacement:na___it",
    path: index9gJaQTLVLfMeta?.path ?? "/na/it/hydraulic-selector/replacement",
    extended: true,
    meta: index9gJaQTLVLfMeta || {},
    alias: index9gJaQTLVLfMeta?.alias || [],
    redirect: index9gJaQTLVLfMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/replacement/index.vue").then(m => m.default || m)
  },
  {
    name: index9gJaQTLVLfMeta?.name ?? "hydraulic-selector-replacement:global___it",
    path: index9gJaQTLVLfMeta?.path ?? "/global/it/hydraulic-selector/replacement",
    extended: true,
    meta: index9gJaQTLVLfMeta || {},
    alias: index9gJaQTLVLfMeta?.alias || [],
    redirect: index9gJaQTLVLfMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/replacement/index.vue").then(m => m.default || m)
  },
  {
    name: index9gJaQTLVLfMeta?.name ?? "hydraulic-selector-replacement:eu___it",
    path: index9gJaQTLVLfMeta?.path ?? "/eu/it/hydraulic-selector/replacement",
    extended: true,
    meta: index9gJaQTLVLfMeta || {},
    alias: index9gJaQTLVLfMeta?.alias || [],
    redirect: index9gJaQTLVLfMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/replacement/index.vue").then(m => m.default || m)
  },
  {
    name: index9gJaQTLVLfMeta?.name ?? "hydraulic-selector-replacement:na___es",
    path: index9gJaQTLVLfMeta?.path ?? "/na/es/hydraulic-selector/replacement",
    extended: true,
    meta: index9gJaQTLVLfMeta || {},
    alias: index9gJaQTLVLfMeta?.alias || [],
    redirect: index9gJaQTLVLfMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/replacement/index.vue").then(m => m.default || m)
  },
  {
    name: index9gJaQTLVLfMeta?.name ?? "hydraulic-selector-replacement:global___es",
    path: index9gJaQTLVLfMeta?.path ?? "/global/es/hydraulic-selector/replacement",
    extended: true,
    meta: index9gJaQTLVLfMeta || {},
    alias: index9gJaQTLVLfMeta?.alias || [],
    redirect: index9gJaQTLVLfMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/replacement/index.vue").then(m => m.default || m)
  },
  {
    name: index9gJaQTLVLfMeta?.name ?? "hydraulic-selector-replacement:eu___es",
    path: index9gJaQTLVLfMeta?.path ?? "/eu/es/hydraulic-selector/replacement",
    extended: true,
    meta: index9gJaQTLVLfMeta || {},
    alias: index9gJaQTLVLfMeta?.alias || [],
    redirect: index9gJaQTLVLfMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/hydraulic-selector/replacement/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWhPMsLzMKMeta?.name ?? "index",
    path: indexsWhPMsLzMKMeta?.path ?? "/",
    extended: true,
    meta: indexsWhPMsLzMKMeta || {},
    alias: indexsWhPMsLzMKMeta?.alias || [],
    redirect: indexsWhPMsLzMKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWhPMsLzMKMeta?.name ?? "index:na___en",
    path: indexsWhPMsLzMKMeta?.path ?? "/na/en",
    extended: true,
    meta: indexsWhPMsLzMKMeta || {},
    alias: indexsWhPMsLzMKMeta?.alias || [],
    redirect: indexsWhPMsLzMKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWhPMsLzMKMeta?.name ?? "index:global___en",
    path: indexsWhPMsLzMKMeta?.path ?? "/global/en",
    extended: true,
    meta: indexsWhPMsLzMKMeta || {},
    alias: indexsWhPMsLzMKMeta?.alias || [],
    redirect: indexsWhPMsLzMKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWhPMsLzMKMeta?.name ?? "index:eu___en",
    path: indexsWhPMsLzMKMeta?.path ?? "/eu/en",
    extended: true,
    meta: indexsWhPMsLzMKMeta || {},
    alias: indexsWhPMsLzMKMeta?.alias || [],
    redirect: indexsWhPMsLzMKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWhPMsLzMKMeta?.name ?? "index:na___de",
    path: indexsWhPMsLzMKMeta?.path ?? "/na/de",
    extended: true,
    meta: indexsWhPMsLzMKMeta || {},
    alias: indexsWhPMsLzMKMeta?.alias || [],
    redirect: indexsWhPMsLzMKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWhPMsLzMKMeta?.name ?? "index:global___de",
    path: indexsWhPMsLzMKMeta?.path ?? "/global/de",
    extended: true,
    meta: indexsWhPMsLzMKMeta || {},
    alias: indexsWhPMsLzMKMeta?.alias || [],
    redirect: indexsWhPMsLzMKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWhPMsLzMKMeta?.name ?? "index:eu___de",
    path: indexsWhPMsLzMKMeta?.path ?? "/eu/de",
    extended: true,
    meta: indexsWhPMsLzMKMeta || {},
    alias: indexsWhPMsLzMKMeta?.alias || [],
    redirect: indexsWhPMsLzMKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWhPMsLzMKMeta?.name ?? "index:na___fr",
    path: indexsWhPMsLzMKMeta?.path ?? "/na/fr",
    extended: true,
    meta: indexsWhPMsLzMKMeta || {},
    alias: indexsWhPMsLzMKMeta?.alias || [],
    redirect: indexsWhPMsLzMKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWhPMsLzMKMeta?.name ?? "index:global___fr",
    path: indexsWhPMsLzMKMeta?.path ?? "/global/fr",
    extended: true,
    meta: indexsWhPMsLzMKMeta || {},
    alias: indexsWhPMsLzMKMeta?.alias || [],
    redirect: indexsWhPMsLzMKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWhPMsLzMKMeta?.name ?? "index:eu___fr",
    path: indexsWhPMsLzMKMeta?.path ?? "/eu/fr",
    extended: true,
    meta: indexsWhPMsLzMKMeta || {},
    alias: indexsWhPMsLzMKMeta?.alias || [],
    redirect: indexsWhPMsLzMKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWhPMsLzMKMeta?.name ?? "index:na___it",
    path: indexsWhPMsLzMKMeta?.path ?? "/na/it",
    extended: true,
    meta: indexsWhPMsLzMKMeta || {},
    alias: indexsWhPMsLzMKMeta?.alias || [],
    redirect: indexsWhPMsLzMKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWhPMsLzMKMeta?.name ?? "index:global___it",
    path: indexsWhPMsLzMKMeta?.path ?? "/global/it",
    extended: true,
    meta: indexsWhPMsLzMKMeta || {},
    alias: indexsWhPMsLzMKMeta?.alias || [],
    redirect: indexsWhPMsLzMKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWhPMsLzMKMeta?.name ?? "index:eu___it",
    path: indexsWhPMsLzMKMeta?.path ?? "/eu/it",
    extended: true,
    meta: indexsWhPMsLzMKMeta || {},
    alias: indexsWhPMsLzMKMeta?.alias || [],
    redirect: indexsWhPMsLzMKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWhPMsLzMKMeta?.name ?? "index:na___es",
    path: indexsWhPMsLzMKMeta?.path ?? "/na/es",
    extended: true,
    meta: indexsWhPMsLzMKMeta || {},
    alias: indexsWhPMsLzMKMeta?.alias || [],
    redirect: indexsWhPMsLzMKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWhPMsLzMKMeta?.name ?? "index:global___es",
    path: indexsWhPMsLzMKMeta?.path ?? "/global/es",
    extended: true,
    meta: indexsWhPMsLzMKMeta || {},
    alias: indexsWhPMsLzMKMeta?.alias || [],
    redirect: indexsWhPMsLzMKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWhPMsLzMKMeta?.name ?? "index:eu___es",
    path: indexsWhPMsLzMKMeta?.path ?? "/eu/es",
    extended: true,
    meta: indexsWhPMsLzMKMeta || {},
    alias: indexsWhPMsLzMKMeta?.alias || [],
    redirect: indexsWhPMsLzMKMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: internal_45registrationMcxzzQlNz6Meta?.name ?? "internal-registration",
    path: internal_45registrationMcxzzQlNz6Meta?.path ?? "/internal-registration",
    extended: true,
    meta: internal_45registrationMcxzzQlNz6Meta || {},
    alias: internal_45registrationMcxzzQlNz6Meta?.alias || [],
    redirect: internal_45registrationMcxzzQlNz6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/internal-registration.vue").then(m => m.default || m)
  },
  {
    name: internal_45registrationMcxzzQlNz6Meta?.name ?? "internal-registration:na___en",
    path: internal_45registrationMcxzzQlNz6Meta?.path ?? "/na/en/internal-registration",
    extended: true,
    meta: internal_45registrationMcxzzQlNz6Meta || {},
    alias: internal_45registrationMcxzzQlNz6Meta?.alias || [],
    redirect: internal_45registrationMcxzzQlNz6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/internal-registration.vue").then(m => m.default || m)
  },
  {
    name: internal_45registrationMcxzzQlNz6Meta?.name ?? "internal-registration:global___en",
    path: internal_45registrationMcxzzQlNz6Meta?.path ?? "/global/en/internal-registration",
    extended: true,
    meta: internal_45registrationMcxzzQlNz6Meta || {},
    alias: internal_45registrationMcxzzQlNz6Meta?.alias || [],
    redirect: internal_45registrationMcxzzQlNz6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/internal-registration.vue").then(m => m.default || m)
  },
  {
    name: internal_45registrationMcxzzQlNz6Meta?.name ?? "internal-registration:eu___en",
    path: internal_45registrationMcxzzQlNz6Meta?.path ?? "/eu/en/internal-registration",
    extended: true,
    meta: internal_45registrationMcxzzQlNz6Meta || {},
    alias: internal_45registrationMcxzzQlNz6Meta?.alias || [],
    redirect: internal_45registrationMcxzzQlNz6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/internal-registration.vue").then(m => m.default || m)
  },
  {
    name: internal_45registrationMcxzzQlNz6Meta?.name ?? "internal-registration:na___de",
    path: internal_45registrationMcxzzQlNz6Meta?.path ?? "/na/de/internal-registration",
    extended: true,
    meta: internal_45registrationMcxzzQlNz6Meta || {},
    alias: internal_45registrationMcxzzQlNz6Meta?.alias || [],
    redirect: internal_45registrationMcxzzQlNz6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/internal-registration.vue").then(m => m.default || m)
  },
  {
    name: internal_45registrationMcxzzQlNz6Meta?.name ?? "internal-registration:global___de",
    path: internal_45registrationMcxzzQlNz6Meta?.path ?? "/global/de/internal-registration",
    extended: true,
    meta: internal_45registrationMcxzzQlNz6Meta || {},
    alias: internal_45registrationMcxzzQlNz6Meta?.alias || [],
    redirect: internal_45registrationMcxzzQlNz6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/internal-registration.vue").then(m => m.default || m)
  },
  {
    name: internal_45registrationMcxzzQlNz6Meta?.name ?? "internal-registration:eu___de",
    path: internal_45registrationMcxzzQlNz6Meta?.path ?? "/eu/de/internal-registration",
    extended: true,
    meta: internal_45registrationMcxzzQlNz6Meta || {},
    alias: internal_45registrationMcxzzQlNz6Meta?.alias || [],
    redirect: internal_45registrationMcxzzQlNz6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/internal-registration.vue").then(m => m.default || m)
  },
  {
    name: internal_45registrationMcxzzQlNz6Meta?.name ?? "internal-registration:na___fr",
    path: internal_45registrationMcxzzQlNz6Meta?.path ?? "/na/fr/internal-registration",
    extended: true,
    meta: internal_45registrationMcxzzQlNz6Meta || {},
    alias: internal_45registrationMcxzzQlNz6Meta?.alias || [],
    redirect: internal_45registrationMcxzzQlNz6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/internal-registration.vue").then(m => m.default || m)
  },
  {
    name: internal_45registrationMcxzzQlNz6Meta?.name ?? "internal-registration:global___fr",
    path: internal_45registrationMcxzzQlNz6Meta?.path ?? "/global/fr/internal-registration",
    extended: true,
    meta: internal_45registrationMcxzzQlNz6Meta || {},
    alias: internal_45registrationMcxzzQlNz6Meta?.alias || [],
    redirect: internal_45registrationMcxzzQlNz6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/internal-registration.vue").then(m => m.default || m)
  },
  {
    name: internal_45registrationMcxzzQlNz6Meta?.name ?? "internal-registration:eu___fr",
    path: internal_45registrationMcxzzQlNz6Meta?.path ?? "/eu/fr/internal-registration",
    extended: true,
    meta: internal_45registrationMcxzzQlNz6Meta || {},
    alias: internal_45registrationMcxzzQlNz6Meta?.alias || [],
    redirect: internal_45registrationMcxzzQlNz6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/internal-registration.vue").then(m => m.default || m)
  },
  {
    name: internal_45registrationMcxzzQlNz6Meta?.name ?? "internal-registration:na___it",
    path: internal_45registrationMcxzzQlNz6Meta?.path ?? "/na/it/internal-registration",
    extended: true,
    meta: internal_45registrationMcxzzQlNz6Meta || {},
    alias: internal_45registrationMcxzzQlNz6Meta?.alias || [],
    redirect: internal_45registrationMcxzzQlNz6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/internal-registration.vue").then(m => m.default || m)
  },
  {
    name: internal_45registrationMcxzzQlNz6Meta?.name ?? "internal-registration:global___it",
    path: internal_45registrationMcxzzQlNz6Meta?.path ?? "/global/it/internal-registration",
    extended: true,
    meta: internal_45registrationMcxzzQlNz6Meta || {},
    alias: internal_45registrationMcxzzQlNz6Meta?.alias || [],
    redirect: internal_45registrationMcxzzQlNz6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/internal-registration.vue").then(m => m.default || m)
  },
  {
    name: internal_45registrationMcxzzQlNz6Meta?.name ?? "internal-registration:eu___it",
    path: internal_45registrationMcxzzQlNz6Meta?.path ?? "/eu/it/internal-registration",
    extended: true,
    meta: internal_45registrationMcxzzQlNz6Meta || {},
    alias: internal_45registrationMcxzzQlNz6Meta?.alias || [],
    redirect: internal_45registrationMcxzzQlNz6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/internal-registration.vue").then(m => m.default || m)
  },
  {
    name: internal_45registrationMcxzzQlNz6Meta?.name ?? "internal-registration:na___es",
    path: internal_45registrationMcxzzQlNz6Meta?.path ?? "/na/es/internal-registration",
    extended: true,
    meta: internal_45registrationMcxzzQlNz6Meta || {},
    alias: internal_45registrationMcxzzQlNz6Meta?.alias || [],
    redirect: internal_45registrationMcxzzQlNz6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/internal-registration.vue").then(m => m.default || m)
  },
  {
    name: internal_45registrationMcxzzQlNz6Meta?.name ?? "internal-registration:global___es",
    path: internal_45registrationMcxzzQlNz6Meta?.path ?? "/global/es/internal-registration",
    extended: true,
    meta: internal_45registrationMcxzzQlNz6Meta || {},
    alias: internal_45registrationMcxzzQlNz6Meta?.alias || [],
    redirect: internal_45registrationMcxzzQlNz6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/internal-registration.vue").then(m => m.default || m)
  },
  {
    name: internal_45registrationMcxzzQlNz6Meta?.name ?? "internal-registration:eu___es",
    path: internal_45registrationMcxzzQlNz6Meta?.path ?? "/eu/es/internal-registration",
    extended: true,
    meta: internal_45registrationMcxzzQlNz6Meta || {},
    alias: internal_45registrationMcxzzQlNz6Meta?.alias || [],
    redirect: internal_45registrationMcxzzQlNz6Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/internal-registration.vue").then(m => m.default || m)
  },
  {
    name: _91management_93J0zfUadgIzMeta?.name ?? "management-management",
    path: _91management_93J0zfUadgIzMeta?.path ?? "/management/:management()",
    extended: true,
    meta: _91management_93J0zfUadgIzMeta || {},
    alias: _91management_93J0zfUadgIzMeta?.alias || [],
    redirect: _91management_93J0zfUadgIzMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/[management].vue").then(m => m.default || m)
  },
  {
    name: _91management_93J0zfUadgIzMeta?.name ?? "management-management:na___en",
    path: _91management_93J0zfUadgIzMeta?.path ?? "/na/en/management/:management",
    extended: true,
    meta: _91management_93J0zfUadgIzMeta || {},
    alias: _91management_93J0zfUadgIzMeta?.alias || [],
    redirect: _91management_93J0zfUadgIzMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/[management].vue").then(m => m.default || m)
  },
  {
    name: _91management_93J0zfUadgIzMeta?.name ?? "management-management:global___en",
    path: _91management_93J0zfUadgIzMeta?.path ?? "/global/en/management/:management",
    extended: true,
    meta: _91management_93J0zfUadgIzMeta || {},
    alias: _91management_93J0zfUadgIzMeta?.alias || [],
    redirect: _91management_93J0zfUadgIzMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/[management].vue").then(m => m.default || m)
  },
  {
    name: _91management_93J0zfUadgIzMeta?.name ?? "management-management:eu___en",
    path: _91management_93J0zfUadgIzMeta?.path ?? "/eu/en/management/:management",
    extended: true,
    meta: _91management_93J0zfUadgIzMeta || {},
    alias: _91management_93J0zfUadgIzMeta?.alias || [],
    redirect: _91management_93J0zfUadgIzMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/[management].vue").then(m => m.default || m)
  },
  {
    name: _91management_93J0zfUadgIzMeta?.name ?? "management-management:na___de",
    path: _91management_93J0zfUadgIzMeta?.path ?? "/na/de/verwaltung/:management",
    extended: true,
    meta: _91management_93J0zfUadgIzMeta || {},
    alias: _91management_93J0zfUadgIzMeta?.alias || [],
    redirect: _91management_93J0zfUadgIzMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/[management].vue").then(m => m.default || m)
  },
  {
    name: _91management_93J0zfUadgIzMeta?.name ?? "management-management:global___de",
    path: _91management_93J0zfUadgIzMeta?.path ?? "/global/de/verwaltung/:management",
    extended: true,
    meta: _91management_93J0zfUadgIzMeta || {},
    alias: _91management_93J0zfUadgIzMeta?.alias || [],
    redirect: _91management_93J0zfUadgIzMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/[management].vue").then(m => m.default || m)
  },
  {
    name: _91management_93J0zfUadgIzMeta?.name ?? "management-management:eu___de",
    path: _91management_93J0zfUadgIzMeta?.path ?? "/eu/de/verwaltung/:management",
    extended: true,
    meta: _91management_93J0zfUadgIzMeta || {},
    alias: _91management_93J0zfUadgIzMeta?.alias || [],
    redirect: _91management_93J0zfUadgIzMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/[management].vue").then(m => m.default || m)
  },
  {
    name: _91management_93J0zfUadgIzMeta?.name ?? "management-management:na___fr",
    path: _91management_93J0zfUadgIzMeta?.path ?? "/na/fr/administration/:management",
    extended: true,
    meta: _91management_93J0zfUadgIzMeta || {},
    alias: _91management_93J0zfUadgIzMeta?.alias || [],
    redirect: _91management_93J0zfUadgIzMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/[management].vue").then(m => m.default || m)
  },
  {
    name: _91management_93J0zfUadgIzMeta?.name ?? "management-management:global___fr",
    path: _91management_93J0zfUadgIzMeta?.path ?? "/global/fr/administration/:management",
    extended: true,
    meta: _91management_93J0zfUadgIzMeta || {},
    alias: _91management_93J0zfUadgIzMeta?.alias || [],
    redirect: _91management_93J0zfUadgIzMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/[management].vue").then(m => m.default || m)
  },
  {
    name: _91management_93J0zfUadgIzMeta?.name ?? "management-management:eu___fr",
    path: _91management_93J0zfUadgIzMeta?.path ?? "/eu/fr/administration/:management",
    extended: true,
    meta: _91management_93J0zfUadgIzMeta || {},
    alias: _91management_93J0zfUadgIzMeta?.alias || [],
    redirect: _91management_93J0zfUadgIzMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/[management].vue").then(m => m.default || m)
  },
  {
    name: _91management_93J0zfUadgIzMeta?.name ?? "management-management:na___it",
    path: _91management_93J0zfUadgIzMeta?.path ?? "/na/it/gestione/:management",
    extended: true,
    meta: _91management_93J0zfUadgIzMeta || {},
    alias: _91management_93J0zfUadgIzMeta?.alias || [],
    redirect: _91management_93J0zfUadgIzMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/[management].vue").then(m => m.default || m)
  },
  {
    name: _91management_93J0zfUadgIzMeta?.name ?? "management-management:global___it",
    path: _91management_93J0zfUadgIzMeta?.path ?? "/global/it/gestione/:management",
    extended: true,
    meta: _91management_93J0zfUadgIzMeta || {},
    alias: _91management_93J0zfUadgIzMeta?.alias || [],
    redirect: _91management_93J0zfUadgIzMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/[management].vue").then(m => m.default || m)
  },
  {
    name: _91management_93J0zfUadgIzMeta?.name ?? "management-management:eu___it",
    path: _91management_93J0zfUadgIzMeta?.path ?? "/eu/it/gestione/:management",
    extended: true,
    meta: _91management_93J0zfUadgIzMeta || {},
    alias: _91management_93J0zfUadgIzMeta?.alias || [],
    redirect: _91management_93J0zfUadgIzMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/[management].vue").then(m => m.default || m)
  },
  {
    name: _91management_93J0zfUadgIzMeta?.name ?? "management-management:na___es",
    path: _91management_93J0zfUadgIzMeta?.path ?? "/na/es/administraci%C3%B3n/:management",
    extended: true,
    meta: _91management_93J0zfUadgIzMeta || {},
    alias: _91management_93J0zfUadgIzMeta?.alias || [],
    redirect: _91management_93J0zfUadgIzMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/[management].vue").then(m => m.default || m)
  },
  {
    name: _91management_93J0zfUadgIzMeta?.name ?? "management-management:global___es",
    path: _91management_93J0zfUadgIzMeta?.path ?? "/global/es/administraci%C3%B3n/:management",
    extended: true,
    meta: _91management_93J0zfUadgIzMeta || {},
    alias: _91management_93J0zfUadgIzMeta?.alias || [],
    redirect: _91management_93J0zfUadgIzMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/[management].vue").then(m => m.default || m)
  },
  {
    name: _91management_93J0zfUadgIzMeta?.name ?? "management-management:eu___es",
    path: _91management_93J0zfUadgIzMeta?.path ?? "/eu/es/administraci%C3%B3n/:management",
    extended: true,
    meta: _91management_93J0zfUadgIzMeta || {},
    alias: _91management_93J0zfUadgIzMeta?.alias || [],
    redirect: _91management_93J0zfUadgIzMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/[management].vue").then(m => m.default || m)
  },
  {
    name: indexRfVItVttNeMeta?.name ?? "management",
    path: indexRfVItVttNeMeta?.path ?? "/management",
    extended: true,
    meta: indexRfVItVttNeMeta || {},
    alias: indexRfVItVttNeMeta?.alias || [],
    redirect: indexRfVItVttNeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: indexRfVItVttNeMeta?.name ?? "management:na___en",
    path: indexRfVItVttNeMeta?.path ?? "/na/en/management",
    extended: true,
    meta: indexRfVItVttNeMeta || {},
    alias: indexRfVItVttNeMeta?.alias || [],
    redirect: indexRfVItVttNeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: indexRfVItVttNeMeta?.name ?? "management:global___en",
    path: indexRfVItVttNeMeta?.path ?? "/global/en/management",
    extended: true,
    meta: indexRfVItVttNeMeta || {},
    alias: indexRfVItVttNeMeta?.alias || [],
    redirect: indexRfVItVttNeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: indexRfVItVttNeMeta?.name ?? "management:eu___en",
    path: indexRfVItVttNeMeta?.path ?? "/eu/en/management",
    extended: true,
    meta: indexRfVItVttNeMeta || {},
    alias: indexRfVItVttNeMeta?.alias || [],
    redirect: indexRfVItVttNeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: indexRfVItVttNeMeta?.name ?? "management:na___de",
    path: indexRfVItVttNeMeta?.path ?? "/na/de/verwaltung",
    extended: true,
    meta: indexRfVItVttNeMeta || {},
    alias: indexRfVItVttNeMeta?.alias || [],
    redirect: indexRfVItVttNeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: indexRfVItVttNeMeta?.name ?? "management:global___de",
    path: indexRfVItVttNeMeta?.path ?? "/global/de/verwaltung",
    extended: true,
    meta: indexRfVItVttNeMeta || {},
    alias: indexRfVItVttNeMeta?.alias || [],
    redirect: indexRfVItVttNeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: indexRfVItVttNeMeta?.name ?? "management:eu___de",
    path: indexRfVItVttNeMeta?.path ?? "/eu/de/verwaltung",
    extended: true,
    meta: indexRfVItVttNeMeta || {},
    alias: indexRfVItVttNeMeta?.alias || [],
    redirect: indexRfVItVttNeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: indexRfVItVttNeMeta?.name ?? "management:na___fr",
    path: indexRfVItVttNeMeta?.path ?? "/na/fr/administration",
    extended: true,
    meta: indexRfVItVttNeMeta || {},
    alias: indexRfVItVttNeMeta?.alias || [],
    redirect: indexRfVItVttNeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: indexRfVItVttNeMeta?.name ?? "management:global___fr",
    path: indexRfVItVttNeMeta?.path ?? "/global/fr/administration",
    extended: true,
    meta: indexRfVItVttNeMeta || {},
    alias: indexRfVItVttNeMeta?.alias || [],
    redirect: indexRfVItVttNeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: indexRfVItVttNeMeta?.name ?? "management:eu___fr",
    path: indexRfVItVttNeMeta?.path ?? "/eu/fr/administration",
    extended: true,
    meta: indexRfVItVttNeMeta || {},
    alias: indexRfVItVttNeMeta?.alias || [],
    redirect: indexRfVItVttNeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: indexRfVItVttNeMeta?.name ?? "management:na___it",
    path: indexRfVItVttNeMeta?.path ?? "/na/it/gestione",
    extended: true,
    meta: indexRfVItVttNeMeta || {},
    alias: indexRfVItVttNeMeta?.alias || [],
    redirect: indexRfVItVttNeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: indexRfVItVttNeMeta?.name ?? "management:global___it",
    path: indexRfVItVttNeMeta?.path ?? "/global/it/gestione",
    extended: true,
    meta: indexRfVItVttNeMeta || {},
    alias: indexRfVItVttNeMeta?.alias || [],
    redirect: indexRfVItVttNeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: indexRfVItVttNeMeta?.name ?? "management:eu___it",
    path: indexRfVItVttNeMeta?.path ?? "/eu/it/gestione",
    extended: true,
    meta: indexRfVItVttNeMeta || {},
    alias: indexRfVItVttNeMeta?.alias || [],
    redirect: indexRfVItVttNeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: indexRfVItVttNeMeta?.name ?? "management:na___es",
    path: indexRfVItVttNeMeta?.path ?? "/na/es/administraci%C3%B3n",
    extended: true,
    meta: indexRfVItVttNeMeta || {},
    alias: indexRfVItVttNeMeta?.alias || [],
    redirect: indexRfVItVttNeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: indexRfVItVttNeMeta?.name ?? "management:global___es",
    path: indexRfVItVttNeMeta?.path ?? "/global/es/administraci%C3%B3n",
    extended: true,
    meta: indexRfVItVttNeMeta || {},
    alias: indexRfVItVttNeMeta?.alias || [],
    redirect: indexRfVItVttNeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: indexRfVItVttNeMeta?.name ?? "management:eu___es",
    path: indexRfVItVttNeMeta?.path ?? "/eu/es/administraci%C3%B3n",
    extended: true,
    meta: indexRfVItVttNeMeta || {},
    alias: indexRfVItVttNeMeta?.alias || [],
    redirect: indexRfVItVttNeMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/management/index.vue").then(m => m.default || m)
  },
  {
    name: _91newsDetail_93qxwtIbxfV1Meta?.name ?? "news-newsDetail",
    path: _91newsDetail_93qxwtIbxfV1Meta?.path ?? "/news/:newsDetail()",
    extended: true,
    meta: _91newsDetail_93qxwtIbxfV1Meta || {},
    alias: _91newsDetail_93qxwtIbxfV1Meta?.alias || [],
    redirect: _91newsDetail_93qxwtIbxfV1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/[newsDetail].vue").then(m => m.default || m)
  },
  {
    name: _91newsDetail_93qxwtIbxfV1Meta?.name ?? "news-newsDetail:na___en",
    path: _91newsDetail_93qxwtIbxfV1Meta?.path ?? "/na/en/news/:newsDetail",
    extended: true,
    meta: _91newsDetail_93qxwtIbxfV1Meta || {},
    alias: _91newsDetail_93qxwtIbxfV1Meta?.alias || [],
    redirect: _91newsDetail_93qxwtIbxfV1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/[newsDetail].vue").then(m => m.default || m)
  },
  {
    name: _91newsDetail_93qxwtIbxfV1Meta?.name ?? "news-newsDetail:global___en",
    path: _91newsDetail_93qxwtIbxfV1Meta?.path ?? "/global/en/news/:newsDetail",
    extended: true,
    meta: _91newsDetail_93qxwtIbxfV1Meta || {},
    alias: _91newsDetail_93qxwtIbxfV1Meta?.alias || [],
    redirect: _91newsDetail_93qxwtIbxfV1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/[newsDetail].vue").then(m => m.default || m)
  },
  {
    name: _91newsDetail_93qxwtIbxfV1Meta?.name ?? "news-newsDetail:eu___en",
    path: _91newsDetail_93qxwtIbxfV1Meta?.path ?? "/eu/en/news/:newsDetail",
    extended: true,
    meta: _91newsDetail_93qxwtIbxfV1Meta || {},
    alias: _91newsDetail_93qxwtIbxfV1Meta?.alias || [],
    redirect: _91newsDetail_93qxwtIbxfV1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/[newsDetail].vue").then(m => m.default || m)
  },
  {
    name: _91newsDetail_93qxwtIbxfV1Meta?.name ?? "news-newsDetail:na___de",
    path: _91newsDetail_93qxwtIbxfV1Meta?.path ?? "/na/de/nachrichten/:newsDetail",
    extended: true,
    meta: _91newsDetail_93qxwtIbxfV1Meta || {},
    alias: _91newsDetail_93qxwtIbxfV1Meta?.alias || [],
    redirect: _91newsDetail_93qxwtIbxfV1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/[newsDetail].vue").then(m => m.default || m)
  },
  {
    name: _91newsDetail_93qxwtIbxfV1Meta?.name ?? "news-newsDetail:global___de",
    path: _91newsDetail_93qxwtIbxfV1Meta?.path ?? "/global/de/nachrichten/:newsDetail",
    extended: true,
    meta: _91newsDetail_93qxwtIbxfV1Meta || {},
    alias: _91newsDetail_93qxwtIbxfV1Meta?.alias || [],
    redirect: _91newsDetail_93qxwtIbxfV1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/[newsDetail].vue").then(m => m.default || m)
  },
  {
    name: _91newsDetail_93qxwtIbxfV1Meta?.name ?? "news-newsDetail:eu___de",
    path: _91newsDetail_93qxwtIbxfV1Meta?.path ?? "/eu/de/nachrichten/:newsDetail",
    extended: true,
    meta: _91newsDetail_93qxwtIbxfV1Meta || {},
    alias: _91newsDetail_93qxwtIbxfV1Meta?.alias || [],
    redirect: _91newsDetail_93qxwtIbxfV1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/[newsDetail].vue").then(m => m.default || m)
  },
  {
    name: _91newsDetail_93qxwtIbxfV1Meta?.name ?? "news-newsDetail:na___fr",
    path: _91newsDetail_93qxwtIbxfV1Meta?.path ?? "/na/fr/actualit%C3%A9s/:newsDetail",
    extended: true,
    meta: _91newsDetail_93qxwtIbxfV1Meta || {},
    alias: _91newsDetail_93qxwtIbxfV1Meta?.alias || [],
    redirect: _91newsDetail_93qxwtIbxfV1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/[newsDetail].vue").then(m => m.default || m)
  },
  {
    name: _91newsDetail_93qxwtIbxfV1Meta?.name ?? "news-newsDetail:global___fr",
    path: _91newsDetail_93qxwtIbxfV1Meta?.path ?? "/global/fr/actualit%C3%A9s/:newsDetail",
    extended: true,
    meta: _91newsDetail_93qxwtIbxfV1Meta || {},
    alias: _91newsDetail_93qxwtIbxfV1Meta?.alias || [],
    redirect: _91newsDetail_93qxwtIbxfV1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/[newsDetail].vue").then(m => m.default || m)
  },
  {
    name: _91newsDetail_93qxwtIbxfV1Meta?.name ?? "news-newsDetail:eu___fr",
    path: _91newsDetail_93qxwtIbxfV1Meta?.path ?? "/eu/fr/actualit%C3%A9s/:newsDetail",
    extended: true,
    meta: _91newsDetail_93qxwtIbxfV1Meta || {},
    alias: _91newsDetail_93qxwtIbxfV1Meta?.alias || [],
    redirect: _91newsDetail_93qxwtIbxfV1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/[newsDetail].vue").then(m => m.default || m)
  },
  {
    name: _91newsDetail_93qxwtIbxfV1Meta?.name ?? "news-newsDetail:na___it",
    path: _91newsDetail_93qxwtIbxfV1Meta?.path ?? "/na/it/novit%C3%A0/:newsDetail",
    extended: true,
    meta: _91newsDetail_93qxwtIbxfV1Meta || {},
    alias: _91newsDetail_93qxwtIbxfV1Meta?.alias || [],
    redirect: _91newsDetail_93qxwtIbxfV1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/[newsDetail].vue").then(m => m.default || m)
  },
  {
    name: _91newsDetail_93qxwtIbxfV1Meta?.name ?? "news-newsDetail:global___it",
    path: _91newsDetail_93qxwtIbxfV1Meta?.path ?? "/global/it/novit%C3%A0/:newsDetail",
    extended: true,
    meta: _91newsDetail_93qxwtIbxfV1Meta || {},
    alias: _91newsDetail_93qxwtIbxfV1Meta?.alias || [],
    redirect: _91newsDetail_93qxwtIbxfV1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/[newsDetail].vue").then(m => m.default || m)
  },
  {
    name: _91newsDetail_93qxwtIbxfV1Meta?.name ?? "news-newsDetail:eu___it",
    path: _91newsDetail_93qxwtIbxfV1Meta?.path ?? "/eu/it/novit%C3%A0/:newsDetail",
    extended: true,
    meta: _91newsDetail_93qxwtIbxfV1Meta || {},
    alias: _91newsDetail_93qxwtIbxfV1Meta?.alias || [],
    redirect: _91newsDetail_93qxwtIbxfV1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/[newsDetail].vue").then(m => m.default || m)
  },
  {
    name: _91newsDetail_93qxwtIbxfV1Meta?.name ?? "news-newsDetail:na___es",
    path: _91newsDetail_93qxwtIbxfV1Meta?.path ?? "/na/es/novedades/:newsDetail",
    extended: true,
    meta: _91newsDetail_93qxwtIbxfV1Meta || {},
    alias: _91newsDetail_93qxwtIbxfV1Meta?.alias || [],
    redirect: _91newsDetail_93qxwtIbxfV1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/[newsDetail].vue").then(m => m.default || m)
  },
  {
    name: _91newsDetail_93qxwtIbxfV1Meta?.name ?? "news-newsDetail:global___es",
    path: _91newsDetail_93qxwtIbxfV1Meta?.path ?? "/global/es/novedades/:newsDetail",
    extended: true,
    meta: _91newsDetail_93qxwtIbxfV1Meta || {},
    alias: _91newsDetail_93qxwtIbxfV1Meta?.alias || [],
    redirect: _91newsDetail_93qxwtIbxfV1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/[newsDetail].vue").then(m => m.default || m)
  },
  {
    name: _91newsDetail_93qxwtIbxfV1Meta?.name ?? "news-newsDetail:eu___es",
    path: _91newsDetail_93qxwtIbxfV1Meta?.path ?? "/eu/es/novedades/:newsDetail",
    extended: true,
    meta: _91newsDetail_93qxwtIbxfV1Meta || {},
    alias: _91newsDetail_93qxwtIbxfV1Meta?.alias || [],
    redirect: _91newsDetail_93qxwtIbxfV1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/[newsDetail].vue").then(m => m.default || m)
  },
  {
    name: indexYY0TzDVdGsMeta?.name ?? "news",
    path: indexYY0TzDVdGsMeta?.path ?? "/news",
    extended: true,
    meta: indexYY0TzDVdGsMeta || {},
    alias: indexYY0TzDVdGsMeta?.alias || [],
    redirect: indexYY0TzDVdGsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYY0TzDVdGsMeta?.name ?? "news:na___en",
    path: indexYY0TzDVdGsMeta?.path ?? "/na/en/news",
    extended: true,
    meta: indexYY0TzDVdGsMeta || {},
    alias: indexYY0TzDVdGsMeta?.alias || [],
    redirect: indexYY0TzDVdGsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYY0TzDVdGsMeta?.name ?? "news:global___en",
    path: indexYY0TzDVdGsMeta?.path ?? "/global/en/news",
    extended: true,
    meta: indexYY0TzDVdGsMeta || {},
    alias: indexYY0TzDVdGsMeta?.alias || [],
    redirect: indexYY0TzDVdGsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYY0TzDVdGsMeta?.name ?? "news:eu___en",
    path: indexYY0TzDVdGsMeta?.path ?? "/eu/en/news",
    extended: true,
    meta: indexYY0TzDVdGsMeta || {},
    alias: indexYY0TzDVdGsMeta?.alias || [],
    redirect: indexYY0TzDVdGsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYY0TzDVdGsMeta?.name ?? "news:na___de",
    path: indexYY0TzDVdGsMeta?.path ?? "/na/de/nachrichten",
    extended: true,
    meta: indexYY0TzDVdGsMeta || {},
    alias: indexYY0TzDVdGsMeta?.alias || [],
    redirect: indexYY0TzDVdGsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYY0TzDVdGsMeta?.name ?? "news:global___de",
    path: indexYY0TzDVdGsMeta?.path ?? "/global/de/nachrichten",
    extended: true,
    meta: indexYY0TzDVdGsMeta || {},
    alias: indexYY0TzDVdGsMeta?.alias || [],
    redirect: indexYY0TzDVdGsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYY0TzDVdGsMeta?.name ?? "news:eu___de",
    path: indexYY0TzDVdGsMeta?.path ?? "/eu/de/nachrichten",
    extended: true,
    meta: indexYY0TzDVdGsMeta || {},
    alias: indexYY0TzDVdGsMeta?.alias || [],
    redirect: indexYY0TzDVdGsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYY0TzDVdGsMeta?.name ?? "news:na___fr",
    path: indexYY0TzDVdGsMeta?.path ?? "/na/fr/actualit%C3%A9s",
    extended: true,
    meta: indexYY0TzDVdGsMeta || {},
    alias: indexYY0TzDVdGsMeta?.alias || [],
    redirect: indexYY0TzDVdGsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYY0TzDVdGsMeta?.name ?? "news:global___fr",
    path: indexYY0TzDVdGsMeta?.path ?? "/global/fr/actualit%C3%A9s",
    extended: true,
    meta: indexYY0TzDVdGsMeta || {},
    alias: indexYY0TzDVdGsMeta?.alias || [],
    redirect: indexYY0TzDVdGsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYY0TzDVdGsMeta?.name ?? "news:eu___fr",
    path: indexYY0TzDVdGsMeta?.path ?? "/eu/fr/actualit%C3%A9s",
    extended: true,
    meta: indexYY0TzDVdGsMeta || {},
    alias: indexYY0TzDVdGsMeta?.alias || [],
    redirect: indexYY0TzDVdGsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYY0TzDVdGsMeta?.name ?? "news:na___it",
    path: indexYY0TzDVdGsMeta?.path ?? "/na/it/novit%C3%A0",
    extended: true,
    meta: indexYY0TzDVdGsMeta || {},
    alias: indexYY0TzDVdGsMeta?.alias || [],
    redirect: indexYY0TzDVdGsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYY0TzDVdGsMeta?.name ?? "news:global___it",
    path: indexYY0TzDVdGsMeta?.path ?? "/global/it/novit%C3%A0",
    extended: true,
    meta: indexYY0TzDVdGsMeta || {},
    alias: indexYY0TzDVdGsMeta?.alias || [],
    redirect: indexYY0TzDVdGsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYY0TzDVdGsMeta?.name ?? "news:eu___it",
    path: indexYY0TzDVdGsMeta?.path ?? "/eu/it/novit%C3%A0",
    extended: true,
    meta: indexYY0TzDVdGsMeta || {},
    alias: indexYY0TzDVdGsMeta?.alias || [],
    redirect: indexYY0TzDVdGsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYY0TzDVdGsMeta?.name ?? "news:na___es",
    path: indexYY0TzDVdGsMeta?.path ?? "/na/es/novedades",
    extended: true,
    meta: indexYY0TzDVdGsMeta || {},
    alias: indexYY0TzDVdGsMeta?.alias || [],
    redirect: indexYY0TzDVdGsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYY0TzDVdGsMeta?.name ?? "news:global___es",
    path: indexYY0TzDVdGsMeta?.path ?? "/global/es/novedades",
    extended: true,
    meta: indexYY0TzDVdGsMeta || {},
    alias: indexYY0TzDVdGsMeta?.alias || [],
    redirect: indexYY0TzDVdGsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexYY0TzDVdGsMeta?.name ?? "news:eu___es",
    path: indexYY0TzDVdGsMeta?.path ?? "/eu/es/novedades",
    extended: true,
    meta: indexYY0TzDVdGsMeta || {},
    alias: indexYY0TzDVdGsMeta?.alias || [],
    redirect: indexYY0TzDVdGsMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: index6AY1IaCh4mMeta?.name ?? "orderHistory",
    path: index6AY1IaCh4mMeta?.path ?? "/orderHistory",
    extended: true,
    meta: index6AY1IaCh4mMeta || {},
    alias: index6AY1IaCh4mMeta?.alias || [],
    redirect: index6AY1IaCh4mMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/orderHistory/index.vue").then(m => m.default || m)
  },
  {
    name: index6AY1IaCh4mMeta?.name ?? "orderHistory:na___en",
    path: index6AY1IaCh4mMeta?.path ?? "/na/en/order-history",
    extended: true,
    meta: index6AY1IaCh4mMeta || {},
    alias: index6AY1IaCh4mMeta?.alias || [],
    redirect: index6AY1IaCh4mMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/orderHistory/index.vue").then(m => m.default || m)
  },
  {
    name: index6AY1IaCh4mMeta?.name ?? "orderHistory:global___en",
    path: index6AY1IaCh4mMeta?.path ?? "/global/en/order-history",
    extended: true,
    meta: index6AY1IaCh4mMeta || {},
    alias: index6AY1IaCh4mMeta?.alias || [],
    redirect: index6AY1IaCh4mMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/orderHistory/index.vue").then(m => m.default || m)
  },
  {
    name: index6AY1IaCh4mMeta?.name ?? "orderHistory:eu___en",
    path: index6AY1IaCh4mMeta?.path ?? "/eu/en/order-history",
    extended: true,
    meta: index6AY1IaCh4mMeta || {},
    alias: index6AY1IaCh4mMeta?.alias || [],
    redirect: index6AY1IaCh4mMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/orderHistory/index.vue").then(m => m.default || m)
  },
  {
    name: index6AY1IaCh4mMeta?.name ?? "orderHistory:na___de",
    path: index6AY1IaCh4mMeta?.path ?? "/na/de/bestellhistorie",
    extended: true,
    meta: index6AY1IaCh4mMeta || {},
    alias: index6AY1IaCh4mMeta?.alias || [],
    redirect: index6AY1IaCh4mMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/orderHistory/index.vue").then(m => m.default || m)
  },
  {
    name: index6AY1IaCh4mMeta?.name ?? "orderHistory:global___de",
    path: index6AY1IaCh4mMeta?.path ?? "/global/de/bestellhistorie",
    extended: true,
    meta: index6AY1IaCh4mMeta || {},
    alias: index6AY1IaCh4mMeta?.alias || [],
    redirect: index6AY1IaCh4mMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/orderHistory/index.vue").then(m => m.default || m)
  },
  {
    name: index6AY1IaCh4mMeta?.name ?? "orderHistory:eu___de",
    path: index6AY1IaCh4mMeta?.path ?? "/eu/de/bestellhistorie",
    extended: true,
    meta: index6AY1IaCh4mMeta || {},
    alias: index6AY1IaCh4mMeta?.alias || [],
    redirect: index6AY1IaCh4mMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/orderHistory/index.vue").then(m => m.default || m)
  },
  {
    name: index6AY1IaCh4mMeta?.name ?? "orderHistory:na___fr",
    path: index6AY1IaCh4mMeta?.path ?? "/na/fr/historique-commande",
    extended: true,
    meta: index6AY1IaCh4mMeta || {},
    alias: index6AY1IaCh4mMeta?.alias || [],
    redirect: index6AY1IaCh4mMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/orderHistory/index.vue").then(m => m.default || m)
  },
  {
    name: index6AY1IaCh4mMeta?.name ?? "orderHistory:global___fr",
    path: index6AY1IaCh4mMeta?.path ?? "/global/fr/historique-commande",
    extended: true,
    meta: index6AY1IaCh4mMeta || {},
    alias: index6AY1IaCh4mMeta?.alias || [],
    redirect: index6AY1IaCh4mMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/orderHistory/index.vue").then(m => m.default || m)
  },
  {
    name: index6AY1IaCh4mMeta?.name ?? "orderHistory:eu___fr",
    path: index6AY1IaCh4mMeta?.path ?? "/eu/fr/historique-commande",
    extended: true,
    meta: index6AY1IaCh4mMeta || {},
    alias: index6AY1IaCh4mMeta?.alias || [],
    redirect: index6AY1IaCh4mMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/orderHistory/index.vue").then(m => m.default || m)
  },
  {
    name: index6AY1IaCh4mMeta?.name ?? "orderHistory:na___it",
    path: index6AY1IaCh4mMeta?.path ?? "/na/it/cronologia-ordini",
    extended: true,
    meta: index6AY1IaCh4mMeta || {},
    alias: index6AY1IaCh4mMeta?.alias || [],
    redirect: index6AY1IaCh4mMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/orderHistory/index.vue").then(m => m.default || m)
  },
  {
    name: index6AY1IaCh4mMeta?.name ?? "orderHistory:global___it",
    path: index6AY1IaCh4mMeta?.path ?? "/global/it/cronologia-ordini",
    extended: true,
    meta: index6AY1IaCh4mMeta || {},
    alias: index6AY1IaCh4mMeta?.alias || [],
    redirect: index6AY1IaCh4mMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/orderHistory/index.vue").then(m => m.default || m)
  },
  {
    name: index6AY1IaCh4mMeta?.name ?? "orderHistory:eu___it",
    path: index6AY1IaCh4mMeta?.path ?? "/eu/it/cronologia-ordini",
    extended: true,
    meta: index6AY1IaCh4mMeta || {},
    alias: index6AY1IaCh4mMeta?.alias || [],
    redirect: index6AY1IaCh4mMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/orderHistory/index.vue").then(m => m.default || m)
  },
  {
    name: index6AY1IaCh4mMeta?.name ?? "orderHistory:na___es",
    path: index6AY1IaCh4mMeta?.path ?? "/na/es/historial-pedidos",
    extended: true,
    meta: index6AY1IaCh4mMeta || {},
    alias: index6AY1IaCh4mMeta?.alias || [],
    redirect: index6AY1IaCh4mMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/orderHistory/index.vue").then(m => m.default || m)
  },
  {
    name: index6AY1IaCh4mMeta?.name ?? "orderHistory:global___es",
    path: index6AY1IaCh4mMeta?.path ?? "/global/es/historial-pedidos",
    extended: true,
    meta: index6AY1IaCh4mMeta || {},
    alias: index6AY1IaCh4mMeta?.alias || [],
    redirect: index6AY1IaCh4mMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/orderHistory/index.vue").then(m => m.default || m)
  },
  {
    name: index6AY1IaCh4mMeta?.name ?? "orderHistory:eu___es",
    path: index6AY1IaCh4mMeta?.path ?? "/eu/es/historial-pedidos",
    extended: true,
    meta: index6AY1IaCh4mMeta || {},
    alias: index6AY1IaCh4mMeta?.alias || [],
    redirect: index6AY1IaCh4mMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/orderHistory/index.vue").then(m => m.default || m)
  },
  {
    name: _91product_93qdgql8cVUCMeta?.name ?? "products-product",
    path: _91product_93qdgql8cVUCMeta?.path ?? "/products/:product()",
    extended: true,
    meta: _91product_93qdgql8cVUCMeta || {},
    alias: _91product_93qdgql8cVUCMeta?.alias || [],
    redirect: _91product_93qdgql8cVUCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93qdgql8cVUCMeta?.name ?? "products-product:na___en",
    path: _91product_93qdgql8cVUCMeta?.path ?? "/na/en/products/:product()",
    extended: true,
    meta: _91product_93qdgql8cVUCMeta || {},
    alias: _91product_93qdgql8cVUCMeta?.alias || [],
    redirect: _91product_93qdgql8cVUCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93qdgql8cVUCMeta?.name ?? "products-product:global___en",
    path: _91product_93qdgql8cVUCMeta?.path ?? "/global/en/products/:product()",
    extended: true,
    meta: _91product_93qdgql8cVUCMeta || {},
    alias: _91product_93qdgql8cVUCMeta?.alias || [],
    redirect: _91product_93qdgql8cVUCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93qdgql8cVUCMeta?.name ?? "products-product:eu___en",
    path: _91product_93qdgql8cVUCMeta?.path ?? "/eu/en/products/:product()",
    extended: true,
    meta: _91product_93qdgql8cVUCMeta || {},
    alias: _91product_93qdgql8cVUCMeta?.alias || [],
    redirect: _91product_93qdgql8cVUCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93qdgql8cVUCMeta?.name ?? "products-product:na___de",
    path: _91product_93qdgql8cVUCMeta?.path ?? "/na/de/products/:product()",
    extended: true,
    meta: _91product_93qdgql8cVUCMeta || {},
    alias: _91product_93qdgql8cVUCMeta?.alias || [],
    redirect: _91product_93qdgql8cVUCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93qdgql8cVUCMeta?.name ?? "products-product:global___de",
    path: _91product_93qdgql8cVUCMeta?.path ?? "/global/de/products/:product()",
    extended: true,
    meta: _91product_93qdgql8cVUCMeta || {},
    alias: _91product_93qdgql8cVUCMeta?.alias || [],
    redirect: _91product_93qdgql8cVUCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93qdgql8cVUCMeta?.name ?? "products-product:eu___de",
    path: _91product_93qdgql8cVUCMeta?.path ?? "/eu/de/products/:product()",
    extended: true,
    meta: _91product_93qdgql8cVUCMeta || {},
    alias: _91product_93qdgql8cVUCMeta?.alias || [],
    redirect: _91product_93qdgql8cVUCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93qdgql8cVUCMeta?.name ?? "products-product:na___fr",
    path: _91product_93qdgql8cVUCMeta?.path ?? "/na/fr/products/:product()",
    extended: true,
    meta: _91product_93qdgql8cVUCMeta || {},
    alias: _91product_93qdgql8cVUCMeta?.alias || [],
    redirect: _91product_93qdgql8cVUCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93qdgql8cVUCMeta?.name ?? "products-product:global___fr",
    path: _91product_93qdgql8cVUCMeta?.path ?? "/global/fr/products/:product()",
    extended: true,
    meta: _91product_93qdgql8cVUCMeta || {},
    alias: _91product_93qdgql8cVUCMeta?.alias || [],
    redirect: _91product_93qdgql8cVUCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93qdgql8cVUCMeta?.name ?? "products-product:eu___fr",
    path: _91product_93qdgql8cVUCMeta?.path ?? "/eu/fr/products/:product()",
    extended: true,
    meta: _91product_93qdgql8cVUCMeta || {},
    alias: _91product_93qdgql8cVUCMeta?.alias || [],
    redirect: _91product_93qdgql8cVUCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93qdgql8cVUCMeta?.name ?? "products-product:na___it",
    path: _91product_93qdgql8cVUCMeta?.path ?? "/na/it/products/:product()",
    extended: true,
    meta: _91product_93qdgql8cVUCMeta || {},
    alias: _91product_93qdgql8cVUCMeta?.alias || [],
    redirect: _91product_93qdgql8cVUCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93qdgql8cVUCMeta?.name ?? "products-product:global___it",
    path: _91product_93qdgql8cVUCMeta?.path ?? "/global/it/products/:product()",
    extended: true,
    meta: _91product_93qdgql8cVUCMeta || {},
    alias: _91product_93qdgql8cVUCMeta?.alias || [],
    redirect: _91product_93qdgql8cVUCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93qdgql8cVUCMeta?.name ?? "products-product:eu___it",
    path: _91product_93qdgql8cVUCMeta?.path ?? "/eu/it/products/:product()",
    extended: true,
    meta: _91product_93qdgql8cVUCMeta || {},
    alias: _91product_93qdgql8cVUCMeta?.alias || [],
    redirect: _91product_93qdgql8cVUCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93qdgql8cVUCMeta?.name ?? "products-product:na___es",
    path: _91product_93qdgql8cVUCMeta?.path ?? "/na/es/products/:product()",
    extended: true,
    meta: _91product_93qdgql8cVUCMeta || {},
    alias: _91product_93qdgql8cVUCMeta?.alias || [],
    redirect: _91product_93qdgql8cVUCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93qdgql8cVUCMeta?.name ?? "products-product:global___es",
    path: _91product_93qdgql8cVUCMeta?.path ?? "/global/es/products/:product()",
    extended: true,
    meta: _91product_93qdgql8cVUCMeta || {},
    alias: _91product_93qdgql8cVUCMeta?.alias || [],
    redirect: _91product_93qdgql8cVUCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: _91product_93qdgql8cVUCMeta?.name ?? "products-product:eu___es",
    path: _91product_93qdgql8cVUCMeta?.path ?? "/eu/es/products/:product()",
    extended: true,
    meta: _91product_93qdgql8cVUCMeta || {},
    alias: _91product_93qdgql8cVUCMeta?.alias || [],
    redirect: _91product_93qdgql8cVUCMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: profile1vvtTRV7QgMeta?.name ?? "profile",
    path: profile1vvtTRV7QgMeta?.path ?? "/profile",
    extended: true,
    meta: profile1vvtTRV7QgMeta || {},
    alias: profile1vvtTRV7QgMeta?.alias || [],
    redirect: profile1vvtTRV7QgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profile1vvtTRV7QgMeta?.name ?? "profile:na___en",
    path: profile1vvtTRV7QgMeta?.path ?? "/na/en/profile",
    extended: true,
    meta: profile1vvtTRV7QgMeta || {},
    alias: profile1vvtTRV7QgMeta?.alias || [],
    redirect: profile1vvtTRV7QgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profile1vvtTRV7QgMeta?.name ?? "profile:global___en",
    path: profile1vvtTRV7QgMeta?.path ?? "/global/en/profile",
    extended: true,
    meta: profile1vvtTRV7QgMeta || {},
    alias: profile1vvtTRV7QgMeta?.alias || [],
    redirect: profile1vvtTRV7QgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profile1vvtTRV7QgMeta?.name ?? "profile:eu___en",
    path: profile1vvtTRV7QgMeta?.path ?? "/eu/en/profile",
    extended: true,
    meta: profile1vvtTRV7QgMeta || {},
    alias: profile1vvtTRV7QgMeta?.alias || [],
    redirect: profile1vvtTRV7QgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profile1vvtTRV7QgMeta?.name ?? "profile:na___de",
    path: profile1vvtTRV7QgMeta?.path ?? "/na/de/profil",
    extended: true,
    meta: profile1vvtTRV7QgMeta || {},
    alias: profile1vvtTRV7QgMeta?.alias || [],
    redirect: profile1vvtTRV7QgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profile1vvtTRV7QgMeta?.name ?? "profile:global___de",
    path: profile1vvtTRV7QgMeta?.path ?? "/global/de/profil",
    extended: true,
    meta: profile1vvtTRV7QgMeta || {},
    alias: profile1vvtTRV7QgMeta?.alias || [],
    redirect: profile1vvtTRV7QgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profile1vvtTRV7QgMeta?.name ?? "profile:eu___de",
    path: profile1vvtTRV7QgMeta?.path ?? "/eu/de/profil",
    extended: true,
    meta: profile1vvtTRV7QgMeta || {},
    alias: profile1vvtTRV7QgMeta?.alias || [],
    redirect: profile1vvtTRV7QgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profile1vvtTRV7QgMeta?.name ?? "profile:na___fr",
    path: profile1vvtTRV7QgMeta?.path ?? "/na/fr/profil",
    extended: true,
    meta: profile1vvtTRV7QgMeta || {},
    alias: profile1vvtTRV7QgMeta?.alias || [],
    redirect: profile1vvtTRV7QgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profile1vvtTRV7QgMeta?.name ?? "profile:global___fr",
    path: profile1vvtTRV7QgMeta?.path ?? "/global/fr/profil",
    extended: true,
    meta: profile1vvtTRV7QgMeta || {},
    alias: profile1vvtTRV7QgMeta?.alias || [],
    redirect: profile1vvtTRV7QgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profile1vvtTRV7QgMeta?.name ?? "profile:eu___fr",
    path: profile1vvtTRV7QgMeta?.path ?? "/eu/fr/profil",
    extended: true,
    meta: profile1vvtTRV7QgMeta || {},
    alias: profile1vvtTRV7QgMeta?.alias || [],
    redirect: profile1vvtTRV7QgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profile1vvtTRV7QgMeta?.name ?? "profile:na___it",
    path: profile1vvtTRV7QgMeta?.path ?? "/na/it/profilo",
    extended: true,
    meta: profile1vvtTRV7QgMeta || {},
    alias: profile1vvtTRV7QgMeta?.alias || [],
    redirect: profile1vvtTRV7QgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profile1vvtTRV7QgMeta?.name ?? "profile:global___it",
    path: profile1vvtTRV7QgMeta?.path ?? "/global/it/profilo",
    extended: true,
    meta: profile1vvtTRV7QgMeta || {},
    alias: profile1vvtTRV7QgMeta?.alias || [],
    redirect: profile1vvtTRV7QgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profile1vvtTRV7QgMeta?.name ?? "profile:eu___it",
    path: profile1vvtTRV7QgMeta?.path ?? "/eu/it/profilo",
    extended: true,
    meta: profile1vvtTRV7QgMeta || {},
    alias: profile1vvtTRV7QgMeta?.alias || [],
    redirect: profile1vvtTRV7QgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profile1vvtTRV7QgMeta?.name ?? "profile:na___es",
    path: profile1vvtTRV7QgMeta?.path ?? "/na/es/perfil",
    extended: true,
    meta: profile1vvtTRV7QgMeta || {},
    alias: profile1vvtTRV7QgMeta?.alias || [],
    redirect: profile1vvtTRV7QgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profile1vvtTRV7QgMeta?.name ?? "profile:global___es",
    path: profile1vvtTRV7QgMeta?.path ?? "/global/es/perfil",
    extended: true,
    meta: profile1vvtTRV7QgMeta || {},
    alias: profile1vvtTRV7QgMeta?.alias || [],
    redirect: profile1vvtTRV7QgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: profile1vvtTRV7QgMeta?.name ?? "profile:eu___es",
    path: profile1vvtTRV7QgMeta?.path ?? "/eu/es/perfil",
    extended: true,
    meta: profile1vvtTRV7QgMeta || {},
    alias: profile1vvtTRV7QgMeta?.alias || [],
    redirect: profile1vvtTRV7QgMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: register_45thankyouYADaN8wnyQMeta?.name ?? "register-thankyou",
    path: register_45thankyouYADaN8wnyQMeta?.path ?? "/register-thankyou",
    extended: true,
    meta: register_45thankyouYADaN8wnyQMeta || {},
    alias: register_45thankyouYADaN8wnyQMeta?.alias || [],
    redirect: register_45thankyouYADaN8wnyQMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register-thankyou.vue").then(m => m.default || m)
  },
  {
    name: register_45thankyouYADaN8wnyQMeta?.name ?? "register-thankyou:na___en",
    path: register_45thankyouYADaN8wnyQMeta?.path ?? "/na/en/register-thankyou",
    extended: true,
    meta: register_45thankyouYADaN8wnyQMeta || {},
    alias: register_45thankyouYADaN8wnyQMeta?.alias || [],
    redirect: register_45thankyouYADaN8wnyQMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register-thankyou.vue").then(m => m.default || m)
  },
  {
    name: register_45thankyouYADaN8wnyQMeta?.name ?? "register-thankyou:global___en",
    path: register_45thankyouYADaN8wnyQMeta?.path ?? "/global/en/register-thankyou",
    extended: true,
    meta: register_45thankyouYADaN8wnyQMeta || {},
    alias: register_45thankyouYADaN8wnyQMeta?.alias || [],
    redirect: register_45thankyouYADaN8wnyQMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register-thankyou.vue").then(m => m.default || m)
  },
  {
    name: register_45thankyouYADaN8wnyQMeta?.name ?? "register-thankyou:eu___en",
    path: register_45thankyouYADaN8wnyQMeta?.path ?? "/eu/en/register-thankyou",
    extended: true,
    meta: register_45thankyouYADaN8wnyQMeta || {},
    alias: register_45thankyouYADaN8wnyQMeta?.alias || [],
    redirect: register_45thankyouYADaN8wnyQMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register-thankyou.vue").then(m => m.default || m)
  },
  {
    name: register_45thankyouYADaN8wnyQMeta?.name ?? "register-thankyou:na___de",
    path: register_45thankyouYADaN8wnyQMeta?.path ?? "/na/de/registrierung-danke",
    extended: true,
    meta: register_45thankyouYADaN8wnyQMeta || {},
    alias: register_45thankyouYADaN8wnyQMeta?.alias || [],
    redirect: register_45thankyouYADaN8wnyQMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register-thankyou.vue").then(m => m.default || m)
  },
  {
    name: register_45thankyouYADaN8wnyQMeta?.name ?? "register-thankyou:global___de",
    path: register_45thankyouYADaN8wnyQMeta?.path ?? "/global/de/registrierung-danke",
    extended: true,
    meta: register_45thankyouYADaN8wnyQMeta || {},
    alias: register_45thankyouYADaN8wnyQMeta?.alias || [],
    redirect: register_45thankyouYADaN8wnyQMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register-thankyou.vue").then(m => m.default || m)
  },
  {
    name: register_45thankyouYADaN8wnyQMeta?.name ?? "register-thankyou:eu___de",
    path: register_45thankyouYADaN8wnyQMeta?.path ?? "/eu/de/registrierung-danke",
    extended: true,
    meta: register_45thankyouYADaN8wnyQMeta || {},
    alias: register_45thankyouYADaN8wnyQMeta?.alias || [],
    redirect: register_45thankyouYADaN8wnyQMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register-thankyou.vue").then(m => m.default || m)
  },
  {
    name: register_45thankyouYADaN8wnyQMeta?.name ?? "register-thankyou:na___fr",
    path: register_45thankyouYADaN8wnyQMeta?.path ?? "/na/fr/enregistrement-merci",
    extended: true,
    meta: register_45thankyouYADaN8wnyQMeta || {},
    alias: register_45thankyouYADaN8wnyQMeta?.alias || [],
    redirect: register_45thankyouYADaN8wnyQMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register-thankyou.vue").then(m => m.default || m)
  },
  {
    name: register_45thankyouYADaN8wnyQMeta?.name ?? "register-thankyou:global___fr",
    path: register_45thankyouYADaN8wnyQMeta?.path ?? "/global/fr/enregistrement-merci",
    extended: true,
    meta: register_45thankyouYADaN8wnyQMeta || {},
    alias: register_45thankyouYADaN8wnyQMeta?.alias || [],
    redirect: register_45thankyouYADaN8wnyQMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register-thankyou.vue").then(m => m.default || m)
  },
  {
    name: register_45thankyouYADaN8wnyQMeta?.name ?? "register-thankyou:eu___fr",
    path: register_45thankyouYADaN8wnyQMeta?.path ?? "/eu/fr/enregistrement-merci",
    extended: true,
    meta: register_45thankyouYADaN8wnyQMeta || {},
    alias: register_45thankyouYADaN8wnyQMeta?.alias || [],
    redirect: register_45thankyouYADaN8wnyQMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register-thankyou.vue").then(m => m.default || m)
  },
  {
    name: register_45thankyouYADaN8wnyQMeta?.name ?? "register-thankyou:na___it",
    path: register_45thankyouYADaN8wnyQMeta?.path ?? "/na/it/registrazione-grazie",
    extended: true,
    meta: register_45thankyouYADaN8wnyQMeta || {},
    alias: register_45thankyouYADaN8wnyQMeta?.alias || [],
    redirect: register_45thankyouYADaN8wnyQMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register-thankyou.vue").then(m => m.default || m)
  },
  {
    name: register_45thankyouYADaN8wnyQMeta?.name ?? "register-thankyou:global___it",
    path: register_45thankyouYADaN8wnyQMeta?.path ?? "/global/it/registrazione-grazie",
    extended: true,
    meta: register_45thankyouYADaN8wnyQMeta || {},
    alias: register_45thankyouYADaN8wnyQMeta?.alias || [],
    redirect: register_45thankyouYADaN8wnyQMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register-thankyou.vue").then(m => m.default || m)
  },
  {
    name: register_45thankyouYADaN8wnyQMeta?.name ?? "register-thankyou:eu___it",
    path: register_45thankyouYADaN8wnyQMeta?.path ?? "/eu/it/registrazione-grazie",
    extended: true,
    meta: register_45thankyouYADaN8wnyQMeta || {},
    alias: register_45thankyouYADaN8wnyQMeta?.alias || [],
    redirect: register_45thankyouYADaN8wnyQMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register-thankyou.vue").then(m => m.default || m)
  },
  {
    name: register_45thankyouYADaN8wnyQMeta?.name ?? "register-thankyou:na___es",
    path: register_45thankyouYADaN8wnyQMeta?.path ?? "/na/es/registrarse-gracias",
    extended: true,
    meta: register_45thankyouYADaN8wnyQMeta || {},
    alias: register_45thankyouYADaN8wnyQMeta?.alias || [],
    redirect: register_45thankyouYADaN8wnyQMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register-thankyou.vue").then(m => m.default || m)
  },
  {
    name: register_45thankyouYADaN8wnyQMeta?.name ?? "register-thankyou:global___es",
    path: register_45thankyouYADaN8wnyQMeta?.path ?? "/global/es/registrarse-gracias",
    extended: true,
    meta: register_45thankyouYADaN8wnyQMeta || {},
    alias: register_45thankyouYADaN8wnyQMeta?.alias || [],
    redirect: register_45thankyouYADaN8wnyQMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register-thankyou.vue").then(m => m.default || m)
  },
  {
    name: register_45thankyouYADaN8wnyQMeta?.name ?? "register-thankyou:eu___es",
    path: register_45thankyouYADaN8wnyQMeta?.path ?? "/eu/es/registrarse-gracias",
    extended: true,
    meta: register_45thankyouYADaN8wnyQMeta || {},
    alias: register_45thankyouYADaN8wnyQMeta?.alias || [],
    redirect: register_45thankyouYADaN8wnyQMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register-thankyou.vue").then(m => m.default || m)
  },
  {
    name: registerduRznR7f4eMeta?.name ?? "register",
    path: registerduRznR7f4eMeta?.path ?? "/register",
    extended: true,
    meta: registerduRznR7f4eMeta || {},
    alias: registerduRznR7f4eMeta?.alias || [],
    redirect: registerduRznR7f4eMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerduRznR7f4eMeta?.name ?? "register:na___en",
    path: registerduRznR7f4eMeta?.path ?? "/na/en/register",
    extended: true,
    meta: registerduRznR7f4eMeta || {},
    alias: registerduRznR7f4eMeta?.alias || [],
    redirect: registerduRznR7f4eMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerduRznR7f4eMeta?.name ?? "register:global___en",
    path: registerduRznR7f4eMeta?.path ?? "/global/en/register",
    extended: true,
    meta: registerduRznR7f4eMeta || {},
    alias: registerduRznR7f4eMeta?.alias || [],
    redirect: registerduRznR7f4eMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerduRznR7f4eMeta?.name ?? "register:eu___en",
    path: registerduRznR7f4eMeta?.path ?? "/eu/en/register",
    extended: true,
    meta: registerduRznR7f4eMeta || {},
    alias: registerduRznR7f4eMeta?.alias || [],
    redirect: registerduRznR7f4eMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerduRznR7f4eMeta?.name ?? "register:na___de",
    path: registerduRznR7f4eMeta?.path ?? "/na/de/registrierung",
    extended: true,
    meta: registerduRznR7f4eMeta || {},
    alias: registerduRznR7f4eMeta?.alias || [],
    redirect: registerduRznR7f4eMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerduRznR7f4eMeta?.name ?? "register:global___de",
    path: registerduRznR7f4eMeta?.path ?? "/global/de/registrierung",
    extended: true,
    meta: registerduRznR7f4eMeta || {},
    alias: registerduRznR7f4eMeta?.alias || [],
    redirect: registerduRznR7f4eMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerduRznR7f4eMeta?.name ?? "register:eu___de",
    path: registerduRznR7f4eMeta?.path ?? "/eu/de/registrierung",
    extended: true,
    meta: registerduRznR7f4eMeta || {},
    alias: registerduRznR7f4eMeta?.alias || [],
    redirect: registerduRznR7f4eMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerduRznR7f4eMeta?.name ?? "register:na___fr",
    path: registerduRznR7f4eMeta?.path ?? "/na/fr/enregistrement",
    extended: true,
    meta: registerduRznR7f4eMeta || {},
    alias: registerduRznR7f4eMeta?.alias || [],
    redirect: registerduRznR7f4eMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerduRznR7f4eMeta?.name ?? "register:global___fr",
    path: registerduRznR7f4eMeta?.path ?? "/global/fr/enregistrement",
    extended: true,
    meta: registerduRznR7f4eMeta || {},
    alias: registerduRznR7f4eMeta?.alias || [],
    redirect: registerduRznR7f4eMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerduRznR7f4eMeta?.name ?? "register:eu___fr",
    path: registerduRznR7f4eMeta?.path ?? "/eu/fr/enregistrement",
    extended: true,
    meta: registerduRznR7f4eMeta || {},
    alias: registerduRznR7f4eMeta?.alias || [],
    redirect: registerduRznR7f4eMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerduRznR7f4eMeta?.name ?? "register:na___it",
    path: registerduRznR7f4eMeta?.path ?? "/na/it/registrazione",
    extended: true,
    meta: registerduRznR7f4eMeta || {},
    alias: registerduRznR7f4eMeta?.alias || [],
    redirect: registerduRznR7f4eMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerduRznR7f4eMeta?.name ?? "register:global___it",
    path: registerduRznR7f4eMeta?.path ?? "/global/it/registrazione",
    extended: true,
    meta: registerduRznR7f4eMeta || {},
    alias: registerduRznR7f4eMeta?.alias || [],
    redirect: registerduRznR7f4eMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerduRznR7f4eMeta?.name ?? "register:eu___it",
    path: registerduRznR7f4eMeta?.path ?? "/eu/it/registrazione",
    extended: true,
    meta: registerduRznR7f4eMeta || {},
    alias: registerduRznR7f4eMeta?.alias || [],
    redirect: registerduRznR7f4eMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerduRznR7f4eMeta?.name ?? "register:na___es",
    path: registerduRznR7f4eMeta?.path ?? "/na/es/registrarse",
    extended: true,
    meta: registerduRznR7f4eMeta || {},
    alias: registerduRznR7f4eMeta?.alias || [],
    redirect: registerduRznR7f4eMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerduRznR7f4eMeta?.name ?? "register:global___es",
    path: registerduRznR7f4eMeta?.path ?? "/global/es/registrarse",
    extended: true,
    meta: registerduRznR7f4eMeta || {},
    alias: registerduRznR7f4eMeta?.alias || [],
    redirect: registerduRznR7f4eMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerduRznR7f4eMeta?.name ?? "register:eu___es",
    path: registerduRznR7f4eMeta?.path ?? "/eu/es/registrarse",
    extended: true,
    meta: registerduRznR7f4eMeta || {},
    alias: registerduRznR7f4eMeta?.alias || [],
    redirect: registerduRznR7f4eMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: indexSm87cCTbKDMeta?.name ?? "searchResults",
    path: indexSm87cCTbKDMeta?.path ?? "/searchResults",
    extended: true,
    meta: indexSm87cCTbKDMeta || {},
    alias: indexSm87cCTbKDMeta?.alias || [],
    redirect: indexSm87cCTbKDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/searchResults/index.vue").then(m => m.default || m)
  },
  {
    name: indexSm87cCTbKDMeta?.name ?? "searchResults:na___en",
    path: indexSm87cCTbKDMeta?.path ?? "/na/en/search-results",
    extended: true,
    meta: indexSm87cCTbKDMeta || {},
    alias: indexSm87cCTbKDMeta?.alias || [],
    redirect: indexSm87cCTbKDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/searchResults/index.vue").then(m => m.default || m)
  },
  {
    name: indexSm87cCTbKDMeta?.name ?? "searchResults:global___en",
    path: indexSm87cCTbKDMeta?.path ?? "/global/en/search-results",
    extended: true,
    meta: indexSm87cCTbKDMeta || {},
    alias: indexSm87cCTbKDMeta?.alias || [],
    redirect: indexSm87cCTbKDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/searchResults/index.vue").then(m => m.default || m)
  },
  {
    name: indexSm87cCTbKDMeta?.name ?? "searchResults:eu___en",
    path: indexSm87cCTbKDMeta?.path ?? "/eu/en/search-results",
    extended: true,
    meta: indexSm87cCTbKDMeta || {},
    alias: indexSm87cCTbKDMeta?.alias || [],
    redirect: indexSm87cCTbKDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/searchResults/index.vue").then(m => m.default || m)
  },
  {
    name: indexSm87cCTbKDMeta?.name ?? "searchResults:na___de",
    path: indexSm87cCTbKDMeta?.path ?? "/na/de/suchergebnisse",
    extended: true,
    meta: indexSm87cCTbKDMeta || {},
    alias: indexSm87cCTbKDMeta?.alias || [],
    redirect: indexSm87cCTbKDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/searchResults/index.vue").then(m => m.default || m)
  },
  {
    name: indexSm87cCTbKDMeta?.name ?? "searchResults:global___de",
    path: indexSm87cCTbKDMeta?.path ?? "/global/de/suchergebnisse",
    extended: true,
    meta: indexSm87cCTbKDMeta || {},
    alias: indexSm87cCTbKDMeta?.alias || [],
    redirect: indexSm87cCTbKDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/searchResults/index.vue").then(m => m.default || m)
  },
  {
    name: indexSm87cCTbKDMeta?.name ?? "searchResults:eu___de",
    path: indexSm87cCTbKDMeta?.path ?? "/eu/de/suchergebnisse",
    extended: true,
    meta: indexSm87cCTbKDMeta || {},
    alias: indexSm87cCTbKDMeta?.alias || [],
    redirect: indexSm87cCTbKDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/searchResults/index.vue").then(m => m.default || m)
  },
  {
    name: indexSm87cCTbKDMeta?.name ?? "searchResults:na___fr",
    path: indexSm87cCTbKDMeta?.path ?? "/na/fr/resultats-recherche",
    extended: true,
    meta: indexSm87cCTbKDMeta || {},
    alias: indexSm87cCTbKDMeta?.alias || [],
    redirect: indexSm87cCTbKDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/searchResults/index.vue").then(m => m.default || m)
  },
  {
    name: indexSm87cCTbKDMeta?.name ?? "searchResults:global___fr",
    path: indexSm87cCTbKDMeta?.path ?? "/global/fr/resultats-recherche",
    extended: true,
    meta: indexSm87cCTbKDMeta || {},
    alias: indexSm87cCTbKDMeta?.alias || [],
    redirect: indexSm87cCTbKDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/searchResults/index.vue").then(m => m.default || m)
  },
  {
    name: indexSm87cCTbKDMeta?.name ?? "searchResults:eu___fr",
    path: indexSm87cCTbKDMeta?.path ?? "/eu/fr/resultats-recherche",
    extended: true,
    meta: indexSm87cCTbKDMeta || {},
    alias: indexSm87cCTbKDMeta?.alias || [],
    redirect: indexSm87cCTbKDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/searchResults/index.vue").then(m => m.default || m)
  },
  {
    name: indexSm87cCTbKDMeta?.name ?? "searchResults:na___it",
    path: indexSm87cCTbKDMeta?.path ?? "/na/it/risultati-ricerca",
    extended: true,
    meta: indexSm87cCTbKDMeta || {},
    alias: indexSm87cCTbKDMeta?.alias || [],
    redirect: indexSm87cCTbKDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/searchResults/index.vue").then(m => m.default || m)
  },
  {
    name: indexSm87cCTbKDMeta?.name ?? "searchResults:global___it",
    path: indexSm87cCTbKDMeta?.path ?? "/global/it/risultati-ricerca",
    extended: true,
    meta: indexSm87cCTbKDMeta || {},
    alias: indexSm87cCTbKDMeta?.alias || [],
    redirect: indexSm87cCTbKDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/searchResults/index.vue").then(m => m.default || m)
  },
  {
    name: indexSm87cCTbKDMeta?.name ?? "searchResults:eu___it",
    path: indexSm87cCTbKDMeta?.path ?? "/eu/it/risultati-ricerca",
    extended: true,
    meta: indexSm87cCTbKDMeta || {},
    alias: indexSm87cCTbKDMeta?.alias || [],
    redirect: indexSm87cCTbKDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/searchResults/index.vue").then(m => m.default || m)
  },
  {
    name: indexSm87cCTbKDMeta?.name ?? "searchResults:na___es",
    path: indexSm87cCTbKDMeta?.path ?? "/na/es/resultados-b%C3%BAsqueda",
    extended: true,
    meta: indexSm87cCTbKDMeta || {},
    alias: indexSm87cCTbKDMeta?.alias || [],
    redirect: indexSm87cCTbKDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/searchResults/index.vue").then(m => m.default || m)
  },
  {
    name: indexSm87cCTbKDMeta?.name ?? "searchResults:global___es",
    path: indexSm87cCTbKDMeta?.path ?? "/global/es/resultados-b%C3%BAsqueda",
    extended: true,
    meta: indexSm87cCTbKDMeta || {},
    alias: indexSm87cCTbKDMeta?.alias || [],
    redirect: indexSm87cCTbKDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/searchResults/index.vue").then(m => m.default || m)
  },
  {
    name: indexSm87cCTbKDMeta?.name ?? "searchResults:eu___es",
    path: indexSm87cCTbKDMeta?.path ?? "/eu/es/resultados-b%C3%BAsqueda",
    extended: true,
    meta: indexSm87cCTbKDMeta || {},
    alias: indexSm87cCTbKDMeta?.alias || [],
    redirect: indexSm87cCTbKDMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/searchResults/index.vue").then(m => m.default || m)
  },
  {
    name: setupByOG7HKR9NMeta?.name ?? "setup",
    path: setupByOG7HKR9NMeta?.path ?? "/setup",
    extended: true,
    meta: setupByOG7HKR9NMeta || {},
    alias: setupByOG7HKR9NMeta?.alias || [],
    redirect: setupByOG7HKR9NMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: setupByOG7HKR9NMeta?.name ?? "setup:na___en",
    path: setupByOG7HKR9NMeta?.path ?? "/na/en/setup",
    extended: true,
    meta: setupByOG7HKR9NMeta || {},
    alias: setupByOG7HKR9NMeta?.alias || [],
    redirect: setupByOG7HKR9NMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: setupByOG7HKR9NMeta?.name ?? "setup:global___en",
    path: setupByOG7HKR9NMeta?.path ?? "/global/en/setup",
    extended: true,
    meta: setupByOG7HKR9NMeta || {},
    alias: setupByOG7HKR9NMeta?.alias || [],
    redirect: setupByOG7HKR9NMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: setupByOG7HKR9NMeta?.name ?? "setup:eu___en",
    path: setupByOG7HKR9NMeta?.path ?? "/eu/en/setup",
    extended: true,
    meta: setupByOG7HKR9NMeta || {},
    alias: setupByOG7HKR9NMeta?.alias || [],
    redirect: setupByOG7HKR9NMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: setupByOG7HKR9NMeta?.name ?? "setup:na___de",
    path: setupByOG7HKR9NMeta?.path ?? "/na/de/einrichtung",
    extended: true,
    meta: setupByOG7HKR9NMeta || {},
    alias: setupByOG7HKR9NMeta?.alias || [],
    redirect: setupByOG7HKR9NMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: setupByOG7HKR9NMeta?.name ?? "setup:global___de",
    path: setupByOG7HKR9NMeta?.path ?? "/global/de/einrichtung",
    extended: true,
    meta: setupByOG7HKR9NMeta || {},
    alias: setupByOG7HKR9NMeta?.alias || [],
    redirect: setupByOG7HKR9NMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: setupByOG7HKR9NMeta?.name ?? "setup:eu___de",
    path: setupByOG7HKR9NMeta?.path ?? "/eu/de/einrichtung",
    extended: true,
    meta: setupByOG7HKR9NMeta || {},
    alias: setupByOG7HKR9NMeta?.alias || [],
    redirect: setupByOG7HKR9NMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: setupByOG7HKR9NMeta?.name ?? "setup:na___fr",
    path: setupByOG7HKR9NMeta?.path ?? "/na/fr/configuration",
    extended: true,
    meta: setupByOG7HKR9NMeta || {},
    alias: setupByOG7HKR9NMeta?.alias || [],
    redirect: setupByOG7HKR9NMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: setupByOG7HKR9NMeta?.name ?? "setup:global___fr",
    path: setupByOG7HKR9NMeta?.path ?? "/global/fr/configuration",
    extended: true,
    meta: setupByOG7HKR9NMeta || {},
    alias: setupByOG7HKR9NMeta?.alias || [],
    redirect: setupByOG7HKR9NMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: setupByOG7HKR9NMeta?.name ?? "setup:eu___fr",
    path: setupByOG7HKR9NMeta?.path ?? "/eu/fr/configuration",
    extended: true,
    meta: setupByOG7HKR9NMeta || {},
    alias: setupByOG7HKR9NMeta?.alias || [],
    redirect: setupByOG7HKR9NMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: setupByOG7HKR9NMeta?.name ?? "setup:na___it",
    path: setupByOG7HKR9NMeta?.path ?? "/na/it/impostazione",
    extended: true,
    meta: setupByOG7HKR9NMeta || {},
    alias: setupByOG7HKR9NMeta?.alias || [],
    redirect: setupByOG7HKR9NMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: setupByOG7HKR9NMeta?.name ?? "setup:global___it",
    path: setupByOG7HKR9NMeta?.path ?? "/global/it/impostazione",
    extended: true,
    meta: setupByOG7HKR9NMeta || {},
    alias: setupByOG7HKR9NMeta?.alias || [],
    redirect: setupByOG7HKR9NMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: setupByOG7HKR9NMeta?.name ?? "setup:eu___it",
    path: setupByOG7HKR9NMeta?.path ?? "/eu/it/impostazione",
    extended: true,
    meta: setupByOG7HKR9NMeta || {},
    alias: setupByOG7HKR9NMeta?.alias || [],
    redirect: setupByOG7HKR9NMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: setupByOG7HKR9NMeta?.name ?? "setup:na___es",
    path: setupByOG7HKR9NMeta?.path ?? "/na/es/instalaci%C3%B3n",
    extended: true,
    meta: setupByOG7HKR9NMeta || {},
    alias: setupByOG7HKR9NMeta?.alias || [],
    redirect: setupByOG7HKR9NMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: setupByOG7HKR9NMeta?.name ?? "setup:global___es",
    path: setupByOG7HKR9NMeta?.path ?? "/global/es/instalaci%C3%B3n",
    extended: true,
    meta: setupByOG7HKR9NMeta || {},
    alias: setupByOG7HKR9NMeta?.alias || [],
    redirect: setupByOG7HKR9NMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: setupByOG7HKR9NMeta?.name ?? "setup:eu___es",
    path: setupByOG7HKR9NMeta?.path ?? "/eu/es/instalaci%C3%B3n",
    extended: true,
    meta: setupByOG7HKR9NMeta || {},
    alias: setupByOG7HKR9NMeta?.alias || [],
    redirect: setupByOG7HKR9NMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: verification_45required2WqqgFfW6jMeta?.name ?? "verification-required",
    path: verification_45required2WqqgFfW6jMeta?.path ?? "/verification-required",
    extended: true,
    meta: verification_45required2WqqgFfW6jMeta || {},
    alias: verification_45required2WqqgFfW6jMeta?.alias || [],
    redirect: verification_45required2WqqgFfW6jMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/verification-required.vue").then(m => m.default || m)
  },
  {
    name: verification_45required2WqqgFfW6jMeta?.name ?? "verification-required:na___en",
    path: verification_45required2WqqgFfW6jMeta?.path ?? "/na/en/verification-required",
    extended: true,
    meta: verification_45required2WqqgFfW6jMeta || {},
    alias: verification_45required2WqqgFfW6jMeta?.alias || [],
    redirect: verification_45required2WqqgFfW6jMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/verification-required.vue").then(m => m.default || m)
  },
  {
    name: verification_45required2WqqgFfW6jMeta?.name ?? "verification-required:global___en",
    path: verification_45required2WqqgFfW6jMeta?.path ?? "/global/en/verification-required",
    extended: true,
    meta: verification_45required2WqqgFfW6jMeta || {},
    alias: verification_45required2WqqgFfW6jMeta?.alias || [],
    redirect: verification_45required2WqqgFfW6jMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/verification-required.vue").then(m => m.default || m)
  },
  {
    name: verification_45required2WqqgFfW6jMeta?.name ?? "verification-required:eu___en",
    path: verification_45required2WqqgFfW6jMeta?.path ?? "/eu/en/verification-required",
    extended: true,
    meta: verification_45required2WqqgFfW6jMeta || {},
    alias: verification_45required2WqqgFfW6jMeta?.alias || [],
    redirect: verification_45required2WqqgFfW6jMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/verification-required.vue").then(m => m.default || m)
  },
  {
    name: verification_45required2WqqgFfW6jMeta?.name ?? "verification-required:na___de",
    path: verification_45required2WqqgFfW6jMeta?.path ?? "/na/de/verification-required",
    extended: true,
    meta: verification_45required2WqqgFfW6jMeta || {},
    alias: verification_45required2WqqgFfW6jMeta?.alias || [],
    redirect: verification_45required2WqqgFfW6jMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/verification-required.vue").then(m => m.default || m)
  },
  {
    name: verification_45required2WqqgFfW6jMeta?.name ?? "verification-required:global___de",
    path: verification_45required2WqqgFfW6jMeta?.path ?? "/global/de/verification-required",
    extended: true,
    meta: verification_45required2WqqgFfW6jMeta || {},
    alias: verification_45required2WqqgFfW6jMeta?.alias || [],
    redirect: verification_45required2WqqgFfW6jMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/verification-required.vue").then(m => m.default || m)
  },
  {
    name: verification_45required2WqqgFfW6jMeta?.name ?? "verification-required:eu___de",
    path: verification_45required2WqqgFfW6jMeta?.path ?? "/eu/de/verification-required",
    extended: true,
    meta: verification_45required2WqqgFfW6jMeta || {},
    alias: verification_45required2WqqgFfW6jMeta?.alias || [],
    redirect: verification_45required2WqqgFfW6jMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/verification-required.vue").then(m => m.default || m)
  },
  {
    name: verification_45required2WqqgFfW6jMeta?.name ?? "verification-required:na___fr",
    path: verification_45required2WqqgFfW6jMeta?.path ?? "/na/fr/verification-required",
    extended: true,
    meta: verification_45required2WqqgFfW6jMeta || {},
    alias: verification_45required2WqqgFfW6jMeta?.alias || [],
    redirect: verification_45required2WqqgFfW6jMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/verification-required.vue").then(m => m.default || m)
  },
  {
    name: verification_45required2WqqgFfW6jMeta?.name ?? "verification-required:global___fr",
    path: verification_45required2WqqgFfW6jMeta?.path ?? "/global/fr/verification-required",
    extended: true,
    meta: verification_45required2WqqgFfW6jMeta || {},
    alias: verification_45required2WqqgFfW6jMeta?.alias || [],
    redirect: verification_45required2WqqgFfW6jMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/verification-required.vue").then(m => m.default || m)
  },
  {
    name: verification_45required2WqqgFfW6jMeta?.name ?? "verification-required:eu___fr",
    path: verification_45required2WqqgFfW6jMeta?.path ?? "/eu/fr/verification-required",
    extended: true,
    meta: verification_45required2WqqgFfW6jMeta || {},
    alias: verification_45required2WqqgFfW6jMeta?.alias || [],
    redirect: verification_45required2WqqgFfW6jMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/verification-required.vue").then(m => m.default || m)
  },
  {
    name: verification_45required2WqqgFfW6jMeta?.name ?? "verification-required:na___it",
    path: verification_45required2WqqgFfW6jMeta?.path ?? "/na/it/verification-required",
    extended: true,
    meta: verification_45required2WqqgFfW6jMeta || {},
    alias: verification_45required2WqqgFfW6jMeta?.alias || [],
    redirect: verification_45required2WqqgFfW6jMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/verification-required.vue").then(m => m.default || m)
  },
  {
    name: verification_45required2WqqgFfW6jMeta?.name ?? "verification-required:global___it",
    path: verification_45required2WqqgFfW6jMeta?.path ?? "/global/it/verification-required",
    extended: true,
    meta: verification_45required2WqqgFfW6jMeta || {},
    alias: verification_45required2WqqgFfW6jMeta?.alias || [],
    redirect: verification_45required2WqqgFfW6jMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/verification-required.vue").then(m => m.default || m)
  },
  {
    name: verification_45required2WqqgFfW6jMeta?.name ?? "verification-required:eu___it",
    path: verification_45required2WqqgFfW6jMeta?.path ?? "/eu/it/verification-required",
    extended: true,
    meta: verification_45required2WqqgFfW6jMeta || {},
    alias: verification_45required2WqqgFfW6jMeta?.alias || [],
    redirect: verification_45required2WqqgFfW6jMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/verification-required.vue").then(m => m.default || m)
  },
  {
    name: verification_45required2WqqgFfW6jMeta?.name ?? "verification-required:na___es",
    path: verification_45required2WqqgFfW6jMeta?.path ?? "/na/es/verification-required",
    extended: true,
    meta: verification_45required2WqqgFfW6jMeta || {},
    alias: verification_45required2WqqgFfW6jMeta?.alias || [],
    redirect: verification_45required2WqqgFfW6jMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/verification-required.vue").then(m => m.default || m)
  },
  {
    name: verification_45required2WqqgFfW6jMeta?.name ?? "verification-required:global___es",
    path: verification_45required2WqqgFfW6jMeta?.path ?? "/global/es/verification-required",
    extended: true,
    meta: verification_45required2WqqgFfW6jMeta || {},
    alias: verification_45required2WqqgFfW6jMeta?.alias || [],
    redirect: verification_45required2WqqgFfW6jMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/verification-required.vue").then(m => m.default || m)
  },
  {
    name: verification_45required2WqqgFfW6jMeta?.name ?? "verification-required:eu___es",
    path: verification_45required2WqqgFfW6jMeta?.path ?? "/eu/es/verification-required",
    extended: true,
    meta: verification_45required2WqqgFfW6jMeta || {},
    alias: verification_45required2WqqgFfW6jMeta?.alias || [],
    redirect: verification_45required2WqqgFfW6jMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/verification-required.vue").then(m => m.default || m)
  },
  {
    name: indexCxBRArySCGMeta?.name ?? "xpress",
    path: indexCxBRArySCGMeta?.path ?? "/xpress",
    extended: true,
    meta: indexCxBRArySCGMeta || {},
    alias: indexCxBRArySCGMeta?.alias || [],
    redirect: indexCxBRArySCGMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/xpress/index.vue").then(m => m.default || m)
  },
  {
    name: indexCxBRArySCGMeta?.name ?? "xpress:na___en",
    path: indexCxBRArySCGMeta?.path ?? "/na/en/xpress",
    extended: true,
    meta: indexCxBRArySCGMeta || {},
    alias: indexCxBRArySCGMeta?.alias || [],
    redirect: indexCxBRArySCGMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/xpress/index.vue").then(m => m.default || m)
  },
  {
    name: indexCxBRArySCGMeta?.name ?? "xpress:global___en",
    path: indexCxBRArySCGMeta?.path ?? "/global/en/xpress",
    extended: true,
    meta: indexCxBRArySCGMeta || {},
    alias: indexCxBRArySCGMeta?.alias || [],
    redirect: indexCxBRArySCGMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/xpress/index.vue").then(m => m.default || m)
  },
  {
    name: indexCxBRArySCGMeta?.name ?? "xpress:eu___en",
    path: indexCxBRArySCGMeta?.path ?? "/eu/en/xpress",
    extended: true,
    meta: indexCxBRArySCGMeta || {},
    alias: indexCxBRArySCGMeta?.alias || [],
    redirect: indexCxBRArySCGMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/xpress/index.vue").then(m => m.default || m)
  },
  {
    name: indexCxBRArySCGMeta?.name ?? "xpress:na___de",
    path: indexCxBRArySCGMeta?.path ?? "/na/de/xpress",
    extended: true,
    meta: indexCxBRArySCGMeta || {},
    alias: indexCxBRArySCGMeta?.alias || [],
    redirect: indexCxBRArySCGMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/xpress/index.vue").then(m => m.default || m)
  },
  {
    name: indexCxBRArySCGMeta?.name ?? "xpress:global___de",
    path: indexCxBRArySCGMeta?.path ?? "/global/de/xpress",
    extended: true,
    meta: indexCxBRArySCGMeta || {},
    alias: indexCxBRArySCGMeta?.alias || [],
    redirect: indexCxBRArySCGMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/xpress/index.vue").then(m => m.default || m)
  },
  {
    name: indexCxBRArySCGMeta?.name ?? "xpress:eu___de",
    path: indexCxBRArySCGMeta?.path ?? "/eu/de/xpress",
    extended: true,
    meta: indexCxBRArySCGMeta || {},
    alias: indexCxBRArySCGMeta?.alias || [],
    redirect: indexCxBRArySCGMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/xpress/index.vue").then(m => m.default || m)
  },
  {
    name: indexCxBRArySCGMeta?.name ?? "xpress:na___fr",
    path: indexCxBRArySCGMeta?.path ?? "/na/fr/xpress",
    extended: true,
    meta: indexCxBRArySCGMeta || {},
    alias: indexCxBRArySCGMeta?.alias || [],
    redirect: indexCxBRArySCGMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/xpress/index.vue").then(m => m.default || m)
  },
  {
    name: indexCxBRArySCGMeta?.name ?? "xpress:global___fr",
    path: indexCxBRArySCGMeta?.path ?? "/global/fr/xpress",
    extended: true,
    meta: indexCxBRArySCGMeta || {},
    alias: indexCxBRArySCGMeta?.alias || [],
    redirect: indexCxBRArySCGMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/xpress/index.vue").then(m => m.default || m)
  },
  {
    name: indexCxBRArySCGMeta?.name ?? "xpress:eu___fr",
    path: indexCxBRArySCGMeta?.path ?? "/eu/fr/xpress",
    extended: true,
    meta: indexCxBRArySCGMeta || {},
    alias: indexCxBRArySCGMeta?.alias || [],
    redirect: indexCxBRArySCGMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/xpress/index.vue").then(m => m.default || m)
  },
  {
    name: indexCxBRArySCGMeta?.name ?? "xpress:na___it",
    path: indexCxBRArySCGMeta?.path ?? "/na/it/xpress",
    extended: true,
    meta: indexCxBRArySCGMeta || {},
    alias: indexCxBRArySCGMeta?.alias || [],
    redirect: indexCxBRArySCGMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/xpress/index.vue").then(m => m.default || m)
  },
  {
    name: indexCxBRArySCGMeta?.name ?? "xpress:global___it",
    path: indexCxBRArySCGMeta?.path ?? "/global/it/xpress",
    extended: true,
    meta: indexCxBRArySCGMeta || {},
    alias: indexCxBRArySCGMeta?.alias || [],
    redirect: indexCxBRArySCGMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/xpress/index.vue").then(m => m.default || m)
  },
  {
    name: indexCxBRArySCGMeta?.name ?? "xpress:eu___it",
    path: indexCxBRArySCGMeta?.path ?? "/eu/it/xpress",
    extended: true,
    meta: indexCxBRArySCGMeta || {},
    alias: indexCxBRArySCGMeta?.alias || [],
    redirect: indexCxBRArySCGMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/xpress/index.vue").then(m => m.default || m)
  },
  {
    name: indexCxBRArySCGMeta?.name ?? "xpress:na___es",
    path: indexCxBRArySCGMeta?.path ?? "/na/es/xpress",
    extended: true,
    meta: indexCxBRArySCGMeta || {},
    alias: indexCxBRArySCGMeta?.alias || [],
    redirect: indexCxBRArySCGMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/xpress/index.vue").then(m => m.default || m)
  },
  {
    name: indexCxBRArySCGMeta?.name ?? "xpress:global___es",
    path: indexCxBRArySCGMeta?.path ?? "/global/es/xpress",
    extended: true,
    meta: indexCxBRArySCGMeta || {},
    alias: indexCxBRArySCGMeta?.alias || [],
    redirect: indexCxBRArySCGMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/xpress/index.vue").then(m => m.default || m)
  },
  {
    name: indexCxBRArySCGMeta?.name ?? "xpress:eu___es",
    path: indexCxBRArySCGMeta?.path ?? "/eu/es/xpress",
    extended: true,
    meta: indexCxBRArySCGMeta || {},
    alias: indexCxBRArySCGMeta?.alias || [],
    redirect: indexCxBRArySCGMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/src/pages/xpress/index.vue").then(m => m.default || m)
  }
]