import revive_payload_client_4sVQNw7RlN from "/home/vsts/work/1/s/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/home/vsts/work/1/s/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _00_easy_auth_client_0tb77qxUeu from "/home/vsts/work/1/s/src/plugins/00.easy-auth.client.ts";
import plugin_vue3_A0OWXRrUgq from "/home/vsts/work/1/s/src/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/vsts/work/1/s/src/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/home/vsts/work/1/s/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/home/vsts/work/1/s/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_wZZ8knA8VI from "/home/vsts/work/1/s/src/node_modules/nuxt-applicationinsights/dist/runtime/app/plugin.client.mjs";
import composition_sLxaNGmlSL from "/home/vsts/work/1/s/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import meta_5aLvpOrsZz from "/home/vsts/work/1/s/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/meta.mjs";
import i18n_yfWm7jX06p from "/home/vsts/work/1/s/src/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/home/vsts/work/1/s/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/home/vsts/work/1/s/src/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import _01_nuxt_mitt_3qqFGEcS8x from "/home/vsts/work/1/s/src/plugins/01.nuxt_mitt.js";
import appInsightExtensions_client_lFLQQc1erH from "/home/vsts/work/1/s/src/plugins/appInsightExtensions.client.js";
import auth_ANExhJHvcR from "/home/vsts/work/1/s/src/plugins/auth.js";
import context_injector_client_k7b0WJjHr3 from "/home/vsts/work/1/s/src/plugins/context_injector.client.js";
import dayjs_FoMn5Zigri from "/home/vsts/work/1/s/src/plugins/dayjs.js";
import deproxy_NPso5RFgQU from "/home/vsts/work/1/s/src/plugins/deproxy.js";
import globalization_wmD5E45aR9 from "/home/vsts/work/1/s/src/plugins/globalization.js";
import gtm_client_KEcT5dOoKs from "/home/vsts/work/1/s/src/plugins/gtm.client.js";
import markdown_iyjKUpGyE7 from "/home/vsts/work/1/s/src/plugins/markdown.js";
import rights_HoQWNWkUSq from "/home/vsts/work/1/s/src/plugins/rights.js";
import sentry_client_GoGQuZo4Et from "/home/vsts/work/1/s/src/plugins/sentry.client.js";
import socialintents_client_HJKfH0gPB1 from "/home/vsts/work/1/s/src/plugins/socialintents.client.js";
import toast_client_437j7GlIrd from "/home/vsts/work/1/s/src/plugins/toast.client.js";
import translation_helper_U03SAmuUVe from "/home/vsts/work/1/s/src/plugins/translation_helper.js";
import uuid_TvkSiJGZZ0 from "/home/vsts/work/1/s/src/plugins/uuid.js";
import vue_cookie_bot_client_8vfZcgBzlc from "/home/vsts/work/1/s/src/plugins/vue-cookie-bot.client.js";
import vue_outside_events_2a7IX3ku4w from "/home/vsts/work/1/s/src/plugins/vue-outside-events.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  _00_easy_auth_client_0tb77qxUeu,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_client_wZZ8knA8VI,
  composition_sLxaNGmlSL,
  meta_5aLvpOrsZz,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  _01_nuxt_mitt_3qqFGEcS8x,
  appInsightExtensions_client_lFLQQc1erH,
  auth_ANExhJHvcR,
  context_injector_client_k7b0WJjHr3,
  dayjs_FoMn5Zigri,
  deproxy_NPso5RFgQU,
  globalization_wmD5E45aR9,
  gtm_client_KEcT5dOoKs,
  markdown_iyjKUpGyE7,
  rights_HoQWNWkUSq,
  sentry_client_GoGQuZo4Et,
  socialintents_client_HJKfH0gPB1,
  toast_client_437j7GlIrd,
  translation_helper_U03SAmuUVe,
  uuid_TvkSiJGZZ0,
  vue_cookie_bot_client_8vfZcgBzlc,
  vue_outside_events_2a7IX3ku4w
]