import * as vueToastifications from 'vue-toastification'
import 'vue-toastification/dist/index.css'

export default defineNuxtPlugin({
  setup(nuxtApp) {
    nuxtApp.vueApp.use(vueToastifications.default, {
      position: 'bottom-right',
      timeout: 5000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      showCloseButtonOnHover: false,
      hideProgressBar: false,
      closeButton: 'button',
      icon: true,
      rtl: false,
      toastClassName: 'easy-toastifications'
    })

    nuxtApp.provide('toast', vueToastifications.useToast())
  }
})
