import {useUserService} from '@/composables/user_service'

export const setTimeZoneSetting = async function(timeZoneUuid) {
  const timeZones = await useUserService().getTimeZones()
  return convertTimezoneToSetting(timeZoneUuid, timeZones)
}

export const splitObject = function(settings) {
  const timezone = settings.find(el => el.key === 'UserProperty:Timezone')
  return {settings: settings.filter(el => el.key !== 'UserProperty:Timezone'), timeZoneUuid: timezone ? timezone.value : null}
}

export const convertTimezoneToSetting = function (timeZoneUuid, timeZones) {
  const timeZonesArray = timeZones?.value ?? []
  return {
    key: 'UserProperty:Timezone',
    name: 'Timezone',
    settingType: 'ListObjects',
    settingUuid: 'timeZoneOption',
    value: timeZoneUuid,
    settingValueKeys: timeZonesArray.map(t => ({value: t.timeZoneUuid, key: t.name})) ?? []
  }
}
