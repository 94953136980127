import {defineStore} from 'pinia'
import {APIS_WITH_MAINTENANCE} from '../assets/js/constants'

const setDefaultMaintenances = () => {
  let obj = {}
  APIS_WITH_MAINTENANCE.forEach(el => obj[el] = false)
  return obj
}

export const useImportantMessagesStore = defineStore('ImportantMessagesModule', {
  state: () => ({
    messages: [],
    maintenances: setDefaultMaintenances()
  }),
  actions: {
    fetchMessages(messages) {
      this.messages = messages
    },
    setMaintenanceStateForApi(maintenanceObject) {
      this.maintenances[maintenanceObject.name] = maintenanceObject.status
    }
  }
})
