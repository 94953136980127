import {
  convertTimezoneToSetting,
  splitObject
} from '~/mixins/timezone_helper'
import {useUserService} from '@/composables/user_service'
import {wipeData} from '~/assets/js/indexed_db_helper'
import jwt_decode from 'jwt-decode'
import {
  BRANDS_MAPPING,
  USER_RIGHTS_REGEXP,
  USER_SETTING_KEYS,
  PREFERRED_MEASUREMENT_IMPERIAL,
  PREFERRED_MEASUREMENT_METRIC,
  REGION_KEYS
} from '~/assets/js/constants'
import {defineStore} from 'pinia'
import {useConfigurationDataStore} from './configuration-data'
import {isTokenExpired} from 'assets/js/authentication'
import {getRegionForSalesArea} from '~/assets/js/utils/region_service'
import {useCustomerService} from '@/composables/customer_service.js'

let customerIsSet = false

export const useUserStore = defineStore('UserModule', {
  persist: {
    storage: persistedState.localStorage,
    paths: ['showInchValues', 'accessToken']
  },
  state: () => ({
    name: '',
    email: '',
    availableCompanies: 0,
    companies: [],
    companyUuid: '',
    userUuid: '',
    selectedCustomer: {customerUuid: 'default'},
    accessToken: '',
    showInchValues: false,
    settings: [],
    timezones: [],
    rights: [],
    excludedBrands: [],
    includedBrandCodes: [],
    userData: {
      userUuid: null
    },
    newCustomer: null,
    companiesLoading: false,
    salesPartnerData: null,
    rightsLoaded: false
  }),
  getters: {
    settingValues() {
      return this.settings.reduce(
        (o, s) => ({
          ...o,
          [s.key.split(':')[0]]: {
            ...o[s.key.split(':')[0]],
            [s.key.split(':')[1]]: s.value
          }
        }),
        {}
      )
    },
    isInternalUser() {
      return this.settings?.find(
        s => s.key === USER_SETTING_KEYS.UserType
      )?.value === 'internal'
    },
    companyNo() {
      let accessToken = {}
      if (this.accessToken) {
        accessToken = jwt_decode(this.accessToken)
      }
      return accessToken?.companyNo
    },
    salesAreaName() {
      let accessToken = {}
      if (this.accessToken) {
        accessToken = jwt_decode(this.accessToken)
      }
      return accessToken?.salesAreaName
    },
    loadedCustomerUuid() {
      let selectedCustomerUuid = this.selectedCustomer?.customerUuid
      if (this.accessToken) {
        let accessToken = {}
        try {
          accessToken = jwt_decode(this.accessToken)
          if (accessToken && accessToken.iss) {
            let tokenparts = accessToken.iss.split('/')
            selectedCustomerUuid = tokenparts && tokenparts.length > 2 ? accessToken.iss.split('/')[3] : null
          }
        } catch (e) {
          // ignore errors
        }
      }

      return selectedCustomerUuid || 'default'
    },
    loggedIn() {
      // eslint-disable-next-line eqeqeq
      return !isTokenExpired(this.accessToken) && this.accessToken !== '' && this.accessToken != null
    }
  },
  actions: {
    setAccessToken(accessToken) {
      this.accessToken = accessToken
    },
    setUserUuid(userUuid) {
      this.userUuid = userUuid
    },
    setUserName(userName) {
      this.name = userName
    },
    setUserEmail(userEmail) {
      this.email = userEmail
    },
    setAvailableCompanies(availableCompanies) {
      this.availableCompanies = availableCompanies
    },
    setCompanies(companies) {
      this.companies = companies
    },
    setRights(rights) {
      this.rights = rights
    },
    setExcludedBrands(excludedBrands) {
      this.excludedBrands = excludedBrands
    },
    setShowInchValues(showInchValues) {
      const {patchUserSettings} = useUserService()
      const update = this.showInchValues !== showInchValues
      this.showInchValues = showInchValues
      let inchSetting = this.settings?.find(s => s.key === USER_SETTING_KEYS.PreferredMeasurement)
      // eslint-disable-next-line eqeqeq
      if (inchSetting != null) {
        this.settings.find(s => s.key === USER_SETTING_KEYS.PreferredMeasurement).value = showInchValues ? PREFERRED_MEASUREMENT_IMPERIAL : PREFERRED_MEASUREMENT_METRIC
      }
      if (update) {
        patchUserSettings(this.userUuid, this.settings.filter(s => s.key === USER_SETTING_KEYS.PreferredMeasurement))
      }
    },
    async setSelectedCustomer(selectedCustomer) {
      if (selectedCustomer) {
        if (customerIsSet) {
          await wipeData()
          this.salesPartnerData = null
        }
        if (this.selectedCustomer.salesOrg !== selectedCustomer.salesOrg) {
          this.setShowInchValues(getRegionForSalesArea(`${selectedCustomer?.salesOrg}-${selectedCustomer?.salesChannel}-${selectedCustomer?.distribution}`) === REGION_KEYS.NA)
        }
        this.selectedCustomer = selectedCustomer
        const configurationDataStore = useConfigurationDataStore()
        await configurationDataStore.fetchConfigurations()
        customerIsSet = true
        this.newCustomer = null
      }
    },
    async fetchUserData() {
      const {getUser, getUserSettings, getUserSettingsValues, getTimeZones} = useUserService()
      let [
        userInfo,
        fetchedSettings,
        fetchedSettingsValues,
        timeZones
      ] = await Promise.all([
        getUser(this.userUuid),
        getUserSettings(this.userUuid),
        getUserSettingsValues(this.userUuid),
        getTimeZones()
      ])

      if (userInfo && fetchedSettings && fetchedSettingsValues) {
        let allSettings = fetchedSettingsValues
          .map(sv => ({
            ...sv,
            ...fetchedSettings.find(s => s.userSettingUuid === sv.settingUuid)
          }))
          .map(s => ({
            key: s.key,
            name: s.name,
            settingType: s.settingType,
            settingUuid: s.settingUuid,
            value: s.userSettingValue,
            settingValueKeys: s.settingValueKeys
          }))
        allSettings.push(
          convertTimezoneToSetting(
            userInfo.timeZone?.timeZoneUuid ?? null,
            timeZones
          )
        )
        this.email = userInfo.email ?? ''
        this.timeZone = timeZones
        this.settings = allSettings
        let inchSetting = this.settings?.find(s => s.key === USER_SETTING_KEYS.PreferredMeasurement)?.value
        if (inchSetting !== null) {
          this.showInchValues = inchSetting === PREFERRED_MEASUREMENT_IMPERIAL
        }
      }
    },
    async updateSettings(updatedSettings) {
      const {patchUserSettings, updateUser} = useUserService()
      const {settings, timeZoneUuid} = splitObject(updatedSettings)
      let storeSettings = this.settings
        .filter(s => settings.some(us => us.settingUuid === s.settingUuid))
        .map(setting => {
          let newSettingValue = settings.find(
            us => us.settingUuid === setting.settingUuid
          ).value
          return {
            value: newSettingValue,
            settingUuid: setting.settingUuid,
            settingType: setting.settingType
          }
        })
      let requests = []
      if (settings.length > 0) {
        requests.push(patchUserSettings(this.userUuid, storeSettings))
      }
      if (timeZoneUuid) {
        requests.push(
          updateUser(this.userUuid, {...this.userData, timeZoneUuid})
        )
      }

      const result =
        requests.length === 0 ||
        (await Promise.all(requests).then(r => r.reduce((b, v) => v && b, true)))
      if (result && settings.length > 0) {
        for (let {settingUuid, value} of updatedSettings) {
          let setting = this.settings.find(s => s.settingUuid === settingUuid)
          if (setting) {
            setting.value = value
            if (setting.key === USER_SETTING_KEYS.PreferredMeasurement) {
              this.showInchValues = setting.value === PREFERRED_MEASUREMENT_IMPERIAL
            }
          }
        }
      }
      return result
    },
    parseUserRights() {
      const realtokenDecoded = jwt_decode(this.accessToken)
      const rights = []
      let foundItem

      while (
        (foundItem = USER_RIGHTS_REGEXP.exec(realtokenDecoded.scope)) !== null
      ) {
        rights.push(foundItem[1])
      }
      this.rights = rights
      this.rightsLoaded = true
    },
    parseExcludedBrands() {
      const brands = jwt_decode(this.accessToken).brands.split(',')
      const excludedBrands = []
      if (!brands.includes(BRANDS_MAPPING.BRAND_FREUDENBERG)) {
        excludedBrands.push('f')
      }
      if (!brands.includes(BRANDS_MAPPING.BRAND_DICHTOMATIK)) {
        excludedBrands.push('d')
      }
      excludedBrands.sort()
      this.excludedBrands = excludedBrands
    },
    parseIncludedBrands() {
      const brands = jwt_decode(this.accessToken)?.brands
      if (brands && brands !== '') {
        const includedBrands = brands.split(',').map((b) => b.substring(0, 1))

        this.includedBrandCodes = includedBrands
      }
    },
    async fetchSalesPartnerData(customerUuid) {
      const {getSalesPartnerData} = useCustomerService()
      if (!this.salesPartnerData) {
        this.salesPartnerData = await getSalesPartnerData(customerUuid)
      }
    }
  }
})
