<template>
  <div>
    <NuxtLayout>
      <AppRefreshIndicator
        v-show="loading"
      />
      <NuxtPage class="page-content" />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import AppRefreshIndicator from '~/components/loading-indicators/app-refresh-indicator.vue'

const nuxtApp = useNuxtApp()
const loading = ref(false)
nuxtApp.hook('page:start', () => {
  loading.value = true
})
nuxtApp.hook('page:finish', () => {
  loading.value = false
})
</script>
<style lang="scss">
.page-content {
  padding-bottom: 12.5rem;
}
</style>
