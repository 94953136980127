import {TRANSLATION_HELPER_CONSTS} from '~/assets/js/constants'

export const translationExpanded = function (value, translationPrefix, defaultValue, i18nFunctions) {
  const {t, te} = i18nFunctions
  // eslint-disable-next-line eqeqeq
  if (te == undefined || t == undefined) {
    return defaultValue || value
  }
  const defaultEntity = TRANSLATION_HELPER_CONSTS.defaultEntity
  if (value && typeof value === 'string') {
    const translationKey = !translationPrefix || (translationPrefix && translationPrefix === '') ?
      value : `${translationPrefix}.${value}`
    if (te(translationKey)) {
      return t(translationKey)
    } else {
      const defaultKey = `${defaultEntity}.${value}`
      return te(defaultKey) ? t(defaultKey) : defaultValue || value
    }
  }
  else if (value && typeof value === 'object' && value.messageKey) {
    const translationKey = !translationPrefix || (translationPrefix && translationPrefix === '') ?
      value.messageKey : `${translationPrefix}.${value.messageKey}`
    const values = value.messageValues && typeof value.messageValues === 'string' ?
      JSON.parse(value.messageValues) : value.messageValues
    if (te(translationKey)) {
      return t(translationKey, values)
    } else {
      const defaultKey = `${defaultEntity}.${value.messageKey}`
      return te(defaultKey) ? t(defaultKey, values) : defaultValue || value.messageKey
    }
  }
  return ''
}

const prefixedTranslation = function (value, translationPrefix, defaultValue) {
  return translationExpanded(value, translationPrefix, defaultValue, {
    t: this?.$?.appContext?.app?.__VUE_I18N__?.global?.t ?? this.$t,
    te: this?.$?.appContext?.app?.__VUE_I18N__?.global?.te ?? this.$te
  })
}

export const translationMixin = {
  methods: {
    $tv: prefixedTranslation
  }
}

const translationDirective = {
  mounted(el, binding) {
    el.innerText = prefixedTranslation.apply(binding.instance, [binding.value, binding.arg])
  },
  updated(el, binding) {
    el.innerText = prefixedTranslation.apply(binding.instance, [binding.value, binding.arg])
  },
  getSSRProps(binding) {
    return {
      innerText: prefixedTranslation.apply(binding.instance, [binding.value, binding.arg])
    }
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  if (!nuxtApp.vueApp.__translation_mixin__) {
    nuxtApp.vueApp.__translation_mixin__ = true
    nuxtApp.vueApp.mixin(translationMixin)
    nuxtApp.vueApp.directive('tv', translationDirective)
  }
})
