export const TOKEN_TYPES = {
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  OPERATOR: 'OPERATOR',
  BRACKET_OPEN: 'BRACKET_OPEN',
  BRACKET_CLOSE: 'BRACKET_CLOSE',
  EOF: 'EOF',
  UNKNOWN: 'UNKNOWN',
  SYMBOL: 'SYMBOL',
  EXPRESSION: 'EXPRESSION'
}

export class Token {
  constructor(value, type) {
    this.type = type
    this.value = value
  }

  toString() {
    return this.value
  }
}
