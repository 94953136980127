//seems to be working fine
import dayjs from 'dayjs'

const formatDate = (date, dateTimeFormat) => {
  let format = (dateTimeFormat || 'MM/DD/YYYY').replace(/d/g, 'D').replace(/y/g, 'Y')
  return dayjs(date).format(format)
}

Date.prototype.toUTCISOString = function() {
  return new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate())).toISOString()
}

Date.prototype.toUTCISOStringIncludeTime = function() {
  return new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate(), this.getHours(), this.getMinutes(), this.getSeconds(), this.getMilliseconds())).toISOString()
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      formatDate: formatDate
    }
  }
})
