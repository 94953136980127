import MarkdownIt from 'markdown-it'

export default defineNuxtPlugin({
  name: 'markdown-plugin',
  setup(nuxtApp) {
    const markdown = new MarkdownIt({
      html: true
    })
    nuxtApp.provide('md', (value) => {
      if (!value) {
        return ''
      }
      return markdown.render(value)
    })
  }
})
