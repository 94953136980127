import axios from '@/services/axios_auth'

export const useConfigurationService = () => {
  const baseurl = useRuntimeConfig().public.BASIC_API_URL  || 'https://apim-enext-stg.azure-api.net'

  const config_axios = axios.create({
    baseURL: useRuntimeConfig().public.CONFIGURATION_API || (baseurl + '/configuration-api/1.0.0')
  })

  const getConfigurationsByScope =  async function (scope, region) {
    const res = await config_axios.get(`/configurations('${scope}')`, {headers: {fstRegion: region}}).catch(() => {return null})
    return res ? res.data : null
  }

  return {
    getConfigurationsByScope
  }
}
