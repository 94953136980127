import {useImportantMessagesStore} from '../store/important-messages'

class MaintenanceError extends Error {
  constructor(message, options) {
    super(message, options)
    this.status = 503
  }
}

let _contextGetter = () => ({})
let _contextGetterInitialized = false

export const maintenanceErrorHandler = err => {
  if (err && err.response && err.response.status && err.response.status === 503) {
    let apiName = err.config && err.config.name
    let store = useImportantMessagesStore(_contextGetter.$pinia)
    if (apiName && store) {
      store.setMaintenanceStateForApi({name: apiName, status: true})
    }
    return Promise.reject(new MaintenanceError('MaintenanceError'))
  } else {
    try {
      if (process && process.sentry) {
        process.sentry.captureException(err, {req: err.request})
      }
    } catch (e) {
      // do nothing
    }
  }
  return Promise.reject(err)
}

export const maintenanceStateHandler = res => {
  let apiName = res.config && res.config.name
  let store = useImportantMessagesStore(_contextGetter.$pinia) // we need to get the CURRENT value from the caller
  if (apiName && store) {
    store.setMaintenanceStateForApi({name: apiName, status: false})
  }
  return Promise.resolve(res)
}

export function initResponseInterceptor(axiosInstance, ctxGetter) {
  if (!_contextGetterInitialized) {
    _contextGetter = ctxGetter
    _contextGetterInitialized = true
  }

  axiosInstance.interceptors.response.use(maintenanceStateHandler, maintenanceErrorHandler)
}
